/* eslint-disable react/prop-types */
import FBComboBox from "components/Elements/FBComboBox";
import FBTextBox from "components/Elements/FBTextBox";
import React from "react";

function ProviderFormPres({
  formValues,
  providerTypes,
  states,
  control,
  isFirstNameRequired,
  isProvinceOrCountryVisible,
  onInputChange
}) {
  const {
    type,
    firstName,
    lastName,
    streetAddress,
    city,
    state,
    zip,
    phoneNumber,
    provinceOrCountry
  } = formValues;

  const lblLastName = isFirstNameRequired ? "Last Name" : "Name";

  return (
    <>
      <FBComboBox
        id="type"
        name="type"
        required
        label="Type of Provider"
        value={type}
        placeholder="Type of Provider"
        items={providerTypes}
        control={control}
        onChange={onInputChange}
      />
      <br />
      <br />
      {isFirstNameRequired && (
        <>
          <FBTextBox
            id="firstName"
            name="firstName"
            required={isFirstNameRequired}
            value={firstName}
            label="First Name"
            placeholder="First Name"
            control={control}
            onChange={onInputChange}

          />
          <br />
          <br />
        </>
      )}
      <FBTextBox
        id="lastName"
        name="lastName"
        required
        value={lastName}
        label={lblLastName}
        placeholder={lblLastName}
        control={control}
        onChange={onInputChange}

      />
      <br />
      <br />
      <FBTextBox
        id="streetAddress"
        name="streetAddress"
        value={streetAddress}
        label="Street Address"
        placeholder="Street Address"
        control={control}
        onChange={onInputChange}

      />
      <br />
      <br />
      <FBTextBox
        id="city"
        name="city"
        required
        value={city}
        label="City"
        placeholder="City"
        control={control}
        onChange={onInputChange}

      />
      <br />
      <br />
      <FBComboBox
        id="state"
        name="state"
        required
        label="State/Province"
        value={state}
        placeholder="State"
        items={states.map((el) => ({
          label: el.abreviation,
          value: el.abreviation,
        }))}
        control={control}
        onChange={onInputChange}

      />
      <br />
      <br />
      {isProvinceOrCountryVisible && (
      <>
        <FBTextBox
          id="provinceOrCountry"
          name="provinceOrCountry"
          value={provinceOrCountry}
          label="Province or Country"
          placeholder="Province or Country"
          control={control}
          onChange={onInputChange}
        />
        <br />
        <br />
      </>
      )}
      <FBTextBox
        id="zip"
        name="zip"
        value={zip}
        label="Zip Code"
        placeholder="Zip Code"
        control={control}
        onChange={onInputChange}
        patternRules={{
          value: /^[a-zA-Z0-9]+$/,
          message: "Invalid zip code",

        }}
      />
      <br />
      <br />
      <FBTextBox
        id="phoneNumber"
        name="phoneNumber"
        value={phoneNumber}
        label="Phone Number"
        placeholder="Phone Number"
        control={control}
        patternRules={{
          // https://www.abstractapi.com/guides/validate-phone-number-javascript
          value: /^(?!(\d)\1{9,})\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/,
          message: "Invalid phone",

        }}
        onChange={onInputChange}

      />
      <br />
    </>
  );
}

export default ProviderFormPres;
