import * as React from "react";
import { useLocation, useNavigate } from "react-router";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TermsAndConditions from "components/Elements/FBTermsAndConditions";
import LifePortraitFooter from "components/Footer/LifePortraitFooter";
import logo from "assets/LifePortrait.png";

export default function TermsAndConditionsPage() {
  const navigate = useNavigate();

  const location = useLocation();

  const goToHome = () => {
    let url = "/";
    const { state } = location;
    const { from } = state || { from: undefined };
    if (from && from === "checklist") url += "Welcome";
    navigate(url);
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark">
        <div className="container-xl">
          <Button className="navbar-brand" onClick={goToHome}>
            <img src={logo} alt="Farm Bureau Logo" />
          </Button>
        </div>
      </nav>
      <div className="container-fluid">
        <div className="container-xl py-3">
          <TermsAndConditions id="termsAndConditions" />
        </div>
        <div className="row justify-content-center px-3 py-3">
          <Button
            variant="outlined"
            onClick={goToHome}
            className="FBMedFormFooterButton"
          >
            <ArrowBackIcon style={{ color: "#143247", fontSize: "18pt" }} />
            &nbsp;Home
          </Button>
        </div>
      </div>
      <LifePortraitFooter id="footer" />
    </>
  );
}
