/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import Modal from "react-bootstrap/Modal";
import AgentCard from "components/Elements/FBAgentCard";
import { getPortraitAgent } from "../state/lifePortraitActions";

function VerifiedPres({ profile, gotoNextPage }) {
  const [showNeedHelp, setShowNeedHelp] = React.useState(false);
  const dispatch = useDispatch();
  const {
    applicationId,
    documentId,
    questionnaireResponse: { agent },
  } = useSelector((state) => state.lifePortrait);
  useEffect(() => {
    if (!agent) {
      dispatch(getPortraitAgent({ applicationId, documentId }));
    }
  }, [applicationId, documentId, dispatch, agent]);
  return (
    <div className="bg-light min-vh-100">
      <div className="container py-3">
        <div>
          <div className="shadow bg-body rounded card-body bg-light">
            <h5 className="d-flex justify-content-center">
              <CheckCircleIcon style={{ fill: "#4caf50" }} />
              &nbsp;You&#39;re verified
            </h5>
            <p className="d-flex justify-content-center card-text py-3">
              Next, is your name and date of birth correct?
            </p>
            <div>
              <div className="d-flex justify-content-center">
                <label htmlFor="lblName" className="form-label px-3">
                  Name:
                </label>
                <label htmlFor="txtName" className="form-label">
                  {profile.firstName} {profile.lastName}
                </label>
              </div>
              <div className="d-flex justify-content-center">
                <label htmlFor="lblDOB" className="form-label px-3">
                  Date of Birth:
                </label>
                <label htmlFor="txtDOB" className="form-label">
                  {profile.birthDate}
                </label>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center py-3">
            <div>
              <Button
                id="txtVerifyCode"
                variant="contained"
                onClick={gotoNextPage}
              >
                <CheckCircleOutlinedIcon />
                &nbsp; Yes, This Is Correct
              </Button>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <div>
              <Button
                id="txtVerifyCode"
                variant=""
                onClick={() => setShowNeedHelp(true)}
              >
                <EditIcon />
                &nbsp; No, I Need To Edit
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showNeedHelp}
        style={{ zIndex: 9999 }}
        onHide={() => setShowNeedHelp(false)}
        centered
      >
        <Modal.Header closeButton>Contact Agent</Modal.Header>
        <Modal.Body>
          <p align="center">NEED HELP?</p>
          <p align="center" className="pb-2">
            Your agent can help you access your Life Portrait and answer
            questions.
          </p>
          <AgentCard id="agentCard" agent={agent} />
        </Modal.Body>
      </Modal>
    </div>
  );
}

VerifiedPres.propTypes = {
  profile: PropTypes.object,
  gotoNextPage: PropTypes.func,
};

export default memo(VerifiedPres);
