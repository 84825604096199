/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { FBControlTypes, idComplete } from "util/genericHelper";
import FBSpinner from "components/Elements/FBSpinner";
import QuestionnaireGroupPres from "../presentation/QuestionnaireGroupPres";
import ActionBarPres from "../presentation/ActionBarPres";

const QuestionnairePageContainer = (props) => {
  const {
    title,
    pageCaption,
    questionsUpdated,
    groupId,
    activePage,
    questions,
    navBtn,
    navGroupBtn,
    navigate,
    responses,
    backBtnClickHandler,
    continueBtnClickHandler,
    setValueInReduxResponses,
    setIsReady,
    isReady,
  } = props;

  const {
    control,
    reset,
    watch,
    setValue,
    getValues,
    formState: { isDirty, errors },
  } = useForm({
    mode: "all",
  });

  const formValues = watch();

  useEffect(() => {
    setIsReady(false);
  }, []);

  useEffect(() => {
    reset(responses);
    setIsReady(true);
  }, [questionsUpdated, reset, responses]);

  const evalFormIsValidFlag = () => {
    // const formValues = getValues();
    function checkForParentVisibility(showLogic, showLogicConditionFlag) {
      let lshowLogicConditionFlag = showLogicConditionFlag;
      if (showLogic) {
        // Check if showLogic condition is satisifed to check
        // if current question is required or not so we evaluate showLogicConditionFlag
        if (
          showLogic.operator === "Single" &&
          Array.isArray(showLogic.condition)
        ) {
          const { id: parentId, triggeringValue } = showLogic.condition[0];
          let parentResponseVal = formValues[parentId];
          const parentQuestion = questions.find((q) => q.id === parentId);
          if (parentQuestion?.input?.dataType === FBControlTypes.Checkbox) {
            // For checkbox cntrl we have to check if value === "Yes" or true
            parentResponseVal =
              formValues[parentId] === "Yes" || formValues[parentId] === true;
          }
          lshowLogicConditionFlag = parentResponseVal === triggeringValue;
          if (lshowLogicConditionFlag && parentQuestion.showLogic) {
            lshowLogicConditionFlag = checkForParentVisibility(
              parentQuestion.showLogic,
              lshowLogicConditionFlag
            );
          }
          return lshowLogicConditionFlag;
        }

        if (showLogic.operator === "Or" && Array.isArray(showLogic.condition)) {
          // eslint-disable-next-line no-debugger
          const conditions = showLogic.condition.filter((condition) => {
            const { id: parentId, triggeringValue } = condition;
            let parentResponseVal = formValues[parentId];
            const parentQuestion = questions.find((q) => q.id === parentId);
            if (parentQuestion?.input?.dataType === FBControlTypes.Checkbox) {
              // For checkbox cntrl we have to check if value === "Yes" or true
              parentResponseVal =
                formValues[parentId] === "Yes" || formValues[parentId] === true;
            }
            return parentResponseVal === triggeringValue;
          });
          lshowLogicConditionFlag = conditions.length > 0;
          if (lshowLogicConditionFlag && conditions.length > 0) {
            const parentQuestions = questions.find(
              (q) => q.id === conditions[0].id
            );
            if (parentQuestions.length > 0 && parentQuestions[0].showLogic) {
              lshowLogicConditionFlag = checkForParentVisibility(
                parentQuestions[0].showLogic,
                lshowLogicConditionFlag
              );
            }
          }
          return lshowLogicConditionFlag;
        }
      }
      return lshowLogicConditionFlag;
    }
    const questionIsValid = !questions.some((el, idx) => {
      const { id, input, showLogic } = el;
      const { required, dataType, options } = input;

      if (dataType === FBControlTypes.Caption) return false;

      let showLogicConditionFlag = false;
      if (showLogic) {
        showLogicConditionFlag = checkForParentVisibility(
          showLogic,
          showLogicConditionFlag
        );
      }

      // Fix for the Primary Care Info section.
      if (
        dataType === FBControlTypes.Checkbox &&
        id.endsWith("-PC-1_NoProvider")
      ) {
        return false;
      }

      if (dataType === FBControlTypes.DateMonthYear) {
        const error = errors[id];
        if (error && error?.type === "validate") {
          return true;
        }
      }

      // Following if block is to check for the checkboxes group
      // and exits if it finds that none of the value are checked and continues otherwise
      if (
        dataType === FBControlTypes.Checkbox &&
        (!showLogic || showLogicConditionFlag)
      ) {
        const { checkBoxGroup } = options || {};
        // below stmt check if any checkbox in the same CheckboxGroup is checked
        const retVal = questions
          .filter(
            (itr) =>
              itr?.input?.dataType === FBControlTypes.Checkbox &&
              itr?.input?.options?.checkBoxGroup === checkBoxGroup
          )
          .some(
            (itr) => formValues[itr.id] === "Yes" || formValues[itr.id] === true
          );
        // if this flag is FALSE meaning >> none of the checkboxes are checked, so exit the loop
        if (!retVal) {
          return true;
        }
      }
      // Exception validation for non-zero Weight in Social and Beha grp
      if (
        dataType === FBControlTypes.Numeric &&
        id.endsWith("_PhyDesc-Weight") &&
        formValues[id]
      ) {
        // eslint-disable-next-line no-restricted-globals
        if (isNaN(formValues[id]) || Number(formValues[id]) === 0) return true;
      }

      if (
        (!showLogic || showLogicConditionFlag) &&
        required &&
        [
          FBControlTypes.HeartAttacks,
          FBControlTypes.Medication,
          FBControlTypes.MultiDateDesc,
          FBControlTypes.MultiAbnormalPaps,
          FBControlTypes.Height,
        ].includes(dataType)
      ) {
        return !(formValues[`${id}${idComplete}`] === true);
      }

      // Following condition evaluates the form state by looking at
      // If showLogic object is present then check whether parent question condition is satisified
      // If question is required then response(value) exists or not.
      return (
        required &&
        ((!showLogic &&
          (formValues[id] === undefined ||
            formValues[id] === null ||
            formValues[id] === "" ||
            (dataType === FBControlTypes.Provider &&
              (formValues[id] === "addNewProvider" ||
                formValues[id] === "manageProvider")))) ||
          (showLogic &&
            showLogicConditionFlag &&
            (formValues[id] === undefined ||
              formValues[id] === null ||
              formValues[id] === "" ||
              (dataType === FBControlTypes.Provider &&
                (formValues[id] === "addNewProvider" ||
                  formValues[id] === "manageProvider")))))
      );
    });

    return questionIsValid;
  };

  const formStateIsInvalid = evalFormIsValidFlag();

  if (!isReady) return <FBSpinner />;

  return (
    <div className="bgSecondaryFBgrey">
      <div className="container-fluid min-vh-100 py-2">
        <form>
          <div className="shadow-sm p-2 mb-2 bg-white rounded">
            <h3>{title}</h3>
            <h5>{pageCaption}</h5>
            {groupId && questions.length ? (
              <QuestionnaireGroupPres
                forceRender={questionsUpdated}
                groupId={groupId}
                pageId={activePage}
                questions={questions}
                control={control}
                tunnelToParent={{
                  setValueInReduxResponses,
                  getValues,
                  setValue,
                }}
              />
            ) : null}
          </div>
          <div className="shadow-sm p-2 mb-2 bg-white rounded">
            <ActionBarPres
              navBtn={navBtn}
              navGroupBtn={navGroupBtn}
              navigate={navigate}
              backBtnClickHandler={backBtnClickHandler}
              getValues={getValues}
              formStateIsInvalid={formStateIsInvalid}
              continueBtnClickHandler={continueBtnClickHandler}
              isDirty={isDirty}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default QuestionnairePageContainer;
