import { getGroupResponseStatus } from "./getGroupResponseStatus";

const createResponseWithDetails = (responseMap) => {
  const responseDetails = [];
  let currentResponse;
  responseMap.forEach((valueMap, id) => {
    valueMap.forEach((value, key) => {
      if (key === "answer") {
        currentResponse = {
          id,
          answer: value,
        };
        responseDetails.push(currentResponse);
      } else if (key === "details") {
        currentResponse.details = createResponseWithDetails(value);
      }
    });
  });
  return responseDetails;
};

export const getGroupsResponseFromFormVals = (formValues) => {
  const answerMap = new Map();
  Object.entries(formValues).forEach(([key, value]) => {
    const ids = key.split("_");
    let currentMap = answerMap;
    ids.forEach((id, index) => {
      let idMap = currentMap.get(id);
      if (!idMap) {
        idMap = new Map();
        currentMap.set(id, idMap);
      }
      currentMap = idMap;

      if (index === ids.length - 1) {
        // Last id with answer
        currentMap.set("answer", value);
      } else if (index > 0) {
        let detailsMap = currentMap.get("details");
        if (!detailsMap) {
          detailsMap = new Map();
          currentMap.set("details", detailsMap);
        }
        currentMap = detailsMap;
      }
    });
  });

  const groupsResponse = [];
  answerMap.forEach((groupResponseMap, groupId) => {
    // key is groupId, value is details Map
    const response = createResponseWithDetails(groupResponseMap);
    groupsResponse.push({ id: groupId, details: response });
  });
  return groupsResponse;
};

export const getGroupResponseFromFormVals = (
  questionnaireMetadata,
  groupId,
  formValues
) => {
  const answerMap = new Map();
  Object.entries(formValues)
    .filter(([key]) => key.startsWith(groupId))
    .sort()
    .forEach(([key, value]) => {
      const ids = key.split("_");

      let currentMap = answerMap;
      ids.forEach((id, index) => {
        let idMap = currentMap.get(id);
        if (!idMap) {
          idMap = new Map();
          currentMap.set(id, idMap);
        }
        currentMap = idMap;

        if (index === ids.length - 1) {
          // Last id with answer
          currentMap.set("answer", value);
        } else if (index > 0) {
          let detailsMap = currentMap.get("details");
          if (!detailsMap) {
            detailsMap = new Map();
            currentMap.set("details", detailsMap);
          }
          currentMap = detailsMap;
        }
      });
    });
  const details = createResponseWithDetails(answerMap.get(groupId));

  const response = { id: groupId, details };
  response.status = getGroupResponseStatus(questionnaireMetadata, response);
  return response;
};
