/* eslint-disable dot-notation */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import { Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { removeDelimiters } from "util/genericHelper";
import AddMedicationPres from "../presentation/AddMedicationPres";
import MedicationListPres from "../presentation/MedicationListPres";
import {
  propNames,
  transformInput,
  transformOutput,
} from "../../../util/medicationHelper";

let renderCount = 0;
const FBMedication = ({
  control: parentFormControl,
  label,
  id,
  required,
  defaultValue,
  value,
  disabled,
  error,
  tunnelToParent: { setValue },
  onChange,
}) => {
  renderCount += 1;

  const { control, watch, reset } = useForm({
    mode: "all",
  });

  const formValues = watch();

  const isValid = !Object.keys(formValues).some((el) => {
    if (
      formValues[el] === undefined ||
      formValues[el] === null ||
      formValues[el] === ""
    ) {
      return true;
    }
    return false;
  });

  const medicationMenuButtons = {
    addNewMedication: "Add New Medication",
    modifyMedication: "Modify Medication",
    manageMedications: "Manage Medications",
  };
  const menuOptionObj = {
    menuLabel: medicationMenuButtons.addNewMedication,
    showMedCard: false,
  };
  const [menuOption, setMenuOption] = useState(menuOptionObj);
  const [medications, setMedications] = useState([]);

  useEffect(() => {
    if (value) {
      const meds = transformInput(id, value, uuidv4, setValue);
      setMedications(meds);
    }
  }, [id, value, setValue]);

  const showMedCardDisplay = () => {
    setMenuOption({ ...menuOption, showMedCard: true });
    reset({
      [`${id}-${propNames.medication}`]: undefined,
      [`${id}-${propNames.current}`]: undefined,
    });
  };
  const setUpdatedrecord = (updatedMedications) => {
    setMedications(updatedMedications);
    const transformedMedicationsforParent = transformOutput(
      id,
      updatedMedications
    );
    if (setValue) {
      setValue(`${id}-complete`, updatedMedications.length > 0);
      setValue(id, transformedMedicationsforParent, { shouldDirty: true });
    }
    if (onChange) onChange(transformedMedicationsforParent);
  };

  const hideMedCard = () => {
    setMenuOption({
      ...menuOption,
      showMedCard: false,
      menuLabel: medicationMenuButtons.addNewMedication,
      defaultValues: undefined,
    });
  };

  const addMedicationHandler = (evt) => {
    let record = {};
    let updatedMedications = [...medications];
    const medicationKey = `${id}-${propNames.medication}`;
    const currentKey = `${id}-${propNames.current}`;
    const medicationValue = removeDelimiters(
      formValues[`${id}-${propNames.medication}`]
    );
    const currentValue = removeDelimiters(
      formValues[`${id}-${propNames.current}`]
    );
    if (menuOption.menuLabel === medicationMenuButtons.addNewMedication) {
      record = {
        guid: uuidv4(),
        [medicationKey]: medicationValue,
        [currentKey]: currentValue,
      };
      updatedMedications = [...medications, record];
    }

    if (menuOption.menuLabel === medicationMenuButtons.modifyMedication) {
      record = {
        ...menuOption.defaultValues,
        [medicationKey]: medicationValue,
        [currentKey]: currentValue,
      };

      const objIndex = updatedMedications.findIndex((obj) => {
        return obj.guid === record.guid;
      });

      updatedMedications[objIndex] = record;
    }

    setUpdatedrecord(updatedMedications);
    hideMedCard();
  };

  const handleProviderItemClick = (evt, record, action) => {
    // console.log(" handleProviderItemClick >>> ", evt, record);

    const objIndex = medications.findIndex((obj) => {
      return obj.guid === record.guid;
    });

    if (action === "edit") {
      setMenuOption({
        ...menuOption,
        menuLabel: medicationMenuButtons.modifyMedication,
        showMedCard: true,
        defaultValues: medications[objIndex],
      });

      reset(medications[objIndex]);
    }

    if (action === "delete") {
      const { guid } = medications[objIndex];
      const updatedMedication = [
        ...medications.filter((el) => el.guid !== guid),
      ];
      setUpdatedrecord(updatedMedication);
    }
  };

  // Function to parse the value - format Med1~1|Med2~0|Med3~0
  // Prepare the structure
  return (
    <Box
      sx={{
        width: "fit-content",
        border: 1,
        marginTop: 1,
        padding: 1,
        borderColor: "lightslategrey",
        borderRadius: 2,
      }}
    >
      {/* {`Rendercount:${renderCount}`} */}
      <Typography color="text.secondary" variant="body2" gutterBottom>
        Add each medication one at a time below
      </Typography>
      {medications && medications.length > 0 && (
        <MedicationListPres
          id={id}
          medications={medications}
          menuOption={menuOption}
          handleProviderItemClick={handleProviderItemClick}
        />
      )}
      {menuOption.showMedCard && (
        <AddMedicationPres
          id={id}
          addMedicationHandler={addMedicationHandler}
          menuOption={menuOption}
          medicationMenuButtons={medicationMenuButtons}
          isValid={isValid}
          control={control}
          hideMedCard={hideMedCard}
          formValues={formValues}
        />
      )}
      {!menuOption.showMedCard && (
        <Button onClick={showMedCardDisplay} variant="outlined">
          {medications?.length > 0
            ? "Add Another Medication"
            : "Add Medication"}
        </Button>
      )}
    </Box>
  );
};

export default FBMedication;
