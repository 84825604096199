/* eslint-disable react/prop-types */
import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { Box } from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
// import ListItemIcon from "@mui/material/ListItemIcon";
// import ListItemText from "@mui/material/ListItemText";
// import Checkbox from "@mui/material/Checkbox";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";

const ProviderListPres = ({ providers, handleProviderItemClick }) => {
  return (
    <Box>
      <List sx={{ width: "100%", maxWidth: 360, bgcolor: "white" }}>
        {providers.map((physician) => {
          const { id, firstName, lastName, type } = physician;
          return (
            <ListItem key={id} disablePadding>
              <ListItemButton>
                <em>{`${type} ${firstName || ""} ${lastName}`}</em>
              </ListItemButton>
              <IconButton
                onClick={(event) => handleProviderItemClick(event, id)}
              >
                <EditIcon />
              </IconButton>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};

export default ProviderListPres;
