/* eslint-disable no-nested-ternary */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import Button from "@mui/material/Button";
import TermsAndConditions from "components/Elements/FBTermsAndConditions";
import AgentCard from "components/Elements/FBAgentCard";
import { Link } from "react-router-dom";
import { getPortraitAgent } from "features/LifePortrait/state/lifePortraitActions";

export default function DefaultFooter(props) {
  const { id, state: navState } = props;
  const [showNeedHelp, setShowNeedHelp] = React.useState(false);
  const [showTermsAndConditions, setShowTermsAndConditions] =
    React.useState(false);
  const dispatch = useDispatch();
  const {
    applicationId,
    documentId,
    questionnaireResponse: { agent },
  } = useSelector((state) => state.lifePortrait);
  useEffect(() => {
    if (!agent) {
      dispatch(getPortraitAgent({ applicationId, documentId }));
    }
  }, [applicationId, documentId, agent, dispatch]);
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const getTermsAndCondition = () => {
    if (window.location.pathname === "/TermsAndConditionsPage") return null;

    return navState ? (
      <li className="ms-3" style={{ paddingTop: "6px", fontSize: "16px" }}>
        <Link
          to="/TermsAndConditionsPage"
          state={{ from: "checklist" }}
          className="text-white text-decoration-none"
        >
          Terms and Conditions
        </Link>
      </li>
    ) : (
      <li className="ms-3">
        <Button
          id="btnTermsConditions"
          variant="text"
          onClick={() => openInNewTab("/TermsAndConditionsPage")}
          className="text-white text-decoration-none"
        >
          Terms and Conditions
        </Button>
      </li>
    );
  };

  return (
    <div className="container-fluid bgPrimaryDarkBlueFB py-1">
      <footer id={id} name={id}>
        <ul className="d-flex justify-content-center pt-1">
          <li className="ms-3">
            <Button
              id="btnNeedHelp"
              variant="text"
              onClick={() => setShowNeedHelp(true)}
              className="text-white text-decoration-none"
            >
              Need Help?
            </Button>
          </li>
          <li className="ms-3">
            <Button
              id="btnPrivacyPolicy"
              variant="text"
              onClick={() => {
                openInNewTab("https://www.fbitn.com/privacy-policy");
              }}
              className="text-white text-decoration-none"
            >
              Privacy Policy
            </Button>
          </li>
          {getTermsAndCondition()}
        </ul>
      </footer>
      <Modal
        show={showNeedHelp}
        style={{ zIndex: 9999 }}
        onHide={() => setShowNeedHelp(false)}
        centered
      >
        <Modal.Header closeButton>Contact Agent</Modal.Header>
        <Modal.Body>
          <p align="center">NEED HELP?</p>
          <p align="center" className="pb-2">
            Your agent can help you access your Life Portrait and answer
            questions.
          </p>
          <AgentCard id="agentCard" agent={agent} />
        </Modal.Body>
      </Modal>
      <Modal
        show={showTermsAndConditions}
        onHide={() => setShowTermsAndConditions(false)}
        dialogClassName="modal-90w"
        aria-labelledby="modalTermsAndConditions"
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title id="modalTermsAndConditions">
            <h5>Terms and Conditions </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TermsAndConditions id="termsAndConditions" />
        </Modal.Body>
      </Modal>
    </div>
  );
}

DefaultFooter.propTypes = {
  id: PropTypes.string.isRequired,
  state: PropTypes.oneOfType([PropTypes.object, undefined]),
};
