import React from "react";
import PropTypes from "prop-types";
import FBYesNoToggleButton from "components/Elements/FBYesNoToggleButton";
import FBCheckbox from "components/Elements/FBCheckbox";

const SiblingExistsPresentation = ({
  control: parentFormControl,
  member,
  resetWhenUnknownInfoHandler,
  formValues,
}) => {
  const { familyMember } = member;
  const siblingExistsUnknownInfo =
    formValues[`${familyMember}_doNotHaveSiblingsInfo`] || false;
  return (
    <div className="shadow-sm p-3 mb-3 bg-white rounded">
      <h5 className="d-flex">Biological siblings?</h5>
      <div className="d-flex">
        <FBYesNoToggleButton
          control={parentFormControl}
          id={`${familyMember}_haveSiblings`}
          controlWidth="130px"
          onChange={(e) => {
            if (e) {
              resetWhenUnknownInfoHandler(
                `${familyMember}_doNotHaveSiblingsInfo`,
                false
              );
            }
          }}
        />
      </div>
      <div className="d-flex">
        <FBCheckbox
          control={parentFormControl}
          id={`${familyMember}_doNotHaveSiblingsInfo`}
          value={siblingExistsUnknownInfo}
          label="Do not have this information"
          controlWidth="260px"
          onChange={(e) => {
            if (e) {
              resetWhenUnknownInfoHandler(`${familyMember}_haveSiblings`, "");
            }
          }}
        />
      </div>
    </div>
  );
};

SiblingExistsPresentation.propTypes = {
  control: PropTypes.object.isRequired,
  member: PropTypes.object.isRequired,
  resetWhenUnknownInfoHandler: PropTypes.func,
  formValues: PropTypes.oneOfType([PropTypes.object, undefined]),
};

export default SiblingExistsPresentation;
