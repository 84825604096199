/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Portal, Stack } from "@mui/material";
import FBTextBox from "components/Elements/FBTextBox";
import FBDatePicker from "components/Elements/FBDatePicker";
import AgentCard from "components/Elements/FBAgentCard";

function EOEditDetails({
  dialogOpen,
  handleClose,
  formValues,
  control,
  editProfile,
  agent,
}) {
  return (
    <Portal>
      <form>
        <Dialog
          open={dialogOpen}
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">
            <Stack direction="row" spacing={2}>
              Edit Details
              {handleClose ? (
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              ) : null}
            </Stack>
          </DialogTitle>
          <DialogContent dividers>
            <Box sx={{ textAlign: "center" }}>
              <div className="card-body">
                <form>
                  <div className="form-group row mb-1">
                    <div className="col-md-6 py-1">
                      <FBTextBox
                        id="prefix"
                        label="Client Prefix"
                        value={formValues.prefix}
                        control={control}
                      />
                    </div>
                    <div className="col-md-6 py-1">
                      <FBTextBox
                        id="suffix"
                        label="Client Suffix"
                        value={formValues.suffix}
                        control={control}
                      />
                    </div>
                  </div>
                  <div className="form-group row mb-1">
                    <div className="col-md-6 py-1">
                      <FBTextBox
                        id="firstName"
                        label="Client First Name"
                        value={formValues.firstName}
                        control={control}
                        required
                      />
                    </div>
                    <div className="col-md-6 py-1">
                      <FBTextBox
                        id="middleName"
                        label="Client Middle Name"
                        value={formValues.middleName}
                        control={control}
                      />
                    </div>
                  </div>
                  <div className="form-group row mb-1">
                    <div className="col-md-6 py-1">
                      <FBTextBox
                        id="lastName"
                        label="Client Last Name"
                        value={formValues.lastName}
                        control={control}
                        required
                      />
                    </div>
                    <div className="col-md-6 py-1">
                      <FBDatePicker
                        id="birthDate"
                        label="DOB"
                        value={formValues.birthDate}
                        control={control}
                        required
                        sx={{ minWidth: 250 }}
                      />
                    </div>
                  </div>
                </form>
                <div className="form-group row mb-1">
                  <div className="col-md py-1">
                    Your agent will be notified of your requested update. You
                    will be contacted if anything further is required due to
                    this request.
                  </div>
                  <p className="text-center">
                    <AgentCard id="agentCard" agent={agent} />
                  </p>
                </div>
              </div>
            </Box>
          </DialogContent>

          <DialogActions>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={editProfile}
              disabled={
                formValues.firstName === null ||
                formValues.lastName === null ||
                formValues.birthDate === null
              }
            >
              Update
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </Portal>
  );
}

export default EOEditDetails;
