import { configureStore } from "@reduxjs/toolkit";
import { responsiveStoreEnhancer } from "redux-responsive";
// import { restoreStateFromLocalStorageToRedux } from "util/localStorageUtil";
import rootReducer from "./rootReducer";

function configureAppStore(preloadedState) {
  // const persistedState = restoreStateFromLocalStorageToRedux();
  return configureStore({
    reducer: rootReducer,
    // preloadedState: { ...preloadedState, ...persistedState },
    preloadedState: { ...preloadedState },
    enhancers: [responsiveStoreEnhancer],
  });
}

const store = configureAppStore({});

export default store;
