import APP_DATA_API_URL from "config";

function getAPIUrls(host = "", paths = {}) {
  return Object.entries(paths).reduce((acc, [key, val]) => {
    return { ...acc, [key]: host + val };
  }, {});
}

const apiEndPoints = {
  PROFILE_PHONEREF: `/portraits/profile-phoneref?a=:appId&d=:docId`,
  SEND_PASSCODE: `/portraits/send-passcode`,
  VERIFY_PASSCODE: `/portraits/validate-passcode`,
  PORTRAIT: `/portraits/app/:appId/doc/:docId`,
  PORTRAIT_SUMMARY: `/portraits/app/:appId/doc/:docId/summary`,
  PORTRAIT_REVIEW: `/portraits/app/:appId/doc/:docId/review`,
  PORTRAIT_STATUS: `/portraits/app/:appId/doc/:docId/status`,
  PORTRAIT_ARCHIVE: `/portraits/app/:appId/doc/:docId/archive`,
  PORTRAIT_SENDEMAIL_AGENT_CLINEWPHONE: `/portraits/app/:appId/doc/:docId/clients/informAgent`,
  PORTRAIT_MODIFY_INSURED: `/portraits/app/:appId/doc/:docId/clients/modifyinsured`,
  PORTRAIT_ANALYTICS: `/portraits/app/:appId/doc/:docId/analytics`,
  PORTRAIT_LAST_VISIT_TIME: `/portraits/app/:appId/doc/:docId/lastVisitTime`,
  PORTRAIT_GROUP: `/portraits/app/:appId/doc/:docId/group`,
  PORTRAIT_PROVIDER: `/portraits/app/:appId/doc/:docId/providers/:providerId`,
  PORTRAIT_SAVE_FAMILY_MEDICAL_HISTROY: `/portraits/app/:appId/doc/:docId/familyhistory`,
  EXAMONE_USER_TOKEN: `/examone/token?d=:docId`,
  PORTRAIT_SEND_PORTRAIT_ENVELOPE: `/portraits/app/:appId/doc/:docId/docusign/envelope`,
  PORTRAIT_COMPLETE: `/portraits/app/:appId/doc/:docId/docusign/complete`,
  AGENT_DASHBOARD_PORTRAIT_SEARCH: `/portraits/search`,
  AGENT_DASHBOARD_PORTRAIT_COUNTS: `/portraits/dashboard/counts`,
  AGENT_DASHBOARD_PORTRAIT_GROUP_SUMMARY: `/portraits/app/:appId/doc/:docId/group/summary`,
  AGENT_DASHBOARD_PORTRAIT_CLIENT_GET: `/portraits/app/:appId/doc/:docId/clients`,
  AGENT_DASHBOARD_PORTRAIT_CLIENT_UPDATE: `/portraits/app/:appId/doc/:docId/clients`,
  AGENT_DASHBOARD_PORTRAIT_TRANSFER: `/portraits/app/:appId/doc/:docId/transfer`,
  AGENT_DASHBOARD_PORTRAIT_TRANSFER_EMAIL_TO_CLIENT: `/portraits/app/:appId/doc/:docId/clientemail`,
  AGENT_DASHBOARD_PORTRAIT_CREATE_ENVELOPE: `/portraits/app/:appId/doc/:docId/docusign/envelope/agent`,
  AGENT_DASHBOARD_PORTRAIT_SIGNATURE_STATUS: `/portraits/app/:appId/doc/:docId/docusign/envelope/agent/status`,
  AGENT_DASHBOARD_PORTRAIT_AGENT_SIGNING_URL: `/portraits/app/:appId/doc/:docId/docusign/envelope/agent/url`,
  AGENT_DASHBOARD_PORTRAIT_ENVELOPE_UPDRECIPIENT: `/portraits/app/:appId/doc/:docId/docusign/envelope/agent/updaterecipient`,
  AGENT_DASHBOARD_PORTRAIT_ENVELOPE_VOID: `/portraits/app/:appId/doc/:docId/docusign/envelope/void`,
  AGENT_DASHBOARD_PORTRAIT_PRINTPDF: `/portraits/app/:appId/doc/:docId/print-pdf`,
  AGENT_DASHBOARD_PORTRAIT_PERMISSIONS: '/portraits/DashboardPermissions'
};

const host = APP_DATA_API_URL || process.env.REACT_APP_DATA_API_URL;
const serviceUrls = getAPIUrls(host, apiEndPoints);

export default serviceUrls;
