import React, { memo } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Modal from "react-bootstrap/Modal";
import AgentCard from "components/Elements/FBAgentCard";
import { Box, DialogActions, DialogContent, Divider } from "@mui/material";
import FBTextBox from "components/Elements/FBTextBox";

function GetStartedPres({
  phoneNum,
  sendCodeHandler,
  agent,
  status,
  setShowNeedHelp,
  showNeedHelp,
  newPhone,
  control,
  sendEmailtoAgentHandler,
  isValid,
}) {
  const showReturningUserVerbage = status !== "Initial";
  const isPortraitInactive = ['Incomplete', 'Declined', 'Complete'].includes(status);
  return (
    <div className="container-xl min-vh-100">
      <main>
        <div className="FBfamilyImage">
          <form className="d-none d-md-block pt-1">
            <div className="row row-cols-1 row-cols-md-1 row-cols-md-1 g-3 px-5 py-3">
              <p className="h4">
                You&#39;re one step closer
                <br />
                to protecting the ones you love most.
              </p>
              {showReturningUserVerbage ? (
                <p className="h6">
                  To continue your Life Portrait, we will text a<br />
                  code to the number we have for your
                  <br />
                  application {phoneNum}.
                </p>
              ) : (
                <p className="h6">
                  To access your Life Portrait, we will text a<br />
                  code to the number we have for your Life Insurance
                  <br />
                  application {phoneNum}.
                </p>
              )}
              <p>
                <Button
                  id="sms"
                  onClick={sendCodeHandler}
                  variant="contained"
                  sx={{ marginRight: "5px" }}
                >
                  Send Me A Code
                </Button>
                <Button id="call" onClick={sendCodeHandler} variant="contained">
                  Call Me with a code
                </Button>
              </p>
              <p>
                <Button onClick={(e) => setShowNeedHelp(e, true)}>
                  I don&#39;t have access to this number
                </Button>
              </p>
            </div>
          </form>
        </div>
      </main>
      <div>
        <div className="row row-cols-1 row-cols-md-2 row-cols-md-2 g-3 px-2">
          <div className="col d-md-none bgSecondaryFBgrey">
            <div className="card-noborder">
              <div className="card-body">
                <h5 className="card-title">
                  You&#39;re one step closer to protecting the ones you love
                  most.
                </h5>
                {showReturningUserVerbage ? (
                  <p className="card-text">
                    To continue your Life Portrait, we will text a code to the
                    number we have for your Life Insurance application{" "}
                    {phoneNum}.
                  </p>
                ) : (
                  <p className="card-text">
                    To access your Life Portrait, we will text a code to the
                    number we have for your Life Insurance application{" "}
                    {phoneNum}.
                  </p>
                )}

                <p className="text-center">
                  <Button
                    id="sms"
                    onClick={sendCodeHandler}
                    variant="contained"
                    sx={{ marginRight: "5px", marginBottom: "5px" }}
                  >
                    Send Me A Code
                  </Button>
                  <Button
                    id="call"
                    onClick={sendCodeHandler}
                    variant="contained"
                  >
                    Call Me with a code
                  </Button>
                </p>
                <p className="text-center">
                  <Button onClick={(e) => setShowNeedHelp(e, true)}>
                    I don&#39;t have access to this number
                  </Button>
                </p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card-noborder">
              <div className="card-body">
                <h5 className="card-title">What is Life Portrait?</h5>
                <p className="card-text">
                  A Life Portrait is the next step in your application for Life
                  Insurance through Farm Bureau Insurance.
                </p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card-noborder">
              <div className="card-body">
                <h5 className="card-title">
                  What do I need to complete my Life Portrait?
                </h5>
                <span className="card-text">
                  <ul>
                    <li>A list of current medications that you take.</li>
                    <li>Contact information for doctors you have seen.</li>
                  </ul>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showNeedHelp}
        style={{ zIndex: 9999 }}
        onHide={(e) => setShowNeedHelp(e, false)}
        centered
      >
        <Modal.Header closeButton>Contact Agent</Modal.Header>
        <Modal.Body>
          <DialogContent>
            <Box
              alignItems="center"
              justifyContent="center"
              sx={{ textAlign: "center" }}
            >
              <FBTextBox
                id="newPhone"
                name="newPhone"
                value={newPhone}
                label="Please provide a new phone number"
                placeholder="Phone Number"
                control={control}
                controlWidth="300px"
                patternRules={{
                  // https://www.abstractapi.com/guides/validate-phone-number-javascript
                  value:
                    /^(?!(\d)\1{9,})\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/,
                  message: "Invalid phone",
                }}
                disabled={isPortraitInactive}
              />
              <p align="center" className="pt-2 pb-2">
                { isPortraitInactive
                  ? (
                    <>
                      This life portrait is no longer in an active status.
                      Contact your agent with any questions you may have.
                    </>
                  )
                  : (
                    <>
                      Your agent will be notified to update your phone number.
                      Once the update is complete, you will receive a new link for your
                      Life Portrait. Please monitor your email for this notification.
                    </>
                  )}
              </p>
              <AgentCard id="agentCard" agent={agent} />
            </Box>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Box width={300} sx={{ textAlign: "center" }}>
              <Button
                onClick={sendEmailtoAgentHandler}
                variant="outlined"
                disabled={!isValid}
              >
                Ok
              </Button>
              &nbsp;
              <Button
                onClick={(e) => setShowNeedHelp(e, false)}
                variant="outlined"
              >
                Cancel
              </Button>
            </Box>
          </DialogActions>
        </Modal.Body>
      </Modal>
    </div>
  );
}

GetStartedPres.propTypes = {
  phoneNum: PropTypes.string,
  sendCodeHandler: PropTypes.func,
  agent: PropTypes.object,
  status: PropTypes.string,
  setShowNeedHelp: PropTypes.func,
  showNeedHelp: PropTypes.bool,
  control: PropTypes.object,
  newPhone: PropTypes.string,
  sendEmailtoAgentHandler: PropTypes.func,
  isValid: PropTypes.bool,
};

export default memo(GetStartedPres);
