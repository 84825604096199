import React, { useState, useRef } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import LifePortraitHeader from "components/Header/LifePortraitHeader";
import LifePortraitFooter from "components/Footer/LifePortraitFooter";
import TermsAndConditionsPres from "../presentation/TermsAndConditionsPres";

export default function TermsAndConditions() {
  const [disableContinue, setDisableContinue] = useState(true);
  const [disableChkAcceptTC, setDisableChkAcceptTC] = useState(true);
  const { firstName } = useSelector((state) => state.lifePortrait);
  const navigate = useNavigate();
  const tcTextRef = useRef();
  const { control } = useForm({ mode: "onChange" });

  const gotoFraudPenaltyAgreement = () => {
    navigate("/FraudPenaltyAgreement/");
  };
  const onTermsAndConditionsAcceptChange = (e) => {
    setDisableContinue(e === false);
  };
  const onTermsAndConditionsDoNotAccept = () => {
    // TODO: handle do not accept t&c
    navigate("/TermsAndConditionsAreYouSure/");
  };
  const onTextScroll = () => {
    if (tcTextRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = tcTextRef.current;
      const zoomLevel = (window.outerWidth - 10) / window.innerWidth;
      if (scrollTop + clientHeight + zoomLevel >= scrollHeight) {
        // scrolled to the end
        setDisableChkAcceptTC(false);
      }
    }
  };

  return (
    <>
      <LifePortraitHeader id="header" />
      <TermsAndConditionsPres
        control={control}
        firstName={firstName}
        disableContinue={disableContinue}
        disableChkAcceptTC={disableChkAcceptTC}
        gotoFraudPenaltyAgreement={gotoFraudPenaltyAgreement}
        tcTextRef={tcTextRef}
        onTextScroll={onTextScroll}
        onTermsAndConditionsAcceptChange={onTermsAndConditionsAcceptChange}
        onTermsAndConditionsDoNotAccept={onTermsAndConditionsDoNotAccept}
      />
      <LifePortraitFooter id="footer" />
    </>
  );
}
