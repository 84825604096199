import * as React from "react";
import PropTypes from "prop-types";
import logo from "assets/LifePortrait.png";
import AgentCard from "components/Elements/FBAgentCard";
import LifePortraitFooter from "components/Footer/LifePortraitFooter";

export default function TermsAndConditionsOptedOutPres(props) {
  const { id, agent } = props;

  return (
    <>
      <nav
        id={id}
        name={id}
        className="navbar navbar-expand-lg navbar-dark bgPrimaryFBgrey"
      >
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <img src={logo} alt="Farm Bureau Logo" />
          </a>
        </div>
      </nav>
      <div className="container-xl min-vh-100 py-3" align="center">
        <div>
          <p>
            <h4>You&#39;ve opted to stop your Life Portrait</h4>
          </p>
        </div>
        <div className="mb-3">We&#39;ve notified your agent.</div>
        <div className="mb-3">
          If you&#39;d like to continue with the Life Insurance application
          later, please contact your agent,
        </div>
        <AgentCard id="agentCard" agent={agent} />
      </div>
      <LifePortraitFooter id="footer" />
    </>
  );
}

TermsAndConditionsOptedOutPres.propTypes = {
  id: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  agent: PropTypes.any.isRequired,
};
