import React, { useState } from "react";
import { useNavigate } from "react-router";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import LifePortraitHeader from "components/Header/LifePortraitHeader";
import LifePortraitFooter from "components/Footer/LifePortraitFooter";
import { RouteConstants } from "routes/AppRoutes";
import { getPortraitData } from "../state/lifePortraitActions";
import TNInsuranceAcknowledgePres from "../presentation/TNInsuranceAcknowledgePres";

export default function TNInsuranceAcknowledge() {
  const dispatch = useDispatch();
  const [disableContinue, setDisableContinue] = useState(true);
  const navigate = useNavigate();
  const { control } = useForm({ mode: "onChange" });
  const { mode, questionnaireResponse } = useSelector((state) => state.lifePortrait);

  const errorNavigation = (errArg) => {
    const {
      code,
      message,
      response: { status, statusText },
    } = errArg;
    navigate(RouteConstants.Error, {
      state: {
        code,
        message,
        status,
        statusText,
      },
    });
  };

  const gotoNextPage = () => {
    dispatch(getPortraitData(errorNavigation));
    if (questionnaireResponse.status === "Declined") {
      navigate("/OptedOut");
    } else if (mode === "examone" || mode === "client") {
      navigate("/TermsAndConditions");
    } else navigate("/FraudPenaltyAgreement/");
  };
  const onAcknowledgmentAcceptChange = (e) => {
    setDisableContinue(e === false);
  };
  const onAcknowledgmentDoNotAccept = () => {
    navigate("/TNInsuranceAcknowledgeAreYouSure/");
  };

  return (
    <>
      <LifePortraitHeader id="header" />
      <TNInsuranceAcknowledgePres
        control={control}
        disableContinue={disableContinue}
        gotoNextPage={gotoNextPage}
        onAcknowledgmentAcceptChange={
          onAcknowledgmentAcceptChange
        }
        onAcknowledgmentDoNotAccept={onAcknowledgmentDoNotAccept}
      />
      <LifePortraitFooter id="footer" />
    </>
  );
}
