/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Box, Typography } from "@mui/material";
import FBComboBox from "components/Elements/FBComboBox";

import { idComplete } from "util/genericHelper";
import {
  propNamesHeight,
  transformHeightInput,
  transformHeightOutput,
} from "util/heightHelper";

// let renderCount = 0;
const FBHeightLanding = (props) => {
  // renderCount += 1;
  const lblComplete = idComplete;
  const { id, required, value, tunnelToParent } = props;

  const {
    setValue: parentSetValue
  } = tunnelToParent;

  const { control, watch, reset } = useForm({
    mode: "all",
  });

  const formValues = watch();

  useEffect(() => {
    let isCompleteLocal = false;
    if (value) {
      const { height, isComplete } = transformHeightInput(id, value);
      isCompleteLocal = isComplete;
      reset(height);
    }
    parentSetValue(`${id}${lblComplete}`, isCompleteLocal);
  }, [id, value]);

  const transformAndUpdateParent = (lid, lformValues) => {
    const { transformedValue, isComplete } = transformHeightOutput(
      lid,
      lformValues
    );
    const custTransValue = transformedValue || undefined;
    if (parentSetValue) {
      parentSetValue(`${id}${lblComplete}`, isComplete);
      parentSetValue(id, custTransValue, { shouldDirty: true });
    }
  };

  const inputChangeHandler = (e, controlId) => {
    const height = (typeof e === 'object') ? { ...formValues } : { ...formValues, [controlId]: e };
    transformAndUpdateParent(id, height);
  };
  const feets = ["0", "1", "2", "3", "4", "5", "6", "7"];
  const inches = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11"];

  return (
    <Box
      sx={{
        width: "fit-content",
        border: 1,
        padding: 1,
        borderColor: "lightslategrey",
        borderRadius: 2,
        display: "flex",
      }}
    >
      <FBComboBox
        id={`${id}-${propNamesHeight.feets}`}
        value={formValues[`${id}-${propNamesHeight.feets}`]}
        control={control}
        minWidth={50}
        required={required}
        items={feets.map((e) => ({ label: e, value: e }))}
        onChange={(e) => {
          inputChangeHandler(e, `${id}-${propNamesHeight.feets}`);
        }}
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginLeft: 0.5,
          marginRight: 0.5,
        }}
      >
        <Typography color="text.secondary">{propNamesHeight.feets}</Typography>
      </Box>
      <FBComboBox
        id={`${id}-${propNamesHeight.inches}`}
        value={formValues[`${id}-${propNamesHeight.inches}`]}
        control={control}
        minWidth={50}
        required={required}
        items={inches.map((e) => ({ label: e, value: e }))}
        onChange={(e) => {
          inputChangeHandler(e, `${id}-${propNamesHeight.inches}`);
        }}
      />
      <Box sx={{ display: "flex", alignItems: "center", marginLeft: 0.5 }}>
        <Typography color="text.secondary" variant="body2">
          {propNamesHeight.inches}
        </Typography>
      </Box>
    </Box>
  );
};

export default FBHeightLanding;
