export const idComplete = "-complete";
export const ErrorRoute = "/info";
export const medFrmRouteSeg = "/group/";
export const FBControlTypes = {
  Caption: "Caption",
  DropDown: "DropDown",
  YesNo: "YesNo",
  Date: "Date",
  DateMonthYear: "DateMonthYear",
  Text: "Text",
  Numeric: "Numeric",
  Radio: "Radio",
  Checkbox: "Checkbox",
  HeartAttacks: "HeartAttacks",
  Medication: "Medication",
  MultiDateDesc: "MultiDateDesc",
  MultiAbnormalPaps: "MultiAbnormalPaps",
  Provider: "Provider",
  Height: "Height",
};
export const rowDelimiter = "|";
export const colDelimiter = "~";
export const keyValueDelimiter = "^";

export const encodeConfig = [
  { character: rowDelimiter, encoder: "<<pipe>>" },
  { character: keyValueDelimiter, encoder: "<<caret>>" },
  { character: colDelimiter, encoder: "<<tilde>>" },
];

export const encode = (val) => {
  if (!val || val === "") return val;
  let transVal = val;
  encodeConfig.forEach((el) => {
    transVal = transVal.replaceAll(el.character, el.encoder);
  });
  return transVal;
};

export const decode = (val) => {
  if (!val || val === "") return val;
  let transVal = val;
  encodeConfig.forEach((el) => {
    transVal = transVal.replaceAll(el.encoder, el.character);
  });
  return transVal;
};

export const removeDelimiters = (val) => {
  if (!val || val === "") return val;
  let transVal = val;
  encodeConfig.forEach((el) => {
    transVal = transVal.replaceAll(el.character, "");
  });
  return transVal;
};
