/* eslint-disable dot-notation */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import { v4 as uuidv4 } from "uuid";
import { setResponses } from "features/Questionnaire/state/questionnaireSlice";
import {
  getProfilePhoneRef,
  verifyPasscodeSvc,
  getPortraitDataSvc,
  getPortraitDataSummarySvc,
  updatePortraitStatusSvc,
  updatePortraitLastVisitTimeSvc,
  sendPasscodeSvc,
  getExamOneUserToken,
  getPortraitAgentSvc,
  sendEmailToAgentOfNewClientPhnSvc,
} from "services/LifePortrait.service";
import {
  getQuestionnaire,
  getFlattenedQuestionnaire,
} from "services/Questionnaire.service";
import { setFamilyMedHistPages } from "features/FamilyMedicalHistory/state/familyHistorySlice";
import {
  restoreStateFromLocalStorageToRedux,
  saveReduxStateInLocalStorage,
} from "util/localStorageUtil";
import { voidEnvelopeSvc } from "services/AgentDashboard.service";
import {
  setAuthToken,
  setPhoneNumber,
  setPortraitSummary,
  setPortraitStatus,
  setPortraitData,
  setPortraitQuestionnaireMetadata,
  setLifePortraitQuestionnaire,
  setPortraitGroupData,
  setLifePortraitState,
  setLifePortraitAgent,
} from "./lifePortraitSlice";

export const setStateInLocalStorage = () => async (getState) => {
  const { lifePortrait } = getState();
  const { authToken, documentId, applicationId, mode } = lifePortrait;

  saveReduxStateInLocalStorage({
    authToken,
    applicationId,
    documentId,
    mode,
  });
};

export const getProfilePhone =
  (navigationCallback) => async (dispatch, getState) => {
    try {
      const state = getState();
      const { applicationId, documentId } = state.lifePortrait;
      const requestData = { applicationId, documentId };
      const response = await getProfilePhoneRef(
        requestData,
        navigationCallback
      );
      if (response) dispatch(setPhoneNumber(response));
    } catch (error) {
      console.log(error);
    }
  };

export const getExamOneUserTokenAction = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const { documentId } = state.lifePortrait;
    const response = await getExamOneUserToken({ documentId });
    if (response) {
      dispatch(setAuthToken({ authToken: response.token }));
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const sendPasscodeAction =
  (channel, navigationCallback) => async (dispatch, getState) => {
    try {
      const state = getState();
      const { applicationId, documentId } = state.lifePortrait;
      const requestData = {
        applicationId,
        documentId,
        channel: channel || "sms",
      };
      const response = await sendPasscodeSvc(requestData, navigationCallback);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const verifyPasscodeAction =
  (passcode, navigationCallback) => async (dispatch, getState) => {
    try {
      const state = getState();
      const { applicationId, documentId, mode } = state.lifePortrait;
      const requestData = { applicationId, documentId, passcode };
      const response = await verifyPasscodeSvc(requestData, navigationCallback);
      if (response?.isValid) {
        dispatch(setAuthToken({ authToken: response.token }));
        saveReduxStateInLocalStorage({
          applicationId,
          documentId,
          authToken: response.token,
          mode,
        });
      }
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

const setResponsesInQuestionnaire = async (questionnaire, responseGroups) => {
  let updatedQuestionnaire = { ...questionnaire };
  const data = {};
  // closure function to update the answer in corresponding questionnaire>question structure
  async function updateQuestionnaire(id, answer) {
    updatedQuestionnaire = {
      ...updatedQuestionnaire,
      groups: updatedQuestionnaire?.groups.map((group) => {
        if (id.startsWith(group.id)) {
          return {
            ...group,
            sections: group.sections.map((section) => {
              const { questions } = section;
              let newQuestions = { ...questions };
              newQuestions = questions.map((quest1stLvl) => {
                if (quest1stLvl.id === id) {
                  return { ...quest1stLvl, value: answer };
                }
                if (id.startsWith(quest1stLvl.id)) {
                  return {
                    ...quest1stLvl,
                    drilldowns: quest1stLvl?.drilldowns?.map((drilldownEl) => {
                      if (drilldownEl.questions) {
                        return {
                          ...drilldownEl,
                          questions: drilldownEl.questions.map(
                            (quest2ndLvl) => {
                              if (quest2ndLvl.id === id) {
                                return { ...quest2ndLvl, value: answer };
                              }
                              return quest2ndLvl;
                            }
                          ),
                        };
                      }
                      return drilldownEl;
                    }),
                  };
                }
                return quest1stLvl;
              });
              return { ...section, questions: newQuestions };
            }),
          };
        }
        return group;
      }),
    };
  }

  // closure function to traverse recursively through response structure
  async function traverseDetails(details, id) {
    const detailsPromises = details?.map(async (detail) => {
      const detailId = `${id}_${detail.id}`;
      const { answer } = detail;
      // Check if detail contains details array, if yes call traverseDetails recursively
      data[detailId] = answer;
      await updateQuestionnaire(detailId, answer);
      if (detail.details) {
        await traverseDetails(detail.details, detailId);
      }
    });
    await Promise.all(detailsPromises);
  }

  // Traverse through groups in lifePortrait>questionnaireResponse
  // and then update the value/answer in lifePortrait>questionnaire in matching questionId
  // also prepare the responses object required to restore the react-hook-form state which will
  // be updated in the questionnaire>responses
  const promises = await responseGroups.map(async (group) => {
    const { id: grpId } = group;
    if (group.details) {
      await traverseDetails(group.details, grpId, updatedQuestionnaire);
    }
  });

  await Promise.all(promises);
  return { updQuest: updatedQuestionnaire, responseHook: data };
};

const prepResponsesForFamilyMedicalHistory = async (
  backendResponses,
  pages
) => {
  const { familyHistory } = backendResponses;
  let updatedPages = [...pages];

  async function flattenObject(parentId, obj, result) {
    let lresult = { ...result };
    for (const member of Object.entries(obj)) {
      const [key, value] = member;
      const propKey = key === "medicalHistory" ? "medHist" : key;
      if (typeof value === "object" && !Array.isArray(value)) {
        lresult = await flattenObject(
          `${parentId === "" ? `${propKey}` : `${parentId}_${propKey}`}`,
          value,
          lresult
        );
      } else if (typeof value === "object" && Array.isArray(value)) {
        if (key === "siblings") {
          const flatSiblingArr = await Promise.all(
            value.map(async (el) => {
              const sibling = await flattenObject("sibling", el, {});
              return { ...sibling, id: uuidv4() };
            })
          );
          lresult = { ...lresult, [key]: flatSiblingArr };
        }
      } else {
        lresult = {
          ...lresult,
          [`${parentId === "" ? `${propKey}` : `${parentId}_${propKey}`}`]:
            value,
        };
      }
    }
    return lresult;
  }

  let finalResult = {};
  if (familyHistory && pages) {
    finalResult = await flattenObject("", familyHistory, {});

    updatedPages = updatedPages.map((page) => {
      const { familyMember } = page;
      let responses;
      if (familyMember === "father" || familyMember === "mother") {
        Object.entries(finalResult)
          .filter(([key]) => key.startsWith(familyMember))
          .map(([key, value]) => {
            responses = { ...responses, [key]: value };
            return key;
          });
      } else if (familyMember === "siblingExists") {
        responses = {
          siblingExists_haveSiblings: finalResult[`haveSiblings`],
          siblingExists_doNotHaveSiblingsInfo:
            finalResult[`doNotHaveSiblingsInfo`],
        };
      } else if (familyMember === "sibling") {
        responses = {
          siblings: finalResult[`siblings`],
        };
      }
      return { ...page, responses };
    });
  }
  return { familyMedicalHistoryPages: updatedPages };
};

export const getPortraitData =
  (navigationCallback) => async (dispatch, getState) => {
    try {
      const state = getState();
      const { lifePortrait, familyHistory } = state;
      const { applicationId, documentId, questionnaire } = lifePortrait;
      const { pages } = familyHistory;
      const requestData = { applicationId, documentId };
      const response = await getPortraitDataSvc(
        requestData,
        navigationCallback
      );
      if (response) {
        dispatch(setPortraitData({ portrait: response.app }));
        // update BE responses in redux
        // lifePortrait>questionnaire &
        // questionnaire>responses
        const { updQuest, responseHook } = await setResponsesInQuestionnaire(
          { ...questionnaire },
          response.app.groups
        );
        dispatch(setLifePortraitQuestionnaire({ questionnaire: updQuest }));
        dispatch(setResponses(responseHook));
        // To restore the response for Family Medical History
        const { familyMedicalHistoryPages } =
          await prepResponsesForFamilyMedicalHistory(response.app, pages);
        dispatch(setFamilyMedHistPages(familyMedicalHistoryPages));
        dispatch(setStateInLocalStorage());
      }
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

/**
 * @description apply the conditions from portrait to questionnaire so that
 * only the relavent questions are asked. Ex: financials, gender based etc
 * @param questionnaire with all the questions
 * @param portraitConditions the conditions for the portrait
 * @return updated questionnaire based on conditions.
 */
const applyQuestionnaireConditions = (questionnaire, portraitConditions) => {
  if (portraitConditions.length === 0) return questionnaire;

  const updatedQuestionnaire = { ...questionnaire };
  updatedQuestionnaire.groups = questionnaire.groups.filter((g) => {
    if (g.conditions === undefined || g.conditions.length === 0) return true;

    return g.conditions.some((c) => portraitConditions.includes(c));
  });

  const { groups } = updatedQuestionnaire;
  for (let i = 0; i < groups.length; i += 1) {
    const sections = groups[i].sections.map((section) => {
      const { questions } = section;
      let newQuestions = questions;
      if (questions.some((q) => q.conditions !== undefined)) {
        newQuestions = questions.filter((q) => {
          if (q.conditions === undefined || q.conditions.length === 0) {
            return true;
          }
          return q.conditions.some((c) => portraitConditions.includes(c));
        });
      }
      return { ...section, questions: newQuestions };
    });
    updatedQuestionnaire.groups[i].sections = sections;
  }

  return updatedQuestionnaire;
};

export const getPortraitDataSummary =
  (navigationCallback) => async (dispatch, getState) => {
    try {
      const state = getState();
      const { applicationId, documentId } = state.lifePortrait;
      const requestData = { applicationId, documentId };
      const response = await getPortraitDataSummarySvc(
        requestData,
        navigationCallback
      );
      if (response) {
        dispatch(
          setPortraitSummary({
            prefix: response.clientPrefix,
            firstName: response.clientFirstName,
            middleName: response.clientMiddleName,
            lastName: response.clientLastName,
            suffix: response.clientSuffix,
            birthDate: response.clientBirthDate,
            status: response.status,
          })
        );

        const { questionsId } = response;
        // retrieve questionnaire meta data using questionnaireId
        const questionnaireMetadata = await getQuestionnaire(
          questionsId,
          navigationCallback
        );
        if (questionnaireMetadata) {
          const filteredQMetadata = applyQuestionnaireConditions(
            questionnaireMetadata,
            response.conditions
          );
          dispatch(
            setPortraitQuestionnaireMetadata({
              questionnaireMetadata: filteredQMetadata,
            })
          );
        }
        const flattenedQuestionnaire = await getFlattenedQuestionnaire(
          questionsId,
          navigationCallback
        );
        if (flattenedQuestionnaire) {
          const filteredQuestionnaire = applyQuestionnaireConditions(
            flattenedQuestionnaire,
            response.conditions
          );
          dispatch(
            setLifePortraitQuestionnaire({
              questionnaire: filteredQuestionnaire,
            })
          );
        }
      }
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const updatePortraitStatus =
  (status, accessMethod, navigationCallback) => async (dispatch, getState) => {
    try {
      const state = getState();
      const { applicationId, documentId, questionnaireResponse } =
        state.lifePortrait;
      if (questionnaireResponse?.status !== status) {
        const response = await updatePortraitStatusSvc(
          {
            applicationId,
            documentId,
            status,
          },
          navigationCallback
        );
        if (response?.success) {
          dispatch(setPortraitStatus({ status }));
        }
        return response;
      }
      return true;
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const deletePortraitFromDashboard =
  (applicationId, documentId, currentStatus, navigationCallback) => async () => {
    try {
      if (currentStatus !== "Deleted") {
        const response = await updatePortraitStatusSvc(
          {
            applicationId,
            documentId,
            status: "Deleted"
          },
          navigationCallback
        );
        return response;
      }
      return true;
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const updatePortraitLastVisitTime =
  (navigationCallback) => async (dispatch, getState) => {
    try {
      const state = getState();
      const { applicationId, documentId } = state.lifePortrait;
      await updatePortraitLastVisitTimeSvc(
        {
          applicationId,
          documentId,
        },
        navigationCallback
      );
      return true;
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const voidEnvelopeClient =
  (navigationCallback) => async (dispatch, getState) => {
    try {
      const state = getState();
      const { applicationId, documentId } = state.lifePortrait;
      const response = await voidEnvelopeSvc(
        { documentId, applicationId },
        navigationCallback
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const hydrateQuestionnaireResponses =
  (transformedResponses) => async (dispatch, getState) => {
    try {
      const state = getState();
      const { questionnaire, questionnaireResponse } = state.lifePortrait;
      const { activeGroup: groupId } = state.questionnaire;
      const hydratedQuestionnaire = { ...questionnaire };
      // 2 Steps - 1>Hydrate questionnaire, 2>Hydrate questionnaireresponses

      let updResponses = { ...questionnaireResponse };

      if (
        updResponses?.groups?.some((el) => {
          return el.id === groupId;
        })
      ) {
        updResponses = {
          ...updResponses,
          groups: updResponses?.groups?.map((el) => {
            return el.id === groupId ? { ...el, ...transformedResponses } : el;
          }),
        };
      } else {
        updResponses = {
          ...updResponses,
          groups: updResponses.groups.concat([{ ...transformedResponses }]),
        };
      }

      dispatch(setPortraitData({ portrait: updResponses }));

      return hydratedQuestionnaire;
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const hydrateQuestionnaire =
  (uiResponses) => async (dispatch, getState) => {
    try {
      const state = getState();
      const { questionnaire } = state.lifePortrait;
      const { activeGroup: groupId } = state.questionnaire;
      // > Hydrate questionnaire (For state restore on UI)
      let updQuest = { ...questionnaire };
      if (updQuest?.groups?.some((el) => el.id === groupId)) {
        updQuest = {
          ...updQuest,
          groups: updQuest?.groups?.map((el) => {
            if (el.id === groupId) {
              return {
                ...el,
                questions: el?.questions?.map((page) => {
                  return {
                    ...page,
                    value: uiResponses[page.id],
                    drilldowns: page?.drilldowns?.map((drilldownEl) => {
                      return {
                        ...drilldownEl,
                        questions: drilldownEl?.questions.map(
                          (leafQuestion) => {
                            return {
                              ...leafQuestion,
                              value: uiResponses[leafQuestion.id],
                            };
                          }
                        ),
                      };
                    }),
                  };
                }),
              };
            }
            return el;
          }),
        };
      }
      dispatch(setLifePortraitQuestionnaire({ questionnaire: updQuest }));
      return true;
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const setPortraitGroupStateData = (groupData) => async (dispatch) => {
  try {
    dispatch(setPortraitGroupData(groupData));
    return true;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const initializePortraitFromSessionStore =
  (errorNavigation) => async (dispatch) => {
    try {
      const obj = restoreStateFromLocalStorageToRedux();
      await dispatch(setLifePortraitState(obj));

      return dispatch(getPortraitDataSummary(errorNavigation)).then(() => {
        dispatch(getPortraitData(errorNavigation)).then(() => {
          errorNavigation();
        });
      });
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getPortraitAgent =
  (payload, errorNavigation) => async (dispatch, getState) => {
    try {
      const state = getState();
      const { applicationId, documentId } = payload || state.lifePortrait;
      const r = await getPortraitAgentSvc(
        { applicationId, documentId },
        errorNavigation
      );
      dispatch(setLifePortraitAgent(r));
      return Promise.resolve(r);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const sendEmailToAgentOfNewClientPhn =
  (payload, navigationCallback) => async (dispatch, getState) => {
    try {
      const state = getState();
      const { applicationId, documentId } = state.lifePortrait;
      const response = await sendEmailToAgentOfNewClientPhnSvc(
        { documentId, applicationId, payload },
        navigationCallback
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };
