import * as React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

export default function FBYesNoToggleButton(props) {
  const {
    control,
    id,
    label,
    required,
    defaultValue,
    value: propValue,
    disabled,
    // error: propError,
    // helperText,
    controlWidth,
    items,
    onChange: onInputChange,
  } = props;

  const selectedButton = {
    backgroundColor: "#F7FAF7",
    borderColor: "#93BB94",
    color: "#2E7D32",
    fontSize: "13pt",
  };
  const unselectedButton = {
    backgroundColor: "#FFFFFF",
    borderColor: "#004F74",
    color: "#004F74",
    fontSize: "13pt",
  };

  return (
    <Controller
      name={id}
      control={control}
      defaultValue={defaultValue || propValue || ""}
      rules={{
        required: { value: required, message: `${label} is required` },
      }}
      render={({ field }) => {
        return (
          <ToggleButtonGroup
            color="primary"
            id={id}
            required={required}
            label={label}
            value={field.value || ""}
            disabled={disabled}
            // error={!!error}
            // helperText={error ? error.message || helperText : helperText}
            sx={{ padding: "0px !important" }}
            // exclusive
            onChange={(e) => {
              field.onChange(e);
              if (onInputChange) onInputChange(e.target.value);
            }}
          >
            <ToggleButton
              value={items[0].value}
              style={
                field.value === items[0].value
                  ? selectedButton
                  : unselectedButton
              }
              sx={{ width: controlWidth, textTransform: "unset !important" }}
            >
              {field.value === items[0].value && (
                <CheckCircleOutlineIcon
                  style={{
                    color: "#2E7D32",
                    marginRight: "10px",
                    fontSize: "15pt",
                  }}
                />
              )}
              {items[0].label}
            </ToggleButton>
            <ToggleButton
              value={items[1].value}
              style={
                field.value === items[1].value
                  ? selectedButton
                  : unselectedButton
              }
              sx={{ width: controlWidth, textTransform: "unset !important" }}
            >
              {field.value === items[1].value && (
                <CheckCircleOutlineIcon
                  style={{
                    color: "#2E7D32",
                    marginRight: "10px",
                    fontSize: "15pt",
                  }}
                />
              )}
              {items[1].label}
            </ToggleButton>
          </ToggleButtonGroup>
        );
      }}
    />
  );
}

FBYesNoToggleButton.propTypes = {
  control: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  // error: PropTypes.bool,
  // helperText: PropTypes.string,
  controlWidth: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  onChange: PropTypes.func,
};

FBYesNoToggleButton.defaultProps = {
  label: "",
  required: false,
  defaultValue: undefined,
  value: undefined,
  disabled: false,
  // error: false,
  // helperText: undefined,
  controlWidth: "130px",
  items: [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ],
  onChange: undefined,
};
