import { createResponsiveStateReducer } from "redux-responsive";

/* reducers from features/ */
import lifePortraitReducer from "features/LifePortrait/state/lifePortraitSlice";
import questionnaireReducer from "features/Questionnaire/state/questionnaireSlice";
import familyHistoryReducer from "features/FamilyMedicalHistory/state/familyHistorySlice";
import reviewReducer from "features/Review/state/reviewSlice";
import medicationHistoryReducer from "features/MedicationHistory/state/medicationHistorySlice";
import agentDashboardReducer from "features/Dashboard/state/agentDashboardSlice";

const rootReducer = {
  browser: createResponsiveStateReducer({
    extraSmall: 480,
    small: 600,
    medium: 960,
    large: 1280,
    extraLarge: 1920,
  }),
  lifePortrait: lifePortraitReducer,
  agentDashboard: agentDashboardReducer,
  questionnaire: questionnaireReducer,
  familyHistory: familyHistoryReducer,
  review: reviewReducer,
  medicationHistory: medicationHistoryReducer,
};

export default rootReducer;
