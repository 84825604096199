/* eslint-disable operator-assignment */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  reviewData: {},
  questionsUpdated: 0,
  questions: [],
  activePage: -1,
  showCompletion: false,
  pageNavState: {
    continueEnabled: false,
  },
  responses: {},
  updateReview: true,
  showReminder: true,
  disableContinue: true,
};
const reviewSlice = createSlice({
  name: "review",
  initialState,
  reducers: {
    setReviewData(state, action) {
      const { payload } = action;
      state.reviewData = payload.reviewData;
    },
    setQuestions(state, action) {
      const { payload } = action;
      state.questions = payload.questions;
      state.questionsUpdated = state.questionsUpdated + 1;
    },
    setActivePage(state, action) {
      const { payload } = action;
      state.activePage = payload;
    },
    setPageNavState(state, action) {
      const { payload } = action;
      state.pageNavState = payload;
    },
    setShowCompletion(state, action) {
      const { payload } = action;
      state.showCompletion = payload;
    },
    setResponses(state, action) {
      const { payload } = action;
      state.responses = payload;
    },
    setUpdateReview(state, action) {
      const { payload } = action;
      state.updateReview = payload;
    },
    setShowReminder(state, action) {
      const { payload } = action;
      state.showReminder = payload;
    },
    setDisableContinue(state, action) {
      const { payload } = action;
      state.disableContinue = payload;
    }
  },
});

export const {
  setReviewData,
  setQuestions,
  setActivePage,
  setPageNavState,
  setShowCompletion,
  setResponses,
  setUpdateReview,
  setShowReminder,
  setDisableContinue
} = reviewSlice.actions;

export default reviewSlice.reducer;
