/* eslint-disable operator-assignment */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  questionsUpdated: 0,
  group: {},
  activeGroup: "",
  questions: [],
  activePage: -1,
  showGroupCompletion: false,
  groupNavState: {
    backEnabled: false,
    continueEnabled: false,
  },
  pageNavState: {
    backEnabled: false,
    continueEnabled: true,
  },
  responses: {},
  providerAddedOrModified: false,
};

const questionnaireSlice = createSlice({
  name: "questionnaire",
  initialState,
  reducers: {
    setGroup(state, action) {
      const { payload } = action;
      state.group = payload;
    },
    setActiveGroup(state, action) {
      const { payload } = action;
      state.activeGroup = payload;
    },
    setQuestions(state, action) {
      const { payload } = action;
      state.questions = payload;
      state.questionsUpdated = state.questionsUpdated + 1;
    },
    setActivePage(state, action) {
      const { payload } = action;
      state.activePage = payload;
    },
    setGroupNavState(state, action) {
      const { payload } = action;
      state.groupNavState = payload;
    },
    setPageNavState(state, action) {
      const { payload } = action;
      state.pageNavState = payload;
    },
    setResponses(state, action) {
      const { payload } = action;
      state.responses = payload;
    },
    setShowGroupCompletion(state, action) {
      const { payload } = action;
      state.showGroupCompletion = payload;
    },
    setProviderAddedOrModified(state, action) {
      const { payload } = action;
      state.providerAddedOrModified = payload;
    },
  },
});

export const {
  setGroup,
  setActiveGroup,
  setQuestions,
  setActivePage,
  setGroupNavState,
  setPageNavState,
  setResponses,
  setShowGroupCompletion,
  setProviderAddedOrModified,
} = questionnaireSlice.actions;

export default questionnaireSlice.reducer;
