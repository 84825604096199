import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
// import { getPortraitReviewData } from "services/LifePortrait.service";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@mui/material";

import { setReviewChecked } from "features/LifePortrait/state/lifePortraitSlice";
import { useNavigate } from "react-router";
import {
  setActivePage as setQActivePage,
  setActiveGroup,
  setGroup,
  setShowGroupCompletion,
  setGroupNavState,
} from "features/Questionnaire/state/questionnaireSlice";
import { ErrorRoute, medFrmRouteSeg } from "util/genericHelper";
import ReviewLandingPres from "../presentation/ReviewLandingPres";

import {
  setActivePage,
  setDisableContinue,
  setPageNavState,
  setUpdateReview,
} from "../state/reviewSlice";
import { getReviewData } from "../state/reviewActions";

export default function ReviewLandingContainer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { reviewChecked, applicationId, documentId, questionnaire } =
    useSelector((state) => state.lifePortrait);

  const { activeGroup } = useSelector((state) => state.questionnaire);
  const { reviewData, showReminder, disableContinue } = useSelector(
    (state) => state.review
  );
  const { control } = useForm({ mode: "onChange" });
  const goToMedform = (groupId) => {
    dispatch(setUpdateReview(true));
    navigate(`${medFrmRouteSeg}${groupId}`);
  };

  const goBack = () => {
    const { groups } = questionnaire;
    const prevSection = groups[groups.length - 2].id;
    navigate(`${medFrmRouteSeg}${prevSection}`);
  };

  const goToFinalQuestions = () => {
    if (activeGroup === "PS-RS-1") {
      navigate(`/group/${activeGroup}`);
      return;
    }
    navigate("/PortraitReview/FinalQuestions");
  };

  const onAgreementAcceptChange = (e) => {
    dispatch(setDisableContinue(e === false));
  };

  const showReview = (e) => {
    setReviewChecked(e === false);
    dispatch(setReviewChecked({ reviewChecked: true }));
  };

  const formatMedication = (med) => {
    return med.includes("Current")
      ? med.replace("Current", "Currently taking this medication")
      : med.replace("- Past", "");
  };

  const splitAnswers = (answer, indent) => {
    const answers = answer.split(" / ");
    return answers.map((newAnswer) => (
      <Typography sx={{ ml: indent * 4, fontWeight: 500 }}>
        {newAnswer.includes("Current") || newAnswer.includes("Past")
          ? formatMedication(newAnswer)
          : newAnswer}
      </Typography>
    ));
  };

  useEffect(() => {
    if (!questionnaire) return;

    window.scrollTo(0, 0);
    const errorNavigation = (errArg) => {
      const {
        code,
        message,
        response: { status, statusText },
      } = errArg;
      navigate(ErrorRoute, {
        state: {
          code,
          message,
          status,
          statusText,
        },
      });
    };

    const requestData = { applicationId, documentId };
    dispatch(getReviewData(requestData, errorNavigation));
    dispatch(setActivePage(0));
    const group = questionnaire.groups.find((g) =>
      ["PC-RS-1", "PS-RS-1", "PT-RS-1"].includes(g.id)
    );
    if (!group) return;
    dispatch(setActiveGroup(group.id));
    // Above code is only specific to Portrait V1 and V2 (child and teen)
    if (!["PS-RS-1"].includes(group.id)) return;
    const currentGroupIndex = questionnaire.groups.findIndex(
      (el) => el.id === group.id
    );
    dispatch(setShowGroupCompletion(false));
    dispatch(setGroup(group));
    dispatch(setQActivePage(-1));
    dispatch(
      setGroupNavState({
        backEnabled: false,
        continueEnabled: false,
        currentGroupIndex,
        currentGroupTitle: group?.title,
        prevGroupIndex: questionnaire.groups[currentGroupIndex - 1]
          ? currentGroupIndex - 1
          : 0,
        prevGroupId: questionnaire.groups[currentGroupIndex - 1]
          ? questionnaire.groups[currentGroupIndex - 1].id
          : questionnaire.groups[0].id,
        prevGroupTitle: questionnaire.groups[currentGroupIndex - 1]
          ? questionnaire.groups[currentGroupIndex - 1].title
          : questionnaire.groups[0].title,
        nextGroupIndex: questionnaire.groups[currentGroupIndex + 1]
          ? currentGroupIndex + 1
          : questionnaire.groups.length - 1,
        nextGroupId: questionnaire.groups[currentGroupIndex + 1]
          ? questionnaire.groups[currentGroupIndex + 1].id
          : questionnaire.groups[questionnaire.groups.length - 1].id,
        nextGroupTitle: questionnaire.groups[currentGroupIndex + 1]
          ? questionnaire.groups[currentGroupIndex + 1].title
          : questionnaire.groups[questionnaire.groups.length - 1].title,
      })
    );
    dispatch(
      setPageNavState({
        ...questionnaire.pageNavState,
        backEnabled: true,
        continueEnabled: true,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!questionnaire) return null;

  return (
    <ReviewLandingPres
      reviewData={reviewData}
      control={control}
      disableContinue={disableContinue}
      openReminder={showReminder}
      onAgreementAcceptChange={onAgreementAcceptChange}
      showReview={showReview}
      splitAnswers={splitAnswers}
      formatMedication={formatMedication}
      reviewChecked={reviewChecked}
      goToFinalQuestions={goToFinalQuestions}
      goToMedform={goToMedform}
      goBack={goBack}
    />
  );
}
