/* eslint-disable react/forbid-prop-types */
import Button from "@mui/material/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import FBCheckbox from "components/Elements/FBCheckbox";

import { memo } from "react";
import PropTypes from "prop-types";

function TNInsuranceAcknowledgePres({
  control,
  gotoNextPage,
  disableContinue,
  onAcknowledgmentAcceptChange,
  onAcknowledgmentDoNotAccept,
}) {
  return (
    <div className="bg-light min-vh-100">
      <div className="container py-3">
        <div className="">
          <form className="">
            <div className="shadow bg-body rounded card-body bg-light">
              <div className="innerDiv justify-content-centerpx-3">
                <h5 className="d-flex justify-content-center card-text py-3">
                  State of Tennessee Insurance
                </h5>
                <div className="d-flex justify-content-center py-1">
                  <FBCheckbox
                    control={control}
                    id="chkTNAcknowledgement"
                    label="I understand that I am applying for a life insurance policy contract that will
                  be processed, underwritten, issued and serviced in the state of Tennessee."
                    value="false"
                  // controlWidth="300px"
                    onChange={onAcknowledgmentAcceptChange}
                  />
                </div>
              </div>
            </div>
            <div>

              <div className="d-flex justify-content-center py-1 mt-3">
                <Button
                  id="btnContinue"
                  variant="contained"
                  disabled={disableContinue}
                  onClick={gotoNextPage}
                >
                  Continue
                </Button>
              </div>
              <div className="d-flex justify-content-center py-1">
                <Button
                  id="btnDoNotAcceptTNAcknowledgement"
                  variant="text"
                  onClick={onAcknowledgmentDoNotAccept}
                >
                  I Do Not Accept This Agreement&nbsp;
                  <ArrowForwardIcon />
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

TNInsuranceAcknowledgePres.propTypes = {
  gotoNextPage: PropTypes.func,
  control: PropTypes.any,
  disableContinue: PropTypes.bool,
  onAcknowledgmentAcceptChange: PropTypes.func,
  onAcknowledgmentDoNotAccept: PropTypes.func,
};

export default memo(TNInsuranceAcknowledgePres);
