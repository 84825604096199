import React from "react";
import PropTypes from "prop-types";
import { Box, Stack, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import FBTextBox from "components/Elements/FBTextBox";
import FBRadioGroup from "components/Elements/FBRadioGroup";
import { propNames } from "util/medicationHelper";

const EditMHPres = ({
  id,
  addMedicationHandler,
  control,
  isValid,
  hideMedCard,
  formValues,
}) => {
  return (
    <Box
      sx={{
        width: "fit-content",
        border: 1,
        marginTop: 1,
        padding: 1,
        borderColor: "lightslategrey",
        borderRadius: 2,
        paddingY: 2
      }}
    >
      <FBTextBox
        id={`${id}-${propNames.medication}`}
        name="medication"
        required
        label="Name of medication"
        placeholder="Name of medication"
        control={control}
        value={formValues[`${id}-${propNames.medication}`]}
      />
      <Typography sx={{ my: 1 }}>
        Are you currently taking this medication?
      </Typography>
      <FBRadioGroup
        id={`${id}-${propNames.current}`}
        label="Are you currently taking this medication?"
        control={control}
        items={[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" },
        ]}
        value={formValues[`${id}-${propNames.current}`]}
      />
      <Stack
        direction="row"
        spacing={2}
        sx={{
          width: "fit-content",
          borderRadius: 2,
        }}
      >
        <Button onClick={(e) => hideMedCard(e)} variant="outlined">
          Cancel
        </Button>
        <Button
          disabled={!isValid}
          onClick={(e) => addMedicationHandler(e)}
          variant="outlined"
          sx={{ my: 1 }}
        >
          Modify This Medication
        </Button>
      </Stack>
    </Box>
  );
};

EditMHPres.propTypes = {
  id: PropTypes.string,
  addMedicationHandler: PropTypes.func,
  control: PropTypes.object,
  isValid: PropTypes.bool,
  hideMedCard: PropTypes.func,
  formValues: PropTypes.object,
};
export default EditMHPres;
