/* eslint-disable react/prop-types */
import React from "react";
import Button from "@mui/material/Button";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Link from "@mui/material/Link";

const SiblingListPresentation = (props) => {
  const {
    familyMember,
    siblings,
    toggleShowSiblingFormHandler,
    siblingRecord,
    setSiblingRecordHandler,
    FormActions,
    reset,
    deleteSiblingHandler,
    showSiblingForm,
    addAnotherSiblingHandler,
  } = props;
  return (
    <>
      <div className="shadow-sm p-3 mb-3 bg-white rounded">
        <h5>Sibling Details</h5>
        <div
          style={{
            marginTop: "10px",
            borderTop: "1px solid #000000",
            borderBottom: "1px solid #000000",
          }}
        >
          {siblings.map((sibling) => {
            const { id } = sibling;
            const siblingType = sibling[`${familyMember}_type`];
            const firstName = sibling[`${familyMember}_firstName`];
            const age = sibling[`${familyMember}_age`];
            const livingStatus = sibling[`${familyMember}_livingStatus`];
            let colValue = `${siblingType}`;
            if (firstName && firstName !== "") {
              colValue = `${firstName}, ${colValue}`;
            }
            if (age && age !== "") {
              colValue = `${colValue}, ${age}`;
            }

            if (livingStatus && livingStatus !== "") {
              colValue = `${colValue}, ${livingStatus}`;
            }

            return (
              <div
                className="row mx-auto"
                style={{ paddingTop: "5px", paddingBottom: "5px" }}
                key={id}
              >
                <div className="col-md-8">{colValue}</div>
                <div className="col-md-2">
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <Link
                    component="button"
                    variant="body2"
                    onClick={(evt) => {
                      evt.preventDefault();
                      toggleShowSiblingFormHandler();
                      setSiblingRecordHandler({
                        ...siblingRecord,
                        action: FormActions.Edit,
                        id,
                      });
                      reset(sibling);
                    }}
                  >
                    Edit
                  </Link>
                </div>
                <div className="col-md-2">
                  <DeleteOutlineIcon
                    onClick={(evt) => {
                      // evt.preventDefault();
                      deleteSiblingHandler(evt, id);
                    }}
                    style={{ color: "#000000", cursor: "pointer" }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {!showSiblingForm && (
        <div>
          <Button variant="contained" onClick={addAnotherSiblingHandler}>
            Add Another Sibling
          </Button>
        </div>
      )}
    </>
  );
};

export default SiblingListPresentation;
