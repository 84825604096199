function rollUpMedications(responses) {
  const meds = [];
  const diabetic = (responses["PC-E-1_Diabetes"] === "Yes" ||
    responses["PS-Q10-1_Diabetes"] === "Yes" ||
    responses["PS-Q10-1_Pre-Diabetes"] === "Yes");

  Object.keys(responses).forEach((key) => {
    if (responses[key] &&
        responses[key].toString().includes("medication^" && "current^") &&
        !key.toString().includes("AdditionalMedications")) {
      meds.push({ id: key, med: responses[key] });
    } else if (diabetic && key.includes("InsulinPump") && responses[key] === "Yes") {
      const insulinPump = { id: key, med: "medication^Insulin Pump~current^1" };
      if (meds.findIndex((m) => m.med === insulinPump.med) === -1) meds.push(insulinPump);
    } else if (diabetic && key.includes("Insulin") && responses[key] === "Yes") {
      const insulin = { id: key, med: "medication^Insulin~current^1" };
      if (meds.findIndex((m) => m.med === insulin.med) === -1) meds.push(insulin);
    }
  });

  return meds;
}

function rollUpAdditionalMedications(medString) {
  const newMeds = [];
  medString.split("|").forEach((med) => {
    const medProps = med.split("~");
    const newMed = {
      medication: medProps[0].slice(0, medProps[0].length).replace("medication^", ""),
      reason: medProps[1].slice(0, medProps[1].length).replace("reason^", ""),
      provider: medProps[2].slice(0, medProps[2].length).replace("provider^", ""),
    };
    newMeds.push(newMed);
  });
  return newMeds;
}

export { rollUpMedications, rollUpAdditionalMedications };
