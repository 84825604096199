import serviceUrls from "./apiConfig/serviceUrls";
import { axiosFileRequest, axiosRequest } from "./apiConfig/axiosRequest";

export const getFlattenedQuestionnaire = (
  questionnaireId,
  navigationCallBack
) => {
  return axiosFileRequest(
    `${questionnaireId}_flattened.json`,
    navigationCallBack
  );
};
export const getQuestionnaire = (questionnaireId, navigationCallBack) => {
  return axiosFileRequest(`${questionnaireId}.json`, navigationCallBack);
};

export const saveGroupResponseSvc = (request, navigationCallBack) => {
  const { applicationId, documentId } = request;
  let urlEndpoint = serviceUrls.PORTRAIT_GROUP;
  urlEndpoint = urlEndpoint
    .replace(":appId", applicationId)
    .replace(":docId", documentId);

  return axiosRequest(
    navigationCallBack,
    "POST",
    urlEndpoint,
    request.group,
    null,
    null,
    null
  );
};

export const saveProviderSvc = (request, navigationCallBack) => {
  const { applicationId, documentId, providerId } = request;
  let urlEndpoint = serviceUrls.PORTRAIT_PROVIDER;
  urlEndpoint = urlEndpoint
    .replace(":appId", applicationId)
    .replace(":docId", documentId)
    .replace(":providerId", providerId);

  return axiosRequest(
    navigationCallBack,
    "PUT",
    urlEndpoint,
    request.provider,
    null,
    null,
    null,
    false
  );
};

export const saveFamilyMedicalHistorySvc = (request, navigationCallBack) => {
  const { applicationId, documentId } = request;
  let urlEndpoint = serviceUrls.PORTRAIT_SAVE_FAMILY_MEDICAL_HISTROY;
  urlEndpoint = urlEndpoint
    .replace(":appId", applicationId)
    .replace(":docId", documentId);

  return axiosRequest(
    navigationCallBack,
    "POST",
    urlEndpoint,
    request.familyMedicalHistory,
    null,
    null,
    null
  );
};
