/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FBSpinner from "components/Elements/FBSpinner";
import Layout from "components/Header/Layout";
import FamilyMedicalHistoryLandingContainer from "features/FamilyMedicalHistory";
import { updatePortraitStatus } from "features/LifePortrait/state/lifePortraitActions";
import { ErrorRoute } from "util/genericHelper";
import { isMobile } from "react-device-detect";
import { setAccessDevice } from "services/LifePortrait.service";
import QuestionnaireGroupContainer from "./QuestionnaireGroupContainer";
import QuestionnaireSectionsContainer from "./QuestionnaireSectionsContainer";

import {
  setActiveGroup,
  setGroup,
  setGroupNavState,
  setActivePage,
  setPageNavState,
  setShowGroupCompletion,
} from "../state/questionnaireSlice";

export default function QuestionnaireLandingContainer() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { applicationId, documentId, isLoading, questionnaire, status } =
    useSelector((state) => state.lifePortrait);

  const navigate = useNavigate();

  const errorNavigation = (errArg) => {
    const {
      code,
      message,
      response: { status: respStatus, statusText },
    } = errArg;
    navigate(ErrorRoute, {
      state: {
        code,
        message,
        status: respStatus,
        statusText,
      },
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(setShowGroupCompletion(false));
    const group = questionnaire?.groups.find((el) => el.id === id);
    if (!group) return;
    const currentGroupIndex = questionnaire.groups.findIndex(
      (el) => el.id === id
    );
    dispatch(setGroup(group));
    dispatch(setActiveGroup(group.id));
    dispatch(setActivePage(-1));
    dispatch(
      setGroupNavState({
        backEnabled: false,
        continueEnabled: false,
        currentGroupIndex,
        currentGroupTitle: group?.title,
        prevGroupIndex: questionnaire.groups[currentGroupIndex - 1]
          ? currentGroupIndex - 1
          : 0,
        prevGroupId: questionnaire.groups[currentGroupIndex - 1]
          ? questionnaire.groups[currentGroupIndex - 1].id
          : questionnaire.groups[0].id,
        prevGroupTitle: questionnaire.groups[currentGroupIndex - 1]
          ? questionnaire.groups[currentGroupIndex - 1].title
          : questionnaire.groups[0].title,
        nextGroupIndex: questionnaire.groups[currentGroupIndex + 1]
          ? currentGroupIndex + 1
          : questionnaire.groups.length - 1,
        nextGroupId: questionnaire.groups[currentGroupIndex + 1]
          ? questionnaire.groups[currentGroupIndex + 1].id
          : questionnaire.groups[questionnaire.groups.length - 1].id,
        nextGroupTitle: questionnaire.groups[currentGroupIndex + 1]
          ? questionnaire.groups[currentGroupIndex + 1].title
          : questionnaire.groups[questionnaire.groups.length - 1].title,
      })
    );
    dispatch(
      setPageNavState({
        ...questionnaire.pageNavState,
        backEnabled: !!["PC-RS-1", "PS-RS-1", "PT-RS-1"].includes(id),
        continueEnabled: true,
      })
    );
    const isNewPortrait = !status || status === "Initial";
    if (isNewPortrait) {
      dispatch(updatePortraitStatus("Started", errorNavigation));
      setAccessDevice(
        {
          applicationId,
          documentId,
          deviceType: isMobile ? "Mobile" : "Desktop",
        },
        errorNavigation
      );
    }
  }, [id]);

  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      // eslint-disable-next-line no-param-reassign
      event.returnValue = "";
      return "";
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, []);

  const checkForSections = (groupId) => {
    const numberOfSections = questionnaire?.groups?.find(
      (el) => el.id === groupId
    )?.sections?.length;
    return numberOfSections === 1;
  };

  const getGroupContainer = (groupId) => {
    const isDrillDownModel = checkForSections(groupId);
    switch (groupId) {
      case "PC-FH-1":
      case "PS-FH-1":
      case "PT-FH-1":
        return <FamilyMedicalHistoryLandingContainer />;
      case "PS-RS-1":
      case "PT-RS-1":
      case "PC-RS-1":
        // Exception for Review and Submit as we have multiple sections
        // we want drilldown model i.e. QuestionnaireGroupContainer
        return isLoading ? (
          <div className="spinner">
            <FBSpinner />
          </div>
        ) : (
          <QuestionnaireGroupContainer />
        );
      default:
        return isLoading ? (
          <div className="spinner">
            <FBSpinner />
          </div>
        ) : isDrillDownModel ? (
          <QuestionnaireGroupContainer />
        ) : (
          <QuestionnaireSectionsContainer />
        );
    }
  };
  return <Layout>{getGroupContainer(id)}</Layout>;
}
