import * as React from "react";
import PropTypes from "prop-types";

export default function TermsAndConditions(props) {
  const { id } = props;

  return (
    <div id={id} name={id}>
      <h5>Terms and Conditions </h5>
      <p>
        All Information (&#34;Content&#34;) provided by Tennessee Farmers
        Insurance Companies on the World Wide Web of the Internet (&#34;its web
        sites&#34;) is owned by or licensed to TFIC and/or its affiliates and
        subsidiaries (collectively &#34;TFIC&#34;). By accessing and using any
        of the TFIC websites you represent that you are 18 years or older and
        understand that through such access and usage you are legally bound by
        these Terms and Conditions.
      </p>
      <p>
        TFIC retains all proprietary rights to TFIC information. All
        unauthorized use of the information (including e-mail addresses)
        contained on these sites is strictly prohibited without prior written
        permission from TFIC.
      </p>
      <p>
        TFIC does, however, authorize you to copy documents published on our
        sites for noncommercial, personal use. In consideration of this
        authorization, you agree that any copy of these documents that you make
        shall retain all copyright and other proprietary notices contained
        herein.
      </p>
      <p>
        Each individual document published by TFIC on our web sites may contain
        other proprietary notices and copyright information relating to that
        individual document. Likewise, any product, process, or technology,
        contained in individual documents on our web sites may be the subject of
        other intellectual property rights reserved by TFIC, and may not be
        licensed hereunder.
      </p>
      <p>
        All trade names, trademarks, service marks, images and logos on TFIC
        owned web sites are proprietary to their respective owners and are
        protected by applicable trademark and copyright laws. Any unauthorized
        use of TFIC owned marks or graphics is strictly prohibited.
      </p>
      <p>
        You may not, nor may you allow others to, directly or indirectly,
        attempt or actually disrupt, impair or interfere with, alter or modify
        any TFIC owned web site or content. Likewise, you may not, nor may you
        allow others to, directly, or indirectly, collect or attempt to collect
        any information about our customers, including passwords, account or
        other data.
      </p>
      <p>
        Users may through hypertext or other computer links gain access to other
        sites on the Internet which are not part of TFIC&apos;s web sites. TFIC
        assumes no responsibility for any material outside of TFIC&apos;s web
        sites that may be accessed through any such &#34;link&#34;.
      </p>
      <p>
        <h5>DISCLAIMER</h5>
      </p>
      <p>
        The material on our sites is provided as is. This means that TFIC
        disclaims all express and implied warranties about the material in the
        sites. This includes, but is not limited to, warranties that: (1) the
        material is of any particular level of quality or is fit for a
        particular purpose; (2) the functional elements contained in the
        materials will be uninterrupted or error-free; (3) defects will be
        corrected; (4) our sites, or the servers that make them available are
        free of computer viruses or other harmful components or conditions; or
        (5) information contained in the sites is accurate as of any particular
        date.
      </p>
      <p>
        TFIC will not be responsible for any damages or injuries that accompany
        or result from your use of its sites. These include (but are not limited
        to) damages or injury caused by any: (1) Use of (or inability to use)
        the sites; (2) Use of (or inability to use) any site to which you
        hyperlink from our sites; (3) Failure of performance; (4) Error; (5)
        Omission; (6) Interruption; (7) Defect; (8) Delay in operation or
        transmission; (9) Computer virus; or (10) Line failure. We are not
        liable for any consequential or incidental damages, which are damages
        intended to compensate someone directly for a loss or injury, damages
        reasonably expected to result from a loss or injury, or other
        miscellaneous damages and expenses resulting directly from a loss or
        injury. Furthermore, we are not liable even if we have been negligent or
        if our authorized representative has been advised of the possibility of
        such damages -- or both.
      </p>
      <p>
        However, in certain states where the law may not allow us to limit or
        exclude liability for these incidental or consequential damages, the
        above limitation may not apply and you may have the right to recover
        these types of damages, in any event, our liability to you for all
        losses, damages, injuries and claims of any kind, whether the damages
        are claimed under the terms of a contract, or they are claimed to be
        caused by negligence or other wrongful conduct or any other legal
        theory, will not be greater than the amount you have paid to access our
        sites.
      </p>
      <p>
        By providing material on our Web site TFIC does not in any way promise
        that the materials will remain available to you or that you will qualify
        for the products we offer. TFIC is entitled to terminate all or part of
        any of its Web sites at any time, without notice to you.
      </p>
      <p>
        <h5>JURISDICTION</h5>
      </p>
      <p>
        Unless otherwise specified, the materials in our site are presented to
        provide information about TFIC, our insurance products and the insurance
        business. TFIC operates and controls its sites from the Company&apos;s
        headquarters in Columbia, Tennessee, in the United States of America,
        and Tennessee law shall govern and control any action concerning the
        TFIC sites. We do not in any way imply that the materials on the sites
        or the products we discuss are available or for use outside the United
        States or in jurisdictions in which we are not licensed to do business,
        or that we are soliciting business in any such jurisdiction.
      </p>
      <p>
        <h5>CONSUMER REPORTS</h5>
      </p>
      <p>
        To provide an online auto quote, we utilize various consumer reports,
        which may include insurance scoring, driving records, claims history,
        registered vehicles and licensed drivers in a household, to the extent
        permitted by law, to confirm the exact risk and premium.
      </p>
      <p>
        <h5>PRIVACY POLICY</h5>
      </p>
      <p>
        Tennessee Farmers Mutual Insurance Company | Tennessee Farmers Assurance
        Company | Tennessee Farmers Life Insurance Company
      </p>
      <p>Security Of Your Nonpublic Personal Information</p>
      <p>
        The privacy of our customers and members is important to us and we are
        committed to its protection. We have a tradition of respecting your
        right to keep personal information private and avoid unwanted
        solicitations. As required by new federal and state laws, we have
        developed this privacy policy to inform you of how we handle your
        personal information.
      </p>
      <p>
        Categories Of Nonpublic Personal Information Collected and Subject to
        Disclosure
      </p>
      <p>
        We collect nonpublic personal information from the following sources:
      </p>
      <p>
        Information we receive on applications or other forms such as names,
        addresses, dates of birth, and information relating to the exposure or
        risk to be insured
      </p>
      <p>
        Information about transactions with us, our affiliates or others such as
        policy numbers and coverage; and
      </p>
      <p>
        Information we receive from consumer and credit reporting agencies such
        as claim and credit histories.
      </p>
      <p>Restrictions On Use Of Information</p>
      <p>Restrictions on Use By Us</p>
      <p>
        We restrict access to nonpublic personal information about you to those
        employees, independent contracting agents, and other affiliated entities
        or individuals who need to know that information to provide products or
        services to you. Their right to further disclose and use that
        information is limited by our employee conduct rules, applicable law,
        and nondisclosure agreements where appropriate. We maintain physical,
        electronic, and procedural safeguards that comply with federal and state
        regulations to guard your nonpublic personal information.
      </p>
      <p>Restrictions On Use By Others</p>
      <p>
        We do not sell your nonpublic personal information to anyone. We only
        disclose nonpublic personal information to others as permitted or
        required by law in conjunction with our normal insurance operations. For
        example, we may provide limited nonpublic personal information to others
        to process claims, to underwrite and maintain your insurance, to combat
        fraud, in response to examination by government agencies, or if required
        by a court. Any person or entity to whom we release information is
        prohibited by law and/or contract with us from misusing nonpublic
        personal information. Because our use of nonpublic personal information
        is restricted as described above, an opt out provision is not necessary
        or available.
      </p>
      <p>
        <h5>MISCELLANEOUS</h5>
      </p>
      <p>
        These Terms and Conditions, and the agreement they create, shall be
        governed by and interpreted according to the laws of the State of
        Tennessee, without giving effect to Tennessee&apos;s conflict of laws
        principles.
      </p>
      <p>
        If any provision of this agreement is unlawful, void or unenforceable,
        it shall not affect the validity and enforceability of any remaining
        provisions.
      </p>
      <p>
        TFIC may modify these Terms and Conditions, and the agreement they
        create, at any time, simply by updating this posting or the information
        on its Web sites and without notice to you.
      </p>
      <p>
        This is the entire agreement regarding all the matters that have been
        discussed in the preceding paragraphs.
      </p>
      <p>
        If you have any comments or concerns regarding our terms and conditions
        or privacy policy, please contact us through our website or by mail to
        Tennessee Farmers Insurance Companies, P.O. Box 998, Columbia, TN
        38402-0998, Attention: Legal Department.
      </p>
    </div>
  );
}

TermsAndConditions.propTypes = {
  id: PropTypes.string.isRequired,
};
