/* eslint-disable react/forbid-prop-types */
import Button from "@mui/material/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import FBCheckbox from "components/Elements/FBCheckbox";

import { memo } from "react";
import PropTypes from "prop-types";

function FraudPenaltyAgreementPres({
  control,
  gotoWelcome,
  disableContinue,
  onFraudPenaltyAgreementAcceptChange,
  onFraudPenaltyAgreementDoNotAccept,
}) {
  return (
    <div className="bg-light min-vh-100">
      <div className="container py-3">
        <div className="">
          <form className="">
            <div className="shadow bg-body rounded card-body bg-light">
              <h5 className="d-flex justify-content-center">
                Ensure your loved ones are protected.
              </h5>
              <p className="d-flex justify-content-center card-text py-3">
                Please read the agreement below.
              </p>
              <div className="outerDiv">
                <div className="innerDiv px-3">
                  <h5>Fraud Penalty Agreement</h5>
                  <p>
                    Failure to provide truthful statements or answers means
                    that coverage may not apply at the time of a claim.
                    Additionally, Tennessee Code Annotated, Title 56 requires
                    that you be advised as follows:  &#34;It is a crime to knowingly
                    provide false, incomplete, or misleading information to an
                    insurance company for the purpose of defrauding the company.
                    Penalties include imprisonment, fines, and denial of insurance benefits.&#34;
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="d-flex justify-content-center py-1">
                <FBCheckbox
                  control={control}
                  id="chkAcceptFraudPenaltyAgreement"
                  label="I Accept This Agreement"
                  value="false"
                  controlWidth="300px"
                  onChange={onFraudPenaltyAgreementAcceptChange}
                />
              </div>
              <div className="d-flex justify-content-center py-1">
                <Button
                  id="btnContinue"
                  variant="contained"
                  disabled={disableContinue}
                  onClick={gotoWelcome}
                >
                  Continue
                </Button>
              </div>
              <div className="d-flex justify-content-center py-1">
                <Button
                  id="btnDoNotAcceptFraudPenaltyAgreement"
                  variant="text"
                  onClick={onFraudPenaltyAgreementDoNotAccept}
                >
                  I Do Not Accept This Agreement&nbsp;
                  <ArrowForwardIcon />
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

FraudPenaltyAgreementPres.propTypes = {
  gotoWelcome: PropTypes.func,
  control: PropTypes.any,
  disableContinue: PropTypes.bool,
  onFraudPenaltyAgreementAcceptChange: PropTypes.func,
  onFraudPenaltyAgreementDoNotAccept: PropTypes.func,
};

export default memo(FraudPenaltyAgreementPres);
