import React, { useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useNavigate } from "react-router";
import Button from "@mui/material/Button";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import logo from "assets/LifePortrait1.png";
import LifePortraitChecklistDrawer from "./LifePortraitChecklistDrawer";

export default function LifePortraitHeaderWithChecklist(props) {
  const { is } = useSelector((state) => state.browser);
  const { id } = props;
  const [isOpen, setIsOpen] = useState(false);
  const { extraLarge, infinity, large } = is;
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setIsOpen(open);
  };
  const handleItemSelect = () => () => {
    setIsOpen(false);
  };
  const navigate = useNavigate();
  const goToTermsAndConditions = () => {
    navigate("/TermsAndConditionsPage");
  };
  const goToPrivacyPolicy = () => {
    navigate("/PrivacyPolicyPage");
  };
  return (
    <>
      <nav id={id} name={id} className="navbar navbar-expand-lg">
        <div className="container-xl">
          <Button id="sidebarCollapse" onClick={toggleDrawer(true)}>
            <MenuIcon />
          </Button>
          <a className="navbar-brand" href="/">
            <img src={logo} alt="Farm Bureau Logo" />
          </a>
          <Button>
            <HelpOutlineIcon />
          </Button>
        </div>
      </nav>
      <Drawer
        open={isOpen}
        onClose={toggleDrawer(false)}
        width="50%"
        variant={large || extraLarge || infinity ? "permanent" : "temporary"} // "temporary" // permanent temporary
      >
        <div
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <div className="py-4">
            <div className="px-4 position-absolute start-0">
              <h5>Life Portrait Checklist</h5>
            </div>
            <div className="px-4 position-absolute end-0">
              <CloseIcon />
            </div>
          </div>
        </div>
        <div className="py-4">
          <div className="px-4 position-absolute start-0">My Life Portrait</div>
          <div className="px-4 position-absolute end-0">
            <HomeIcon />
          </div>
        </div>
        <div className="px-3 py-3">
          <Divider style={{ color: "#000000" }} />
        </div>
        <div className="px-1">
          <LifePortraitChecklistDrawer
            id={id}
            onItemSelect={handleItemSelect()}
          />
        </div>
        <div className="px-3 py-3">
          <Divider style={{ color: "#000000" }} />
        </div>
        <div className="px-3">
          <Button
            id="btnPrivacyPolicy"
            variant="text"
            onClick={goToPrivacyPolicy}
            className="text-white text-decoration-none"
          >
            Privacy Policy
          </Button>
        </div>
        <div className="px-3 py-3">
          <Button
            id="btnTermsConditions"
            variant="text"
            onClick={goToTermsAndConditions}
            className="text-white text-decoration-none"
          >
            Terms and Conditions
          </Button>
        </div>
      </Drawer>
    </>
  );
}

LifePortraitHeaderWithChecklist.propTypes = {
  id: PropTypes.string.isRequired,
};
