/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Controller } from "react-hook-form";
import { useNavigate } from "react-router";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
// import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import SettingsIcon from "@mui/icons-material/Settings";
import AddIcon from "@mui/icons-material/Add";

import { useDispatch, useSelector } from "react-redux";
import { ErrorRoute } from "util/genericHelper";
import ProviderContainer from "./ProviderContainer";
import { setProviders } from "../state/providerActions";

function FBProvider({
  control,
  label,
  id,
  required,
  defaultValue,
  value: propValue,
  disabled,
  error: propError,
  onChange: onInputChange,
  MenuProps,
  // setValue,
  // setValueInReduxResponses,
  tunnelToParent,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const lifePortrait = useSelector((state) => state.lifePortrait);

  const {
    questionnaireResponse: { providers },
    states,
  } = lifePortrait;

  const items = providers.map((el) => {
    return {
      label: `${el.type} ${el.firstName ? el.firstName : ""} ${el.lastName}`,
      value: el.id,
    };
  });

  const providerMenuButtons = {
    addNewProvider: "Add New Provider",
    modifyProvider: "Modify Provider",
    manageProviders: "Manage Providers",
  };
  const menuOptionObj = {
    menuLabel: providerMenuButtons.addNewProvider,
    dialogOpen: false,
    status: undefined,
  };
  const [menuOption, setMenuOption] = useState(menuOptionObj);

  const menuActionHandler = (evt, selectedMenuOption) => {
    setMenuOption({
      ...menuOption,
      menuLabel: selectedMenuOption,
      dialogOpen: true,
    });
  };

  const setProviderValue = (guid) => {
    if (tunnelToParent) {
      const { setValue, setValueInReduxResponses, getValues } = tunnelToParent;
      // this is important logic to set the parent form state in redux responses.
      // Continue button logic.
      if (setValue) setValue(id, guid, { shouldDirty: true });
      if (setValueInReduxResponses) {
        setValueInReduxResponses(id, guid, getValues);
      }
    }
    if (onInputChange) onInputChange(guid);
  };

  const errorNavigation = (errArg) => {
    const {
      code,
      message,
      response: { status, statusText },
    } = errArg;
    navigate(ErrorRoute, {
      state: {
        code,
        message,
        status,
        statusText,
      },
    });
  };

  const addProviderHandler = (formValues) => {
    const { menuLabel, modifiedGuid } = menuOption;
    let updatedPhysicians = [...providers];
    let record = {};
    let custMenuOptionObj = {};
    if (modifiedGuid && menuLabel === providerMenuButtons.modifyProvider) {
      updatedPhysicians = [
        ...providers.map((el) => {
          if (el.id === modifiedGuid) {
            record = { ...el, ...formValues };
            return record;
          }
          return el;
        }),
      ];
      record = { ...record, action: "modify" };
      custMenuOptionObj = {
        ...menuOption,
        dialogOpen: false,
        menuLabel: providerMenuButtons.addNewProvider,
        modifiedGuid: undefined,
        status: undefined,
      };
    }

    if (!modifiedGuid && menuLabel === providerMenuButtons.addNewProvider) {
      record = { ...formValues, id: uuidv4() };
      updatedPhysicians = providers.concat([record]);
      record = { ...record, action: "add" };
      custMenuOptionObj = {
        ...menuOption,
        dialogOpen: true,
        modifiedGuid: undefined,
        status: "saved",
      };
    }
    dispatch(setProviders(updatedPhysicians, record, errorNavigation));

    setMenuOption(custMenuOptionObj);

    // To expose the selectedrecord to parent component.
    setProviderValue(record.id);
  };

  return (
    <>
      <Controller
        name={id}
        control={control}
        defaultValue={defaultValue || propValue || ""}
        rules={{
          required: { value: required, message: `${label} is required` },
        }}
        render={({ field, fieldState: { error = propError } }) => {
          if (field.value === undefined || field.value === "") {
            // eslint-disable-next-line no-param-reassign
            field.value = propValue || "";
          }
          return (
            <FormControl sx={{ minWidth: 300 }} size="small">
              <InputLabel id="fb-state-label">{label}</InputLabel>
              <Select
                id={id}
                name={id}
                required={required}
                label={label}
                value={field.value}
                disabled={disabled}
                error={!!error}
                // helperText={error ? error.message || helperText : helperText}
                placeholder="Select or add provider"
                autoWidth
                sx={{ "& legend": { width: "auto" } }}
                onChange={(e) => {
                  field.onChange(e);
                  if (onInputChange) onInputChange(e.target.value);
                }}
                MenuProps={MenuProps}
              >
                {items.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    <em>{item.label}</em>
                  </MenuItem>
                ))}
                {items?.length > 0 && (
                  <MenuItem
                    key="manageProvider"
                    value="manageProvider"
                    onClick={(e) => {
                      menuActionHandler(e, providerMenuButtons.manageProviders);
                    }}
                  >
                    <ListItemIcon>
                      <SettingsIcon fontSize="small" />
                    </ListItemIcon>
                    <em>{providerMenuButtons.manageProviders}</em>
                  </MenuItem>
                )}
                <MenuItem
                  key="addNewProvider"
                  value="addNewProvider"
                  onClick={(e) => {
                    menuActionHandler(e, providerMenuButtons.addNewProvider);
                  }}
                >
                  <ListItemIcon>
                    <AddIcon fontSize="small" />
                  </ListItemIcon>
                  <em>{providerMenuButtons.addNewProvider}</em>
                </MenuItem>
              </Select>
            </FormControl>
          );
        }}
      />
      {menuOption.dialogOpen && (
        <ProviderContainer
          id={`provider-${id}`}
          menuOption={menuOption}
          setMenuOption={setMenuOption}
          addProviderHandler={addProviderHandler}
          providerMenuButtons={providerMenuButtons}
          providers={providers}
          states={states}
        />
      )}
    </>
  );
}

FBProvider.propTypes = {};

export default FBProvider;
