import Layout from "components/Header/Layout";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { RouteConstants } from "routes/AppRoutes";
import { medFrmRouteSeg } from "util/genericHelper";
import { isMobile } from "react-device-detect";
import { setAccessDevice } from "services/LifePortrait.service";
import {
  updatePortraitStatus,
  updatePortraitLastVisitTime,
} from "../state/lifePortraitActions";
import WelcomePres from "../presentation/WelcomePres";

export default function LifePortraitWelcome() {
  const { applicationId, documentId, firstName, status, questionnaire, questionnaireResponse } =
    useSelector((state) => state.lifePortrait);
  const isNewPortrait = !status || status === "Initial";
  let lastLoggedInfo = "";
  if (!isNewPortrait && questionnaireResponse?.lastVisited) {
    const lastVisited = new Date(questionnaireResponse.lastVisited);
    lastLoggedInfo = `Last accessed ${lastVisited.toLocaleDateString()} ${lastVisited.toLocaleTimeString(
      [],
      { timeStyle: "short" }
    )}`;
  }
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const errorNavigation = (errArg) => {
    const {
      code,
      message,
      response: { status: respStatus, statusText },
    } = errArg;
    navigate(RouteConstants.Error, {
      state: {
        code,
        message,
        status: respStatus,
        statusText,
      },
    });
  };
  const gotoPortrait = () => {
    const groupId = questionnaire.groups[0].id;
    if (isNewPortrait) {
      dispatch(updatePortraitStatus("Started", errorNavigation));
      setAccessDevice({
        applicationId,
        documentId,
        deviceType: isMobile ? "Mobile" : "Desktop"
      }, errorNavigation);
    }

    navigate(`${medFrmRouteSeg}${groupId}`);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const errorNav = (errArg) => {
      const {
        code,
        message,
        response: { status: respStatus, statusText },
      } = errArg;
      navigate(RouteConstants.Error, {
        state: {
          code,
          message,
          status: respStatus,
          statusText,
        },
      });
    };
    dispatch(updatePortraitLastVisitTime(errorNav));
  }, [dispatch, navigate]);

  return (
    <Layout>
      <WelcomePres
        isNewPortrait={isNewPortrait}
        firstName={firstName}
        gotoPortrait={gotoPortrait}
        lastLoggedInfo={lastLoggedInfo}
      />
    </Layout>
  );
}
