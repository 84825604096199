/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { getGroupResponseFromFormVals } from "util/getGroupResponseFromFormVals";
import { saveReviewResponses } from "features/Review/state/reviewActions";
import { FBControlTypes, idComplete } from "util/genericHelper";
import ReviewQuestionsPres from "../presentation/ReviewQuestionsPres";
import { setActivePage, setQuestions } from "../state/reviewSlice";
import { setResponses } from "../../Questionnaire/state/questionnaireSlice";

export default function ReviewQuestionsContainer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    control,
    watch,
    reset,
    setValue,
    formState: { isDirty, errors },
  } = useForm({ mode: "onChange" });
  const formValues = watch();
  const { questions, questionsUpdated } = useSelector((s) => s.review);
  const { questionnaire, questionnaireMetadata } = useSelector(
    (state) => state.lifePortrait
  );
  const { responses } = useSelector((state) => state.questionnaire);
  const group = questionnaire.groups.find(
    (g) => g.id === "PS-RS-1" || g.id === "PC-RS-1" || g.id === "PT-RS-1"
  );

  const evalFormIsValidFlag = () => {
    // const formValues = getValues();
    function checkForParentVisibility(showLogic, showLogicConditionFlag) {
      let lshowLogicConditionFlag = showLogicConditionFlag;
      if (showLogic) {
        // Check if showLogic condition is satisifed to check
        // if current question is required or not so we evaluate showLogicConditionFlag
        if (
          showLogic.operator === "Single" &&
          Array.isArray(showLogic.condition)
        ) {
          const { id: parentId, triggeringValue } = showLogic.condition[0];
          let parentResponseVal = formValues[parentId];
          const parentQuestion = questions.find((q) => q.id === parentId);
          if (parentQuestion?.input?.dataType === FBControlTypes.Checkbox) {
            // For checkbox cntrl we have to check if value === "Yes" or true
            parentResponseVal =
              formValues[parentId] === "Yes" || formValues[parentId] === true;
          }
          lshowLogicConditionFlag = parentResponseVal === triggeringValue;
          if (lshowLogicConditionFlag && parentQuestion.showLogic) {
            lshowLogicConditionFlag = checkForParentVisibility(
              parentQuestion.showLogic,
              lshowLogicConditionFlag
            );
          }
          return lshowLogicConditionFlag;
        }

        if (showLogic.operator === "Or" && Array.isArray(showLogic.condition)) {
          // eslint-disable-next-line no-debugger
          const conditions = showLogic.condition.filter((condition) => {
            const { id: parentId, triggeringValue } = condition;
            let parentResponseVal = formValues[parentId];
            const parentQuestion = questions.find((q) => q.id === parentId);
            if (parentQuestion?.input?.dataType === FBControlTypes.Checkbox) {
              // For checkbox cntrl we have to check if value === "Yes" or true
              parentResponseVal =
                formValues[parentId] === "Yes" || formValues[parentId] === true;
            }
            return parentResponseVal === triggeringValue;
          });
          lshowLogicConditionFlag = conditions.length > 0;
          if (lshowLogicConditionFlag && conditions.length > 0) {
            const parentQuestions = questions.find(
              (q) => q.id === conditions[0].id
            );
            if (parentQuestions.length > 0 && parentQuestions[0].showLogic) {
              lshowLogicConditionFlag = checkForParentVisibility(
                parentQuestions[0].showLogic,
                lshowLogicConditionFlag
              );
            }
          }
          return lshowLogicConditionFlag;
        }
      }
      return lshowLogicConditionFlag;
    }
    const questionIsValid = !questions.some((el, idx) => {
      const { id, input, showLogic } = el;
      const { required, dataType, options } = input;

      if (dataType === FBControlTypes.Caption) return false;

      let showLogicConditionFlag = false;
      if (showLogic) {
        showLogicConditionFlag = checkForParentVisibility(
          showLogic,
          showLogicConditionFlag
        );
      }

      // Fix for the Primary Care Info section.
      if (
        dataType === FBControlTypes.Checkbox &&
        id.endsWith("-PC-1_NoProvider")
      ) {
        return false;
      }

      if (dataType === FBControlTypes.DateMonthYear) {
        const error = errors[id];
        if (error && error?.type === "validate") {
          return true;
        }
      }

      // Following if block is to check for the checkboxes group
      // and exits if it finds that none of the value are checked and continues otherwise
      if (
        dataType === FBControlTypes.Checkbox &&
        (!showLogic || showLogicConditionFlag)
      ) {
        const { checkBoxGroup } = options || {};
        // below stmt check if any checkbox in the same CheckboxGroup is checked
        const retVal = questions
          .filter(
            (itr) =>
              itr?.input?.dataType === FBControlTypes.Checkbox &&
              itr?.input?.options?.checkBoxGroup === checkBoxGroup
          )
          .some(
            (itr) => formValues[itr.id] === "Yes" || formValues[itr.id] === true
          );
        // if this flag is FALSE meaning >> none of the checkboxes are checked, so exit the loop
        if (!retVal) {
          return true;
        }
      }

      if (
        (!showLogic || showLogicConditionFlag) &&
        required &&
        [
          FBControlTypes.HeartAttacks,
          FBControlTypes.Medication,
          FBControlTypes.MultiDateDesc,
          FBControlTypes.MultiAbnormalPaps,
          FBControlTypes.Height,
        ].includes(dataType)
      ) {
        return !(formValues[`${id}${idComplete}`] === true);
      }

      // Following condition evaluates the form state by looking at
      // If showLogic object is present then check whether parent question condition is satisified
      // If question is required then response(value) exists or not.
      return (
        required &&
        ((!showLogic &&
          (formValues[id] === undefined ||
            formValues[id] === null ||
            formValues[id] === "" ||
            (dataType === FBControlTypes.Provider &&
              (formValues[id] === "addNewProvider" ||
                formValues[id] === "manageProvider")))) ||
          (showLogic &&
            showLogicConditionFlag &&
            (formValues[id] === undefined ||
              formValues[id] === null ||
              formValues[id] === "" ||
              (dataType === FBControlTypes.Provider &&
                (formValues[id] === "addNewProvider" ||
                  formValues[id] === "manageProvider")))))
      );
    });

    return questionIsValid;
  };

  const formStateIsInvalid = evalFormIsValidFlag();

  const saveResponses = async (data) => {
    const groupResponse = getGroupResponseFromFormVals(
      questionnaireMetadata,
      group.id,
      data
    );
    await dispatch(
      saveReviewResponses(groupResponse, { ...responses, ...data })
    );
  };

  const transformResponsesAndSetInRedux = (data) => {
    let updData = {};
    Object.keys(data).forEach((el) => {
      let val = data[el];
      if (typeof val === "object") {
        try {
          val = data[el].toISOString();
          // eslint-disable-next-line no-empty
        } catch {}
      }
      updData = {
        ...updData,
        [el]: val,
      };
    });
    dispatch(setResponses({ ...responses, ...updData }));
    return { ...responses, ...updData };
  };

  const transformResponsesToHandleCheckBox = (iResponses, iQuestions) => {
    let result = {};
    Object.keys(iResponses).forEach((el) => {
      const question = iQuestions.find((quest) => quest.id === el);
      const {
        input: { dataType },
      } = question || { input: { dataType: undefined } };
      let value = iResponses[el];
      if (dataType === "Checkbox") {
        if (iResponses[el] === "true" || iResponses[el] === true) value = "Yes";
        if (iResponses[el] === "false" || iResponses[el] === false) {
          value = "No";
        }
      }
      result = { ...result, [el]: value };
    });
    return result;
  };

  const onSubmit = async (data) => {
    const transformedChkbxResponses = transformResponsesToHandleCheckBox(
      data,
      questions
    );
    const updData = transformResponsesAndSetInRedux(transformedChkbxResponses);
    await saveResponses(updData);
  };

  const handleBackBtn = async (e) => {
    onSubmit(formValues, e);
    navigate("/PortraitReview");
  };

  const handleContinueBtnClick = async (e) => {
    navigate("/MedicationHistory");
    onSubmit(formValues, e);
  };
  const loadLevel0Questions = () => {
    const grp = questionnaire.groups.find(
      (g) => g.id === "PS-RS-1" || g.id === "PC-RS-1" || g.id === "PT-RS-1"
    );
    return grp.sections[0].questions.map((el) => {
      // Logic to handle transform the triggeringValue = "Yes"
      // to true for parent control if it is checkbox.
      let value = responses[el.id];
      const {
        input: { dataType },
        showLogic,
      } = el;

      // transforming value to true or false only for checkboxes
      // to restore the state onload of the page.
      if (
        value &&
        dataType === FBControlTypes.Checkbox &&
        typeof value === "string"
      ) {
        value = value.toLowerCase() === "yes";
      }

      // if showlogic exists on the question, then we check
      // if the questions present in condition is checkbox,
      // then we transform the triggeringValue to true
      if (showLogic) {
        const updatedShowLogic = {
          ...showLogic,
          condition: showLogic.condition.map((cnd) => {
            const parentQuest = grp.sections[0].questions.find(
              (parentQuestItr) => parentQuestItr.id === cnd.id
            );

            const {
              input: { dataType: parentDataType },
            } = parentQuest;

            if (parentDataType === FBControlTypes.Checkbox) {
              return {
                ...cnd,
                triggeringValue:
                  cnd.triggeringValue === "Yes" ? true : cnd.triggeringValue,
              };
            }
            return cnd;
          }),
        };
        return {
          ...el,
          id: `${el.id}`,
          value,
          showLogic: showLogic ? updatedShowLogic : undefined,
        };
      }

      return {
        ...el,
        id: el.id,
        caption: el.caption,
        value,
        input: {
          ...el.input,
          dataType: el.input.dataType,
          required: el.input.required,
        },
      };
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    dispatch(setActivePage(1));
    reset(responses);
    const tmpQuestions = loadLevel0Questions();
    dispatch(setQuestions({ questions: tmpQuestions }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, responses]);

  const getTitle = (groupId) => {
    switch (groupId) {
      case "PS-RS-1":
        return "Other than previously answered, have you had any of the following within the past 5 years?";
      case "PC-RS-1":
        return "In the past 5 years, has the proposed insured ever been treated for or been diagnosed by a member of the medical profession with any other condition that requires ongoing follow up or consultations with a physician or hospitalization (inpatient or outpatient) or caused a significant amount of time lost from school?";
      case "PT-RS-1":
        return "In the past 5 years, has the proposed insured ever been treated for or been diagnosed by a member of the medical profession with any other condition that requires ongoing follow up or consultations with a physician or hospitalization (inpatient or outpatient) or caused a significant amount of time lost from school?";
      default:
        return "";
    }
  };

  return (
    questions.length > 0 && (
      <ReviewQuestionsPres
        control={control}
        questions={questions}
        handleBackBtn={handleBackBtn}
        formStateIsInvalid={formStateIsInvalid}
        handleContinueBtnClick={handleContinueBtnClick}
        reviewGroupId={questionnaireMetadata.id}
        setValue={setValue}
        question={getTitle(group.id)}
        forceRender={questionsUpdated}
      />
    )
  );
}
