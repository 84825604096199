import React from "react";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import NumberFormatCustom from "./NumberFormatCustom";
// import { Identity } from "@mui/base";

export default function FBNumber(props) {
  const {
    control,
    id,
    label,
    required,
    defaultValue,
    value: propValue,
    disabled,
    error: propError,
    helperText,
    placeholder,
    controlWidth,
    variant,
    onChange: onInputChange,
    currency,
    thousands,
  } = props;

  return (
    <Controller
      name={id}
      control={control}
      defaultValue={defaultValue || propValue || ""}
      rules={{
        required: { value: required, message: `${label} is required` },
      }}
      render={({ field, fieldState: { error = propError } }) => {
        if (field.value === undefined || field.value === "") {
          // eslint-disable-next-line no-param-reassign
          field.value = propValue || "";
        }
        return (
          <TextField
            id={id}
            name={id}
            required={required}
            label={label}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              inputProps: {
                min: 0,
                max: 10000000000,
                prefix: currency ? "$" : undefined,
                thousandSeparator: thousands || currency,
              },
              inputComponent: NumberFormatCustom,
            }}
            value={propValue}
            disabled={disabled}
            error={!!error}
            helperText={error ? error.message || helperText : helperText}
            placeholder={placeholder}
            variant={variant}
            size="small"
            sx={{
              width: controlWidth,
              "& legend": { width: "auto" },
              "& .MuiInputBase-root": {
                "& input": {
                  textAlign: "right",
                },
              },
            }}
            onKeyDown={(e) => {
              if (e.key === "e") e.preventDefault();
            }}
            onChange={(e) => {
              field.onChange(e);
              if (onInputChange) onInputChange(e.target.value);
            }}
          />
        );
      }}
    />
  );
}

FBNumber.propTypes = {
  control: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  placeholder: PropTypes.string,
  controlWidth: PropTypes.string,
  variant: PropTypes.string,
  onChange: PropTypes.func,
  currency: PropTypes.bool,
  thousands: PropTypes.bool,
};

FBNumber.defaultProps = {
  label: "",
  required: false,
  defaultValue: undefined,
  value: undefined,
  disabled: false,
  error: false,
  helperText: undefined,
  placeholder: undefined,
  controlWidth: "250px",
  variant: "standard",
  onChange: undefined,
  currency: undefined,
  thousands: true,
};
