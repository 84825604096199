/* eslint-disable react/no-array-index-key */
import React from "react";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { Box } from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Chip from "@mui/material/Chip";
import { propNames } from "util/medicationHelper";

const MedicationListPres = ({
  id,
  medications,
  handleProviderItemClick,
  menuOption,
}) => {
  const disableActionBtn = menuOption.showMedCard;
  return (
    <Box>
      <List sx={{ width: "100%", maxWidth: 320, bgcolor: "white" }}>
        {medications.map((el, idx) => {
          const { guid } = el;
          return (
            <ListItem key={`${id}-${idx}-${guid}`} disablePadding>
              <ListItemButton>
                {`${el[`${id}-${propNames.medication}`]}`}
                {el[`${id}-${propNames.current}`] === "Yes" ? (
                  <Chip
                    label="current"
                    color="success"
                    variant="outlined"
                    size="small"
                    sx={{ marginLeft: 1 }}
                  />
                ) : null}
              </ListItemButton>
              <IconButton
                onClick={(event) => handleProviderItemClick(event, el, "edit")}
                disabled={disableActionBtn}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                aria-label="delete"
                size="small"
                disabled={disableActionBtn}
                onClick={(event) => {
                  handleProviderItemClick(event, el, "delete");
                }}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};

MedicationListPres.propTypes = {
  id: PropTypes.string,
  medications: PropTypes.arrayOf(PropTypes.object),
  handleProviderItemClick: PropTypes.func,
  menuOption: PropTypes.object,
};

export default MedicationListPres;
