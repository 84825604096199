/* eslint-disable dot-notation */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Button from "@mui/material/Button";
import QuestionnaireGroupCompleted from "features/Questionnaire/presentation/QuestionnaireGroupCompleted";
import { setShowGroupCompletion } from "features/Questionnaire/state/questionnaireSlice";
import { ErrorRoute, medFrmRouteSeg } from "util/genericHelper";
import { disableRouteToReviewNSubmit } from "util/evalEnableReviewAndSubmitRoute";
import FamilyMemberPresentation from "../presentation/FamilyMemberPresentation";
import {
  cboConditionsList,
  medicalConditions,
  siblingTypes,
  aliveDeceasedTypes,
  LivingEnum,
  FormActions,
  FamilyMembers,
} from "../helper/FamilyMemberUtils";
import SiblingContainer from "./SiblingContainer";
import {
  setFamilyMedHistActivePage,
  setFamilyMedHistActivePageIndex,
  setFamilyMedHistPages,
  setSiblingRecord,
  toggleShowSiblingForm,
} from "../state/familyHistorySlice";

import SiblingExistsPresentation from "../presentation/SiblingExistsPresentation";
import { saveFMHResponses } from "../state/familyHistoryActions";

function FamilyMedicalHistoryLandingContainer() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { questionnaire, familyHistory: familyMedicalHistroy } = useSelector(
    (state) => state
  );
  const {
    mode,
    questionnaire: questionnaireLP,
    questionnaireResponse,
  } = useSelector((state) => state.lifePortrait);
  const {
    groupNavState: navGroupBtn,
    showGroupCompletion,
    activeGroup,
  } = questionnaire;
  const { activePageIndex: pageIndex } = familyMedicalHistroy;
  const { control, watch, reset, setValue } = useForm({ mode: "onChange" });
  const formValues = watch();

  useEffect(() => {
    dispatch(setShowGroupCompletion(false));
    dispatch(setFamilyMedHistActivePage(familyMedicalHistroy.pages[0]));
    dispatch(setFamilyMedHistActivePageIndex(0));
    // reset(familyMedicalHistroy.pages[0].responses);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    reset(familyMedicalHistroy.pages[pageIndex].responses);
  }, [pageIndex]);

  const updateResponse = (data) => {
    const currentPageIndex = familyMedicalHistroy.activePageIndex;
    const updatedPages = [...familyMedicalHistroy.pages];
    return {
      ...updatedPages[currentPageIndex],
      responses: data,
    };
  };

  const prepPayload = async (pages, status) => {
    let family = {};
    let siblingExists = false;
    let siblingExistProps = {};
    await pages.forEach(async ({ familyMember: member, responses }) => {
      let familyMember = {};
      if (member === FamilyMembers.Father || member === FamilyMembers.Mother) {
        for (const [key, value] of Object.entries(responses)) {
          if (key.startsWith(`${member}_medHist_`)) {
            familyMember = {
              ...familyMember,
              medicalHistory: {
                ...familyMember?.medicalHistory,
                [key.replace(`${member}_medHist_`, "")]: value,
              },
            };
          } else {
            familyMember = {
              ...familyMember,
              [key.replace(`${member}_`, "")]: value,
            };
          }
        }
        family[member] = familyMember;
      }

      if (member === FamilyMembers.SiblingExists) {
        siblingExists = responses[`${member}_haveSiblings`] === "Yes";
        for (const [key, value] of Object.entries(responses)) {
          siblingExistProps = {
            ...siblingExistProps,
            [key.replace(`${member}_`, "")]: value,
          };
        }
        family = { ...family, ...siblingExistProps };
      }

      if (member === FamilyMembers.Sibling) {
        let transformedSiblings = [];
        if (siblingExists) {
          const { siblings } = responses;

          transformedSiblings = siblings.map(({ id, ...sibling }) => {
            let siblingObj = {};
            for (const [key, value] of Object.entries(sibling)) {
              if (key.startsWith(`${member}_medHist_`)) {
                siblingObj = {
                  ...siblingObj,
                  medicalHistory: {
                    ...siblingObj?.medicalHistory,
                    [key.replace(`${member}_medHist_`, "")]: value,
                  },
                };
              } else {
                siblingObj = {
                  ...siblingObj,
                  [key.replace(`${member}_`, "")]: value,
                };
              }
            }

            return { ...siblingObj };
          });
        }

        family = { ...family, siblings: transformedSiblings };
      }
    });

    return { groupId: activeGroup, status, familyHistory: family };
  };

  const errorNavigation = (errArg) => {
    const {
      code,
      message,
      response: { status, statusText },
    } = errArg;
    navigate(ErrorRoute, {
      state: {
        code,
        message,
        status,
        statusText,
      },
    });
  };

  // eslint-disable-next-line no-unused-vars
  const continueHandler = async () => {
    const currentPageIndex = familyMedicalHistroy.activePageIndex;
    const updatedPages = [...familyMedicalHistroy.pages];
    updatedPages[currentPageIndex] = updateResponse(formValues);

    // Case specific for Siblings
    const { familyMember } = familyMedicalHistroy.activePage;
    if (familyMember === "siblingExists") {
      const siblingUnknownInfo =
        formValues[`${familyMember}_doNotHaveSiblingsInfo`];
      let showSiblingDetails = !siblingUnknownInfo;
      if (showSiblingDetails) {
        showSiblingDetails = !!(
          formValues[`${familyMember}_haveSiblings`] &&
          formValues[`${familyMember}_haveSiblings`] === "Yes"
        );
      }
      if (!showSiblingDetails) {
        // Save the responses and Exit the section.
        dispatch(setFamilyMedHistPages(updatedPages));
        dispatch(
          saveFMHResponses(
            await prepPayload(updatedPages, "completed"),
            errorNavigation
          )
        );
        dispatch(setShowGroupCompletion(true));
        return;
      }
    }

    if (familyMember === "sibling") {
      const family = await prepPayload(updatedPages, "completed");
      dispatch(setFamilyMedHistPages(updatedPages));
      dispatch(setFamilyMedHistActivePage(updatedPages[currentPageIndex]));
      dispatch(setShowGroupCompletion(true));
      dispatch(saveFMHResponses(family));

      return;
    }

    // Increment the Index for switching from Father > Mother > Sibling
    const activePageIndex =
      currentPageIndex === familyMedicalHistroy.pages.length - 1
        ? currentPageIndex
        : familyMedicalHistroy.activePageIndex + 1;

    dispatch(setFamilyMedHistActivePage(updatedPages[activePageIndex]));
    dispatch(setFamilyMedHistActivePageIndex(activePageIndex));
    dispatch(setFamilyMedHistPages(updatedPages));

    // if (updatedPages[activePageIndex].responses) {
    //   reset(updatedPages[activePageIndex].responses);
    // } else reset();
  };

  const previousHandler = () => {
    // Saving the current FamilyMember responses
    const currentPageIndex = familyMedicalHistroy.activePageIndex;
    const updatedPages = [...familyMedicalHistroy.pages];
    updatedPages[currentPageIndex] = updateResponse(formValues);

    if (currentPageIndex === 0) {
      dispatch(setShowGroupCompletion(false));
      navigate(`${medFrmRouteSeg}${navGroupBtn.prevGroupId}`);
    }

    const activePageIndex =
      currentPageIndex === 0
        ? currentPageIndex
        : familyMedicalHistroy.activePageIndex - 1;

    dispatch(setFamilyMedHistActivePage(updatedPages[activePageIndex]));
    dispatch(setFamilyMedHistActivePageIndex(activePageIndex));
    dispatch(setFamilyMedHistPages(updatedPages));

    // if (updatedPages[activePageIndex].responses) {
    //   reset(updatedPages[activePageIndex].responses);
    // } else reset();
  };

  const {
    activePage: { familyMember },
    showSiblingForm,
    siblingRecord,
  } = familyMedicalHistroy;

  const toggleShowSiblingFormHandler = (e, siblingFormState) => {
    let lsiblingFormState = !showSiblingForm;
    if (siblingFormState) lsiblingFormState = siblingFormState;
    dispatch(toggleShowSiblingForm(lsiblingFormState));
  };

  const setSiblingsHandler = (data) => {
    const currentPageIndex = familyMedicalHistroy.activePageIndex;
    const updatedPages = [...familyMedicalHistroy.pages];
    updatedPages[currentPageIndex] = updateResponse({
      ...formValues,
      siblings: data,
    });
    dispatch(setFamilyMedHistActivePage(updatedPages[currentPageIndex]));
    dispatch(setFamilyMedHistPages(updatedPages));
    // toggleShowSiblingFormHandler();
  };

  const setSiblingRecordHandler = (data) => {
    dispatch(setSiblingRecord(data));
  };

  const gotoNextGroup = () => {
    if (["PC-RS-1", "PS-RS-1", "PT-RS-1"].includes(navGroupBtn.nextGroupId)) {
      let route =
        mode === "examone"
          ? "/PortraitReview/FinalQuestions"
          : `/PortraitReview`;
      if (mode === "examone" && navGroupBtn.nextGroupId === "PS-RS-1") {
        route = `${medFrmRouteSeg}${navGroupBtn.nextGroupId}`;
      }
      navigate(route);
    } else navigate(`${medFrmRouteSeg}${navGroupBtn.nextGroupId}`);
  };

  const resetWhenUnknownInfoHandler = (id, value) => {
    setValue(id, value);
  };

  const medicalHistoryConditionChangeHandler = (e, medId) => {
    const responses = { ...formValues };
    if (
      (medId === `${familyMember}_medHist_iDontKnow` ||
        medId === `${familyMember}_medHist_noneOfTheAbove`) &&
      e
    ) {
      for (const [key, value] of Object.entries(responses)) {
        if (
          (key !== `${familyMember}_medHist_iDontKnow` ||
            key !== `${familyMember}_medHist_noneOfTheAbove`) &&
          key.startsWith(`${familyMember}_medHist_`) &&
          value
        ) {
          if (
            key.endsWith("_TypeOfCancer") ||
            key.endsWith("_AgeAtDiagnosis")
          ) {
            resetWhenUnknownInfoHandler(key, "");
          } else resetWhenUnknownInfoHandler(key, false);
        }
      }
    }
    if (
      (medId !== `${familyMember}_medHist_iDontKnow` ||
        medId !== `${familyMember}_medHist_noneOfTheAbove`) &&
      e
    ) {
      for (const [key, value] of Object.entries(responses)) {
        if (
          (key === `${familyMember}_medHist_iDontKnow` ||
            key === `${familyMember}_medHist_noneOfTheAbove`) &&
          value
        ) {
          if (
            key.endsWith("_TypeOfCancer") ||
            key.endsWith("_AgeAtDiagnosis")
          ) {
            resetWhenUnknownInfoHandler(key, "");
          } else resetWhenUnknownInfoHandler(key, false);
        }
      }
    }
  };

  const getDisableStateForContinueBtn = () => {
    let disableContinue = true;

    if (
      familyMember === FamilyMembers.Father ||
      familyMember === FamilyMembers.Mother
    ) {
      if (formValues[`${familyMember}_doNotHaveInfo`]) {
        disableContinue = false;
      } else if (formValues[`${familyMember}_livingStatus`]) {
        // Age and Do not have age info validation
        let ageflag = true;
        if (
          formValues[`${familyMember}_age`] !== "" ||
          (formValues[`${familyMember}_age`] === "" &&
            formValues[`${familyMember}_doNotHaveAgeInfo`] === true)
        ) {
          ageflag = false;
        }

        // Incase of deceased - Primary cause of Death and detail validation
        let primaryCauseOfDeathFlag = false;
        const primaryCauseOfDeathComplete = !(
          formValues[`${familyMember}_primaryCauseOfDeath`] === undefined ||
          formValues[`${familyMember}_primaryCauseOfDeath`] === "" ||
          formValues[`${familyMember}_primaryCauseOfDeath`] === null
        );
        if (
          formValues[`${familyMember}_livingStatus`] === "Deceased" &&
          !primaryCauseOfDeathComplete
        ) {
          primaryCauseOfDeathFlag = true;
        }

        if (
          formValues[`${familyMember}_livingStatus`] === "Deceased" &&
          primaryCauseOfDeathComplete
        ) {
          if (
            (formValues[`${familyMember}_primaryCauseOfDeath`] === "Other" ||
              formValues[`${familyMember}_primaryCauseOfDeath`] === "Cancer") &&
            formValues[`${familyMember}_primaryCauseOfDeathDetail`] === ""
          ) {
            primaryCauseOfDeathFlag = true;
          } else primaryCauseOfDeathFlag = false;
        }

        // Medical History checkboxes validation - checks if atleast one checkbox is checked
        // If Cancer or Heart disease are checked, then logic verifies if
        // cascading inputs are filled.
        let medConditionFlag = true;

        const atleast1Itemchecked = medicalConditions.some(
          (el) => formValues[`${familyMember}_medHist_${el.id}`] === true
        );

        if (atleast1Itemchecked) {
          const medArr = medicalConditions.filter(
            (el) =>
              (el.id === "heartDisease" || el.id === "cancer") &&
              formValues[`${familyMember}_medHist_${el.id}`] === true
          );

          if (medArr.length === 0) {
            medConditionFlag = false;
          } else {
            const isIncomplete = medArr.every((el) => {
              if (
                el.id === "heartDisease" &&
                formValues[`${familyMember}_medHist_${el.id}`] === true
              ) {
                if (
                  formValues[
                    `${familyMember}_medHist_${el.id}_AgeAtDiagnosis`
                  ] === undefined &&
                  formValues[`${familyMember}_medHist_${el.id}_UnKnownAge`] ===
                    undefined
                ) {
                  return false;
                }
                if (
                  formValues[
                    `${familyMember}_medHist_${el.id}_AgeAtDiagnosis`
                  ] === "" &&
                  formValues[`${familyMember}_medHist_${el.id}_UnKnownAge`] ===
                    false
                ) {
                  return false;
                }
                return true;
              }

              if (
                el.id === "cancer" &&
                formValues[`${familyMember}_medHist_${el.id}`] === true
              ) {
                if (
                  formValues[
                    `${familyMember}_medHist_${el.id}_TypeOfCancer`
                  ] === "" ||
                  formValues[
                    `${familyMember}_medHist_${el.id}_TypeOfCancer`
                  ] === undefined
                ) {
                  return false;
                }
                return true;
              }

              return true;
            });
            medConditionFlag = !isIncomplete;
          }
        }
        disableContinue =
          ageflag || primaryCauseOfDeathFlag || medConditionFlag;
      }
    } else if (familyMember === FamilyMembers.SiblingExists) {
      if (formValues[`${familyMember}_doNotHaveSiblingsInfo`]) {
        disableContinue = false;
      } else if (formValues[`${familyMember}_haveSiblings`]) {
        disableContinue = false;
      }
    } else if (familyMember === FamilyMembers.Sibling) {
      if (
        formValues.siblings &&
        Array.isArray(formValues.siblings) &&
        formValues?.siblings?.length > 0 &&
        !showSiblingForm
      ) {
        disableContinue = false;
      }
    }
    return disableContinue;
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {showGroupCompletion ? (
        <QuestionnaireGroupCompleted
          currentGroup={navGroupBtn.currentGroupTitle}
          nextGroup={navGroupBtn.nextGroupTitle}
          gotoNextGroup={gotoNextGroup}
          disableRouteToReviewNSubmit={disableRouteToReviewNSubmit(
            navGroupBtn,
            questionnaireResponse,
            questionnaireLP
          )}
        />
      ) : (
        <div className="bgSecondaryFBgrey min-vh-100 py-3 px-3">
          <div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              {(familyMember === "father" || familyMember === "mother") && (
                <FamilyMemberPresentation
                  member={familyMedicalHistroy.activePage}
                  control={control}
                  medicalConditions={medicalConditions}
                  cboConditionsList={cboConditionsList}
                  formValues={formValues}
                  LivingEnum={LivingEnum}
                  resetWhenUnknownInfoHandler={resetWhenUnknownInfoHandler}
                  medicalHistoryConditionChangeHandler={
                    medicalHistoryConditionChangeHandler
                  }
                />
              )}
              {familyMember === "siblingExists" && (
                <SiblingExistsPresentation
                  control={control}
                  member={familyMedicalHistroy.activePage}
                  resetWhenUnknownInfoHandler={resetWhenUnknownInfoHandler}
                  formValues={formValues}
                />
              )}
              {familyMember === "sibling" && (
                <SiblingContainer
                  member={familyMedicalHistroy.activePage}
                  medicalConditions={medicalConditions}
                  cboConditionsList={cboConditionsList}
                  siblingTypes={siblingTypes}
                  aliveDeceasedTypes={aliveDeceasedTypes}
                  setSiblings={setValue}
                  LivingEnum={LivingEnum}
                  setSiblingsHandler={setSiblingsHandler}
                  showSiblingForm={showSiblingForm}
                  toggleShowSiblingFormHandler={toggleShowSiblingFormHandler}
                  siblingRecord={siblingRecord}
                  setSiblingRecordHandler={setSiblingRecordHandler}
                  FormActions={FormActions}
                  previousHandler={previousHandler}
                />
              )}
              <div className="shadow-sm p-2 mb-2 bg-white rounded">
                <div className="row justify-content-center py-3">
                  <Button
                    variant="outlined"
                    onClick={previousHandler}
                    className="FBMedFormFooterButton"
                  >
                    <ArrowBackIcon
                      style={{ color: "#143247", fontSize: "18pt" }}
                    />
                    &nbsp;Back
                  </Button>
                  <Button
                    variant="contained"
                    // type="submit"
                    disabled={getDisableStateForContinueBtn()}
                    className="FBMedFormFooterButton FBMedFormFooterButtonRight"
                    onClick={continueHandler}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
}

export default FamilyMedicalHistoryLandingContainer;
