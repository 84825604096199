import React, { useState } from "react";
import { useNavigate } from "react-router";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import LifePortraitHeader from "components/Header/LifePortraitHeader";
import LifePortraitFooter from "components/Footer/LifePortraitFooter";
import { RouteConstants } from "routes/AppRoutes";
import { getPortraitData } from "../state/lifePortraitActions";
import FraudPenaltyAgreementPres from "../presentation/FraudPenaltyAgreementPres";

export default function FraudPenaltyAgreement() {
  const dispatch = useDispatch();
  const [disableContinue, setDisableContinue] = useState(true);
  const navigate = useNavigate();
  const { control } = useForm({ mode: "onChange" });

  const errorNavigation = (errArg) => {
    const {
      code,
      message,
      response: { status, statusText },
    } = errArg;
    navigate(RouteConstants.Error, {
      state: {
        code,
        message,
        status,
        statusText,
      },
    });
  };

  const gotoWelcome = () => {
    dispatch(getPortraitData(errorNavigation));
    navigate("/Welcome/");
  };
  const onFraudPenaltyAgreementAcceptChange = (e) => {
    setDisableContinue(e === false);
  };
  const onFraudPenaltyAgreementDoNotAccept = () => {
    navigate("/FraudPenaltyAgreementAreYouSure/");
  };

  return (
    <>
      <LifePortraitHeader id="header" />
      <FraudPenaltyAgreementPres
        control={control}
        disableContinue={disableContinue}
        gotoWelcome={gotoWelcome}
        onFraudPenaltyAgreementAcceptChange={
          onFraudPenaltyAgreementAcceptChange
        }
        onFraudPenaltyAgreementDoNotAccept={onFraudPenaltyAgreementDoNotAccept}
      />
      <LifePortraitFooter id="footer" />
    </>
  );
}
