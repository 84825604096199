/* eslint-disable react/no-danger */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/state-in-constructor */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unneeded-ternary */
import React from "react";
// import classNames from "classnames";
import InputLabel from "@mui/material/InputLabel";
import { Typography } from "@mui/material";

const ContentControlHOC = (
  ComposedComponent,
  updateStateCb,
  setComponentRef,
  onUpdateState
) => {
  class ControlHOCClass extends React.Component {
    state = {
      ...this.props,
    };

    componentDidMount() {
      this.componentMounted = true;
      if (updateStateCb) updateStateCb(this.updateState);
    }

    componentWillUnmount() {
      // we have no idea of a component's lifecycle from the outside so this is a simple workaround
      // to fix the setState({}) no-op warning
      this.componentMounted = false;
    }

    // setup reference callback so setState can be called from outside the HOC
    updateState = (newState) => {
      if (onUpdateState) {
        onUpdateState(newState);
      }

      if (!this.componentMounted) return;

      this.setState({ ...this.state, ...newState });
    };

    render() {
      const {
        isComponentVisible,
        fullWidth,
        componentRef,
        showLabels,
        complexShowLogic,
        numberOfColumns,
        rootNumberOfColumns,
        parentNumberOfColumns: ignoreParent,
        controlTypeName,
        isHiddenMount,
        indentation,
        ...otherProps
      } = this.state;

      const { parentNumberOfColumns } = this.props;

      if (!isComponentVisible && !isHiddenMount) return null;

      // when `showLabels` is enabled we do not want these components to render their own label
      const showLabelIgnoreList = ["Radio"];
      const labelProp =
        showLabels && showLabelIgnoreList.includes(controlTypeName)
          ? { label: undefined }
          : {};
      const componentProps = { ...otherProps };

      if (
        !["Caption", "Checkbox", "Radio", "DropDown", "Text"].includes(
          controlTypeName
        )
      ) {
        delete componentProps.increaseFontSize;
      }

      const component = (
        <ComposedComponent {...componentProps} {...labelProp} />
      );

      // render column
      if (typeof parentNumberOfColumns === "number") {
        return (
          <div
            ref={setComponentRef}
            key={otherProps.id}
            className="content-parser-col"
            style={{
              flexBasis: `${100 / parentNumberOfColumns}%`,
              marginBottom: "10px",
            }}
          >
            {component}
          </div>
        );
      }

      const { caption: labelText, note } = otherProps;

      let indentationStyle = {};
      if (indentation) {
        let indent = 0;
        if (typeof indentation === "string") {
          indent = parseInt(indentation, 10);
        }
        if (typeof indentation === "number") {
          indent = indentation;
        }
        indentationStyle = {
          marginLeft: `${indent * 20}px`,
        };
      }

      return (
        <div
          style={{ marginBottom: "10px", ...indentationStyle }}
          // className={rowClass}
          ref={setComponentRef}
          key={otherProps.id}
        >
          {showLabels && !["Checkbox"].includes(controlTypeName) && (
            <div>
              <InputLabel
                htmlFor={labelText}
                style={{ fontWeight: "bold", paddingBottom: "5px" }}
              >
                {labelText}
              </InputLabel>
            </div>
          )}
          <div>{component}</div>
          {note && (
            <Typography
              variant="body2"
              sx={{ marginY: 1, fontWeight: "bolder" }}
            >
              {note}
            </Typography>
          )}
        </div>
      );
    }
  }

  return ControlHOCClass;
};

export default ContentControlHOC;
