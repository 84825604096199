import axios from "axios";
import { setIsLoading } from "features/LifePortrait/state/lifePortraitSlice";
// import https from "https";
import store from "../../store/configureStore";

export function axiosRequest(
  navigationCallback,
  method,
  url,
  data,
  config,
  errorAction,
  headerParams,
  byPassLoadingIndicator = true
) {
  if (byPassLoadingIndicator) store.dispatch(setIsLoading({ isLoading: true }));
  const paramToken = headerParams ? headerParams.Token : null;
  const token = paramToken || store.getState()?.lifePortrait?.authToken;
  // if (!token) {
  //   return store.dispatch("LOGIN_URL");
  // }

  const axiosConfig = {
    url,
    method: method.toLowerCase(),
    timeout: 80000,
    // httpsAgent: new https.Agent({ rejectUnauthorized: false }),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: method.toLowerCase() !== "get" ? data : null,
    params: method.toLowerCase() === "get" ? data : {},
    ...config,
  };

  if (headerParams) {
    axiosConfig.headers = Object.assign(axiosConfig.headers, headerParams);
  }

  return axios(axiosConfig)
    .then((res) => {
      const { data: respData } = res;
      if (
        respData.success !== undefined &&
        respData.success === false &&
        respData.error
      ) {
        let { code: errorCode } = respData.error;
        errorCode = errorCode && parseInt(errorCode, 10);

        if ((errorCode && errorCode === 404) || errorCode === 403) {
          // TODO  store.dispatch(loginFailure(loginError({
          // message: intl.get('global.errors.invalidToken') })));
          return { messages: {} };
        }
      }
      if (byPassLoadingIndicator) {
        store.dispatch(setIsLoading({ isLoading: false }));
      }
      return respData;
    })
    .catch((error) => {
      console.error("error >>>", error);
      const { data: errData } = error.response;
      if (navigationCallback) navigationCallback(error);
      if (byPassLoadingIndicator) {
        store.dispatch(setIsLoading({ isLoading: false }));
      }
      return errData;
    });
}

// Read a file under Public folder using axios
export function axiosFileRequest(fileWithPath, navigationCallback) {
  const url = `${process.env.REACT_APP_DATA_API_URL}/${fileWithPath}`;
  return axios
    .get(url)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.error("error >>>", error);
      if (navigationCallback) navigationCallback(error);
      return null;
    });
}
