import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";

export default function FBComboBox(props) {
  const {
    control,
    id,
    label,
    required,
    defaultValue,
    value: propValue,
    disabled,
    disableClearable,
    error: propError,
    items,
    onChange: onInputChange,
    minWidth,
  } = props;

  return (
    <Controller
      name={id}
      control={control}
      defaultValue={defaultValue || propValue || ""}
      rules={{
        required: { value: required, message: `${label} is required` },
      }}
      render={({ field, fieldState: { error = propError } }) => {
        const val = propValue || field.value || "";
        let selectedItem = null;
        if (items.some((e) => e.value === val)) {
          // eslint-disable-next-line prefer-destructuring
          selectedItem = items.filter((e) => e.value === val)[0];
        }

        function setSelectedItem(e, value) {
          if (onInputChange) {
            if (value) {
              onInputChange(value);
              field.onChange({
                ...e,
                target: { ...e.target, value },
              });
            } else {
              onInputChange(e, "", "clear");
              field.onChange({
                ...e,
                target: { ...e.target, value: "" },
              });
            }
          }
        }

        return (
          <FormControl sx={{ minWidth }} size="small">
            <Autocomplete
              id={id}
              name={id}
              required={required}
              label={label}
              value={selectedItem}
              disabled={disabled}
              disableClearable={disableClearable}
              error={!!error}
              sx={{
                "& legend": { width: "auto" },
                ".MuiOutlinedInput-root": { padding: "3px 9px 3px 9px" },
              }}
              onChange={(e, newItem) => {
                setSelectedItem(e, newItem?.value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={label}
                  onBlur={(e) => {
                    const txtVal = params?.inputProps?.value;
                    const matchedItem = items.find((c) => c.label === txtVal || c.value === txtVal);
                    if (matchedItem?.label === txtVal || matchedItem?.value === txtVal) {
                      setSelectedItem(e, matchedItem.value);
                    } else setSelectedItem(e, undefined);
                  }}
                />
              )}
              options={items}
              isOptionEqualToValue={(option, arrItem) => {
                return option.value === arrItem.value;
              }}
            />
          </FormControl>
        );
      }}
    />
  );
}

FBComboBox.propTypes = {
  control: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  disableClearable: PropTypes.bool,
  error: PropTypes.bool,
  placeholder: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  onChange: PropTypes.func,
  minWidth: PropTypes.number,
};

FBComboBox.defaultProps = {
  label: "",
  required: false,
  defaultValue: undefined,
  value: undefined,
  disabled: false,
  disableClearable: false,
  error: false,
  placeholder: undefined,
  onChange: undefined,
  minWidth: 300,
};
