import React from "react";
import PropTypes from "prop-types";
import { Box, Stack, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import FBTextBox from "components/Elements/FBTextBox";
import FBRadioGroup from "components/Elements/FBRadioGroup";
import { propNames } from "util/medicationHelper";

const AddMedicationPres = ({
  id,
  addMedicationHandler,
  menuOption,
  medicationMenuButtons,
  control,
  isValid,
  hideMedCard,
  formValues,
}) => {
  const { menuLabel } = menuOption;

  return (
    <Box sx={{ paddingY: 2 }}>
      <FBTextBox
        id={`${id}-${propNames.medication}`}
        name="medication"
        required
        label="Name of medication"
        placeholder="Name of medication"
        control={control}
        value={formValues[`${id}-${propNames.medication}`]}
      />
      <Typography sx={{ my: 1 }}>Is this a current medication?</Typography>
      <FBRadioGroup
        id={`${id}-${propNames.current}`}
        label="Is this a current medication?"
        control={control}
        items={[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" },
        ]}
        value={formValues[`${id}-${propNames.current}`]}
      />
      <Stack
        direction="row"
        spacing={2}
        sx={{
          width: "fit-content",
          borderRadius: 2,
        }}
      >
        <Button onClick={(e) => hideMedCard(e)} variant="outlined">
          Cancel
        </Button>
        <Button
          disabled={!isValid}
          onClick={(e) => addMedicationHandler(e)}
          variant="outlined"
          sx={{ my: 1 }}
        >
          {medicationMenuButtons.addNewMedication === menuLabel
            ? `Add This Medication`
            : `Modify This Medication`}
        </Button>
      </Stack>
    </Box>
  );
};

AddMedicationPres.propTypes = {
  id: PropTypes.string,
  addMedicationHandler: PropTypes.func,
  menuOption: PropTypes.object,
  control: PropTypes.object,
  medicationMenuButtons: PropTypes.object,
  isValid: PropTypes.bool,
  hideMedCard: PropTypes.func,
  formValues: PropTypes.object,
};
export default AddMedicationPres;
