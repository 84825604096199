/* eslint-disable no-debugger */
/* eslint-disable operator-assignment */
import { createSlice } from "@reduxjs/toolkit";
import { FormActions } from "../helper/FamilyMemberUtils";

const pages = [
  {
    familyMember: "father",
    status: "started",
    responses: undefined,
  },
  {
    familyMember: "mother",
    status: "started",
    responses: undefined,
  },
  {
    familyMember: "siblingExists",
    status: "started",
    responses: undefined,
  },
  {
    familyMember: "sibling",
    status: "started",
    responses: undefined,
  },
];
const initialState = {
  activePageIndex: 0,
  activePage: pages[0],
  pages,
  showSiblingForm: false,
  siblingRecord: {
    action: FormActions.Add,
    id: undefined,
  },
};

const familyHistorySlice = createSlice({
  name: "familyHistory",
  initialState,
  reducers: {
    setFamilyMedHistActivePageIndex(state, action) {
      const { payload } = action;
      state.activePageIndex = payload;
    },
    setFamilyMedHistActivePage(state, action) {
      const { payload } = action;
      state.activePage = payload;
    },
    setFamilyMedHistPages(state, action) {
      const { payload } = action;
      state.pages = payload;
    },
    toggleShowSiblingForm(state, action) {
      const { payload } = action;
      state.showSiblingForm = payload;
    },
    setSiblingRecord(state, action) {
      const { payload } = action;
      state.siblingRecord = payload;
    },
  },
});

export const {
  setFamilyMedHistActivePageIndex,
  setFamilyMedHistActivePage,
  setFamilyMedHistPages,
  toggleShowSiblingForm,
  setSiblingRecord,
} = familyHistorySlice.actions;

export default familyHistorySlice.reducer;
