/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import FacebookIcon from '@mui/icons-material/FacebookRounded';
import TwitterIcon from '@mui/icons-material/Twitter';
import Layout from "components/Header/Layout";
// import { useLocation, useNavigate } from "react-router";
// import { RouteConstants } from "routes/AppRoutes";
// import { useDispatch } from "react-redux";
// import { updatePortraitStatus } from "../state/lifePortraitActions";

export default function AgentCompletionPres() {
  // const navigate = useNavigate();
  // function useQuery() {
  //   const { search } = useLocation();
  //   const params = new URLSearchParams(search);
  //   const newParams = new URLSearchParams();

  //   params.forEach((key, value) => {
  //     newParams.append(value.toLowerCase(), key);
  //   });

  //   return newParams;
  // }
  // const query = useQuery();
  // const documentId = query.get('d');
  // const applicationId = query.get('a');
  // const dispatch = useDispatch();

  // // eslint-disable-next-line no-unused-vars

  // const errorNavigation = (errArg) => {
  //   const {
  //     code,
  //     message,
  //     response: { status, statusText },
  //   } = errArg;
  //   navigate(RouteConstants.Error, {
  //     state: {
  //       code,
  //       message,
  //       status,
  //       statusText,
  //     },
  //   });
  // };

  return (
    <Layout>

      <div className="container-xl min-vh-100" align="center">
        <div className="pt-4">
          <p style={{ fontWeight: 'bolder', fontSize: '42px', letterSpacing: '0.025em', color: '#143247' }}>
            You&#39;ve completed your Life Portrait!
          </p>
        </div>
      </div>
      <div className="mt-auto bgPrimaryDarkBlueFB">
        <footer className="d-flex flex-wrap justify-content-between align-items-center px-2 py-2 border-top border-3">
          <div className="col-md-4 d-flex align-items-center">
            <span className="text-white small">
              &copy; {new Date().getFullYear()} Tennessee Farmers Insurance Companies.<br />
              P.O. Box 307 Columbia, TN  38402-0307<br />
              Customer Service Center 877-876-2222
            </span>
          </div>
          <ul className="nav col-md-4 justify-content-end list-unstyled d-flex">
            <li className="ms-3"><a aria-label="farmbureau" className="text-white text-decoration-none" href="https://www.fbitn.com/" target="_blank" rel="noopener noreferrer">fbitn.com</a></li>
            <li className="ms-3"><a aria-label="facebook" className="text-muted" href="https://www.facebook.com/FarmBureauInsuranceTN" target="_blank" rel="noopener noreferrer"><FacebookIcon color="white" /></a></li>
            <li className="ms-3"><a aria-label="twitter" className="text-muted" href="https://twitter.com/FarmerCharlie" target="_blank" rel="noopener noreferrer"><TwitterIcon color="white" /></a></li>
          </ul>
        </footer>
      </div>
    </Layout>
  );
}
