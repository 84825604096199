/* eslint-disable operator-assignment */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  portraitMedicationHistory: [],
  additionalMedications: [],
  showMedEditForm: null
};
const medicationHistorySlice = createSlice({
  name: "medicationHistory",
  initialState,
  reducers: {
    setPortraitMedicationHistory(state, action) {
      const { payload } = action;
      state.portraitMedicationHistory = payload.portraitMedicationHistory;
    },
    setAdditionalMedications(state, action) {
      const { payload } = action;
      state.additionalMedications = payload.additionalMedications;
    },
    setShowMedEditForm(state, action) {
      const { payload } = action;
      state.showMedEditForm = payload.showMedEditForm;
    },
  },
});

export const {
  setPortraitMedicationHistory,
  setAdditionalMedications,
  setShowMedEditForm
} = medicationHistorySlice.actions;

export default medicationHistorySlice.reducer;
