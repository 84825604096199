import React from "react";

function App() {
  return (
    <div className="App">
      <header className="App-header" />
      <h4>Life Portrait</h4>
    </div>
  );
}

export default App;
