/* eslint-disable react/forbid-prop-types */
import { memo } from "react";
import Layout from "components/Header/Layout";
import PropTypes from "prop-types";
import ContentParser from "components/ContentParser";
import { Button } from "@mui/material";

function ReviewQuestionsPres({
  control,
  // disableContinue,
  // onAcknowledgeAnswersChange,
  questions,
  formStateIsInvalid,
  handleBackBtn,
  handleContinueBtnClick,
  reviewGroupId,
  setValue,
  question,
  forceRender
}) {
  return (
    <Layout>
      <div className="bgSecondaryFBgrey container-fluid min-vh-100 py-3 px-3">
        <div className="shadow-sm p-2 mb-2 bg-white rounded">
          <h5>
            {question}
          </h5>
          <ContentParser
            key={reviewGroupId}
            groupId={reviewGroupId}
            pageId={1}
            numberOfColumns={1}
            forceRender={forceRender}
            data={{ pageElements: questions }}
            control={control}
            tunnelToParent={{
              setValue,
            }}
            // onChange={(e) => console.log(e)}
          />

        </div>
        <div className="shadow-sm p-2 mb-2 bg-white rounded">
          <div className="row justify-content-center py-3">
            <Button
              variant="outlined"
              className="FBMedFormFooterButton FBMedFormFooterButtonRight"
              onClick={handleBackBtn}
            >
                &nbsp;Back
            </Button>
            <Button
              variant="contained"
              disabled={!formStateIsInvalid}
              onClick={handleContinueBtnClick}
              className="FBMedFormFooterButton FBMedFormFooterButtonRight"
            >
              Continue
            </Button>
          </div>
        </div>
      </div>
    </Layout>
  );
}

ReviewQuestionsPres.propTypes = {
  control: PropTypes.any,
  // onAcknowledgeAnswersChange: PropTypes.func,
  questions: PropTypes.array,
  formStateIsInvalid: PropTypes.bool,
  handleBackBtn: PropTypes.func,
  handleContinueBtnClick: PropTypes.func,
  reviewGroupId: PropTypes.string,
  setValue: PropTypes.func,
  question: PropTypes.string,
  forceRender: PropTypes.number
};

export default memo(ReviewQuestionsPres);
