import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";

import LifePortraitHeader from "components/Header/LifePortraitHeader";
import LifePortraitFooter from "components/Footer/LifePortraitFooter";

import {
  getPhoneNumber,
  setPortraitIds,
} from "features/LifePortrait/state/lifePortraitSlice";
import { RouteConstants } from "routes/AppRoutes";
import { sendPortraitEnvelope } from "services/LifePortrait.service";
import { restoreStateFromLocalStorageToRedux } from "util/localStorageUtil";
import {
  verifyPasscodeAction,
  getPortraitData,
  getPortraitDataSummary,
  sendPasscodeAction,
} from "../state/lifePortraitActions";
import CheckYourTextsPres from "../presentation/CheckYourTextsPres";
import RedirectToDocusignPres from "../presentation/RedirectingToDocusign";

export default function LifePortraitGetStarted() {
  const phoneNumber = useSelector(getPhoneNumber());
  // const { phoneNumber } = rState.lifePortrait;
  const dispatch = useDispatch();
  const { applicationId, documentId } = useSelector(
    (state) => state.lifePortrait
  );
  const { control } = useForm({ mode: "onChange" });
  const navigate = useNavigate();
  const [message, setMessage] = useState(null);
  const [redirect, setRedirect] = useState(null);
  const [passcode, setPasscode] = useState("");
  window.scrollTo(0, 0);

  // console.log(rState);
  const errorNavigation = (errArg) => {
    const {
      code,
      message: errorMessage,
      response: { status, statusText },
    } = errArg;
    navigate(RouteConstants.Error, {
      state: {
        code,
        message: errorMessage,
        status,
        statusText,
      },
    });
  };

  useEffect(() => {
    if (!applicationId && !documentId) {
      const obj = restoreStateFromLocalStorageToRedux();
      dispatch(
        setPortraitIds({
          applicationId: obj?.applicationId,
          documentId: obj?.documentId,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const queryString = `?a=${applicationId}&d=${documentId}`;
  const verifyCode = () => {
    setMessage(null);
    dispatch(verifyPasscodeAction(passcode))
      .then((result) => {
        if (result?.isValid) {
          dispatch(getPortraitDataSummary(errorNavigation)).then((r) => {
            if (r.status === "Initial") {
              navigate("/YouAreVerified/");
            } else if (r.status === "Declined") {
              dispatch(getPortraitData(errorNavigation));
              navigate("/OptedOut/");
            } else if (r.status === "Complete" || r.status === "Signed") {
              dispatch(getPortraitData(errorNavigation));
              navigate(`/Completion${queryString}`, {
                state: {
                  from: "CheckYourTexts",
                },
              });
            } else if (r.status === "Incomplete") {
              navigate("/Incomplete");
            } else if (r.status === "SentForSignature") {
              setRedirect(true);
              const returnURL = process.env.REACT_APP_MSAL_REDIRECT_URL.replace(
                "blank",
                `Completion?a=${applicationId}&d=${documentId}`
              );
              sendPortraitEnvelope(
                { applicationId, documentId, returnURL },
                errorNavigation
              ).then((response) => {
                window.open(response.signerURL, "_self");
              });
            } else {
              dispatch(getPortraitData(errorNavigation));
              navigate("/Welcome/");
            }
          });
        } else if (result.errorMessage) {
          setMessage(result.errorMessage);
        } else setMessage("Invalid Code");
      })
      .catch((err) => {
        setMessage(err.message);
      });
  };

  const onVerificationCodeChange = (code) => {
    setPasscode(code);
    if (message) setMessage(null);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    verifyCode();
  };

  const sendCodeHandler = (e) => {
    dispatch(sendPasscodeAction(e.target.id, errorNavigation)).then((res) => {
      if (res.success) navigate("/LifePortraitCheckYourTexts/");
    });
  };

  return (
    <>
      <LifePortraitHeader id="header" />
      {redirect ? (
        <RedirectToDocusignPres />
      ) : (
        <CheckYourTextsPres
          control={control}
          message={message}
          onVerificationCodeChange={onVerificationCodeChange}
          phoneNumber={phoneNumber}
          verifyCode={verifyCode}
          handleSubmit={handleSubmit}
          sendCodeHandler={sendCodeHandler}
          passcode={passcode}
        />
      )}

      <LifePortraitFooter id="footer" />
    </>
  );
}
