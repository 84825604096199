import * as React from "react";
import PropTypes from "prop-types";
import { InputLabel } from "@mui/material";

export default function FBCaption(props) {
  const { id, label } = props;

  return <InputLabel id={id} label={label} />;
}

FBCaption.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
};

FBCaption.defaultProps = {
  label: "",
};
