/* eslint-disable operator-assignment */
import { createSlice } from "@reduxjs/toolkit";
import {
  ArchiveStatus,
  DashboardTabs,
  portraitFeatureModalEnum,
} from "../helper/portraitHelper";

const initialState = {
  isLoading: false,
  portraitPayload: {
    mode: "",
    agentUserId: "",
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    maxRecords: -1,
    continuationToken: undefined,
    archiveStatus: ArchiveStatus.None,
  },
  currentTab: DashboardTabs.Dashboard,
  messageBox: {
    state: false,
    message: "",
  },
  confirmDialog: {
    state: false,
    message: "",
  },
  archiveDialog: {
    state: false,
    row: {},
    newArchive: "",
  },
  deleteDialog: {
    state: false,
    row: {},
  },
  gridState: { page: 0, pageSize: 10 },
  columnVisibilityModel: { agentName: false },
  portraitApplications: [],
  // archivedApplications: [],
  snapshotCounts: {},
  portraitDetail: {
    details: {},
    groupSummary: {},
    clients: [],
    recipients: [],
    agentSignedUrl: undefined,
  },
  portraitFeatureModal: portraitFeatureModalEnum.closeModal,
  deletePermission: false
};

const agentDashboardSlice = createSlice({
  name: "agentDashboard",
  initialState,
  reducers: {
    setCurrentTab(state, action) {
      const { payload } = action;
      state.currentTab = payload;
    },
    setIsLoading(state, action) {
      const { payload } = action;
      state.isLoading = payload;
    },
    setPortraitPayload(state, action) {
      const { payload } = action;
      state.portraitPayload = payload;
    },
    setPortraitApplications(state, action) {
      const { payload } = action;
      state.portraitApplications = payload.data;
    },
    // setArchivedApplications(state, action) {
    //   const { payload } = action;
    //   state.archivedApplications = payload.data;
    // },
    setSnapshotCounts(state, action) {
      const { payload } = action;
      state.snapshotCounts = payload;
    },
    setPortraitDetails(state, action) {
      const { payload } = action;
      state.portraitDetail.details = payload;
    },
    setPortraitDetailGroupSummary(state, action) {
      const { payload } = action;
      state.portraitDetail.groupSummary = payload;
    },
    setPortraitDetailClients(state, action) {
      const { payload } = action;
      state.portraitDetail.clients = payload;
    },
    setGridState(state, action) {
      const { payload } = action;
      state.gridState = payload;
    },
    setPortraitFeatureModal(state, action) {
      const { payload } = action;
      state.portraitFeatureModal = payload;
    },
    setMessageBoxState(state, action) {
      const { payload } = action;
      state.messageBox = payload;
    },
    setConfirmDialogState(state, action) {
      const { payload } = action;
      state.confirmDialog = payload;
    },
    setArchiveDialogState(state, action) {
      const { payload } = action;
      state.archiveDialog = payload;
    },
    setDeleteDialogState(state, action) {
      const { payload } = action;
      state.deleteDialog = payload;
    },
    setPortraitAgentSignedUrl(state, action) {
      const { payload } = action;
      state.portraitDetail.agentSignedUrl = payload;
    },
    setPortraitDetailRecipients(state, action) {
      const { payload } = action;
      state.portraitDetail.recipients = payload;
    },
    setDeletePermission(state, action) {
      const { payload } = action;
      state.deletePermission = payload;
    },
    setColumnVisibilityModel(state, action) {
      const { payload } = action;
      state.columnVisibilityModel = payload;
    }
  },
});

export const {
  setCurrentTab,
  setIsLoading,
  setPortraitPayload,
  setPortraitApplications,
  // setArchivedApplications,
  setPortraitDetails,
  setPortraitDetailGroupSummary,
  setSnapshotCounts,
  setGridState,
  setPortraitFeatureModal,
  setPortraitDetailClients,
  setMessageBoxState,
  setPortraitDetailRecipients,
  setPortraitAgentSignedUrl,
  setConfirmDialogState,
  setArchiveDialogState,
  setDeleteDialogState,
  setDeletePermission,
  setColumnVisibilityModel
} = agentDashboardSlice.actions;

export default agentDashboardSlice.reducer;
