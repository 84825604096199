/* eslint-disable no-debugger */
import { Box, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { RouteConstants } from "routes/AppRoutes";

function ErrorHandler() {
  const { applicationId, documentId, mode } = useSelector(
    (state) => state.lifePortrait
  );
  const { state } = useLocation();
  const { status, statusText, code, message, originUri } = state;
  const uriSegment = `?a=${applicationId}&d=${documentId}`;
  let uri = `${window.location.origin}`;
  if (mode && mode === "agent") uri = `${uri}/${RouteConstants.AgentLanding}`;
  else if (mode && mode === "examone") {
    uri = `${uri}/${RouteConstants.ExamOneLanding}`;
  }
  uri = applicationId ? `${uri}${uriSegment}` : uri;
  if (originUri) uri = originUri;
  return (
    <Box
      sx={{
        borderColor: "primary.main",
        borderRadius: 2,
        boxShadow: 1,
        width: 3 / 4,
        margin: 6,
        padding: 4,
        alignContent: "center",
      }}
    >
      <h4>
        {mode === "client"
          ? `Please contact your agent for further assistance.`
          : ""}
      </h4>
      <h6>
        {!status ? `Oops something went wrong !!! ` : ""}{" "}
        <a href={uri}>Click here</a> to get to the portrait again.
      </h6>
      <br />
      <Typography>{`${status}-${statusText}`}</Typography>
      <Typography>{`${code}-${message}`}</Typography>
    </Box>
  );
}

export default ErrorHandler;
