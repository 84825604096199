import * as React from "react";
import { useNavigate } from "react-router";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import logo from "assets/LifePortrait.png";
import LifePortraitFooter from "components/Footer/LifePortraitFooter";
import { useDispatch } from "react-redux";
import { RouteConstants } from "routes/AppRoutes";
import { updatePortraitStatus } from "../state/lifePortraitActions";

export default function FraudPenaltyAgreementAreYouSurePres(props) {
  const { id } = props;
  const navigate = useNavigate();
  const gotoFraudPenaltyAgreement = () => {
    navigate("/FraudPenaltyAgreement/");
  };
  const dispatch = useDispatch();

  const errorNavigation = (errArg) => {
    const {
      code,
      message,
      response: { status, statusText },
    } = errArg;
    navigate(RouteConstants.Error, {
      state: {
        code,
        message,
        status,
        statusText,
      },
    });
  };

  const gotoOptedOut = () => {
    dispatch(updatePortraitStatus("Declined", errorNavigation));
    navigate("/OptedOut/");
  };
  return (
    <>
      <nav
        id={id}
        name={id}
        className="navbar navbar-expand-lg navbar-dark bgPrimaryFBgrey"
      >
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <img src={logo} alt="Farm Bureau Logo" />
          </a>
        </div>
      </nav>
      <div className="container-xl min-vh-100 py=3" align="center">
        <div>
          <p>
            <h4>Are you sure?</h4>
          </p>
        </div>
        <div className="mb-3">
          Life Insurance ensures your family&#39;s security when you need it
          most.
        </div>
        <div className="mb-3">
          You will lose any progress you&#39;ve made in the life application
          process if you do not continue.
        </div>
        <div className="d-flex justify-content-center py-1">
          <Button
            id="btnContinue"
            variant="contained"
            onClick={gotoFraudPenaltyAgreement}
          >
            Continue To Life Portrait
          </Button>
        </div>
        <div className="d-flex justify-content-center py-1">
          <Button
            id="btnDoNotAcceptFraudPenaltyAgreement"
            variant="text"
            onClick={gotoOptedOut}
          >
            I Do Not Want To Continue Life Portrait&nbsp;
            <ArrowForwardIcon />
          </Button>
        </div>
      </div>
      <LifePortraitFooter id="footer" />
    </>
  );
}

FraudPenaltyAgreementAreYouSurePres.defaultProps = {
  id: "fpaAreYouSure",
};
FraudPenaltyAgreementAreYouSurePres.propTypes = {
  id: PropTypes.string,
};
