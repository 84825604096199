import * as React from "react";
import { useNavigate } from "react-router";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PrivacyPolicy from "components/Elements/FBPrivacyPolicy";
import LifePortraitHeader from "components/Header/LifePortraitHeaderWithChecklist";
import LifePortraitFooter from "components/Footer/LifePortraitFooter";

export default function PrivacyPolicyPage() {
  const navigate = useNavigate();
  const goToHome = () => {
    navigate("/Welcome");
  };
  return (
    <>
      <LifePortraitHeader id="header" />
      <div className="bg-light min-vh-100">
        <div className="container py-3">
          <PrivacyPolicy id="privacyPolicy" />
        </div>
        <div className="row justify-content-center px-3 py-3">
          <Button
            variant="outlined"
            onClick={goToHome}
            className="FBMedFormFooterButton"
          >
            <ArrowBackIcon style={{ color: "#143247", fontSize: "18pt" }} />
            &nbsp;Back
          </Button>
        </div>
      </div>
      <LifePortraitFooter id="footer" />
    </>
  );
}
