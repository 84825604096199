/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { memo } from "react";
import ContentParser from "components/ContentParser";
import PropTypes from "prop-types";

function QuestionnaireGroupPres({
  groupId,
  activePage,
  questions,
  onChange,
  forceRender,
  control,
  tunnelToParent,
}) {
  return (
    <ContentParser
      key={groupId || 1}
      groupId={groupId}
      pageId={activePage}
      numberOfColumns={1}
      forceRender={forceRender}
      data={{ pageElements: questions }}
      onChange={onChange}
      control={control}
      tunnelToParent={tunnelToParent}
    />
  );
}

// GetStartedPres.propTypes = {
//   phoneNum: PropTypes.string,
//   gotoCheckYourTexts: PropTypes.func,
// };

export default memo(QuestionnaireGroupPres);
