import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import LifePortraitHeader from "components/Header/LifePortraitHeader";
import { RouteConstants } from "routes/AppRoutes";
import {
  setPortraitIds,
  setMode,
} from "../LifePortrait/state/lifePortraitSlice";
import {
  getPortraitData,
  getPortraitDataSummary,
  getExamOneUserTokenAction,
} from "../LifePortrait/state/lifePortraitActions";

export default function ExamOneLanding() {
  // const { instance, inProgress, accounts } = useMsal();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const applicationId = searchParams.get("a");
  const documentId = searchParams.get("d");

  useEffect(() => {
    dispatch(setPortraitIds({ applicationId, documentId }));
    dispatch(setMode({ mode: "examone" }));
    dispatch(getExamOneUserTokenAction())
      .then(() => {
        const errorNavigation = (errArg) => {
          const {
            code,
            message,
            response: { status, statusText },
          } = errArg;
          navigate(RouteConstants.Error, {
            state: {
              code,
              message,
              status,
              statusText,
            },
          });
        };

        dispatch(getPortraitDataSummary(errorNavigation))
          .then((s) => {
            if (s.status === "Initial") {
              navigate("/YouAreVerified");
            } else if (s.status === "Declined") {
              dispatch(getPortraitData(errorNavigation));
              navigate("/OptedOut/");
            } else if (s.status === "Complete" || s.status === "QxComplete") {
              const queryString = `?a=${applicationId}&d=${documentId}`;
              navigate(`/EOCompletion${queryString}`);
            } else if (s.status === "Incomplete") {
              navigate("/Incomplete");
            } else {
              dispatch(getPortraitData());
              navigate("/Welcome/");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [applicationId, documentId, navigate, dispatch]);

  return (
    <>
      <LifePortraitHeader id="header" />
      <div className="container-xl min-vh-100">
        <form className="shadow bg-body rounded py-3">
          <div className="card-body">
            <p className="card-text">
              Please wait while the portrait is being prepared...
            </p>
          </div>
        </form>
      </div>
    </>
  );
}
