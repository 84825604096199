/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/forbid-prop-types */
import Layout from "components/Header/Layout";
import React, { memo } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography, Link } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FBReviewReminder from "components/Elements/FBReviewReminder";
import FBCheckbox from "components/Elements/FBCheckbox";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import PropTypes from "prop-types";

function ReviewLandingPres({
  reviewData,
  control,
  disableContinue,
  openReminder,
  onAgreementAcceptChange,
  showReview,
  splitAnswers,
  formatMedication,
  reviewChecked,
  goToFinalQuestions,
  goToMedform,
  goBack
}) {
  return (
    <Layout>

      { reviewChecked ? null : (
        <Dialog open={openReminder}>
          <Box className="py-3 px-3">
            <FBReviewReminder />
            <Divider />
            <div className="d-flex justify-content-center">
              <FBCheckbox
                control={control}
                id="chkReminder"
                label="I accept this agreement"
                value="false"
                controlWidth="300px"
                onChange={onAgreementAcceptChange}
              />
            </div>
            <div className="d-flex justify-content-center">
              <Button
                id="btnContinue"
                variant="contained"
                disabled={disableContinue}
                onClick={showReview}
              >
                Continue
              </Button>
            </div>
          </Box>
        </Dialog>
      )}
      { reviewData.groups?.map((group) => (
        group.id === "PS-RS-1" ? null : (
          <div className="container-fluid">
            <Accordion defaultExpanded={group.details}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{ fontWeight: 500, flexDirection: 'row-reverse' }}
              >
                {group.title}
              </AccordionSummary>
              <AccordionDetails>
                { group.details ? group.details?.map((details) => (
                  details.level === 0
                    ? (
                      <div className="pt-2 pb-2 ">
                        <Typography
                          sx={{ fontWeight: 1000, fontSize: 20, pl: 2, ml: details.level }}
                        > {details.answer}
                        </Typography>
                      </div>
                    )
                    : (
                      <>
                        <Typography sx={{ ml: details.level * 4 }}>
                          {details.question}
                        </Typography>
                        {
                        details.answer.includes(' / ')
                          ? splitAnswers(details.answer, details.level)
                          : (
                            <div className="pb-1">
                              <Typography sx={{ ml: details.level * 4, fontWeight: 500 }}>
                                {
                                  details.question.includes('medication')
                                    ? formatMedication(details.answer)
                                    : details.answer
                                }
                              </Typography>
                            </div>
                          )
                      }
                      </>
                    )
                )) : <Typography> You do not have any details in this section </Typography>}
              </AccordionDetails>
              <div className="px-3 pb-3" style={{ cursor: 'pointer' }}>
                <Link underline="always" onClick={() => goToMedform(group.id)}> Go to this section </Link>
              </div>
            </Accordion><br />
          </div>
        )))}
      <div className="container-fluid">
        <div className="row justify-content-center py-3">
          <Button
            variant="outlined"
            onClick={goBack}
            className="FBMedFormFooterButton FBMedFormFooterButtonRight"
          >
            <ArrowBackIcon style={{ color: "#143247", fontSize: "18pt" }} />
              &nbsp;Prev Section
          </Button>
          <Button
            variant="contained"
            onClick={goToFinalQuestions}
            className="FBMedFormFooterButton FBMedFormFooterButtonRight"
          >
              &nbsp;Continue
          </Button>
        </div>
      </div>
    </Layout>
  );
}

ReviewLandingPres.propTypes = {
  reviewData: PropTypes.object,
  control: PropTypes.any,
  disableContinue: PropTypes.bool,
  openReminder: PropTypes.bool,
  onAgreementAcceptChange: PropTypes.func,
  showReview: PropTypes.func,
  splitAnswers: PropTypes.func,
  formatMedication: PropTypes.func,
  reviewChecked: PropTypes.bool,
  goToFinalQuestions: PropTypes.func,
  goToMedform: PropTypes.func,
  goBack: PropTypes.func,
};

export default memo(ReviewLandingPres);
