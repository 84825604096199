import {
  rowDelimiter,
  colDelimiter,
  keyValueDelimiter,
  removeDelimiters,
} from "./genericHelper";

export const propNames = {
  medication: "medication",
  current: "current",
};

export const transformInput = (id, val, uuidv4, setValue) => {
  // let val = "Med1~1|Med2~0|Med3~0";
  const returnObj = [];
  if (val) {
    const rows = val.split(rowDelimiter);
    if (rows.length) {
      rows.forEach((el) => {
        if (el && el !== "") {
          const cols = el.split(colDelimiter);
          if (cols.length > 0) {
            const medication = cols[0].split(keyValueDelimiter)[1];
            const current = cols[1].split(keyValueDelimiter)[1];
            returnObj.push({
              guid: uuidv4(),
              [`${id}-${propNames.medication}`]: medication,
              [`${id}-${propNames.current}`]: current === "1" ? "Yes" : "No",
            });
          }
        }
      });
    }
  }
  if (setValue) {
    setValue(`${id}-complete`, returnObj.length > 0);
  }
  return returnObj;
};

export const transformOutput = (id, meds) => {
  // sample = "Med1~1|Med2~0|Med3~0";
  let returnVal = "";
  if (meds && meds.length > 0) {
    meds.forEach((el) => {
      const medicationVal = removeDelimiters(
        el[`${id}-${propNames.medication}`]
      );
      const currentVal = removeDelimiters(el[`${id}-${propNames.current}`]);
      returnVal += `${
        propNames.medication
      }${keyValueDelimiter}${medicationVal}${colDelimiter}${
        propNames.current
      }${keyValueDelimiter}${currentVal === "Yes" ? 1 : 0}${rowDelimiter}`;
    });
  }
  return returnVal.slice(0, -1);
};
