/* eslint-disable react/prop-types */
import React from "react";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { medFrmRouteSeg } from "util/genericHelper";

const ActionBarPres = ({
  navBtn,
  navGroupBtn,
  navigate,
  backBtnClickHandler,
  getValues,
  formStateIsInvalid,
  // handleSubmit,
  // onSubmit,
  continueBtnClickHandler,
  isDirty,
}) => {
  return (
    <div className="row justify-content-center py-3">
      {!navBtn.backEnabled ? (
        <Button
          id="backGroupBtn"
          disabled={
            navGroupBtn.prevGroupIndex === 0 &&
            navGroupBtn.prevGroupIndex === navGroupBtn.currentGroupIndex
          }
          variant="outlined"
          onClick={() => {
            navigate(`${medFrmRouteSeg}${navGroupBtn.prevGroupId}`);
          }}
          className="FBMedFormFooterButton"
        >
          <ArrowBackIcon style={{ color: "#143247", fontSize: "18pt" }} />
          &nbsp;Prev Section
        </Button>
      ) : (
        <Button
          id="backBtn"
          disabled={!navBtn.backEnabled}
          variant="outlined"
          onClick={(e) => {
            backBtnClickHandler(e, getValues, isDirty);
            // handleSubmit(saveData)();
          }}
          className="FBMedFormFooterButton"
        >
          <ArrowBackIcon style={{ color: "#143247", fontSize: "18pt" }} />
          &nbsp;Back
        </Button>
      )}
      {!navGroupBtn.continueEnabled ? (
        <Button
          id="continueButton"
          disabled={!(formStateIsInvalid && navBtn.continueEnabled)}
          variant="contained"
          type="submit"
          className="FBMedFormFooterButton FBMedFormFooterButtonRight"
          onClick={(e) => {
            e.preventDefault();
            // handleSubmit(onSubmit)("continue");
            continueBtnClickHandler(e, getValues, isDirty);
          }}
        >
          Continue
        </Button>
      ) : (
        <Button
          id="nextGroupButton"
          disabled={!(formStateIsInvalid && navGroupBtn.continueEnabled)}
          variant="contained"
          type="submit"
          className="FBMedFormFooterButton FBMedFormFooterButtonRight"
          onClick={(e) => {
            e.preventDefault();
            // handleSubmit(onSubmit)("nextsection");
            continueBtnClickHandler(e, getValues, isDirty);
          }}
        >
          Continue
          {/* Next Section */}
        </Button>
      )}
    </div>
  );
};

export default ActionBarPres;
