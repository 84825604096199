/* eslint-disable no-restricted-syntax */
import PropTypes from "prop-types";
import FBYesNoToggleButton from "components/Elements/FBYesNoToggleButton";
import FBNumber from "components/Elements/FBNumber";
import FBComboBox from "components/Elements/FBComboBox";
import FBMedicalHistoryCondition from "components/Elements/FBMedicalHistoryCondition";
import FBCheckbox from "components/Elements/FBCheckbox";
import FBTextBox from "components/Elements/FBTextBox";

export default function FamilyMemberPresentation(props) {
  const {
    control,
    member,
    medicalConditions,
    cboConditionsList,
    formValues,
    LivingEnum,
    resetWhenUnknownInfoHandler,
    medicalHistoryConditionChangeHandler,
  } = props;
  const { familyMember } = member;

  const parentAlive = formValues[`${familyMember}_livingStatus`] || undefined;
  const parentAliveUnknownInfo = formValues[`${familyMember}_doNotHaveInfo`];
  let showDetails = !parentAliveUnknownInfo;
  if (showDetails) {
    showDetails = formValues[`${familyMember}_livingStatus`] || undefined;
  }

  const showCauseOfDeathFreeFormTxtbx =
    formValues[`${familyMember}_primaryCauseOfDeath`] === "Other" ||
    formValues[`${familyMember}_primaryCauseOfDeath`] === "Cancer";

  return (
    <>
      <div className="shadow-sm p-3 mb-3 bg-white rounded" id={familyMember}>
        <h5 className="d-flex">{`Biological ${familyMember} is:`}</h5>
        <div className="d-flex">
          <FBYesNoToggleButton
            control={control}
            id={`${familyMember}_livingStatus`}
            controlWidth="130px"
            items={[
              { label: LivingEnum.Alive, value: LivingEnum.Alive },
              { label: LivingEnum.Deceased, value: LivingEnum.Deceased },
            ]}
            onChange={(e) => {
              if (e) {
                resetWhenUnknownInfoHandler(
                  `${familyMember}_doNotHaveInfo`,
                  false
                );
                resetWhenUnknownInfoHandler(`${familyMember}_age`, "");
              }
            }}
          />
        </div>
        <div className="d-flex">
          <FBCheckbox
            control={control}
            id={`${familyMember}_doNotHaveInfo`}
            value={parentAliveUnknownInfo}
            label="Do not have this information"
            controlWidth="260px"
            onChange={(e) => {
              if (e) {
                resetWhenUnknownInfoHandler(`${familyMember}_livingStatus`, "");
                resetWhenUnknownInfoHandler(`${familyMember}_age`, "");
              }
            }}
          />
        </div>
      </div>
      {showDetails && (
        <div id={`${familyMember}_DeceasedQuestions`}>
          <div className="shadow-sm p-3 mb-3 bg-white rounded">
            <h6 className="d-flex">
              {parentAlive === LivingEnum.Deceased
                ? "What was the age at death?"
                : ""}
              {parentAlive === LivingEnum.Alive && familyMember === "father"
                ? "How old is he?"
                : ""}
              {parentAlive === LivingEnum.Alive && familyMember === "mother"
                ? "How old is she?"
                : ""}
            </h6>
            <div className="d-flex" id={`${familyMember}_question`}>
              <FBNumber
                control={control}
                id={`${familyMember}_age`}
                label="Age"
                variant="standard"
                disabled={formValues[`${familyMember}_doNotHaveAgeInfo`]}
                defaultValue={formValues[`${familyMember}_age`]}
                value={formValues[`${familyMember}_age`]}
              />
            </div>
            <div className="d-flex py-3">
              <FBCheckbox
                control={control}
                id={`${familyMember}_doNotHaveAgeInfo`}
                label="Do not have this information"
                controlWidth="260px"
                defaultValue={formValues[`${familyMember}_doNotHaveAgeInfo`]}
                value={formValues[`${familyMember}_doNotHaveAgeInfo`]}
                onChange={(e) => {
                  if (e) {
                    resetWhenUnknownInfoHandler(`${familyMember}_age`, "");
                  }
                }}
              />
            </div>
            {parentAlive === LivingEnum.Deceased && (
              <>
                <h6 className="d-flex">What was the primary cause of death?</h6>
                <div className="d-flex">
                  <FBComboBox
                    id={`${familyMember}_primaryCauseOfDeath`}
                    items={cboConditionsList}
                    control={control}
                    // label="Primary cause of death"
                    controlWidth="260px"
                    defaultValue={
                      formValues[`${familyMember}_primaryCauseOfDeath`]
                    }
                    onChange={(e) => {
                      const condtn = cboConditionsList.find(
                        (el) => el.value === e
                      );
                      if (condtn) {
                        resetWhenUnknownInfoHandler(
                          `${familyMember}_medHist_${condtn.cascadeMedHist}`,
                          true
                        );
                      }
                    }}
                  />
                </div>
                {/* <h6 className="d-flex">Type Of Cancer</h6> */}
                {showCauseOfDeathFreeFormTxtbx && (
                  <div className="d-flex py-3">
                    <FBTextBox
                      control={control}
                      id={`${familyMember}_primaryCauseOfDeathDetail`}
                      label={
                        formValues[`${familyMember}_primaryCauseOfDeath`] ===
                        "Other"
                          ? "Desc"
                          : "Type of cancer"
                      }
                      variant="standard"
                      value={
                        formValues[`${familyMember}_primaryCauseOfDeathDetail`]
                      }
                    />
                  </div>
                )}
              </>
            )}
          </div>
          <div className="shadow-sm p-3 mb-3 bg-white rounded">
            <h6 className="d-flex">
              Are any of the conditions below a part of their medical history.
              Select all that apply.
            </h6>
            <div className="">
              {medicalConditions.map((item) => {
                const medId = `${familyMember}_medHist_${item.id}`;
                return (
                  <FBMedicalHistoryCondition
                    key={medId}
                    id={medId}
                    control={control}
                    item={item}
                    medicalHistoryCondition={formValues}
                    controlWidth="250px"
                    setValue={resetWhenUnknownInfoHandler}
                    onChange={(e) => {
                      medicalHistoryConditionChangeHandler(e, medId);
                    }}
                  />
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

FamilyMemberPresentation.propTypes = {
  control: PropTypes.object.isRequired,
  member: PropTypes.object.isRequired,
  medicalConditions: PropTypes.arrayOf(PropTypes.object),
  cboConditionsList: PropTypes.arrayOf(PropTypes.object),
  formValues: PropTypes.oneOfType([PropTypes.object, undefined]),
  LivingEnum: PropTypes.object,
  resetWhenUnknownInfoHandler: PropTypes.func,
  medicalHistoryConditionChangeHandler: PropTypes.func,
};

FamilyMemberPresentation.defaultProps = {};
