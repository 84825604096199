import serviceUrls from "./apiConfig/serviceUrls";
import { axiosRequest } from "./apiConfig/axiosRequest";

export const getPortraitDataSvc = (params, navigationCallBack) => {
  let urlEndpoint = serviceUrls.PORTRAIT;
  urlEndpoint = urlEndpoint
    .replace(":appId", params.applicationId)
    .replace(":docId", params.documentId);
  return axiosRequest(
    navigationCallBack,
    "get",
    urlEndpoint,
    null,
    null,
    null,
    null
  );
};

export const getPortraitDataSummarySvc = (params, navigationCallBack) => {
  let urlEndpoint = serviceUrls.PORTRAIT_SUMMARY;
  urlEndpoint = urlEndpoint
    .replace(":appId", params.applicationId)
    .replace(":docId", params.documentId);
  return axiosRequest(
    navigationCallBack,
    "get",
    urlEndpoint,
    null,
    null,
    null,
    null
  );
};

export const getProfilePhoneRef = (params, navigationCallBack) => {
  let urlEndpoint = serviceUrls.PROFILE_PHONEREF;
  urlEndpoint = urlEndpoint
    .replace(":appId", params.applicationId)
    .replace(":docId", params.documentId);
  return axiosRequest(
    navigationCallBack,
    "get",
    urlEndpoint,
    null,
    null,
    null,
    null
  );
};

export const getExamOneUserToken = (params, navigationCallBack) => {
  let urlEndpoint = serviceUrls.EXAMONE_USER_TOKEN;
  urlEndpoint = urlEndpoint.replace(":docId", params.documentId);
  return axiosRequest(
    navigationCallBack,
    "get",
    urlEndpoint,
    null,
    { withCredentials: true },
    null,
    null
  );
};

export const sendPasscodeSvc = (payload, navigationCallBack) => {
  return axiosRequest(
    navigationCallBack,
    "post",
    serviceUrls.SEND_PASSCODE,
    payload,
    null,
    null,
    null
  );
};

export const verifyPasscodeSvc = (payload, navigationCallBack) => {
  return axiosRequest(
    navigationCallBack,
    "post",
    serviceUrls.VERIFY_PASSCODE,
    payload,
    null,
    null,
    null
  );
};

export const updatePortraitStatusSvc = (request, navigationCallBack) => {
  const { applicationId, documentId } = request;
  let urlEndpoint = serviceUrls.PORTRAIT_STATUS;
  urlEndpoint = urlEndpoint
    .replace(":appId", applicationId)
    .replace(":docId", documentId);

  return axiosRequest(
    navigationCallBack,
    "POST",
    urlEndpoint,
    request.status,
    null,
    null,
    null
  );
};

export const updatePortraitLastVisitTimeSvc = (request, navigationCallBack) => {
  const { applicationId, documentId } = request;
  let urlEndpoint = serviceUrls.PORTRAIT_LAST_VISIT_TIME;
  urlEndpoint = urlEndpoint
    .replace(":appId", applicationId)
    .replace(":docId", documentId);

  return axiosRequest(
    navigationCallBack,
    "POST",
    urlEndpoint,
    {},
    null,
    null,
    null
  );
};

export const getPortraitReviewData = (request, navigationCallBack) => {
  const { applicationId, documentId } = request;
  let urlEndpoint = serviceUrls.PORTRAIT_REVIEW;

  urlEndpoint = urlEndpoint
    .replace(":appId", applicationId)
    .replace(":docId", documentId);

  return axiosRequest(
    navigationCallBack,
    "GET",
    urlEndpoint,
    request.group,
    null,
    null,
    null
  );
};

export const getPortraitAgentSvc = (params, navigationCallBack) => {
  let urlEndpoint = `${serviceUrls.PORTRAIT}/agent`;
  urlEndpoint = urlEndpoint
    .replace(":appId", params.applicationId)
    .replace(":docId", params.documentId);
  return axiosRequest(
    navigationCallBack,
    "get",
    urlEndpoint,
    null,
    null,
    null,
    null
  );
};

export const sendPortraitEnvelope = (request, navigationCallBack) => {
  const { applicationId, documentId } = request;
  let urlEndpoint = serviceUrls.PORTRAIT_SEND_PORTRAIT_ENVELOPE;
  urlEndpoint = urlEndpoint
    .replace(":appId", applicationId)
    .replace(":docId", documentId);

  return axiosRequest(
    navigationCallBack,
    "POST",
    urlEndpoint,
    { returnURL: request.returnURL },
    { withCredentials: true },
    null,
    null
  );
};

export const completeAfterSigning = (request, navigationCallBack) => {
  const { applicationId, documentId } = request;
  let urlEndpoint = serviceUrls.PORTRAIT_COMPLETE;
  urlEndpoint = urlEndpoint
    .replace(":appId", applicationId)
    .replace(":docId", documentId);
  return axiosRequest(
    navigationCallBack,
    "PUT",
    urlEndpoint,
    null,
    { withCredentials: true },
    null,
    null
  );
};

export const setAccessDevice = (request, navigationCallBack) => {
  const { applicationId, documentId } = request;
  let urlEndpoint = serviceUrls.PORTRAIT_ANALYTICS;
  urlEndpoint = urlEndpoint
    .replace(":appId", applicationId)
    .replace(":docId", documentId);
  return axiosRequest(
    navigationCallBack,
    "POST",
    urlEndpoint,
    { deviceType: request.deviceType },
    null,
    null,
    null
  );
};

export const modifyClientDetails = (request, navigationCallBack) => {
  const { applicationId, documentId, profile } = request;

  let urlEndpoint = serviceUrls.PORTRAIT_MODIFY_INSURED;
  urlEndpoint = urlEndpoint
    .replace(":appId", applicationId)
    .replace(":docId", documentId);
  return axiosRequest(
    navigationCallBack,
    "PUT",
    urlEndpoint,
    {
      prefix: profile.prefix,
      firstName: profile.firstName,
      middleName: profile.middleName,
      lastName: profile.lastName,
      suffix: profile.suffix,
      birthDate: profile.birthDate,
    },
    { withCredentials: true },
    null,
    null
  );
};

export const sendEmailToAgentOfNewClientPhnSvc = (
  request,
  navigationCallBack
) => {
  const { applicationId, documentId, payload } = request;

  let urlEndpoint = serviceUrls.PORTRAIT_SENDEMAIL_AGENT_CLINEWPHONE;
  urlEndpoint = urlEndpoint
    .replace(":appId", applicationId)
    .replace(":docId", documentId);
  return axiosRequest(
    navigationCallBack,
    "PUT",
    urlEndpoint,
    {
      newPhone: payload.newPhone,
    },
    { withCredentials: true },
    null,
    null
  );
};
