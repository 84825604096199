/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Badge, Box, Typography } from "@mui/material";
import FBNumber from "components/Elements/FBNumber";
import FBMonthYearPicker from "components/Elements/FBMonthYearPicker";
import FBComboBox from "components/Elements/FBComboBox";
import FBTextBox from "components/Elements/FBTextBox";

import {
  transformMultiAbnormalPapsInput,
  transformMultiAbnormalPapsOutput,
  propNamesPapSmears,
} from "util/multiAbnormalPapsHelper";
import { idComplete } from "util/genericHelper";

// let renderCount = 0;
const FBMultiAbnormalPapsLanding = (props) => {
  // renderCount += 1;
  const lblComplete = idComplete;
  const { id, required, value, tunnelToParent, options, label } = props;
  const { setValue: parentSetValue } = tunnelToParent;
  const {
    dateShow,
    dateCaption,
    text1Show,
    text1Caption,
    text2Show,
    text2Caption,
    text3Show,
    text3Caption,
    text4Show,
    text4Caption,
    itemCaption,
  } = {
    dateShow: true,
    dateCaption: "When was the abnormal pap smear?",
    text1Show: true,
    text1Caption: "What was the abnormality on the pap smear?",
    text2Show: true,
    text2Caption: "Description?",
    text3Show: true,
    text3Caption: "What most recent treatment was given for the abnormal pap smear?",
    text4Show: true,
    text4Caption: "Description?",
    itemCaption: "Abnormal Pap Smear",
    ...options,
  };

  const tTypeEnum = {
    Cancer: "Cancer",
    Dysplasia: "Dysplasia",
    Other: "Other",
  };
  const papSmearTypes = [
    { label: tTypeEnum.Cancer, value: tTypeEnum.Cancer },
    { label: tTypeEnum.Dysplasia, value: tTypeEnum.Dysplasia },
    { label: tTypeEnum.Other, value: tTypeEnum.Other },
  ];

  const treatmentTypeEnum = {
    LEEP: "LEEP",
    Colposcopy: "Colposcopy",
    Hysterectomy: "Hysterectomy",
    Other: "Other",
  };
  const treatmentTypes = [
    { label: treatmentTypeEnum.LEEP, value: treatmentTypeEnum.LEEP },
    {
      label: treatmentTypeEnum.Colposcopy,
      value: treatmentTypeEnum.Colposcopy,
    },
    {
      label: treatmentTypeEnum.Hysterectomy,
      value: treatmentTypeEnum.Hysterectomy,
    },
    { label: treatmentTypeEnum.Other, value: treatmentTypeEnum.Other },
  ];

  const { control, watch, reset } = useForm({
    mode: "all",
  });

  const formValues = watch();

  const [numberOfTimes, setNumberOfTimes] = useState(null);

  useEffect(() => {
    let isCompleteLocal = false;
    if (value) {
      const {
        numberOfTimes: lnumberOfTimes,
        responses,
        isComplete,
      } = transformMultiAbnormalPapsInput(id, value);
      isCompleteLocal = isComplete;
      const evalnum = parseInt(lnumberOfTimes, 10);
      // eslint-disable-next-line no-restricted-globals
      if (typeof evalnum === "number" && !isNaN(evalnum)) {
        setNumberOfTimes(evalnum);
        // if (lnumberOfTimes) setValue(id, value);
        reset(responses);
      }
    }
    parentSetValue(`${id}${lblComplete}`, isCompleteLocal);
    parentSetValue(id, value);
  }, [id, value]);

  const transformAndUpdateParent = (lid, lnumberOfTimes, lformValues) => {
    const { transformedValue, isComplete } = transformMultiAbnormalPapsOutput(
      lid,
      lnumberOfTimes,
      lformValues
    );
    parentSetValue(`${id}${lblComplete}`, isComplete);
    parentSetValue(
      id,
      transformedValue === "[null]" ? undefined : transformedValue,
      { shouldDirty: true }
    );
  };

  const numberOfTimesChangeHandler = (e) => {
    let lnoOfTimes = e === "" || e === undefined ? null : parseInt(e, 10);
    // Following condition always restricts the use to 10 items
    if (typeof lnoOfTimes === "number" && lnoOfTimes >= 10) lnoOfTimes = 10;
    setNumberOfTimes(lnoOfTimes);
    transformAndUpdateParent(id, lnoOfTimes, formValues);
  };

  const inputChangeHandler = (e, controlId) => {
    transformAndUpdateParent(id, numberOfTimes, {
      ...formValues,
      [controlId]: e,
    });
  };

  const comboBoxChangeHandler = (e, controlId) => {
    const changedValue = (typeof e === 'object') ? { ...formValues } : { ...formValues, [controlId]: e };
    transformAndUpdateParent(id, numberOfTimes, changedValue);
  };

  return (
    <Box
      sx={{
        width: "fit-content",
        border: 1,
        padding: 1,
        borderColor: "lightslategrey",
        borderRadius: 2,
      }}
    >
      {/* {`Rendercount:${renderCount}`} */}
      <Typography color="text.secondary" variant="body2" gutterBottom>
        {label}
      </Typography>
      <FBNumber
        id={`${id}-${propNamesPapSmears.numberOfTimes}`}
        required={required}
        control={control}
        onChange={numberOfTimesChangeHandler}
        value={numberOfTimes}
      />
      <br />
      {typeof numberOfTimes === "number" &&
        numberOfTimes > 0 &&
        [...Array(numberOfTimes)].map((el, index) => {
          const showDesc =
            formValues[`${id}-${index}-${propNamesPapSmears.drpPapSmear}`] ===
            tTypeEnum.Other;
          const showTreatmentDesc =
            formValues[`${id}-${index}-${propNamesPapSmears.drpTreatment}`] ===
            treatmentTypeEnum.Other;
          return (
            <Box
              key={`${id}-${index}`}
              sx={{
                marginTop: "10px",
                // marginBottom: "5px",
                width: "fit-content",
                border: 1,
                padding: 1,
                borderColor: "lightslategrey",
                borderRadius: 2,
              }}
            >
              <Typography variant="subtitle1" gutterBottom>
                <Badge>{`${itemCaption}-${index + 1}`}</Badge>
              </Typography>
              {dateShow && (
                <>
                  <Typography
                    color="text.secondary"
                    variant="body2"
                    gutterBottom
                  >
                    {dateCaption}
                  </Typography>
                  <FBMonthYearPicker
                    id={`${id}-${index}-${propNamesPapSmears.date}`}
                    control={control}
                    required={required}
                    onChange={(e) => {
                      inputChangeHandler(e, `${id}-${index}-date`);
                    }}
                    value={
                      formValues[`${id}-${index}-${propNamesPapSmears.date}`]
                    }
                  />
                  <br />
                </>
              )}
              {text1Show && (
                <>
                  <Typography
                    color="text.secondary"
                    variant="body2"
                    gutterBottom
                  >
                    {text1Caption}
                  </Typography>
                  <FBComboBox
                    id={`${id}-${index}-${propNamesPapSmears.drpPapSmear}`}
                    control={control}
                    required={required}
                    items={papSmearTypes}
                    onChange={(e) => {
                      comboBoxChangeHandler(
                        e,
                        `${id}-${index}-${propNamesPapSmears.drpPapSmear}`
                      );
                    }}
                  />
                </>
              )}
              {text2Show && showDesc && (
                <>
                  <Typography
                    color="text.secondary"
                    variant="body2"
                    gutterBottom
                  >
                    {text2Caption}
                  </Typography>
                  <FBTextBox
                    id={`${id}-${index}-${propNamesPapSmears.otherPapSmear}`}
                    control={control}
                    required={required}
                    onChange={(e) => {
                      inputChangeHandler(
                        e,
                        `${id}-${index}-${propNamesPapSmears.otherPapSmear}`
                      );
                    }}
                    value={
                      formValues[
                        `${id}-${index}-${propNamesPapSmears.otherPapSmear}`
                      ]
                    }
                  />
                </>
              )}
              {text3Show && (
                <>
                  <Typography
                    color="text.secondary"
                    variant="body2"
                    gutterBottom
                  >
                    {text3Caption}
                  </Typography>
                  <FBComboBox
                    id={`${id}-${index}-${propNamesPapSmears.drpTreatment}`}
                    control={control}
                    required={required}
                    items={treatmentTypes}
                    onChange={(e) => {
                      comboBoxChangeHandler(
                        e,
                        `${id}-${index}-${propNamesPapSmears.drpTreatment}`
                      );
                    }}
                  />
                </>
              )}
              {text4Show && showTreatmentDesc && (
                <>
                  <Typography
                    color="text.secondary"
                    variant="body2"
                    gutterBottom
                  >
                    {text4Caption}
                  </Typography>
                  <FBTextBox
                    id={`${id}-${index}-${propNamesPapSmears.otherTreatment}`}
                    control={control}
                    required={required}
                    onChange={(e) => {
                      inputChangeHandler(
                        e,
                        `${id}-${index}-${propNamesPapSmears.otherTreatment}`
                      );
                    }}
                    value={
                      formValues[
                        `${id}-${index}-${propNamesPapSmears.otherTreatment}`
                      ]
                    }
                  />
                </>
              )}
            </Box>
          );
        })}
    </Box>
  );
};

export default FBMultiAbnormalPapsLanding;
