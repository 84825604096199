import { setPortraitData } from "features/LifePortrait/state/lifePortraitSlice";
import { saveFamilyMedicalHistorySvc } from "services/Questionnaire.service";

export const saveFMHResponses =
  (fmhResponses, errorNavigation) => async (dispatch, getState) => {
    try {
      const state = getState();
      const { applicationId, documentId } = state.lifePortrait;
      const requestPayload = {
        applicationId,
        documentId,
        familyMedicalHistory: fmhResponses,
      };
      const response = await saveFamilyMedicalHistorySvc(
        requestPayload,
        errorNavigation
      );
      if (response?.success) {
        // update state with the changed group data
        // dispatch(hydrateQuestionnaire(uiFromResponses));
        const { status } = fmhResponses;
        const {
          lifePortrait: { questionnaireResponse },
          questionnaire: { activeGroup },
        } = state;

        let updatedQuestionnaireResponse = { ...questionnaireResponse };
        const { groups } = updatedQuestionnaireResponse;
        const updGroups = [...groups];
        const idx = updGroups.findIndex((el) => el.id === activeGroup);
        if (idx === -1) {
          updGroups.push({ id: activeGroup, status });
        } else {
          updGroups[idx] = {
            ...updGroups[idx],
            status,
          };
        }
        updatedQuestionnaireResponse = {
          ...updatedQuestionnaireResponse,
          groups: updGroups,
        };
        dispatch(setPortraitData({ portrait: updatedQuestionnaireResponse }));
      }
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };
