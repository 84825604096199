import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";

import LifePortraitHeader from "components/Header/LifePortraitHeader";
import LifePortraitFooter from "components/Footer/LifePortraitBasicFooter";

import { RouteConstants } from "routes/AppRoutes";
import { saveReduxStateInLocalStorage } from "util/localStorageUtil";
import {
  setMode,
  setPortraitIds,
  setShowNeedHelp,
} from "../state/lifePortraitSlice";
import {
  getPortraitAgent,
  getProfilePhone,
  sendEmailToAgentOfNewClientPhn,
  sendPasscodeAction,
} from "../state/lifePortraitActions";

import GetStartedPres from "../presentation/GetStartedPres";
import UnauthorizedPres from "../presentation/UnauthorizedPres";

export default function LifePortraitGetStarted() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [portraitIdPresent, setPortraitIdPresent] = useState(false);
  const [searchParams] = useSearchParams();
  const {
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    mode: "all",
  });
  const formValues = watch();
  const newPhone = formValues.newPhone || "";
  const {
    showNeedHelp,
    status: portraitStatus,
    phoneNumber: phoneNum,
    questionnaireResponse: { agent },
  } = useSelector((state) => state.lifePortrait);

  useEffect(() => {
    // Read info from query strings
    const applicationId = searchParams.get("a");
    const documentId = searchParams.get("d");
    if (applicationId && documentId) {
      const errorNavigation = (errArg) => {
        const {
          code,
          message,
          response: { status, statusText },
        } = errArg;
        navigate(RouteConstants.Error, {
          state: {
            code,
            message,
            status,
            statusText,
          },
        });
      };

      setPortraitIdPresent(true);
      dispatch(setPortraitIds({ applicationId, documentId }));
      dispatch(getProfilePhone(errorNavigation));
      const mode = "client";
      dispatch(setMode({ mode }));
      saveReduxStateInLocalStorage({ applicationId, documentId, mode });
      if (!agent) {
        dispatch(getPortraitAgent({ applicationId, documentId }));
      }
    }
  }, [dispatch, searchParams, navigate, agent]);

  const errorNav = (errArg) => {
    const {
      code,
      message,
      response: { status, statusText },
    } = errArg;
    navigate(RouteConstants.Error, {
      state: {
        code,
        message,
        status,
        statusText,
      },
    });
  };

  const sendCodeHandler = (e) => {
    dispatch(sendPasscodeAction(e.target.id, errorNav)).then((res) => {
      if (res.success) navigate("/LifePortraitCheckYourTexts/");
    });
  };

  const setShowNeedHelpHandler = (e, flag) => {
    reset();
    dispatch(setShowNeedHelp({ showNeedHelp: flag }));
  };

  const sendEmailtoAgentHandler = () => {
    dispatch(sendEmailToAgentOfNewClientPhn({ newPhone })).then(() => {
      window.close();
    });
  };

  let isValid = !(newPhone === undefined || newPhone === "");
  if (isValid === true && !!errors?.newPhone) {
    isValid = !errors?.newPhone;
  }

  return (
    <>
      <LifePortraitHeader id="header" />
      {portraitIdPresent ? (
        <GetStartedPres
          sendCodeHandler={sendCodeHandler}
          phoneNum={phoneNum || ""}
          agent={agent}
          status={portraitStatus}
          setShowNeedHelp={setShowNeedHelpHandler}
          showNeedHelp={showNeedHelp}
          control={control}
          newPhone={newPhone}
          sendEmailtoAgentHandler={sendEmailtoAgentHandler}
          isValid={isValid}
        />
      ) : (
        <UnauthorizedPres id="unauthorizedPres" />
      )}

      <LifePortraitFooter id="footer" />
    </>
  );
}
