/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Divider from "@mui/material/Divider";
import FacebookIcon from "@mui/icons-material/FacebookRounded";
import TwitterIcon from "@mui/icons-material/Twitter";
import AgentCard from "components/Elements/FBAgentCard";
import logo from "assets/fbilogo.png";
import {
  getPortraitAgent,
  voidEnvelopeClient,
} from "features/LifePortrait/state/lifePortraitActions";
import { completeAfterSigning } from "services/LifePortrait.service";
import redLine from "assets/redLine.jpg";
import { useLocation, useNavigate } from "react-router";
import { RouteConstants } from "routes/AppRoutes";
import { restoreStateFromLocalStorageToRedux } from "util/localStorageUtil";
import { setLifePortraitState } from "../state/lifePortraitSlice";

export default function CompletionPres(props) {
  const { id } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const {
    questionnaireResponse: { agent },
  } = useSelector((state) => state.lifePortrait);

  const { search } = location;
  function useQuery() {
    const params = new URLSearchParams(search);
    const newParams = new URLSearchParams();

    params.forEach((key, value) => {
      newParams.append(value.toLowerCase(), key);
    });

    return newParams;
  }
  const query = useQuery();
  const documentId = query.get("d");
  const applicationId = query.get("a");
  const event = query.get("event");

  const errorNavigation = (errArg) => {
    const {
      code,
      message,
      response: { status, statusText },
    } = errArg;
    navigate(RouteConstants.Error, {
      state: {
        code,
        message,
        status,
        statusText,
      },
    });
  };

  useEffect(async () => {
    const obj = restoreStateFromLocalStorageToRedux();
    if (obj) await dispatch(setLifePortraitState(obj));

    if (!agent) {
      dispatch(getPortraitAgent({ applicationId, documentId }));
    }
    if (event === "signing_complete") {
      completeAfterSigning({ applicationId, documentId }, errorNavigation);
    } else if (event === "decline") {
      dispatch(voidEnvelopeClient(errorNavigation));
    }
  }, [applicationId, documentId]);

  return (
    <>
      <nav
        id={id}
        name={id}
        className="navbar navbar-expand-lg"
        style={{ backgroundColor: "#eee" }}
      >
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <img src={logo} alt="Farm Bureau Logo" />
          </a>
        </div>
      </nav>
      <div className="container-xl min-vh-100" align="center">
        <div className="pt-4">
          <p
            style={{
              fontWeight: "bolder",
              fontSize: "42px",
              letterSpacing: "0.025em",
              color: "#143247",
            }}
          >
            You&#39;ve completed your Life Portrait!
          </p>
        </div>
        <div>
          <img src={redLine} alt="red line" />
        </div>
        <div className="py-2">We&#39;ve notified your Farm Bureau agent.</div>
        <div className="mb-3">
          They will be in contact with you about next steps in the process.
        </div>
        <Divider />
        <br />
        <AgentCard id="agentCard" agent={agent} />
      </div>
      <div className="mt-auto bgPrimaryDarkBlueFB">
        <footer className="d-flex flex-wrap justify-content-between align-items-center px-2 py-2 border-top border-3">
          <div className="col-md-4 d-flex align-items-center">
            <span className="text-white small">
              &copy; {new Date().getFullYear()} Tennessee Farmers Insurance
              Companies.
              <br />
              P.O. Box 307 Columbia, TN 38402-0307
              <br />
              Customer Service Center 877-876-2222
            </span>
          </div>
          <ul className="nav col-md-4 justify-content-end list-unstyled d-flex">
            <li className="ms-3">
              <a
                aria-label="farmbureau"
                className="text-white text-decoration-none"
                href="https://www.fbitn.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                fbitn.com
              </a>
            </li>
            <li className="ms-3">
              <a
                aria-label="facebook"
                className="text-muted"
                href="https://www.facebook.com/FarmBureauInsuranceTN"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FacebookIcon color="white" />
              </a>
            </li>
            <li className="ms-3">
              <a
                aria-label="twitter"
                className="text-muted"
                href="https://twitter.com/FarmerCharlie"
                target="_blank"
                rel="noopener noreferrer"
              >
                <TwitterIcon color="white" />
              </a>
            </li>
          </ul>
        </footer>
      </div>
    </>
  );
}

CompletionPres.propTypes = {
  id: PropTypes.string,
};
