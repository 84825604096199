/* eslint-disable indent */
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import { NavLink, useNavigate } from "react-router-dom";
import ListItemText from "@mui/material/ListItemText";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PendingIcon from "@mui/icons-material/Pending";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { ListItemIcon } from "@mui/material";
import { medFrmRouteSeg } from "util/genericHelper";
import { initializePortraitFromSessionStore } from "features/LifePortrait/state/lifePortraitActions";

function getGroupStatus(portraitData, groupId) {
  const group = portraitData?.groups?.find((g) => g.id === groupId);
  return group?.status ? group.status : "notstarted";
}

export default function LifePortraitChecklistDrawer(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id, onItemSelect } = props;
  const { lifePortrait, questionnaire } = useSelector((state) => state);
  const { authToken } = lifePortrait;

  React.useEffect(() => {
    if (!authToken) {
      // console.log(window.location);
      dispatch(
        initializePortraitFromSessionStore(() => {
          navigate("/Welcome");
          // navigate(window.location.pathname);
        })
      );
    }
  }, [authToken, navigate, dispatch]);

  const portraitChecklist = lifePortrait?.questionnaire?.groups?.map((el) => ({
    id: el.id,
    title: el.title,
    status: getGroupStatus(lifePortrait?.questionnaireResponse, el.id),
    enabled: true,
  }));
  const { activeGroup } = questionnaire;
  const { mode } = useSelector((state) => state.lifePortrait);
  let selectedMenuItemIndex;
  if (activeGroup && activeGroup !== "") {
    selectedMenuItemIndex = lifePortrait.questionnaire?.groups?.findIndex(
      (el) => el.id === activeGroup
    );
  }

  const reveiwArr = ["PS-RS-1", "PC-RS-1", "PT-RS-1"];
  let reviewFlag =
    portraitChecklist &&
    !portraitChecklist
      .filter((el) => !reveiwArr.includes(el.id))
      .every((el) => el.status === "completed")
      ? "locked"
      : "unlocked";
  if (reviewFlag === "unlocked" && portraitChecklist) {
    const group = portraitChecklist.find((el) => reveiwArr.includes(el.id));
    if (group.status === "completed") reviewFlag = "completed";
  }

  return (
    <MenuList id={id} name={id}>
      {portraitChecklist?.map((item, index) => {
        if (item.enabled) {
          let route = "";
          if (
            reveiwArr.includes(item.id) &&
            ["unlocked", "completed"].includes(reviewFlag)
          ) {
            route =
              mode === "examone"
                ? "/PortraitReview/FinalQuestions"
                : "/PortraitReview";
            if (mode === "examone" && item.id === "PS-RS-1") {
              route = `/group/${item.id}`;
            }
          } else if (!reveiwArr.includes(item.id)) {
            route = `${medFrmRouteSeg}${item.id}`;
          }

          return (
            <Paper
              key={`p${item.id}`}
              sx={{
                width: 350,
                maxWidth: "100%",
                backgroundColor: "FBdarkblue.main",
                color: "white.main",
              }}
            >
              <NavLink
                to={route}
                style={{ textDecoration: "none" }}
                key={item.id}
              >
                <MenuItem
                  key={item.id}
                  selected={index === selectedMenuItemIndex}
                  className="NavMenuItem"
                  onClick={() => {
                    onItemSelect(item.id);
                  }}
                  disabled={
                    reveiwArr.includes(item.id)
                      ? reviewFlag === "locked"
                      : false
                  }
                >
                  <ListItemText primary={item.title} />
                  <ListItemIcon>
                    {!reveiwArr.includes(item.id)
                      ? {
                          notstarted: (
                            <ArrowForwardIosIcon sx={{ color: "white.main" }} />
                          ),
                          started: (
                            <PendingIcon sx={{ color: "secondary.main" }} />
                          ),
                          completed: (
                            <CheckCircleIcon sx={{ color: "success.main" }} />
                          ),
                        }[item.status]
                      : {
                          locked: <LockIcon sx={{ color: "white.main" }} />,
                          unlocked: (
                            <LockOpenIcon sx={{ color: "white.main" }} />
                          ),
                          completed: (
                            <CheckCircleIcon sx={{ color: "success.main" }} />
                          ),
                        }[reviewFlag]}
                  </ListItemIcon>
                </MenuItem>
              </NavLink>
            </Paper>
          );
        }
        return (
          <MenuItem disabled key={item.id}>
            <ListItemText primary={item.title} disabled />
          </MenuItem>
        );
      })}
    </MenuList>
  );
}

LifePortraitChecklistDrawer.propTypes = {
  id: PropTypes.string.isRequired,
  onItemSelect: PropTypes.func.isRequired,
};
