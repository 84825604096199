import { setLifePortraitProviders } from "features/LifePortrait/state/lifePortraitSlice";
import { setProviderAddedOrModified } from "features/Questionnaire/state/questionnaireSlice";
import { saveProviderSvc } from "services/Questionnaire.service";

export const setProviders =
  (providers, provider, errorNavigation) => async (dispatch, getState) => {
    try {
      const state = getState();
      const { applicationId, documentId } = state.lifePortrait;
      const requestPayload = {
        applicationId,
        documentId,
        providerId: provider.id,
        provider,
      };
      const response = await saveProviderSvc(requestPayload, errorNavigation);
      if (response?.success) {
        dispatch(setLifePortraitProviders({ providers: [...providers] }));
        dispatch(setProviderAddedOrModified(true));
      }

      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };
