/* eslint-disable indent */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import { useForm } from "react-hook-form";
import { Box, Portal, Stack, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import ProviderFormPres from "../presentation/ProviderFormPres";
import ProviderListPres from "../presentation/ProviderListPres";

const ProviderContainer = ({
  menuOption,
  setMenuOption,
  addProviderHandler,
  providerMenuButtons,
  providers,
  states,
}) => {
  const pTypeEnum = {
    Doctor: "Doctor",
    NursePractitioner: "Nurse Practitioner",
    PhysicianAssistant: "Physician Assistant",
    Hospital: "Hospital",
    Clinic: "Clinic",
    Other: "Other",
  };
  const providerTypes = [
    { label: pTypeEnum.Doctor, value: pTypeEnum.Doctor },
    { label: pTypeEnum.NursePractitioner, value: pTypeEnum.NursePractitioner },
    {
      label: pTypeEnum.PhysicianAssistant,
      value: pTypeEnum.PhysicianAssistant,
    },
    { label: pTypeEnum.Hospital, value: pTypeEnum.Hospital },
    { label: pTypeEnum.Clinic, value: pTypeEnum.Clinic },
    { label: pTypeEnum.Other, value: pTypeEnum.Other },
  ];

  const formDefaultState = {
    type: "Doctor",
    firstName: "",
    lastName: "",
    streetAddress: "",
    city: "",
    state: "",
    zip: "",
    phoneNumber: "",
  };

  const {
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    mode: "all",
  });

  const { dialogOpen, menuLabel, status } = menuOption;
  const handleClose = () => {
    setMenuOption({
      ...menuOption,
      dialogOpen: false,
      status: undefined,
      menuLabel: providerMenuButtons.addNewProvider,
    });
  };

  const formValues = watch();
  const descriptionElementRef = React.useRef(null);
  const [modified, setModified] = React.useState(false);
  React.useEffect(() => {
    if (dialogOpen) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [dialogOpen]);

  const handleSave = (e) => {
    addProviderHandler(formValues);
  };

  const handleProviderItemClick = (e, guid) => {
    const record = providers.filter((el) => el.id === guid)[0];
    setMenuOption({
      ...menuOption,
      menuLabel: providerMenuButtons.modifyProvider,
      dialogOpen: true,
      modifiedGuid: guid,
    });

    reset(record);
  };

  const { type, state } = formValues;

  const isFirstNameRequired =
    type === pTypeEnum.Doctor ||
    type === pTypeEnum.NursePractitioner ||
    type === pTypeEnum.PhysicianAssistant;

  const showOtherInStateOptions = [pTypeEnum.Doctor, pTypeEnum.NursePractitioner,
                                   pTypeEnum.PhysicianAssistant].includes(type);

  const stateOptions = showOtherInStateOptions ? [...states, { abreviation: 'Other', name: 'Other' }] : [...states];

  const isProvinceOrCountryVisible = state === "Other";

  React.useEffect(() => {
    if (!showOtherInStateOptions && state === "Other") {
      // reset state and provinceOrCountry when the selected provider is not one of the following:
      // Doctor, Nurse Practitioner, Physician Assistant
      reset({ ...formValues, state: '', provinceOrCountry: '' });
    } else if (showOtherInStateOptions && state !== "Other") {
      // reset provinceOrCountry when state is changed to any value other than 'Other'
      reset({ ...formValues, provinceOrCountry: '' });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showOtherInStateOptions, state]);

  let isValid;
  if (
    (Object.keys(formValues).length === 0 &&
      menuLabel === providerMenuButtons.addNewProvider) ||
    (Object.keys(formValues).length === 1 &&
      menuLabel === providerMenuButtons.modifyProvider)
  ) {
    isValid = false;
  } else {
    isValid = !Object.keys(formValues).some((el) => {
      if (
        (!isFirstNameRequired && el === "firstName") ||
        ["streetAddress", "phoneNumber", "zip"].includes(el) || // Optional fields
        (!isProvinceOrCountryVisible && el === "provinceOrCountry")
      ) {
        return false;
      }
      if (
        formValues[el] === undefined ||
        formValues[el] === null ||
        formValues[el] === ""
      ) {
        return true;
      }
      return false;
    });
  }

  if (isValid === true && !!errors?.phoneNumber) {
    // check for invalid phone number
    isValid = !errors?.phoneNumber;
  } else if (isValid === true && !!errors?.zip) {
    // check for invalid phone number
    isValid = !errors?.zip;
  }

  return (
    <Portal>
      <form>
        <Dialog
          open={dialogOpen}
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">
            <Stack direction="row" spacing={2}>
              {menuLabel === providerMenuButtons.modifyProvider
                ? `Edit Provider`
                : menuLabel}
              {handleClose ? (
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              ) : null}
            </Stack>
          </DialogTitle>
          <DialogContent dividers>
            <Box>
              {status === "saved" && (
                <Box
                  alignItems="center"
                  justifyContent="center"
                  width={300}
                  height={150}
                  sx={{ textAlign: "center" }}
                >
                  <CheckCircleIcon
                    fontSize="large"
                    style={{ fill: "#4caf50" }}
                  />
                  <Typography variant="h6">Provider added</Typography>
                  <p>
                    You can now select this provider in the dropdown from
                    anywhere in your Life Portrait.
                  </p>
                </Box>
              )}
              {status !== "saved" &&
                (menuLabel === providerMenuButtons.addNewProvider ||
                  menuLabel === providerMenuButtons.modifyProvider) && (
                  <ProviderFormPres
                    formValues={formValues}
                    providerTypes={providerTypes}
                    states={stateOptions}
                    onInputChange={() => setModified(true)}
                    control={control}
                    isFirstNameRequired={isFirstNameRequired}
                    isProvinceOrCountryVisible={isProvinceOrCountryVisible}
                  />
                )}
              {status !== "saved" &&
                menuLabel === providerMenuButtons.manageProviders && (
                  <ProviderListPres
                    providers={providers}
                    handleProviderItemClick={handleProviderItemClick}
                    handleClose={handleClose}
                  />
                )}
            </Box>
          </DialogContent>
          {status === "saved" ? (
            <DialogActions>
              <Box
                alignItems="center"
                justifyContent="center"
                width={300}
                sx={{ textAlign: "center" }}
              >
                <Button onClick={handleClose} variant="outlined">
                  Got It
                </Button>
              </Box>
            </DialogActions>
          ) : (
            (menuLabel === providerMenuButtons.addNewProvider ||
              menuLabel === providerMenuButtons.modifyProvider) && (
              <DialogActions>
                <Button variant="outlined" onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={handleSave}
                  disabled={!isValid || (providerMenuButtons.modifyProvider && !modified)}
                >
                  {menuLabel === providerMenuButtons.addNewProvider
                    ? `Add Provider`
                    : `Update`}
                </Button>
              </DialogActions>
            )
          )}
        </Dialog>
      </form>
    </Portal>
  );
};

export default ProviderContainer;
