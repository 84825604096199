import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "components/App/App";
import LifePortraitGetStarted from "features/LifePortrait/container/GetStarted";
import LifePortraitCheckYourTexts from "features/LifePortrait/container/CheckYourTexts";
import YouAreVerified from "features/LifePortrait/container/Verified";
import TermsAndConditions from "features/LifePortrait/container/TermsAndConditions";
import TermsAndConditionsPage from "components/Header/TermsAndConditionsPage";
import TermsAndConditionsAreYouSure from "features/LifePortrait/presentation/TermsAndConditionsAreYouSurePres";
import OptedOut from "features/LifePortrait/container/OptedOut";
import PrivacyPolicyPage from "components/Header/PrivacyPolicyPage";
import FraudPenaltyAgreement from "features/LifePortrait/container/FraudPenaltyAgreement";
import FraudPenaltyAgreementAreYouSure from "features/LifePortrait/presentation/FraudPenaltyAgreementAreYouSurePres";
import Welcome from "features/LifePortrait/container/Welcome";
import TestParser from "features/TestParser";
import TestPageParser from "features/TestParser/renderPage";
import Playground from "features/Playground";
import { QuestionnaireLandingContainer } from "features/Questionnaire";
import ReviewLanding, { ReviewQuestions } from "features/Review";
import Completion from "features/LifePortrait/presentation/CompletionPres";
import MedicationHistoryLandingContainer from "features/MedicationHistory/container/MedicationHistoryLandingContainer";
import AgentLanding from "features/Agent/Landing";
import ExamOneLanding from "features/ExamOne/Landing";
import ErrorHandler from "features/Error/ErrorHandler";
import Dashboard from "features/Dashboard/loadable";
import PortraitDetailContainer from "features/DashboardPortraitDetail/loadable";
import AgentCompletion from "features/LifePortrait/presentation/AgentCompletionPres";
import EOCompletion from "features/LifePortrait/presentation/EOCompletionPres";
import { medFrmRouteSeg } from "util/genericHelper";
import Incomplete from "features/LifePortrait/presentation/IncompletePres";
import TNInsuranceAcknowledge from "features/LifePortrait/container/TNInsuranceAcknowledge";
import TNInsuranceAcknowledgeAreYouSure from "features/LifePortrait/presentation/TNInsuranceAcknowledgeAreYouSurePres";

export const RouteConstants = {
  testparser: "/testparser",
  testpage: "/testpage",
  playground: "/playground",
  app: "/app",
  LifePortraitGetStarted: "LifePortraitGetStarted",
  LifePortraitCheckYourTexts: "LifePortraitCheckYourTexts",
  YouAreVerified: "YouAreVerified",
  TermsAndConditions: "TermsAndConditions",
  TermsAndConditionsPage: "TermsAndConditionsPage",
  TermsAndConditionsAreYouSure: "TermsAndConditionsAreYouSure",
  OptedOut: "OptedOut",
  PrivacyPolicyPage: "PrivacyPolicyPage",
  FraudPenaltyAgreement: "FraudPenaltyAgreement",
  FraudPenaltyAgreementAreYouSure: "FraudPenaltyAgreementAreYouSure",
  TNInsuranceAcknowledge: "TNInsuranceAcknowledge",
  TNInsuranceAcknowledgeAreYouSure: "TNInsuranceAcknowledgeAreYouSure",
  Welcome: "Welcome",
  MedFormId: `${medFrmRouteSeg}:id`,
  PortraitReview: "PortraitReview",
  PortraitReview_FinalQuestions: "PortraitReview/FinalQuestions",
  MedicationHistory: "MedicationHistory",
  AgentCompletion: "AgentCompletion",
  Completion: "Completion",
  EOCompletion: "EOCompletion",
  Incomplete: "Incomplete",
  AgentLanding: "AgentLanding",
  ExamOneLanding: "ExamOneLanding",
  blank: "blank",
  Error: "/info",
  Dashboard: "/Dashboard",
  DashboardDetail: "/Dashboard/Detail",
};

function AppRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LifePortraitGetStarted />} />
        <Route path={RouteConstants.testparser} element={<TestParser />} />
        <Route path={RouteConstants.testpage} element={<TestPageParser />} />
        <Route path={RouteConstants.playground} element={<Playground />} />
        <Route path={RouteConstants.app} element={<App />} />
        <Route
          path={RouteConstants.LifePortraitGetStarted}
          element={<LifePortraitGetStarted />}
        />
        <Route
          path={RouteConstants.LifePortraitCheckYourTexts}
          element={<LifePortraitCheckYourTexts />}
        />
        <Route
          path={RouteConstants.YouAreVerified}
          element={<YouAreVerified />}
        />
        <Route
          path={RouteConstants.TermsAndConditions}
          element={<TermsAndConditions />}
        />
        <Route
          path={RouteConstants.TermsAndConditionsPage}
          element={<TermsAndConditionsPage />}
        />
        <Route
          path={RouteConstants.TermsAndConditionsAreYouSure}
          element={<TermsAndConditionsAreYouSure />}
        />
        <Route path={RouteConstants.OptedOut} element={<OptedOut />} />
        <Route
          path={RouteConstants.PrivacyPolicyPage}
          element={<PrivacyPolicyPage />}
        />
        <Route
          path={RouteConstants.FraudPenaltyAgreement}
          element={<FraudPenaltyAgreement />}
        />
        <Route
          path={RouteConstants.FraudPenaltyAgreementAreYouSure}
          element={<FraudPenaltyAgreementAreYouSure />}
        />
        <Route
          path={RouteConstants.TNInsuranceAcknowledge}
          element={<TNInsuranceAcknowledge />}
        />
        <Route
          path={RouteConstants.TNInsuranceAcknowledgeAreYouSure}
          element={<TNInsuranceAcknowledgeAreYouSure />}
        />
        <Route path={RouteConstants.Welcome} element={<Welcome />} />
        {/* <Route path="/MedForm/:id" element={<FormPage />} /> */}
        <Route
          path={RouteConstants.MedFormId}
          element={<QuestionnaireLandingContainer />}
        />
        <Route
          path={RouteConstants.PortraitReview}
          element={<ReviewLanding />}
        />
        <Route
          path={RouteConstants.PortraitReview_FinalQuestions}
          element={<ReviewQuestions />}
        />
        <Route
          path={RouteConstants.MedicationHistory}
          element={<MedicationHistoryLandingContainer />}
        />
        <Route path={RouteConstants.Completion} element={<Completion />} />
        <Route
          path={RouteConstants.AgentCompletion}
          element={<AgentCompletion />}
        />
        <Route path={RouteConstants.EOCompletion} element={<EOCompletion />} />
        <Route path={RouteConstants.Incomplete} element={<Incomplete />} />
        <Route path={RouteConstants.AgentLanding} element={<AgentLanding />} />
        <Route
          path={RouteConstants.ExamOneLanding}
          element={<ExamOneLanding />}
        />
        <Route path={RouteConstants.blank} element={<h4>Please Wait...</h4>} />
        <Route path={RouteConstants.Error} element={<ErrorHandler />} />
        <Route path={RouteConstants.Dashboard} element={<Dashboard />} />
        <Route
          path={RouteConstants.DashboardDetail}
          element={<PortraitDetailContainer />}
        />
        <Route path="*" element={<h4>Not Found</h4>} />
      </Routes>
    </BrowserRouter>
  );
}

export default AppRoutes;
