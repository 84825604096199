import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import LifePortraitHeader from "components/Header/LifePortraitHeader";
import LifePortraitFooter from "components/Footer/LifePortraitFooter";
import { useForm } from "react-hook-form";
import { modifyClientDetails } from "services/LifePortrait.service";
import { RouteConstants } from "routes/AppRoutes";
import VerifiedPres from "../presentation/VerifiedPres";
import VerifyClientDetailsPres from "../presentation/VerifyClientDetailsPres";
import { setLifePortraitState } from "../state/lifePortraitSlice";
import EditClientDetails from "../presentation/EditClientDetailsPres";
import { getPortraitAgent } from "../state/lifePortraitActions";

export default function Verified() {
  const navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = useState(false);
  const { lifePortrait } = useSelector((state) => state);
  const {
    applicationId,
    documentId,
    prefix,
    firstName,
    middleName,
    lastName,
    suffix,
    birthDate,
    mode,
    questionnaireResponse: { agent },
  } = useSelector((state) => state.lifePortrait);
  const { control, watch, reset } = useForm({ mode: "all" });
  const formValues = watch();
  const dispatch = useDispatch();
  const errorNavigation = (errArg) => {
    const {
      code,
      message,
      response: { status: respStatus, statusText },
    } = errArg;
    navigate(RouteConstants.Error, {
      state: {
        code,
        message,
        status: respStatus,
        statusText,
      },
    });
  };
  const gotoNextPage = () => {
    navigate("/TNInsuranceAcknowledge");
  };

  const editProfile = () => {
    let val = formValues.birthDate;
    if (typeof val === "object") {
      try {
        val = formValues.birthDate.toLocaleDateString();
        // eslint-disable-next-line no-empty
      } catch {}
    }
    const profile = {
      prefix: formValues.prefix,
      firstName: formValues.firstName,
      middleName: formValues.middleName,
      lastName: formValues.lastName,
      suffix: formValues.suffix,
      birthDate: val,
    };
    modifyClientDetails(
      { applicationId, documentId, profile },
      errorNavigation
    ).then(() => {
      dispatch(
        setLifePortraitState({
          ...lifePortrait,
          prefix: formValues.prefix,
          firstName: formValues.firstName,
          middleName: formValues.middleName,
          lastName: formValues.lastName,
          suffix: formValues.suffix,
          birthDate: val,
        })
      );
      setDialogOpen(false);
    });
  };

  const handleClose = () => {
    setDialogOpen(false);
    reset({
      prefix,
      firstName,
      middleName,
      lastName,
      suffix,
      birthDate,
    });
  };

  useEffect(() => {
    reset({
      prefix,
      firstName,
      middleName,
      lastName,
      suffix,
      birthDate,
    });
    if (!agent) {
      dispatch(getPortraitAgent({ applicationId, documentId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <LifePortraitHeader id="header" />
      {mode !== "agent" ? (
        <>
          <VerifyClientDetailsPres
            control={control}
            formValues={formValues}
            gotoTermsAndConditions={gotoNextPage}
            editProfile={editProfile}
            setDialogOpen={setDialogOpen}
          />
          <EditClientDetails
            dialogOpen={dialogOpen}
            handleClose={handleClose}
            control={control}
            formValues={formValues}
            editProfile={editProfile}
            agent={agent}
          />
        </>
      ) : (
        <VerifiedPres
          profile={{
            firstName,
            lastName,
            birthDate,
          }}
          gotoNextPage={gotoNextPage}
        />
      )}
      <LifePortraitFooter id="footer" />
    </>
  );
}
