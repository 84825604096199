/* eslint-disable react/jsx-props-no-spreading */
// import { useState } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import FBNumber from "components/Elements/FBNumber";
import FBCheckbox from "components/Elements/FBCheckbox";
import Checkbox from "@mui/material/Checkbox";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import FBTextBox from "./FBTextBox";

export default function FBMedicalHistoryCondition(props) {
  const {
    control,
    id,
    // label,
    defaultValue,
    // value: propValue,
    disabled,
    error: propError,
    helperText,
    placeholder,
    controlWidth,
    onChange: onInputChange,
    item,
    medicalHistoryCondition,
    setValue,
  } = props;
  const { label, value: propValue, showDrilldown } = item;

  const showCancerFreeFormTxtBx =
    id.endsWith("_cancer") && medicalHistoryCondition[`${id}`] === true;

  return (
    <Controller
      name={`${id}`}
      control={control}
      defaultValue={
        defaultValue || propValue || medicalHistoryCondition[`${id}`]
      }
      render={({ field, fieldState: { error = propError } }) => {
        const { value } = field;
        return (
          <div>
            <FormGroup>
              <FormControlLabel
                name={`${id}`}
                id={`${id}`}
                control={
                  <Checkbox defaultValue={value} checked={value || false} />
                }
                // value={field.value}
                disabled={disabled}
                error={!!error}
                helperText={error ? error.message || helperText : helperText}
                placeholder={placeholder}
                label={label}
                sx={{ width: controlWidth }}
                onChange={(e) => {
                  field.onChange(e);
                  if (onInputChange) onInputChange(e.target.checked);
                }}
              />
            </FormGroup>
            {/* this only needs to display of Heart Disease is seleced */}
            {id.endsWith("_heartDisease") && value && showDrilldown && (
              <div className="row">
                <div
                  className="row"
                  style={{
                    marginLeft: "50px",
                    marginBottom: "20px",
                    width: "300px",
                  }}
                >
                  <InputLabel
                    htmlFor={`${id}_lblAge`}
                    style={{ fontWeight: "bold", paddingBottom: "5px" }}
                    // controlWidth="130px"
                  >
                    What was the age at diagnosis?
                  </InputLabel>
                  <FBNumber
                    control={control}
                    id={`${id}_AgeAtDiagnosis`}
                    controlWidth="230px"
                    defaultValue={
                      medicalHistoryCondition[`${id}_AgeAtDiagnosis`]
                    }
                    value={medicalHistoryCondition[`${id}_AgeAtDiagnosis`]}
                  />
                  <FBCheckbox
                    control={control}
                    id={`${id}_unKnownAge`}
                    label="I don't have this information"
                    controlWidth="250px"
                    defaultValue={medicalHistoryCondition[`${id}_UnKnownAge`]}
                    onChange={(e) => {
                      if (e) {
                        setValue(`${id}_AgeAtDiagnosis`, "");
                      }
                    }}
                  />
                </div>
              </div>
            )}
            {showCancerFreeFormTxtBx && showDrilldown && (
              <div className="row">
                <div
                  className="row"
                  style={{
                    marginLeft: "50px",
                    marginBottom: "20px",
                    width: "300px",
                  }}
                >
                  <InputLabel
                    htmlFor={`${id}_lblTypeOfCancer`}
                    style={{ fontWeight: "bold", paddingBottom: "5px" }}
                  >
                    Type of Cancer?
                  </InputLabel>
                  <FBTextBox
                    control={control}
                    id={`${id}_TypeOfCancer`}
                    controlWidth="260px"
                    // label="Type of Cancer?"
                    required
                    defaultValue={medicalHistoryCondition[`${id}_TypeOfCancer`]}
                    value={medicalHistoryCondition[`${id}_TypeOfCancer`]}
                  />
                </div>
              </div>
            )}
          </div>
        );
      }}
    />
  );
}

FBMedicalHistoryCondition.propTypes = {
  control: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  placeholder: PropTypes.string,
  controlWidth: PropTypes.string,
  onChange: PropTypes.func,
  item: PropTypes.object,
  medicalHistoryCondition: PropTypes.object,
  setValue: PropTypes.func,
};

FBMedicalHistoryCondition.defaultProps = {
  label: "",
  defaultValue: undefined,
  value: undefined,
  disabled: false,
  error: false,
  helperText: undefined,
  placeholder: undefined,
  controlWidth: "200px",
  onChange: undefined,
};
