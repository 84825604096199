/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { makeStyles } from "@mui/styles";

const useFormGroupStyles = makeStyles({
  root: {
    display: "block",
  },
});

export default function FBCheckbox(props) {
  const formGroupClasses = useFormGroupStyles();
  const {
    control,
    id,
    label,
    required,
    defaultValue,
    value: propValue,
    disabled,
    error: propError,
    helperText,
    placeholder,
    controlWidth,
    onChange: onInputChange,
  } = props;

  const [checked, setChecked] = useState(null);

  useEffect(() => {
    let initValue = propValue || false;
    if (propValue) {
      if (typeof propValue === "string") {
        initValue = !!(
          propValue.toLowerCase() === "yes" ||
          propValue.toLowerCase() === "true"
        );
      }
    }
    setChecked(initValue);
  }, [id, propValue]);

  return (
    <Controller
      name={id}
      control={control}
      defaultValue={defaultValue || propValue || false}
      rules={{
        required: { value: required, message: `${label} is required` },
      }}
      render={({ field, fieldState: { error = propError } }) => {
        return (
          <FormGroup classes={formGroupClasses}>
            <FormControlLabel
              name={id}
              id={id}
              required={required}
              disabled={disabled}
              control={
                <Checkbox disabled={disabled} checked={checked || false} />
              }
              error={!!error}
              helperText={
                error && error.message
                  ? error.message || helperText
                  : helperText
              }
              placeholder={placeholder}
              label={label}
              sx={{ width: controlWidth, "& root": { display: "block" } }}
              onChange={(e) => {
                field.onChange(e);
                setChecked(e.target.checked);
                if (onInputChange) onInputChange(e.target.checked);
              }}
            />
          </FormGroup>
        );
      }}
    />
  );
}

FBCheckbox.propTypes = {
  control: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  placeholder: PropTypes.string,
  controlWidth: PropTypes.string,
  onChange: PropTypes.func,
};

FBCheckbox.defaultProps = {
  label: "",
  required: false,
  defaultValue: undefined,
  value: undefined,
  disabled: false,
  error: false,
  helperText: undefined,
  placeholder: undefined,
  controlWidth: undefined,
  onChange: undefined,
};
