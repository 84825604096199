/* eslint-disable react/forbid-prop-types */
import Button from "@mui/material/Button";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import FBCheckbox from "components/Elements/FBCheckbox";
import TermsAndConditions from "components/Elements/FBTermsAndConditions";

import { memo } from "react";
import PropTypes from "prop-types";

function TermsAndConditionsPres({
  control,
  firstName,
  gotoFraudPenaltyAgreement,
  disableContinue,
  disableChkAcceptTC,
  tcTextRef,
  onTextScroll,
  onTermsAndConditionsAcceptChange,
  onTermsAndConditionsDoNotAccept,
}) {
  return (
    <div className="bg-light min-vh-100">
      <div className="container py-3">
        <div className="">
          <form className="">
            <div className="shadow bg-body rounded card-body bg-light">
              <h5 className="d-flex justify-content-center">
                <CheckCircleIcon style={{ fill: "#4caf50" }} />
                &nbsp;Thank you, {firstName}
              </h5>
              <p className="d-flex justify-content-center card-text py-3">
                Take a look over the Terms and Conditions below.
              </p>
              <div className="outerDiv">
                <div
                  className="innerDiv px-3"
                  ref={tcTextRef}
                  onScroll={onTextScroll}
                >
                  <TermsAndConditions id="termsAndConditions" />
                </div>
              </div>
            </div>
            <div>
              <div className="d-flex justify-content-center py-1">
                <FBCheckbox
                  control={control}
                  id="chkAcceptTermsConditions"
                  label="I Accept Terms and Conditions"
                  value="false"
                  controlWidth="300px"
                  disabled={disableChkAcceptTC}
                  onChange={onTermsAndConditionsAcceptChange}
                />
              </div>
              <div className="d-flex justify-content-center py-1">
                <Button
                  id="btnContinue"
                  variant="contained"
                  disabled={disableContinue}
                  onClick={gotoFraudPenaltyAgreement}
                >
                  Continue
                </Button>
              </div>
              <div className="d-flex justify-content-center py-1">
                <Button
                  id="btnDoNotAcceptTermsConditions"
                  variant="text"
                  onClick={onTermsAndConditionsDoNotAccept}
                >
                  I Do Not Accept Terms And Conditions&nbsp;
                  <ArrowForwardIcon />
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

TermsAndConditionsPres.propTypes = {
  gotoFraudPenaltyAgreement: PropTypes.func,
  control: PropTypes.any,
  firstName: PropTypes.string,
  disableContinue: PropTypes.bool,
  disableChkAcceptTC: PropTypes.bool,
  tcTextRef: PropTypes.any,
  onTextScroll: PropTypes.func,
  onTermsAndConditionsAcceptChange: PropTypes.func,
  onTermsAndConditionsDoNotAccept: PropTypes.func,
};

export default memo(TermsAndConditionsPres);
