import Button from "@mui/material/Button";
import NotificationsIcon from "@mui/icons-material/Notifications";
import FBTextBox from "components/Elements/FBTextBox";
import { memo } from "react";
import PropTypes from "prop-types";

function CheckYourTextsPres({
  phoneNumber,
  onVerificationCodeChange,
  verifyCode,
  handleSubmit,
  message,
  control,
  sendCodeHandler,
  passcode,
}) {
  return (
    <div className="bg-light min-vh-100">
      <div className="container">
        <div className="py-3">
          <form className="shadow bg-body rounded" onSubmit={handleSubmit}>
            <div className="card-body">
              <h5 className="card-title">
                <NotificationsIcon />
                &nbsp;Check your texts
              </h5>
              <p className="card-text">
                We&#39;ve sent a code to the number we have for your Life
                Portrait ending in {phoneNumber}.
              </p>
              <div className="row">
                <div>
                  <FBTextBox
                    control={control}
                    id="txtVerificationCode"
                    label="Enter Verification Code"
                    onChange={onVerificationCodeChange}
                    autoFocus
                  />
                  &nbsp;&nbsp;
                  <Button
                    id="btnVerifyCode"
                    variant="contained"
                    onClick={verifyCode}
                    sx={{ marginTop: "3px" }}
                    disabled={!passcode || passcode === ""}
                  >
                    Verify Code
                  </Button>
                </div>
                {message && <p className="text-danger">{message}</p>}
              </div>
              <p className="card-text py-3">
                It may take a minute to receive your code. Haven&#39;t received
                it?
              </p>
              <p className="card-text">
                <Button
                  id="sms"
                  onClick={sendCodeHandler}
                  variant="outlined"
                  sx={{ marginRight: "5px", marginBottom: "5px" }}
                >
                  Resend a new code
                </Button>
                &nbsp;&nbsp;
                <Button
                  id="call"
                  onClick={sendCodeHandler}
                  variant="outlined"
                  sx={{ marginRight: "5px", marginBottom: "5px" }}
                >
                  Call Me with new code
                </Button>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

CheckYourTextsPres.propTypes = {
  phoneNumber: PropTypes.string,
  onVerificationCodeChange: PropTypes.func,
  verifyCode: PropTypes.func,
  handleSubmit: PropTypes.func,
  message: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  control: PropTypes.any,
  sendCodeHandler: PropTypes.func,
  passcode: PropTypes.string,
};

export default memo(CheckYourTextsPres);
