/* eslint-disable indent */
import {
  hydrateQuestionnaireResponses,
  hydrateQuestionnaire,
} from "features/LifePortrait/state/lifePortraitActions";
import { setResponses } from "features/Questionnaire/state/questionnaireSlice";
import { saveGroupResponseSvc } from "services/Questionnaire.service";
import { getPortraitReviewData } from "services/LifePortrait.service";
import { setReviewData, setUpdateReview } from "./reviewSlice";

export const saveReviewResponses =
  (groupData, uiFromResponses, navigationCallback) =>
  async (dispatch, getState) => {
    try {
      const state = getState();
      const { applicationId, documentId } = state.lifePortrait;
      const requestPayload = { applicationId, documentId, group: groupData };
      const response = await saveGroupResponseSvc(
        requestPayload,
        navigationCallback
      );
      if (response?.success) {
        // update state with the changed group data
        dispatch(hydrateQuestionnaireResponses(groupData));
        // for restoring the UI state, hydrate the questionnaire with responses
        dispatch(hydrateQuestionnaire(uiFromResponses));
        dispatch(setResponses(uiFromResponses));
      }
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

const getReviewData = (requestData, navigationCallback) => async (dispatch) => {
  try {
    return getPortraitReviewData(requestData, navigationCallback).then(
      (response) => {
        dispatch(setReviewData({ reviewData: response }));
        dispatch(setUpdateReview(false));
      }
    );
  } catch (error) {
    return Promise.reject(error);
  }
};

export { getReviewData };
