/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import Button from "@mui/material/Button";
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useForm } from "react-hook-form";
// import FBDatePicker from "components/Elements/FBDatePicker";
// import FBTextBox from "components/Elements/FBTextBox";
// import FBComboBox from "components/Elements/FBComboBox";
// import FBMonthYearPicker from "components/Elements/FBMonthYearPicker";
// import FBRadioGroup from "components/Elements/FBRadioGroup";
import FBPhysician from "features/Provider";
import FBMedication from "features/Medication";
import FBMultiDateDesc from "features/MultiDateDesc";

export default function Playground() {
  const { handleSubmit, control, formState, setValue } = useForm({
    mode: "onChange",
  });
  const { isValid } = formState;
  const [state] = useState({
    firstName: null,
    lastName: null,
    datePicker: null,
    monthYearPicker: null,
    address: null,
  });

  const onSubmit = (data) => {
    console.log("formValues >>>", isValid, data);
  };

  // console.log("isValid >>>", isValid);

  return (
    <div className="bgSecondaryFBgrey">
      <div className="container-xl min-vh-100 py-3">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div
            className="questionnaire-page-container"
            style={{ paddingLeft: "20px" }}
          >
            <div className="content-parser-container">
              {/* <FBRadioGroup
                id="radio1"
                label="What is the radio button type?"
                value="Yes"
                control={control}
                items={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]}
              />
              <FBComboBox
                id="fbcomboboxId"
                control={control}
                label="Dropdown"
                items={[
                  { label: "Type1", value: "Type1" },
                  { label: "Type2", value: "Type2" },
                ]}
                required
              />
              <FBDatePicker
                id="fbDatePickrId"
                control={control}
                label="Date Picker"
                value={state.datePicker}
                required
              />
              <FBMonthYearPicker
                id="fbMonthYearPickrId"
                control={control}
                label="Month Year Picker"
                value={state.monthYearPicker}
                required
              /> */}
              {/* <FBTextBox
                id="fbTextBox-FN"
                control={control}
                label="First Name"
                value={state.firstName}
                required
              />
              <FBTextBox
                id="fbTextBox-LN"
                control={control}
                label="Last Name"
                value={state.lastName}
                required
              />
              <FBTextBox
                id="fbTextBox-address"
                control={control}
                label="Address"
                value={state.address}
                required
                multiline
                maxRows={4}
              /> */}
            </div>
            <br />

            {/* <FBPhysician
              label="Physician/Doctor"
              id="physicianId"
              required
              control={control}
              error={false}
              setValue={setValue}
              // value=""
              // onInputChange={(e) => {
              //   console.log(e);
              // }}
            /> */}

            {/* <FBMedication
              label="Physician/Doctor"
              id="physicianId"
              required
              control={control}
              error={false}
              setValue={setValue}
              // value="Lopressor~1|Med2~0"
            /> */}

            <FBMultiDateDesc
              controlLabels="Hospitalization"
              id="hospitalizationId"
              required
              control={control}
              error={false}
              setValue={setValue}
              value="[2]date^2013-01-21T21:16:08.309Z~text1^Text1Value as das dasdd~text2^zzzzzzz~provider^provider-1|date^2013-05-16T21:16:08.309Z~text1^qqqqqqd~text2^ppppppp~provider^provider-2"
              type="hospitalization"
              options={{
                dateShow: true,
                dateCaption: "when did they occur?",
                text1Show: false,
                text1Caption: "text 1 question?",
                text2Show: false,
                text2Caption: "text 2 question?",
                providerShow: true,
                providerCaption: "What hospital?",
              }}
            />

            <br />
            <br />

            <div className="row justify-content-center">
              <Button
                // disabled={!isValid}
                variant="contained"
                type="submit"
                className="FBMedFormFooterButton FBMedFormFooterButtonRight"
              >
                Continue
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
