import PropTypes from 'prop-types';
import Button from "@mui/material/Button";

export default function DefaultFooter(props) {
  const {
    id,
  } = props;
  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <div className="container-fluid bgPrimaryDarkBlueFB py-1">
      <footer id={id} name={id}>
        <ul className="d-flex justify-content-center pt-1">
          <li className="ms-3">
            <Button
              id="btnPrivacyPolicy"
              variant="text"
              onClick={() => openInNewTab('https://www.fbitn.com/privacy-policy')}
              className="text-white text-decoration-none"
            >
              Privacy Policy
            </Button>
          </li>
          <li className="ms-3">
            <Button
              id="btnTermsConditions"
              variant="text"
              onClick={() => openInNewTab('/TermsAndConditionsPage')}
              className="text-white text-decoration-none"
            >
              Terms and Conditions
            </Button>
          </li>
        </ul>
      </footer>
    </div>
  );
}

DefaultFooter.propTypes = {
  id: PropTypes.string.isRequired,
};
