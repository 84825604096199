/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import {
  setResponses,
  setShowGroupCompletion,
} from "features/Questionnaire/state/questionnaireSlice";
import { getGroupResponseFromFormVals } from "util/getGroupResponseFromFormVals";
import { useNavigate } from "react-router";
import { RouteConstants } from "routes/AppRoutes";
import { updatePortraitStatus } from "features/LifePortrait/state/lifePortraitActions";
import { sendPortraitEnvelope } from "services/LifePortrait.service";
import RedirectToDocusignPres from "features/LifePortrait/presentation/RedirectingToDocusign";
import Layout from "components/Header/Layout";
import MedicationHistoryLandingPres from "../presentation/MedicationHistoryLandingPres";
import {
  rollUpAdditionalMedications,
  rollUpMedications,
} from "../helper/RollUpMedsUtil";
import {
  setShowMedEditForm,
  setPortraitMedicationHistory,
  setAdditionalMedications,
} from "../state/medicationHistorySlice";
import { saveMedicationHistoryResponses } from "../state/medicationHistoryActions";

export default function MedicationHistoryLandingContainer() {
  const [redirect, setRedirect] = useState(false);
  const { responses } = useSelector((state) => state.questionnaire);
  const { control, watch, reset, setValue } = useForm({ mode: "all" });
  const formValues = watch();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    questionnaire,
    questionnaireMetadata,
    applicationId,
    documentId,
    mode,
    isLoading,
  } = useSelector((state) => state.lifePortrait);
  const { activeGroup } = useSelector((state) => state.questionnaire);
  const { portraitMedicationHistory, additionalMedications, showMedEditForm } =
    useSelector((state) => state.medicationHistory);
  const group = questionnaire?.groups?.find(
    (g) => g?.id === "PS-RS-1" || g?.id === "PC-RS-1" || g?.id === "PT-RS-1"
  );
  const additionalMedsQuestion = group.sections[1].questions.find(
    (q) => q.id === `${group.id}_OtherMeds`
  ).caption;
  const acknowledgement = group.sections[1].questions.find(
    (q) => q.id === `${group.id}_Acknowledgement`
  )?.caption;

  useEffect(() => {
    if (!questionnaire) return;
    window.scrollTo(0, 0);
    const meds = rollUpMedications(responses);
    dispatch(setPortraitMedicationHistory({ portraitMedicationHistory: meds }));
    // eslint-disable-next-line max-len
    if (responses[`${group.id}_OtherMeds_AdditionalMedications`]) {
      dispatch(
        setAdditionalMedications({
          additionalMedications: rollUpAdditionalMedications(
            responses[`${group.id}_OtherMeds_AdditionalMedications`]
          ),
        })
      );
    }
    reset({
      ynOtherMeds: responses[`${group.id}_OtherMeds`],
      acknowledgement: responses[`${group.id}_Acknowledgement`],
    });
  }, []);

  const errorNavigation = (errArg) => {
    const {
      code,
      message,
      response: { status, statusText },
    } = errArg;
    navigate(RouteConstants.Error, {
      state: {
        code,
        message,
        status,
        statusText,
      },
    });
  };

  const saveResponses = async (data) => {
    const groupResponse = getGroupResponseFromFormVals(
      questionnaireMetadata,
      group.id,
      data
    );
    await dispatch(
      saveMedicationHistoryResponses(
        groupResponse,
        { ...responses, ...data },
        errorNavigation
      )
    );
  };

  const onSubmit = async (customResponses) => {
    // Save raw responses
    let filteredGroupResponses = {};
    Object.keys(customResponses)
      .filter((el) => el.startsWith(group.id))
      .forEach((el) => {
        filteredGroupResponses = {
          ...filteredGroupResponses,
          [el]: customResponses[el],
        };
      });
    await saveResponses(filteredGroupResponses);
  };

  const handleContinueBtnClick = () => {
    let medString = "";
    additionalMedications.forEach((med, index) => {
      if (index > 0) medString += "|";
      medString += `medication^${med.medication}~reason^${med.reason}~provider^${med.provider}`;
    });

    const customResponses = {
      ...responses,
      [`${group.id}_OtherMeds`]: formValues.ynOtherMeds,
      [`${group.id}_OtherMeds_AdditionalMedications`]: medString,
      [`${group.id}_Acknowledgement`]: formValues.acknowledgement
        ? "Yes"
        : "No",
    };
    dispatch(setResponses(customResponses));
    onSubmit(customResponses).then(() => {
      const updateStatus = async () => {
        await dispatch(updatePortraitStatus("QxComplete", errorNavigation));
      };

      updateStatus().then(() => {
        dispatch(setShowGroupCompletion(true));
        if (mode === "client") {
          setRedirect(true);
          const returnURL = process.env.REACT_APP_MSAL_REDIRECT_URL.replace(
            "blank",
            `Completion?a=${applicationId}&d=${documentId}`
          );
          sendPortraitEnvelope(
            { applicationId, documentId, returnURL },
            errorNavigation
          ).then((response) => {
            window.open(response.signerURL, "_self");
          });
        } else if (mode === "agent") {
          navigate(`/AgentCompletion`);
        } else if (mode === "examone") {
          navigate(`/EOCompletion?a=${applicationId}&d=${documentId}`);
        }
      });
    });
  };

  const handleBackBtnClick = () => {
    let medString = "";
    additionalMedications.forEach((med, index) => {
      if (index > 0) medString += "|";
      medString += `medication^${med.medication}~reason^${med.reason}~provider^${med.provider}`;
    });

    const customResponses = {
      ...responses,
      [`${group.id}_OtherMeds`]: formValues.ynOtherMeds,
      [`${group.id}_OtherMeds_AdditionalMedications`]: medString,
      [`${group.id}_Acknowledgement`]: formValues.acknowledgement,
    };
    dispatch(setResponses(customResponses));
    onSubmit(customResponses);
    dispatch(setShowMedEditForm({ showMedEditForm: null }));
    if (activeGroup === "PS-RS-1") {
      navigate(`/group/${activeGroup}`);
      return;
    }
    navigate("/PortraitReview/FinalQuestions");
  };

  const openMedForm = () => {
    const updatedMeds = [...additionalMedications];
    updatedMeds.push({
      medication: "",
      reason: "",
      provider: "",
      edit: true,
      temp: true,
    });
    dispatch(setAdditionalMedications({ additionalMedications: updatedMeds }));
    dispatch(setShowMedEditForm({ showMedEditForm: "new" }));
  };

  const invalidMeds = () => {
    let invalid = false;
    additionalMedications.forEach((med) => {
      if (
        med.medication === "" ||
        med.reason === "" ||
        med.provider === "" ||
        showMedEditForm
      ) {
        invalid = true;
      }
    });

    return invalid;
  };

  return redirect ? (
    <Layout>
      <RedirectToDocusignPres />
    </Layout>
  ) : (
    <MedicationHistoryLandingPres
      setValue={setValue}
      control={control}
      portraitMedications={portraitMedicationHistory}
      additionalMedications={additionalMedications}
      handleContinueBtnClick={handleContinueBtnClick}
      handleBackBtnClick={handleBackBtnClick}
      openMedForm={openMedForm}
      additionalMedsQuestion={additionalMedsQuestion}
      errorNavigation={errorNavigation}
      portraitMode={mode}
      groupId={group?.id}
      formValues={formValues}
      invalidMeds={invalidMeds}
      showMedEditForm={showMedEditForm}
      isLoading={isLoading}
      acknowledgement={acknowledgement}
      questionnaireId={questionnaire.id}
    />
  );
}
