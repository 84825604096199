/* eslint-disable dot-notation */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import { Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { removeDelimiters } from "util/genericHelper";
import { useDispatch, useSelector } from "react-redux";
import { getGroupResponseFromFormVals } from "util/getGroupResponseFromFormVals";
import { setResponses } from "features/Questionnaire/state/questionnaireSlice";
import EditPortraitMedication from "../presentation/EditPortraitMedication";
import PortraitMedicationList from "../presentation/PortraitMedHistoryPres";
import {
  propNames,
  transformInput,
  transformOutput,
} from "../../../util/medicationHelper";
import { saveMedicationHistoryResponses } from "../state/medicationHistoryActions";

let renderCount = 0;
const PortaitMedHistoryContainer = ({
  control: parentFormControl,
  label,
  id,
  required,
  defaultValue,
  value,
  disabled,
  error,
  setValue,
  onChange,
  errorNavigation,
}) => {
  renderCount += 1;
  const { control, watch, reset } = useForm({
    mode: "all",
  });
  const dispatch = useDispatch();
  const formValues = watch();
  const { responses } = useSelector((state) => state.medicationHistory);
  const { questionnaireMetadata } = useSelector((state) => state.lifePortrait);
  const { questionnaire } = useSelector((state) => state);
  const isValid = !Object.keys(formValues).some((el) => {
    if (
      formValues[el] === undefined ||
      formValues[el] === null ||
      formValues[el] === ""
    ) {
      return true;
    }
    return false;
  });

  const medicationMenuButtons = {
    addNewMedication: "Add New Medication",
    modifyMedication: "Modify Medication",
    manageMedications: "Manage Medications",
  };
  const menuOptionObj = {
    menuLabel: medicationMenuButtons.addNewMedication,
    showMedCard: false,
  };
  const [menuOption, setMenuOption] = useState(menuOptionObj);
  const [medications, setMedications] = useState(
    transformInput(id, value, uuidv4)
  );

  const showMedCardDisplay = () => {
    setMenuOption({ ...menuOption, showMedCard: true });
    reset({
      [`${id}-${propNames.medication}`]: undefined,
      [`${id}-${propNames.current}`]: undefined,
    });
  };
  const setUpdatedrecord = (updatedMedications) => {
    setMedications(updatedMedications);
    const transformedMedicationsforParent = transformOutput(
      id,
      updatedMedications
    );
    if (setValue) setValue(id, transformedMedicationsforParent);
    if (onChange) onChange(transformedMedicationsforParent);
  };

  const hideMedCard = () => {
    setMenuOption({
      ...menuOption,
      showMedCard: false,
      menuLabel: medicationMenuButtons.addNewMedication,
      defaultValues: undefined,
    });
  };

  const saveResponses = async (data, group) => {
    const groupResponse = getGroupResponseFromFormVals(
      questionnaireMetadata,
      group,
      data
    );
    // eslint-disable-next-line max-len
    await dispatch(
      saveMedicationHistoryResponses(
        groupResponse,
        { ...questionnaire.responses, ...data },
        errorNavigation
      )
    );
  };

  const onSubmit = async (customResponses, group) => {
    // Save raw responses
    let filteredGroupResponses = {};
    Object.keys(customResponses)
      .filter((el) => el.startsWith(group))
      .forEach((el) => {
        filteredGroupResponses = {
          ...filteredGroupResponses,
          [el]: customResponses[el],
        };
      });
    await saveResponses(filteredGroupResponses, group);
  };

  const addMedicationHandler = async (evt) => {
    // console.log(formValues);
    let record = {};
    let updatedMedications = [...medications];
    const medicationKey = `${id}-${propNames.medication}`;
    const currentKey = `${id}-${propNames.current}`;
    const medicationValue = removeDelimiters(
      formValues[`${id}-${propNames.medication}`]
    );
    const currentValue = removeDelimiters(
      formValues[`${id}-${propNames.current}`]
    );
    if (menuOption.menuLabel === medicationMenuButtons.addNewMedication) {
      record = {
        guid: uuidv4(),
        [medicationKey]: medicationValue,
        [currentKey]: currentValue,
      };
      updatedMedications = [...medications, record];
    }

    if (menuOption.menuLabel === medicationMenuButtons.modifyMedication) {
      record = {
        ...menuOption.defaultValues,
        [medicationKey]: medicationValue,
        [currentKey]: currentValue,
      };

      const objIndex = updatedMedications.findIndex((obj) => {
        return obj.guid === record.guid;
      });

      updatedMedications[objIndex] = record;
    }
    setUpdatedrecord(updatedMedications);
    const medIndex = medications.findIndex((med) => med.guid === record.guid);
    medications.splice(medIndex, 1, record);
    // concat the meds together
    let medString = "";
    medications.forEach((med, index) => {
      if (index > 0) medString += "|";
      medString += `medication^${
        med[`${id}-${propNames.medication}`]
      }~current^${med[`${id}-${propNames.current}`] === "Yes" ? 1 : 0}`;
    });

    const customResponses = { ...questionnaire.responses, [id]: medString };
    dispatch(setResponses(customResponses));
    onSubmit(customResponses, id.slice(0, id.indexOf("_")));
    hideMedCard();
  };

  const handleProviderItemClick = (evt, record, action) => {
    const objIndex = medications.findIndex((obj) => {
      return obj.guid === record.guid;
    });

    if (action === "edit") {
      setMenuOption({
        ...menuOption,
        menuLabel: medicationMenuButtons.modifyMedication,
        showMedCard: true,
        defaultValues: medications[objIndex],
      });

      reset(medications[objIndex]);
    }

    if (action === "delete") {
      const { guid } = medications[objIndex];
      const updatedMedication = [
        ...medications.filter((el) => el.guid !== guid),
      ];
      setUpdatedrecord(updatedMedication);
    }
  };

  // Function to parse the value - format Med1~1|Med2~0|Med3~0
  // Prepare the structure
  return (
    <div>
      {medications && medications.length > 0 && (
        <PortraitMedicationList
          id={id}
          medications={medications}
          handleEditClick={handleProviderItemClick}
        />
      )}
      {menuOption.showMedCard && (
        <EditPortraitMedication
          id={id}
          addMedicationHandler={addMedicationHandler}
          menuOption={menuOption}
          medicationMenuButtons={medicationMenuButtons}
          isValid={isValid}
          control={control}
          hideMedCard={hideMedCard}
          formValues={formValues}
        />
      )}
    </div>
  );
};

export default PortaitMedHistoryContainer;
