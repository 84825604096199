import { Typography } from "@mui/material";

export const HomeOfficeConst = "HomeOffice";

export const DashboardTabs = {
  Dashboard: 0,
  Archive: 1,
};

export const ArchiveStatus = {
  None: "None",
  Archive: "Archive",
  Delete: "Delete",
};

export const ClientRole = {
  insured: "Insured",
  authorizedRepresentative: "Authorized Representative",
};

export const RecipientRole = {
  agent: "Agent",
  client: "Client",
};

export const PortraitStatuses = {
  Initial: "Initial",
  Started: "Started",
  Declined: "Declined",
  Notstarted: "Notstarted",
  QxComplete: "QxComplete",
  SentForSignature: "SentForSignature",
  Complete: "Complete",
  Deleted: "Deleted"
};

export const PortraitStatusesForUI = {
  Initial: "Initial",
  Started: "Started",
  Declined: "Declined",
  Notstarted: "Not started",
  QxComplete: "QxComplete",
  SentForSignature: "Sent for signature",
  Complete: "Complete",
};

export const portraitCols = [
  {
    field: "applicantName",
    headerName: "Applicant Name",
    width: 200,
    renderCell: (params) => {
      return (
        <strong>
          <Typography variant="subtitle2" sx={{ cursor: "pointer" }}>
            {params.value}
          </Typography>
        </strong>
      );
    },
    hideable: false,
  },
  {
    field: "location",
    headerName: "Completer",
    width: 100,
    options: {},
    hideable: false,
  },
  {
    field: "status",
    headerName: "Status",
    width: 140,
    renderCell: (params) => {
      return PortraitStatusesForUI[params.value] || params.value;
    },
    options: {},
    hideable: false,
  },
  {
    field: "lastVisited",
    headerName: "Last Visited",
    width: 100,
    type: "date",
    renderCell: (params) => {
      let lastVisitedStr = "";
      if (params.value) {
        lastVisitedStr = new Date(params.value).toLocaleDateString();
      }
      return lastVisitedStr;
    },
    options: {},
    hideable: false,
  },
  {
    field: "dueDate",
    headerName: "Due Date",
    width: 100,
    type: "date",
    renderCell: (params) => {
      const { dueDateFlag, dueDate } = params.row;
      const lastVisitedStr = new Date(dueDate).toLocaleDateString();
      const higlLight = dueDateFlag ? { color: "red" } : undefined;
      return <Typography style={higlLight}>{lastVisitedStr}</Typography>;
    },
    options: {},
    hideable: false,
  },
  {
    field: "email",
    headerName: "Email Address",
    width: 150,
    flex: 1,
    options: {},
    hideable: false,
  },
  {
    field: "agentName",
    headerName: "Agent Name",
    width: 150,
    flex: 1,
    options: {},
    hideable: false,
  },
  {
    field: "actions",
    headerName: "Actions",
    flex: 1,
    hideable: false,
    type: "actions",
  },
];

export const openDeleteRowDialog = (row) => {
  return row;
};

export const portraitFeatureModalEnum = {
  editClientModal: "editClientDetailsModal",
  transferPortraitModal: "transferPortraitModal",
  docuSignPortraitModal: "docuSignPortraitModal",
  closeModal: "close",
};

export const RecipientStatus = {
  NotSent: "Not Sent",
  Sent: "Sent",
  AutoResponded: "AutoResponded",
  AwaitingClientSigs: "AwaitingClientSigs",
  Declined: "Declined",
};

export const deliveryOptions = {
  Email: "Email",
  SMS: "SMS"
};
