// Logic to evaluate if all the sections are complete for review and submit
export const disableRouteToReviewNSubmit = (
  lnavGroupBtn,
  lquestionnaireResponse,
  lquestionnaireLP
) => {
  const reviewArr = ["PS-RS-1", "PC-RS-1", "PT-RS-1"];

  // console.log(
  //   ">>> disableRouteToReviewNSubmit>> 1 reviewArr.includes",
  //   reviewArr.includes(lnavGroupBtn.nextGroupId),
  //   lnavGroupBtn.nextGroupId
  // );

  if (!reviewArr.includes(lnavGroupBtn.nextGroupId)) return false;

  const completedGroupsCount = lquestionnaireResponse?.groups?.filter(
    (el) => !reviewArr.includes(el.id) && el.status === "completed"
  ).length;

  const schemaGroupsCount = lquestionnaireLP?.groups?.filter(
    (el) => !reviewArr.includes(el.id)
  ).length;

  // console.log(
  //   ">>> ",
  //   completedGroupsCount,
  //   schemaGroupsCount,
  //   lquestionnaireLP,
  //   lquestionnaireResponse
  // );

  if (completedGroupsCount === schemaGroupsCount) return false;

  return true;
};
