/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Badge, Box, Typography } from "@mui/material";
import FBNumber from "components/Elements/FBNumber";
import FBMonthYearPicker from "components/Elements/FBMonthYearPicker";
import FBComboBox from "components/Elements/FBComboBox";
import FBProvider from "features/Provider";
import FBTextBox from "components/Elements/FBTextBox";

import {
  transformHeartAttackInput,
  transformHeartAttackOutput,
  propNamesHeartAttack,
} from "util/heartAttackHelper";
import FBMedication from "features/Medication";
import { idComplete } from "util/genericHelper";

// let renderCount = 0;
const FBHeartAttackLanding = (props) => {
  // renderCount += 1;
  const lblComplete = idComplete;
  const {
    id,
    required,
    value,
    // setValue: parentSetValue,
    tunnelToParent,
    options,
    label,
  } = props;

  const {
    setValue: parentSetValue,
    setValueInReduxResponses,
    getValues,
  } = tunnelToParent;

  const {
    dateShow,
    dateCaption,
    text1Show,
    text1Caption,
    text2Show,
    text2Caption,
    providerShow,
    providerCaption,
    itemCaption,
  } = {
    dateShow: true,
    dateCaption: "When did this heart attack occur?",
    text1Show: true,
    text1Caption: "What treatment was given for the heart attack?",
    text2Show: true,
    text2Caption: "Description?",
    providerShow: true,
    providerCaption: "Who is the doctor who treated the heart attack?",
    itemCaption: "Heart Attack",
    ...options,
  };

  const tTypeEnum = {
    BypassSurgery: "Bypass surgery",
    Angioplasty: "Angioplasty/Stent",
    Medication: "Medication",
    Other: "Other",
  };
  const treatmentTypes = [
    { label: tTypeEnum.BypassSurgery, value: tTypeEnum.BypassSurgery },
    { label: tTypeEnum.Angioplasty, value: tTypeEnum.Angioplasty },
    { label: tTypeEnum.Medication, value: tTypeEnum.Medication },
    { label: tTypeEnum.Other, value: tTypeEnum.Other },
  ];

  const { control, watch, reset, setValue } = useForm({
    mode: "all",
  });

  const formValues = watch();

  const [numberOfTimes, setNumberOfTimes] = useState(null);

  useEffect(() => {
    let isCompleteLocal = false;
    if (value) {
      const {
        numberOfTimes: lnumberOfTimes,
        responses,
        isComplete,
      } = transformHeartAttackInput(id, value);
      isCompleteLocal = isComplete;
      const evalnum = parseInt(lnumberOfTimes, 10);
      // eslint-disable-next-line no-restricted-globals
      if (typeof evalnum === "number" && !isNaN(evalnum)) {
        setNumberOfTimes(evalnum);
        // if (lnumberOfTimes) setValue(id, value);
        reset(responses);
      }
    }
    parentSetValue(`${id}${lblComplete}`, isCompleteLocal);
    // parentSetValue(id, value);
  }, [id, value]);

  const transformAndUpdateParent = (lid, lnumberOfTimes, lformValues) => {
    const { transformedValue, isComplete } = transformHeartAttackOutput(
      lid,
      lnumberOfTimes,
      lformValues
    );
    const custTransValue =
      transformedValue === "[null]" ? undefined : transformedValue;
    if (parentSetValue) {
      parentSetValue(`${id}${lblComplete}`, isComplete);
      parentSetValue(id, custTransValue, { shouldDirty: true });
    }
    if (setValueInReduxResponses) {
      setValueInReduxResponses(id, custTransValue, getValues);
    }
  };

  const numberOfTimesChangeHandler = (e) => {
    let lnoOfTimes = e === "" || e === undefined ? null : parseInt(e, 10);
    // Following condition always restricts the use to 10 items
    if (typeof lnoOfTimes === "number" && lnoOfTimes >= 10) lnoOfTimes = 10;
    setNumberOfTimes(lnoOfTimes);
    transformAndUpdateParent(id, lnoOfTimes, formValues);
  };

  const inputChangeHandler = (e, controlId) => {
    transformAndUpdateParent(id, numberOfTimes, {
      ...formValues,
      [controlId]: e,
    });
  };

  const comboBoxChangeHandler = (e, controlId) => {
    const changedValue = (typeof e === 'object') ? { ...formValues } : { ...formValues, [controlId]: e };
    transformAndUpdateParent(id, numberOfTimes, changedValue);
  };

  return (
    <Box
      sx={{
        width: "fit-content",
        border: 1,
        padding: 1,
        borderColor: "lightslategrey",
        borderRadius: 2,
      }}
    >
      {/* {`Rendercount:${renderCount}`} */}
      <Typography color="text.secondary" variant="body2" gutterBottom>
        {label}
      </Typography>
      <FBNumber
        id={`${id}-${propNamesHeartAttack.numberOfTimes}`}
        required={required}
        control={control}
        onChange={numberOfTimesChangeHandler}
        value={numberOfTimes}
      />
      <br />
      {typeof numberOfTimes === "number" &&
        numberOfTimes > 0 &&
        [...Array(numberOfTimes)].map((el, index) => {
          const showDesc =
            formValues[`${id}-${index}-${propNamesHeartAttack.dropdown}`] ===
            tTypeEnum.Other;
          const showMedication =
            formValues[`${id}-${index}-${propNamesHeartAttack.dropdown}`] ===
            tTypeEnum.Medication;
          return (
            <Box
              key={`${id}-${index}`}
              sx={{
                marginTop: "10px",
                // marginBottom: "5px",
                width: "fit-content",
                border: 1,
                padding: 1,
                borderColor: "lightslategrey",
                borderRadius: 2,
              }}
            >
              <Typography variant="subtitle1" gutterBottom>
                <Badge>{`${itemCaption}-${index + 1}`}</Badge>
              </Typography>
              {dateShow && (
                <>
                  <Typography
                    color="text.secondary"
                    variant="body2"
                    gutterBottom
                  >
                    {dateCaption}
                  </Typography>
                  <FBMonthYearPicker
                    id={`${id}-${index}-${propNamesHeartAttack.date}`}
                    control={control}
                    required={required}
                    onChange={(e) => {
                      inputChangeHandler(e, `${id}-${index}-date`);
                    }}
                    value={
                      formValues[`${id}-${index}-${propNamesHeartAttack.date}`]
                    }
                  />
                  <br />
                </>
              )}
              {text1Show && (
                <>
                  <Typography
                    color="text.secondary"
                    variant="body2"
                    gutterBottom
                  >
                    {text1Caption}
                  </Typography>
                  <FBComboBox
                    id={`${id}-${index}-${propNamesHeartAttack.dropdown}`}
                    control={control}
                    required={required}
                    items={treatmentTypes}
                    onChange={(e) => {
                      comboBoxChangeHandler(
                        e,
                        `${id}-${index}-${propNamesHeartAttack.dropdown}`
                      );
                    }}
                  />
                </>
              )}
              {text2Show && showDesc && (
                <>
                  <Typography
                    color="text.secondary"
                    variant="body2"
                    gutterBottom
                  >
                    {text2Caption}
                  </Typography>
                  <FBTextBox
                    id={`${id}-${index}-${propNamesHeartAttack.otherDesc}`}
                    control={control}
                    required={required}
                    onChange={(e) => {
                      inputChangeHandler(
                        e,
                        `${id}-${index}-${propNamesHeartAttack.otherDesc}`
                      );
                    }}
                    value={
                      formValues[
                        `${id}-${index}-${propNamesHeartAttack.otherDesc}`
                      ]
                    }
                  />
                </>
              )}
              {text2Show && showMedication && (
                <FBMedication
                  id={`${id}-${index}-${propNamesHeartAttack.medicationTreatment}`}
                  control={control}
                  value={
                    formValues[
                      `${id}-${index}-${propNamesHeartAttack.medicationTreatment}`
                    ]
                  }
                  required={required}
                  tunnelToParent={{ setValue }}
                  onChange={(e) => {
                    inputChangeHandler(
                      e,
                      `${id}-${index}-${propNamesHeartAttack.medicationTreatment}`
                    );
                  }}
                />
              )}
              {providerShow && (
                <>
                  <Typography
                    color="text.secondary"
                    variant="body2"
                    gutterBottom
                  >
                    {providerCaption}
                  </Typography>
                  {/* <FBComboBox */}
                  <FBProvider
                    id={`${id}-${index}-${propNamesHeartAttack.provider}`}
                    control={control}
                    required={required}
                    tunnelToParent={{
                      setValue,
                    }}
                    onChange={(e) => {
                      inputChangeHandler(
                        e,
                        `${id}-${index}-${propNamesHeartAttack.provider}`
                      );
                    }}
                  />
                </>
              )}
            </Box>
          );
        })}
    </Box>
  );
};

export default FBHeartAttackLanding;
