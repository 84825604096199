/* eslint-disable default-param-last */
/* eslint-disable no-case-declarations */
const IN_DELIMITER = "~";

const ConditionTypes = {
  Or: (logicStatements) => logicStatements.reduce((acc, next) => acc || next),
  And: (logicStatements) => logicStatements.reduce((acc, next) => acc && next),
  Single: (logicStatements) => logicStatements[0] === true,
};

const OperatorTypes = {
  Equal: (a, b) => a === b,
  NotEqual: (a, b) => a !== b,
  Greater: (a, b) => a > b,
  Less: (a, b) => a < b,
  In: (needle, haystack) => haystack.split(IN_DELIMITER).includes(needle),
  NotIn: (needle, haystack) =>
    needle !== undefined &&
    haystack.split(IN_DELIMITER).includes(needle) === false,
};

function translateLogicStatement(data) {
  const result = {
    id: data.id,
    operator: data.operator,
    trigger: data.triggeringValue,
  };
  return result;
}

function transformTriggerCompareValue(element, value) {
  const curControlTypeName = element && element.controlTypeName;
  const origControlTypeName = element && element.origControlTypeName;
  let transformedValue = value;

  // support `ReadOnly` in HTCombo
  const controlTypeName = origControlTypeName || curControlTypeName;

  switch (controlTypeName) {
    case "Date":
      const dateVal = value ? new Date(value) : value;
      if (dateVal && dateVal !== "Invalid Date") {
        transformedValue = dateVal.getTime();
      }
      break;
    case "Number":
      transformedValue = parseInt(value, 10) || undefined;
      break;
    default:
      break;
  }
  return transformedValue;
}

function getTriggerValue(allElements, allElementsIndex, data) {
  const position = allElementsIndex[data.id] && allElementsIndex[data.id][0];

  if (
    position === undefined ||
    !data.isSamePage ||
    (position !== undefined && !allElements[position].showLogicLookUp)
  ) {
    // if position is undefined there is no transformation to be done
    if (position === undefined) {
      return data.trigger;
    }

    return transformTriggerCompareValue(allElements[position], data.trigger);
  }

  const lookUpValue = allElements[position].showLogicLookUp;

  if (!lookUpValue) return "";

  if (data.operator.includes("In", "NotIn")) {
    const triggerVals = data.trigger
      .split(IN_DELIMITER)
      .map((val) => lookUpValue[val]);
    return triggerVals.join(IN_DELIMITER);
  }

  return transformTriggerCompareValue(
    allElements[position],
    lookUpValue[data.trigger]
  );
}

function getLogicPosition(
  allElements,
  allElementsIndex,
  data,
  logicIndexPosition = 0,
  parentId
) {
  let logicPos = 0;

  if (parentId) {
    const logicEl = allElementsIndex[data.id];
    if (logicEl) {
      const elIndex =
        logicIndexPosition >= logicEl.length ? 0 : logicIndexPosition;
      const compareEl = allElements[logicEl[elIndex]];

      if (compareEl.parentId === parentId) {
        logicPos = logicIndexPosition;
      }
    }
  }

  return logicPos;
}

function getCompareVisiblity(
  allElements,
  allElementsIndex,
  data,
  logicIndexPosition = 0,
  parentId
) {
  // return a Boolean whether an element is visible or not
  const logicPos = getLogicPosition(
    allElements,
    allElementsIndex,
    data,
    logicIndexPosition,
    parentId
  );
  const position =
    allElementsIndex[data.id] && allElementsIndex[data.id][logicPos];

  // if provided an element from another page just always return this as `true`
  // and let the showLogic statement evaluate whether it's visible or not
  if (position === undefined && !data.isSamePage) {
    return true;
  }

  const isCompareVisible = allElements[position].props.isComponentVisible;
  return isCompareVisible;
}

function getConditionFn(operator) {
  return (conditions) => ConditionTypes[operator](conditions);
}

function getCompareValue(
  allElements,
  allElementsIndex,
  data,
  logicIndexPosition = 0,
  parentId
) {
  if (allElementsIndex[data.id] === undefined) {
    // console.warn(`A showLogic statement is referencing elementId
    // '${data.id} that does not exist'`);
  }

  const logicPos = getLogicPosition(
    allElements,
    allElementsIndex,
    data,
    logicIndexPosition,
    parentId
  );
  const position =
    allElementsIndex[data.id] && allElementsIndex[data.id][logicPos];

  // exists on current page
  // doesnt exist on current page but backend provides value
  // should exist on current page but doesnt. return null

  if (position !== undefined) {
    const val = allElements[position].props.value;
    return transformTriggerCompareValue(allElements[position], val);
  }

  throw Error(
    "Missing comparison value in showLogic/complexShowLogic statement."
  );
}

function transformTriggerValue(existingtriggerValue) {
  const updatedTrigger = existingtriggerValue.split("~");
  updatedTrigger.push("");
  return updatedTrigger.join("~");
}

// if the id we are searching for does not exit
// then we just assume we're going to show it
function getLogicFn(data) {
  return (allElements, elementsIndex, logicIndexPosition, parentId) => {
    const compareVal = getCompareValue(
      allElements,
      elementsIndex,
      data,
      logicIndexPosition,
      parentId
    );

    if (compareVal === undefined) return false;
    if (compareVal === null) return null;

    // if (!data.isSamePage && data.otherPageValue === "") return false;

    let triggerValue = getTriggerValue(allElements, elementsIndex, data);
    const isCompareVisible = getCompareVisiblity(
      allElements,
      elementsIndex,
      data,
      logicIndexPosition,
      parentId
    );

    const logicPos = getLogicPosition(
      allElements,
      elementsIndex,
      data,
      logicIndexPosition,
      parentId
    );
    const position = elementsIndex[data.id] && elementsIndex[data.id][logicPos];

    // this fix was added because we can not compare a date vs. and empty date
    // otherwise that date could always end up being greater or less than
    // resulting in a showLogic condition that returns true
    if (position !== undefined) {
      // when a control is provided no value (think initialization)
      // then we should evaluate the showLogic
      // with a return value of `false` per business requirement the compareVal needs to exist
      if (compareVal === "") {
        if (allElements[position].controlTypeName === "Date") {
          return false;
        }
        // compareVal empty and element is Dropdown then ...
        if (
          triggerValue &&
          typeof triggerValue !== "boolean" &&
          triggerValue.includes("(Select)") &&
          allElements[position].controlTypeName === "DropDown"
        ) {
          if (data.operator === "Equal") {
            return true;
          }
          if (data.operator === "NotEqual") {
            return false;
          }
          // We are going to consider "" as (Select).
          if (data.operator === "NotIn" || data.operator === "In") {
            triggerValue = transformTriggerValue(triggerValue);
          }
        }
      }
    }
    return (
      OperatorTypes[data.operator](compareVal, triggerValue) && isCompareVisible
    );
  };
}

// eslint-disable-next-line consistent-return
function traverseLogicCompute(
  data,
  elements,
  elementsIndex,
  logicIndexPosition,
  parentId
) {
  if (!Array.isArray(data) && !data.condition && !data.group) {
    const translatedLogicStatement = translateLogicStatement(data);
    return getLogicFn(translatedLogicStatement)(
      elements,
      elementsIndex,
      logicIndexPosition,
      parentId
    );
  }

  if (
    !data.group &&
    data.condition &&
    Array.isArray(data.condition) &&
    data.condition.length
  ) {
    return getConditionFn(data.operator)(
      data.condition.map((item) =>
        traverseLogicCompute(
          item,
          elements,
          elementsIndex,
          logicIndexPosition,
          parentId
        )
      )
    );
  }

  if (data.group && Array.isArray(data.group) && data.group.length) {
    if (data.condition) {
      return getConditionFn(data.operator)(
        data.condition
          .map((item) =>
            traverseLogicCompute(
              item,
              elements,
              elementsIndex,
              logicIndexPosition,
              parentId
            )
          )
          .concat(
            ...data.group.map((item) =>
              traverseLogicCompute(
                item,
                elements,
                elementsIndex,
                logicIndexPosition,
                parentId
              )
            )
          )
      );
    }
    return getConditionFn(data.operator)(
      data.group.map((item) =>
        traverseLogicCompute(
          item,
          elements,
          elementsIndex,
          logicIndexPosition,
          parentId
        )
      )
    );
  }
}

function computeLogic(
  data,
  elements,
  elementsIndex,
  logicIndexPosition,
  parentId
) {
  const logicResult = traverseLogicCompute(
    data,
    elements,
    elementsIndex,
    logicIndexPosition,
    parentId
  );
  return logicResult;
}

export {
  traverseLogicCompute,
  computeLogic,
  getConditionFn,
  getLogicFn,
  translateLogicStatement,
};
