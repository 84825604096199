/* eslint-disable react/forbid-prop-types */
import { Button, Typography, Box } from "@mui/material";
import FBTextBox from "components/Elements/FBTextBox";
import FBProvider from "features/Provider";
import PropTypes from "prop-types";

export default function AddMedicationPres({
  option,
  id,
  addMedicationHandler,
  control,
  isValid,
  hideMedCard,
  formValues,
  editMedication,
  tunnelToParent,
}) {
  return (
    <div>
      <Box
        sx={{
          width: "fit-content",
          border: 1,
          marginTop: 1,
          padding: 2,
          borderColor: "lightslategrey",
          borderRadius: 2,
          paddingY: 2,
        }}
      >
        <div>
          <Typography>
            What is the name of the medication prescribed?
          </Typography>
          <FBTextBox
            id={`${id}-medication`}
            name="medication"
            required
            control={control}
            value={formValues[`${id}-medication`]}
            onChange={editMedication}
          />
        </div>
        <div>
          <Typography>Why was the medication prescribed?</Typography>
          <FBTextBox
            id={`${id}-reason`}
            name="medication"
            required
            control={control}
            value={formValues[`${id}-reason`]}
          />
        </div>
        <div>
          <Typography>
            Who is the doctor who prescribed the medication?
          </Typography>
          <FBProvider
            id={`${id}-provider`}
            required
            control={control}
            value={formValues[`${id}-provider`]}
            tunnelToParent={tunnelToParent}
          />
        </div>

        <div className="row justify-content-center py-3">
          <Button
            variant="outlined"
            onClick={() => hideMedCard(option !== "edit")}
            sx={{ width: 180 }}
          >
            Cancel
          </Button>
          <Button
            variant="outlined"
            sx={{ width: 180, marginLeft: 2 }}
            onClick={addMedicationHandler}
            disabled={!isValid}
          >
            {option === "edit" ? "Modify medication" : "Add Medication"}
          </Button>
        </div>
      </Box>
    </div>
  );
}

AddMedicationPres.propTypes = {
  option: PropTypes.string,
  id: PropTypes.string,
  addMedicationHandler: PropTypes.func,
  control: PropTypes.object,
  isValid: PropTypes.bool,
  hideMedCard: PropTypes.func,
  formValues: PropTypes.any,
  editMedication: PropTypes.any,
  tunnelToParent: PropTypes.object,
};
