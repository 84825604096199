/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";
import PropTypes from "prop-types";
import logo from "assets/LifePortrait.png";

export default function LifePortraitHeader(props) {
  const { id } = props;

  return (
    <nav id={id} name={id} className="navbar navbar-expand-lg navbar-dark">
      <div className="container-xl">
        <a className="navbar-brand" href="/">
          <img src={logo} alt="Farm Bureau Logo" />
        </a>
      </div>
    </nav>
  );
}

LifePortraitHeader.propTypes = {
  id: PropTypes.string.isRequired,
};
