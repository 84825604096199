import {
  rowDelimiter,
  colDelimiter,
  keyValueDelimiter,
  decode,
  encode,
} from "./genericHelper";

export const propNamesHeartAttack = {
  numberOfTimes: "numberOfTimes",
  date: "date",
  dropdown: "treatment",
  otherDesc: "otherTreatment",
  medicationTreatment: "medicationTreatment",
  provider: "provider",
};

export const transformHeartAttackInput = (id, val) => {
  // eslint-disable-next-line max-len
  // "[2]date^2017-04-04T18:02:50.050Z~treatment^~otherTreatment^~provider^progui1|date^2015-05-04T18:03:07.865Z~treatment^~otherTreatment^~provider^progui1";
  let returnObj = {};
  let isInComplete = false;
  let numberOfTimes;
  if (val) {
    numberOfTimes = val.slice(0, val.indexOf("]") + 1).slice(1, -1);
    const strippedHosp = val.replace(val.slice(0, val.indexOf("]") + 1), "");
    returnObj = { ...returnObj, [`${id}-numberOfTimes`]: numberOfTimes };
    const rows = strippedHosp.split(rowDelimiter);

    if (rows.length) {
      rows.forEach(async (el, index) => {
        if (el && el !== "") {
          const cols = el.split(colDelimiter);
          if (cols.length > 0) {
            cols.forEach((col) => {
              const keyValueArr = col.split(keyValueDelimiter);

              if (keyValueArr.length > 0) {
                const key = keyValueArr[0];
                let value = keyValueArr[1];
                if (
                  (key === propNamesHeartAttack.date ||
                    key === propNamesHeartAttack.dropdown ||
                    key === propNamesHeartAttack.provider) &&
                  (value === undefined || value === "")
                ) {
                  isInComplete = true;
                }

                if (value && value !== "") {
                  if (key === propNamesHeartAttack.date) {
                    value = new Date(value);
                  }
                  if (key === propNamesHeartAttack.desc) {
                    value = decode(value);
                  }
                  if (key === propNamesHeartAttack.medicationTreatment) {
                    value = decode(value);
                  }
                  returnObj = {
                    ...returnObj,
                    [`${id}-${index}-${key}`]: value,
                  };
                }
              }
            });
          }
        }
      });
      // await Promise.all(promises);
    }
    if (!isInComplete && numberOfTimes && parseInt(numberOfTimes, 10) < 0) {
      isInComplete = true;
    }
  }
  return { numberOfTimes, responses: returnObj, isComplete: !isInComplete };
};

export const transformHeartAttackOutput = (id, value, responseCollection) => {
  let returnVal = "";
  const numberOfTimes = value;
  let isInComplete = false;
  if (
    numberOfTimes < 0 ||
    numberOfTimes === null ||
    numberOfTimes === undefined
  ) {
    isInComplete = true;
  }
  if (numberOfTimes > 0) {
    [...Array(numberOfTimes)].forEach((el, index) => {
      const date =
        responseCollection[`${id}-${index}-${propNamesHeartAttack.date}`];
      const dropdown =
        encode(
          responseCollection[`${id}-${index}-${propNamesHeartAttack.dropdown}`]
        ) || "";
      const otherDesc =
        encode(
          responseCollection[`${id}-${index}-${propNamesHeartAttack.otherDesc}`]
        ) || "";
      const medicationTreatment =
        encode(
          responseCollection[
            `${id}-${index}-${propNamesHeartAttack.medicationTreatment}`
          ]
        ) || "";
      const provider =
        responseCollection[`${id}-${index}-${propNamesHeartAttack.provider}`] ||
        "";

      if (
        !date ||
        date === "" ||
        !dropdown ||
        dropdown === "" ||
        (dropdown === "Other" && (!otherDesc || otherDesc === "")) ||
        (dropdown === "Medication" &&
          (!medicationTreatment || medicationTreatment === "")) ||
        !provider ||
        provider === "" ||
        provider === "addNewProvider" ||
        provider === "manageProvider"
      ) {
        isInComplete = true;
      }

      let dateStr = "";
      try {
        dateStr = date ? date?.toISOString() : ``;
        // eslint-disable-next-line no-empty
      } catch {
        isInComplete = true;
      }

      returnVal += `${propNamesHeartAttack.date}${keyValueDelimiter}${dateStr}${colDelimiter}${propNamesHeartAttack.dropdown}${keyValueDelimiter}${dropdown}${colDelimiter}${propNamesHeartAttack.otherDesc}${keyValueDelimiter}${otherDesc}${colDelimiter}${propNamesHeartAttack.medicationTreatment}${keyValueDelimiter}${medicationTreatment}${colDelimiter}${propNamesHeartAttack.provider}${keyValueDelimiter}${provider}${rowDelimiter}`;
    });
  }

  return {
    transformedValue: `[${numberOfTimes}]${returnVal.slice(0, -1)}`,
    isComplete: !isInComplete,
  };
};
