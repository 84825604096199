import * as React from "react";
import PropTypes from 'prop-types';

export default function PrivacyPolicy(props) {
  const {
    id,
  } = props;

  return (
    <div id={id} name={id}>
      <h5>Privacy Policy</h5>
      <p>
        Tennessee Farmers Mutual Insurance Company<br />
        Tennessee Farmers Assurance Company<br />
        Tennessee Farmers Life Insurance Company
      </p>
      <p><h5>Security Of Your Nonpublic Personal Information</h5></p>
      <p>
        The privacy of our customers and members is important to us
        and we are committed to its protection. We have a tradition
        of respecting your right to keep personal information
        private and avoid unwanted solicitations. As required by new
        federal and state laws, we have developed this privacy
        policy to inform you of how we handle your personal
        information.
      </p>
      <p>
        <h5>
          Categories Of Nonpublic Personal Information Collected and
          Subject to Disclosure
        </h5>
      </p>
      <p>
        We collect nonpublic personal information from the following
        sources:
      </p>
      <p>
        Information we receive on applications or other forms such
        as names, addresses , dates of birth, and information
        relating to the exposure or risk to be insured
      </p>
      <p>
        Information about transactions with us, our affiliates or
        others such as policy numbers and coverage; and
      </p>
      <p>
        Information we receive from consumer and credit reporting
        agencies such as claim and credit histories.
      </p>
      <p><h5>Restrictions On Use Of Information</h5></p>
      <p><h6>Restrictions on Use By Us</h6></p>
      <p>
        We restrict access to nonpublic personal information about
        you to those employees, independent contracting agents, and
        other affiliated entities or individuals who need to know
        that information to provide products or services to you.
        Their right to further disclose and use that information is
        limited by our employee conduct rules, applicable law, and
        nondisclosure agreements where appropriate. We maintain
        physical, electronic, and procedural safeguards that comply
        with federal and state regulations to guard your nonpublic
        personal information.
      </p>
      <p><h6>Restrictions On Use By Others</h6></p>
      <p>
        We do not sell your nonpublic personal information to
        anyone. We only disclose nonpublic personal information to
        others as permitted or required by law in conjunction with
        our normal insurance operations. For example, we may provide
        limited nonpublic personal information to others to process
        claims, to underwrite and maintain your insurance, to combat
        fraud, in response to examination by government agencies, or
        if required by a court. Any person or entity to whom we
        release information is prohibited by law and/or contract
        with us from misusing nonpublic personal information.
        Because our use of nonpublic personal information is
        restricted as described above, an opt out provision is not
        necessary or available.
      </p>
    </div>
  );
}

PrivacyPolicy.propTypes = {
  id: PropTypes.string.isRequired,
};
