/* eslint-disable indent */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getGroupResponseFromFormVals } from "util/getGroupResponseFromFormVals";
import { FBControlTypes, ErrorRoute, medFrmRouteSeg } from "util/genericHelper";
import { disableRouteToReviewNSubmit } from "util/evalEnableReviewAndSubmitRoute";
import FBSpinner from "components/Elements/FBSpinner";
import {
  setActivePage,
  setGroupNavState,
  setPageNavState,
  setProviderAddedOrModified,
  setResponses,
  setShowGroupCompletion,
} from "../state/questionnaireSlice";

import {
  saveGroupResponses,
  setQuestionsActionMethod,
} from "../state/questionnaireActions";
import QuestionnairePageContainer from "./QuestionnairePageContainer";
import QuestionnaireGroupCompleted from "../presentation/QuestionnaireGroupCompleted";

export default function QuestionnaireGroupContainer() {
  const [isReady, setIsReady] = useState(false);
  // eslint-disable-next-line react/prop-types
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const questionnaire = useSelector((state) => state.questionnaire);
  const {
    questionnaireMetadata,
    mode,
    questionnaire: questionnaireLP,
    questionnaireResponse,
  } = useSelector((state) => state.lifePortrait);
  const {
    activeGroup: groupId,
    group,
    activePage,
    groupNavState: navGroupBtn,
    pageNavState: navBtn,
    questions,
    responses,
    questionsUpdated,
    showGroupCompletion,
    providerAddedOrModified,
  } = questionnaire;

  let filteredGroupResponses = {};
  Object.keys(responses)
    .filter((el) => el.startsWith(groupId))
    .forEach((el) => {
      filteredGroupResponses = {
        ...filteredGroupResponses,
        [el]: responses[el],
      };
    });

  const loadLevel0Questions = (grp) => {
    return grp.sections[0].questions.map((el) => {
      let { value } = el;
      if (responses[el.id] !== undefined) value = responses[el.id];
      if (el.input.dataType === "Checkbox" && value === "No") value = false;
      return {
        ...el,
        id: el.id,
        caption: el.caption,
        value,
        input: {
          ...el.input,
          dataType: el.input.dataType,
          required: el.input.required,
        },
      };
    });
  };

  useEffect(() => {
    if (groupId === "") return;
    // OnComponentLoad > 1st we get the top level questions to capture the corresponding responses
    const tmpQuestions = group.sections ? loadLevel0Questions(group) : [];
    dispatch(setQuestionsActionMethod(tmpQuestions)).then(() => {
      setIsReady(true);
    });
    if (tmpQuestions && tmpQuestions.length === 0) {
      dispatch(
        setGroupNavState({
          ...navGroupBtn,
          continueEnabled: true,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId]);

  const errorNavigation = (errArg) => {
    const {
      code,
      message,
      response: { status, statusText },
    } = errArg;
    navigate(ErrorRoute, {
      state: {
        code,
        message,
        status,
        statusText,
      },
    });
  };

  const saveCurrentGroupResponses = async (data) => {
    // Transform the form data to Group responses
    const groupResponse = getGroupResponseFromFormVals(
      questionnaireMetadata,
      groupId,
      data
    );
    await dispatch(saveGroupResponses(groupResponse, data, errorNavigation));
  };

  const getPageGroups = (data) => {
    // To fetch the pages which needs to be filled
    return group.sections[0].questions.filter((el) => {
      const {
        input: { dataType },
      } = el;
      const drilldowns = el?.drilldowns?.filter((childEl) => {
        if (!childEl.drilldownWhen) return false;

        return (
          (dataType === "YesNo" &&
            childEl.drilldownWhen.includes(data[`${el.id}`])) ||
          // (dataType === "Provider" &&
          //   childEl.drilldownWhen.includes("*") &&
          //   (data[`${el.id}`] !== undefined ||
          //     data[`${el.id}`] !== null ||
          //     data[`${el.id}`] !== "addNewProvider" ||
          //     data[`${el.id}`] !== "manageProvider")) ||
          (dataType === "Checkbox" &&
            childEl.drilldownWhen.includes(
              data[`${el.id}`] === false ? "No" : data[`${el.id}`]
            ))
        );
      });
      return drilldowns?.length > 0;
    });
  };

  const setNavigationStatus = (updActivePage, pageGroups) => {
    if (["PS-RS-1"].includes(group.id) && updActivePage === -1) {
      dispatch(
        setPageNavState({
          ...navBtn,
          backEnabled: true,
          continueEnabled: true,
        })
      );
      dispatch(
        setGroupNavState({
          ...navGroupBtn,
          backEnabled: true,
          continueEnabled: false,
        })
      );
      dispatch(setActivePage(updActivePage));
      return;
    }

    // Default condition is back btn disabled and continue btn enabled
    if (updActivePage === -1 && pageGroups.length > 0) {
      dispatch(
        setPageNavState({
          ...navBtn,
          backEnabled: false,
          continueEnabled: true,
        })
      );
      dispatch(
        setGroupNavState({
          ...navGroupBtn,
          backEnabled: true,
          continueEnabled: false,
        })
      );
    } else if (
      updActivePage > -1 &&
      updActivePage < pageGroups.length - 1 &&
      pageGroups.length > 0
    ) {
      // 1st drill down page in the group - wherein activepage
      dispatch(
        setPageNavState({ ...navBtn, backEnabled: true, continueEnabled: true })
      );
      dispatch(
        setGroupNavState({
          ...navGroupBtn,
          backEnabled: true,
          continueEnabled: false,
        })
      );
    } else if (
      updActivePage === pageGroups.length - 1 &&
      pageGroups.length > 0
    ) {
      // Move to next section/group as all drill downs pages have been navigated
      dispatch(
        setPageNavState({
          ...navBtn,
          backEnabled: true,
          continueEnabled: false,
        })
      );
      dispatch(
        setGroupNavState({
          ...navGroupBtn,
          backEnabled: false,
          continueEnabled: true,
        })
      );
    }

    dispatch(setActivePage(updActivePage));
  };

  const setNextPageQuestions = (
    updActivePage,
    nextQuestionsEl,
    updatedQuestionnaire,
    formResponses
  ) => {
    let nextPageQuestions;
    if (nextQuestionsEl && nextQuestionsEl.drilldowns) {
      // const parentId = nextQuestionsEl.id;
      const drilldownObj = nextQuestionsEl.drilldowns;
      // .filter((subEl) => {
      //   return subEl.drilldownWhen.includes(formResponses[`${parentId}`]);
      // });

      nextPageQuestions = drilldownObj[0].questions.map((el) => {
        // Logic to handle transform the triggeringValue = "Yes"
        // to true for parent control if it is checkbox.
        let value = formResponses[el.id];
        const {
          input: { dataType },
          showLogic,
        } = el;

        // transforming value to true or false only for checkboxes
        // to restore the state onload of the page.
        if (
          value &&
          dataType === FBControlTypes.Checkbox &&
          typeof value === "string"
        ) {
          value = value.toLowerCase() === "yes";
        }

        // if showlogic exists on the question, then we check
        // if the questions present in condition is checkbox,
        // then we transform the triggeringValue to true
        if (showLogic) {
          const updatedShowLogic = {
            ...showLogic,
            condition: showLogic.condition.map((cnd) => {
              const parentQuest = drilldownObj[0].questions.find(
                (parentQuestItr) => parentQuestItr.id === cnd.id
              );

              const {
                input: { dataType: parentDataType },
              } = parentQuest;

              if (parentDataType === FBControlTypes.Checkbox) {
                return {
                  ...cnd,
                  triggeringValue:
                    cnd.triggeringValue === "Yes" ? true : cnd.triggeringValue,
                };
              }
              return cnd;
            }),
          };
          return {
            ...el,
            id: `${el.id}`,
            value,
            showLogic: showLogic ? updatedShowLogic : undefined,
          };
        }

        return {
          ...el,
          id: `${el.id}`,
          value,
        };
      });
    } else if (!nextQuestionsEl && updActivePage === -1) {
      nextPageQuestions = loadLevel0Questions(updatedQuestionnaire);

      dispatch(
        setPageNavState({
          ...navBtn,
          backEnabled: !!["PS-RS-1"].includes(group.id),
          continueEnabled: true,
        })
      );
    }
    dispatch(setQuestionsActionMethod(nextPageQuestions)).then(() => {
      setIsReady(true);
    });
  };

  const transformResponsesAndSetInRedux = (data) => {
    let updData = {};
    if (groupId && groupId !== "") {
      Object.keys(data).forEach((el) => {
        let val = data[el];
        if (typeof val === "object") {
          try {
            val = data[el].toISOString();
            // eslint-disable-next-line no-empty
          } catch {}
        }
        updData = {
          ...updData,
          [el]: val,
        };
      });
      dispatch(setResponses({ ...responses, ...updData }));
    }
    return { ...responses, ...updData };
  };

  const gotoNextGroup = () => {
    if (["PC-RS-1", "PS-RS-1", "PT-RS-1"].includes(navGroupBtn.nextGroupId)) {
      let route =
        mode === "examone"
          ? "/PortraitReview/FinalQuestions"
          : `/PortraitReview`;
      if (mode === "examone" && navGroupBtn.nextGroupId === "PS-RS-1") {
        route = `${medFrmRouteSeg}${navGroupBtn.nextGroupId}`;
      }
      navigate(route);
    } else navigate(`${medFrmRouteSeg}${navGroupBtn.nextGroupId}`);
  };

  const transformResponsesToHandleCheckBox = (iResponses, iQuestions) => {
    let result = {};
    Object.keys(iResponses).forEach((el) => {
      const question = iQuestions.find((quest) => quest.id === el);
      const {
        input: { dataType },
      } = question || { input: { dataType: undefined } };
      let value = iResponses[el];
      if (dataType === FBControlTypes.Checkbox) {
        if (iResponses[el] === "true" || iResponses[el] === true) value = "Yes";
        if (iResponses[el] === "false" || iResponses[el] === false) {
          value = "No";
        }
      }
      result = { ...result, [el]: value };
    });
    return result;
  };

  // Continue and Next section click handler(s)
  const onSubmit = async (data, e, isDirty) => {
    const transformedChkbxResponses = transformResponsesToHandleCheckBox(
      data,
      questions
    );

    // Save raw responses
    const updData = transformResponsesAndSetInRedux(transformedChkbxResponses);

    if ((isDirty || providerAddedOrModified) && questions.length > 0) {
      await saveCurrentGroupResponses(updData);
    }

    // Review and Submit routing for Adult
    if (e.target.id === "nextGroupButton" && ["PS-RS-1"].includes(group.id)) {
      navigate("/MedicationHistory");
      return;
    }

    if (e.target.id === "nextGroupButton") {
      dispatch(setShowGroupCompletion(true));
      setIsReady(true);
      return;
    }

    const updatedQuestionnaire = { ...group };

    // To fetch the drilldown pages
    const pageGroups = getPageGroups(data);

    let nextQuestionsEl;
    let updActivePage = activePage;

    if (pageGroups.length > 0) {
      updActivePage =
        pageGroups &&
        pageGroups.length > 0 &&
        activePage < pageGroups.length - 1
          ? activePage + 1
          : activePage;
    }
    if (updActivePage >= 0 && updActivePage < pageGroups.length) {
      nextQuestionsEl = pageGroups[updActivePage];
    }

    if (updActivePage === -1 && pageGroups.length === 0) {
      // Move to next section/group, as no drill down pages
      saveCurrentGroupResponses(updData);

      // Review and Submit routing for Adult
      if (["PS-RS-1"].includes(group.id)) {
        navigate("/MedicationHistory");
        return;
      }

      dispatch(setShowGroupCompletion(true));
      setIsReady(true);
      return;
    }

    setNavigationStatus(updActivePage, pageGroups);

    setNextPageQuestions(
      updActivePage,
      nextQuestionsEl,
      updatedQuestionnaire,
      updData
    );
  };

  const continueBtnClickHandler = async (e, getValues, isDirty) => {
    setIsReady(false);
    window.scrollTo(0, 0);
    const formValues = getValues();
    onSubmit(formValues, e, isDirty);
  };

  const backBtnClickHandler = async (e, getValues, isDirty) => {
    setIsReady(false);
    window.scrollTo(0, 0);
    const formValues = getValues();
    const transformedChkbxResponses = transformResponsesToHandleCheckBox(
      formValues,
      questions
    );

    // Save raw responses
    const updData = transformResponsesAndSetInRedux(transformedChkbxResponses);

    if ((isDirty || providerAddedOrModified) && questions.length > 0) {
      await saveCurrentGroupResponses(updData);
    }

    const pageGroups = getPageGroups(responses);
    const updatedQuestionnaire = { ...group };
    let nextQuestionsEl;
    let updActivePage = activePage;

    if (pageGroups.length > 0) {
      updActivePage =
        pageGroups && pageGroups.length > -1 && activePage < pageGroups.length
          ? activePage - 1
          : activePage;
    }
    if (updActivePage >= 0 && updActivePage < pageGroups.length) {
      nextQuestionsEl = pageGroups[updActivePage];
    }

    setNavigationStatus(updActivePage, pageGroups);

    setNextPageQuestions(
      updActivePage,
      nextQuestionsEl,
      updatedQuestionnaire,
      responses
    );

    transformResponsesAndSetInRedux(formValues);

    if (["PS-RS-1"].includes(group.id) && activePage === -1) {
      navigate(`/PortraitReview`);
    }
  };

  const setValueInReduxResponses = (id, value, getValues) => {
    const formData = getValues();
    transformResponsesAndSetInRedux({ ...formData, [id]: value });
    dispatch(setProviderAddedOrModified(true));
  };

  if (!isReady) {
    return <FBSpinner />;
  }
  // eslint-disable-next-line react/prop-types
  const { title, caption: groupCaption } = group;

  const { caption: pageCaption } =
    activePage > -1 &&
    group &&
    group.sections[0].questions &&
    group.sections[0].questions.filter((el) => responses[el.id] === "Yes")[
      activePage
    ]
      ? group.sections[0].questions.filter((el) => responses[el.id] === "Yes")[
          activePage
        ]
      : { caption: undefined };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {showGroupCompletion ? (
        <QuestionnaireGroupCompleted
          currentGroup={navGroupBtn.currentGroupTitle}
          nextGroup={navGroupBtn.nextGroupTitle}
          gotoNextGroup={gotoNextGroup}
          disableRouteToReviewNSubmit={disableRouteToReviewNSubmit(
            navGroupBtn,
            questionnaireResponse,
            questionnaireLP
          )}
        />
      ) : (
        <QuestionnairePageContainer
          title={title}
          pageCaption={activePage === -1 ? groupCaption : pageCaption}
          questionsUpdated={questionsUpdated}
          groupId={groupId}
          activePage={activePage}
          questions={questions}
          navBtn={navBtn}
          navGroupBtn={navGroupBtn}
          navigate={navigate}
          backBtnClickHandler={backBtnClickHandler}
          continueBtnClickHandler={continueBtnClickHandler}
          responses={filteredGroupResponses}
          setValueInReduxResponses={setValueInReduxResponses}
          isReady={isReady}
          setIsReady={setIsReady}
        />
      )}
    </>
  );
}
