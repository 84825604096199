/* eslint-disable indent */
import {
  hydrateQuestionnaireResponses,
  hydrateQuestionnaire,
} from "features/LifePortrait/state/lifePortraitActions";
import { saveGroupResponseSvc } from "services/Questionnaire.service";
import { setProviderAddedOrModified, setQuestions } from "./questionnaireSlice";
// import { saveReduxStateInLocalStorage } from "util/localStorageUtil";

export const saveGroupResponses =
  (groupData, uiFromResponses, navigationCallback) =>
  async (dispatch, getState) => {
    try {
      const state = getState();
      const { applicationId, documentId } = state.lifePortrait;
      const requestPayload = { applicationId, documentId, group: groupData };
      const response = await saveGroupResponseSvc(
        requestPayload,
        navigationCallback
      );
      if (response?.success) {
        // update state with the changed group data
        dispatch(hydrateQuestionnaireResponses(groupData));
        // for restoring the UI state, hydrate the questionnaire with responses
        dispatch(hydrateQuestionnaire(uiFromResponses));
        // .then(() => {
        //   saveReduxStateInLocalStorage(getState());
        // });
        dispatch(setProviderAddedOrModified(false));
      }
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const setQuestionsActionMethod = (questions) => async (dispatch) => {
  try {
    dispatch(setQuestions(questions));
    return Promise.resolve(true);
  } catch (error) {
    return Promise.reject(error);
  }
};
