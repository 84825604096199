/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-array-index-key */
import React from "react";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Stack, Typography } from "@mui/material";

const AdditionalMedicationPres = ({ medication,
  showEditForm,
  showMedEditForm,
  deleteMedication }) => {
  return (!medication.temp
    ? (
      <Stack direction="row">
        <Typography sx={{ marginY: 'auto' }}>{medication.medication}</Typography>
        <IconButton
          size="small"
          onClick={showEditForm}
          disabled={showMedEditForm}
        >
          <EditIcon fontSize="small" sx={{ marginLeft: 1 }} />
        </IconButton>
        <IconButton onClick={() => { deleteMedication(medication); }}>
          <DeleteIcon fontSize="small" sx={{ marginLeft: 1 }} />
        </IconButton>
      </Stack>
    )
    : null
  );
};

AdditionalMedicationPres.propTypes = {
  medication: PropTypes.any,
  showEditForm: PropTypes.func,
  deleteMedication: PropTypes.func,
  showMedEditForm: PropTypes.string,
};

export default AdditionalMedicationPres;
