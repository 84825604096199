/* eslint-disable indent */
import {
  getDashboardPortraitsSvc,
  getDashboardSnapshotSvc,
  getPortraitGroupSummarySvc,
  updatePortraitArchiveSvc,
} from "services/AgentDashboard.service";
import { getPortraitDataSummarySvc } from "services/LifePortrait.service";
import { setSAMAccountName } from "features/LifePortrait/state/lifePortraitSlice";
import {
  setArchiveDialogState,
  setPortraitApplications,
  setPortraitDetailGroupSummary,
  setPortraitDetails,
  setSnapshotCounts,
} from "./agentDashboardSlice";

const addDays = (date, days) => {
  const copy = new Date(Number(date));
  copy.setDate(date.getDate() + days);
  return copy;
};

export const getDashboardPortraits =
  (payload, navigationCallback) => async (dispatch, getState) => {
    try {
      const state = getState();
      const { SAMaccountName, mode } = state.lifePortrait;
      const response = await getDashboardPortraitsSvc(
        { ...payload, mode, agentUserId: SAMaccountName },
        navigationCallback
      );
      if (response) {
        const data = response.results.map((el) => {
          let lastVisitedStr = el.sentDate;
          let dueDateFlag = false;
          if (el.sentDate) {
            const dueDate = addDays(new Date(el.sentDate), 14);
            const todaysDate = new Date();
            if (dueDate < todaysDate) {
              lastVisitedStr = dueDate.toISOString();
              dueDateFlag = true;
            }
          }
          return {
            ...el,
            applicantName: `${el.clientFirstName || ""} ${
              el.clientLastName || ""
            }`,
            dueDate: lastVisitedStr,
            dueDateFlag,
          };
        });
        dispatch(setPortraitApplications({ data }));
      }
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getDashboardSnapshotCounts =
  (navigationCallback) => async (dispatch, getState) => {
    try {
      const state = getState();
      const { SAMaccountName, mode } = state.lifePortrait;

      const response = await getDashboardSnapshotSvc(
        { mode, agentUserId: SAMaccountName },
        navigationCallback
      );

      if (response) {
        dispatch(
          setSnapshotCounts({
            ...response,
          })
        );
      }
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getPortraitDetailDataSummary =
  (requestData, navigationCallback) => async (dispatch) => {
    try {
      const response = await getPortraitDataSummarySvc(
        requestData,
        navigationCallback
      );
      if (response) {
        dispatch(
          setPortraitDetails({
            ...response,
            // firstName: response.clientFirstName,
            // lastName: response.clientLastName,
            // birthDate: response.clientBirthDate,
            // status: response.status,
          })
        );
      }
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getPortraitGroupSummary =
  (requestData, navigationCallback) => async (dispatch) => {
    try {
      const response = await getPortraitGroupSummarySvc(
        { ...requestData },
        navigationCallback
      );
      if (response) {
        dispatch(
          setPortraitDetailGroupSummary({
            ...response,
          })
        );
      }
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const setArchiveDialog = (row) => async (dispatch) => {
  dispatch(setArchiveDialogState(row));
};

export const updatePortraitArchive =
  (request, navigationCallback) => async (dispatch, getState) => {
    try {
      const { applicationId, documentId, archive } = request;
      const response = await updatePortraitArchiveSvc(
        {
          applicationId,
          documentId,
          archive,
        },
        navigationCallback
      );
      if (response) {
        const state = getState();
        const { portraitPayload } = state.agentDashboard;
        dispatch(getDashboardPortraits(portraitPayload, navigationCallback));
      }
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const setSAMAccountNameAct = (obj) => async (dispatch) => {
  dispatch(setSAMAccountName(obj));
};
