import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ContentParser from "components/ContentParser";
import { useForm } from "react-hook-form";

import Result from "./poc.json";

export default function TestParser() {
  // const [pages, setPages] = useState(null);
  const [activePage, setActivePage] = useState(-1);
  const [navBtn, setNavBtn] = useState({
    backEnabled: false,
    continueEnabled: true,
  });
  // set of questions per page which will be sent to Contentparser
  const [questions, setQuestions] = useState(null);
  // will hold the responses for all the questions and drilldown questions
  // eslint-disable-next-line no-unused-vars
  const [answers, setAnswers] = useState({});
  // Orignal questions structure, eventually populate with answers structure.
  // eslint-disable-next-line no-unused-vars
  const [questionnaire, setQuestionnaire] = useState(Result);

  const { handleSubmit, control, formState } = useForm({ mode: "onChange" });
  const { isValid } = formState;
  const { title, caption: pageCaption } = Result;

  const loadLevel0Questions = (group) => {
    const pageId = Result.id;
    return group.questions.map((el) => {
      return {
        id: `${pageId}_${el.id}`,
        caption: el.caption,
        value: el.value,
        input: {
          dataType: el.input.dataType,
          required: el.input.required,
          drillDownWhen: el.input.drillDownWhen,
        },
      };
    });
  };

  useEffect(() => {
    // When component loads then 1st we get the top level questions
    // to capture the corresponding responses
    setQuestions(loadLevel0Questions(Result));
  }, []);

  const onSubmit = (data, e) => {
    const pageId = questionnaire.id;
    // This logic can be pushed to when we intend to save the response to BE
    const tmpQuestions = questionnaire.questions.map((el) => ({
      ...el,
      value: data[`${pageId}_${el.id}`],
      drilldowns: el.drilldowns.map((childEl) => {
        return { ...childEl, value: data[`${pageId}_${el.id}_${childEl.id}`] };
      }),
    }));

    // Save raw responses
    setAnswers({ ...data });

    const updatedQuestionnaire = { ...questionnaire, questions: tmpQuestions };

    // To fetch the pages which needs to be filled
    const pageGroups = updatedQuestionnaire.questions.filter((el) => {
      const drilldowns = el.drilldowns.filter((subEl) => {
        return subEl.drillDownWhen.includes(data[`${pageId}_${el.id}`]);
      });

      return drilldowns.length > 0;
      // el.input.drillDownWhen &&
      // el.input.drillDownWhen.includes(data[`${pageId}_${el.id}`])
    });
    // setPages(pageGroups);
    let nextQuestionsEl;
    let updActivePage = activePage;

    if (pageGroups.length > 0) {
      if (e === "continue") {
        updActivePage =
          pageGroups &&
          pageGroups.length > 0 &&
          activePage < pageGroups.length - 1
            ? activePage + 1
            : activePage;
      } else {
        // next0QuestionsEl = pageGroups[updActivePage];
        updActivePage =
          pageGroups && pageGroups.length > -1 && activePage < pageGroups.length
            ? activePage - 1
            : activePage;
      }
    }
    if (updActivePage >= 0 && updActivePage < pageGroups.length) {
      nextQuestionsEl = pageGroups[updActivePage];
    }

    // Default condition is back btn disabled and continue btn enabled
    if (updActivePage === -1 && pageGroups.length === 0) {
      setNavBtn({ ...navBtn, backEnabled: false });
      // Move to next section/group can be enabled here, as no drill down questions
    } else if (updActivePage === -1 && pageGroups.length > 0) {
      setNavBtn({ ...navBtn, backEnabled: false, continueEnabled: true });
    } else if (
      updActivePage > -1 &&
      updActivePage < pageGroups.length - 1 &&
      pageGroups.length > 0
    ) {
      // 1st drill down page in the group - wherein activepage
      setNavBtn({ ...navBtn, backEnabled: true, continueEnabled: true });
    } else if (
      updActivePage === pageGroups.length - 1 &&
      pageGroups.length > 0
    ) {
      setNavBtn({ ...navBtn, backEnabled: true, continueEnabled: false });
      // Move to next section/group as all drill downs pages have been navigated
    }

    setActivePage(updActivePage);
    // setCurrentPage(nextQuestionsEl);

    if (nextQuestionsEl && nextQuestionsEl.drilldowns) {
      const parentId = nextQuestionsEl.id;
      const drilldownObj = nextQuestionsEl.drilldowns.filter((subEl) => {
        return subEl.drillDownWhen.includes(data[`${pageId}_${parentId}`]);
      });

      const nextPageQuestions = drilldownObj[0].questions.map((el) => {
        return { ...el, id: `${pageId}_${parentId}_${el.id}` };
      });
      setQuestions(nextPageQuestions);
    } else if (!nextQuestionsEl && updActivePage === -1) {
      setQuestions(loadLevel0Questions(updatedQuestionnaire));
      setNavBtn({ ...navBtn, backEnabled: false, continueEnabled: true });
    }
    // 2
    // Also need to check if all the required questions have been answered
    // before navigating to the next drilldown set of questions in-line

    // setQuestionnaire(updatedQuestionnaire);
    console.log(
      "Updated questionnaire with responses >>>",
      updatedQuestionnaire
    );
  };

  return (
    <div className="bgSecondaryFBgrey">
      <div className="container-xl min-vh-100 py-3">
        <form>
          <div className="shadow-sm p-3 mb-3 bg-white rounded">
            <div>
              <h3>{title}</h3>
              {/* <h5>{pageCaption || undefined}</h5> */}
              <h5>{pageCaption}</h5>
              <ContentParser
                modelElements={questions || []}
                control={control}
                // onChange={onChange}
              />
            </div>
            <div className="row justify-content-center">
              <Button
                id="backBtn"
                disabled={!navBtn.backEnabled}
                variant="outlined"
                onClick={() => {
                  handleSubmit(onSubmit)("back");
                }}
                className="FBMedFormFooterButton"
              >
                <ArrowBackIcon style={{ color: "#143247", fontSize: "18pt" }} />
                &nbsp;Back
              </Button>
              <Button
                id="continueButton"
                disabled={!(isValid && navBtn.continueEnabled)}
                variant="contained"
                type="submit"
                className="FBMedFormFooterButton FBMedFormFooterButtonRight"
                onClick={() => {
                  handleSubmit(onSubmit)("continue");
                }}
              >
                Continue
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
