/* eslint-disable react/prop-types */
import React from "react";

import Button from "@mui/material/Button";
import FBCheckbox from "components/Elements/FBCheckbox";
import FBRadioGroup from "components/Elements/FBRadioGroup";
import FBTextBox from "components/Elements/FBTextBox";
import FBNumber from "components/Elements/FBNumber";
import FBComboBox from "components/Elements/FBComboBox";
import FBMedicalHistoryCondition from "components/Elements/FBMedicalHistoryCondition";

const SiblingFormPresentation = (props) => {
  const {
    familyMember,
    // handleSubmit,
    // onSubmit,
    control,
    siblingTypes,
    aliveDeceasedTypes,
    showDetails,
    siblingAlive,
    LivingEnum,
    cboConditionsList,
    medicalConditions,
    formSiblingValues,
    disableSiblingModifyBtn,
    addSiblingHandler,
    siblingRecord,
    FormActions,
    cancelBtnHandler,
    medicalHistoryConditionChangeHandler,
    setValue,
  } = props;
  const showCauseOfDeathFreeFormTxtbx =
    formSiblingValues[`${familyMember}_primaryCauseOfDeath`] === "Other" ||
    formSiblingValues[`${familyMember}_primaryCauseOfDeath`] === "Cancer";
  return (
    <form>
      <div>
        <div className="shadow-sm p-3 mb-3 bg-white rounded">
          <h6>Which sibling is being added?</h6>
          <FBRadioGroup
            control={control}
            id={`${familyMember}_type`}
            label=""
            controlWidth="350px"
            items={siblingTypes}
            value={formSiblingValues[`${familyMember}_type`]}
          />
          <div className="py-2">
            <FBTextBox
              control={control}
              id={`${familyMember}_firstName`}
              label="First Name (optional)"
              variant="standard"
              value={formSiblingValues[`${familyMember}_firstName`]}
            />
          </div>
          <div className="py-2">
            <h6>Is this sibling alive or deceased?</h6>
            <FBRadioGroup
              control={control}
              id={`${familyMember}_livingStatus`}
              controlWidth="350px"
              items={aliveDeceasedTypes}
              value={formSiblingValues[`${familyMember}_livingStatus`]}
              onChange={() => {
                setValue(`${familyMember}_doNotHaveInfo`, false);
                setValue(`${familyMember}_age`, "");
              }}
            />
            <FBCheckbox
              control={control}
              id={`${familyMember}_doNotHaveInfo`}
              label="Do not have this information"
              controlWidth="260px"
              value={formSiblingValues[`${familyMember}_doNotHaveInfo`]}
              onChange={(e) => {
                if (e) {
                  setValue(`${familyMember}_livingStatus`, "");
                  setValue(`${familyMember}_age`, "");
                }
              }}
            />
          </div>
        </div>
        {showDetails && (
          <div>
            <div id="SiblingQuestions">
              <div className="shadow-sm p-3 mb-3 bg-white rounded">
                <h6 className="">
                  {siblingAlive === LivingEnum.Deceased
                    ? "What was the age at death?"
                    : ""}
                  {siblingAlive === LivingEnum.Alive ? "How old are they?" : ""}
                </h6>
                <div className="">
                  <FBNumber
                    control={control}
                    id={`${familyMember}_age`}
                    label="Age"
                    variant="standard"
                    disabled={
                      formSiblingValues[`${familyMember}_doNotHaveAgeInfo`]
                    }
                    value={formSiblingValues[`${familyMember}_age`]}
                  />
                </div>
                <div className="py-3">
                  <FBCheckbox
                    control={control}
                    id={`${familyMember}_doNotHaveAgeInfo`}
                    label="Do not have this information"
                    controlWidth="260px"
                    onChange={(e) => {
                      if (e) {
                        setValue(`${familyMember}_age`, "");
                      }
                    }}
                    value={
                      formSiblingValues[`${familyMember}_doNotHaveAgeInfo`]
                    }
                  />
                </div>
                {siblingAlive === LivingEnum.Deceased && (
                  <>
                    <h6 className="">What was the primary cause of death?</h6>
                    <div className="">
                      <FBComboBox
                        id={`${familyMember}_primaryCauseOfDeath`}
                        items={cboConditionsList}
                        control={control}
                        // label="Primary cause of death"
                        controlWidth="260px"
                        value={
                          formSiblingValues[
                            `${familyMember}_primaryCauseOfDeath`
                          ]
                        }
                        onChange={(e) => {
                          const condtn = cboConditionsList.find(
                            (el) => el.value === e
                          );
                          if (condtn) {
                            setValue(
                              `${familyMember}_medHist_${condtn.cascadeMedHist}`,
                              true
                            );
                          }
                        }}
                      />
                    </div>
                    {showCauseOfDeathFreeFormTxtbx && (
                      <div className="d-flex py-3">
                        <FBTextBox
                          control={control}
                          id={`${familyMember}_primaryCauseOfDeathDetail`}
                          label={
                            formSiblingValues[
                              `${familyMember}_primaryCauseOfDeath`
                            ] === "Other"
                              ? "Desc"
                              : "Type of cancer"
                          }
                          variant="standard"
                          value={
                            formSiblingValues[
                              `${familyMember}_primaryCauseOfDeathDetail`
                            ]
                          }
                        />
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className="shadow-sm p-3 mb-3 bg-white rounded">
                <h6 className="row">
                  {`Are any of the conditions below a part of this
              sibling's medical history?`}
                </h6>
                <div className="">
                  {medicalConditions.map((item) => {
                    const medId = `${familyMember}_medHist_${item.id}`;
                    return (
                      <FBMedicalHistoryCondition
                        key={medId}
                        id={medId}
                        control={control}
                        item={item}
                        medicalHistoryCondition={formSiblingValues}
                        controlWidth="250px"
                        setValue={setValue}
                        onChange={(e) => {
                          medicalHistoryConditionChangeHandler(e, medId);
                        }}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        )}
        <div>
          <Button
            variant="contained"
            disabled={disableSiblingModifyBtn}
            onClick={addSiblingHandler}
          >
            {`${
              siblingRecord.action === FormActions.Add ? "Add" : "Modify"
            } This Sibling`}
          </Button>{" "}
          <Button variant="outlined" onClick={cancelBtnHandler}>
            Cancel
          </Button>
        </div>
      </div>
    </form>
  );
};

export default SiblingFormPresentation;
