/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
/* eslint-disable prefer-const */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-plusplus */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable default-param-last */
/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
// import PropTypes from "prop-types";
import cloneDeep from "lodash.clonedeep";
import findIndex from "lodash.findindex";
import FBSpinner from "components/Elements/FBSpinner";
import {
  createElementEntry,
  getElementComponent,
  transformElementValue,
} from "./ContentElements";
import { computeLogic } from "./ContentLogic";

const ContentParser = (props) => {
  const { forceRender } = props;
  const [parserReady, setParserReady] = useState(false);
  const [compParserState, setCompParserState] = useState(null);
  // console.log("ContentParser >>> ", props, compParserState, parserReady);

  let parserState = {
    initCallbacks: [],
    elements: [],
    elementsIndex: {},
    logicIndex: {},
    formValues: {
      dataRequests: {},
      tables: {},
      rawElements: {},
      rawElementsIndex: {},
    },
    lookUps: {},
    rootNumberOfColumns: props.numberOfColumns,
  };

  const setFormValue = (id, value, parentId) => {
    if (!parentId) {
      // parserState.formValues.dataRequests[id] = value;
      // parserState.formValues.rawElements = parserState.elements;
      // parserState.formValues.rawElementsIndex = parserState.elementsIndex;

      parserState = {
        ...parserState,
        formValues: {
          dataRequests: { ...parserState.formValues.dataRequests, [id]: value },
          rawElements: parserState.elements,
          rawElementsIndex: parserState.elementsIndex,
        },
      };
      // return;
    }
  };

  const setFormValues = () => {
    const { elements, elementsIndex } = parserState;

    elements.forEach((el) => {
      const {
        id,
        logicId,
        isRootLevel,
        ignoreFormValue,
        origRowIndex,
        colIndex,
        isTable,
        parentId,
        props: localProps,
      } = el;

      if (parentId) {
        const value = transformElementValue(
          el,
          localProps.value,
          { elements, elementsIndex },
          false
        );
        setFormValue(logicId, value, parentId, origRowIndex, colIndex);
      } else if (isRootLevel && !isTable && !ignoreFormValue) {
        setFormValue(id, localProps.value);
      }
    });
  };

  const evaluateComputeLogic = (elements, elementsIndex, logicIndex) => {
    const newElements = [...elements];

    elements.forEach((el) => {
      if (el.showLogic) {
        let positions = logicIndex[el.logicId]; // logicIndex[el.id];
        // let logicIndexPosition;

        const isComponentVisible = computeLogic(
          el.showLogic,
          newElements,
          logicIndex
          // logicIndexPosition,
          // logicIndexPosition && el.parentId
        );

        positions.forEach((position) => {
          newElements[position] = {
            ...elements[position],
            props: { ...elements[position].props, isComponentVisible },
          };
        });
      }
    });

    return newElements;
  };

  const applyShowLogicUpdates = (
    elements,
    newElements,
    forceUpdate = false,
    id
  ) => {
    const hasErrorCP = props.hasError;
    elements.forEach((previousEl, index) => {
      const currentEl = newElements[index];

      if (previousEl.id !== currentEl.id) {
        throw Error(
          "ContentParser: applyElementUpdates has a state mismatch. Dynamic entries could be the issue"
        );
      }

      if (
        forceUpdate ||
        previousEl.props.isComponentVisible !==
          currentEl.props.isComponentVisible
      ) {
        /* THE BELOW IF STATEMENT IS THE BIGGEST I'VE EVER SEEN
         * THERE MUST BE A BETTER WAY TO DO THIS
         */
        // required field highlighting
        if (currentEl.props.id === id || !id) {
          if (hasErrorCP) {
            let hasError;
            if (
              (currentEl.props.value === "" ||
                (typeof currentEl.props.value === "string" &&
                  currentEl.props.value.toLowerCase() === "false" &&
                  previousEl.props.value.toLowerCase() === "true") ||
                (currentEl.props.value &&
                  typeof currentEl.props.value === "string" &&
                  currentEl.props.value.search("Select") > -1 &&
                  previousEl.props.value.search("Select") === -1) ||
                currentEl.props.value === null ||
                typeof currentEl.props.value === "undefined") &&
              previousEl.props.value &&
              (previousEl.props.value.length > 0 ||
                typeof previousEl.props.value === "object")
            ) {
              hasError = false;
            } else {
              hasError =
                (currentEl.props.value === "" ||
                  (typeof currentEl.props.value === "string" &&
                    currentEl.props.value.toLowerCase() === "false") ||
                  (currentEl.props.value &&
                    typeof currentEl.props.value === "string" &&
                    currentEl.props.value.search("Select") !== -1) ||
                  currentEl.props.value === null ||
                  typeof currentEl.props.value === "undefined") &&
                currentEl.props.required === true
                  ? true
                  : false;
            }
            currentEl.updateComponent({ ...currentEl.props, hasError });
          } else {
            currentEl.updateComponent({ ...currentEl.props });
          }
        } else {
          currentEl.updateComponent({ ...currentEl.props });
        }
      }
    });
  };

  const checkDownDropComplete = (el) => {
    const { props: localProps } = el;
    const lookUpIndex = findIndex(
      localProps.listOptions,
      (item) => item.displayText === "(Select)"
    );

    if (lookUpIndex !== -1) {
      return localProps.listOptions[lookUpIndex].lookUpBindValue === props.value
        ? false
        : true;
    }

    return true;
  };

  const checkFormComplete = () => {
    const { elements, elementsIndex, formValues } = parserState;
    let isFormComplete = true;

    const { dataRequests } = formValues;
    const elKeys = Object.keys(dataRequests);

    /* check all regular page elements that are required are filled out */
    for (let index = 0; index < elKeys.length; index++) {
      const elId = elKeys[index];
      const formVal = dataRequests[elId];
      const elIndex = elementsIndex[elId];
      const el = elements[elIndex];

      const { required, isComponentVisible, controlTypeName, reviewerOnly } =
        el.props;

      // if submit button is disabled then we can enable submit button
      // provided all mandetory fields are provided.(As existing functionality)

      if (required && isComponentVisible && !reviewerOnly) {
        if (controlTypeName === "DropDown") {
          if (checkDownDropComplete(el)) {
            isFormComplete = checkDownDropComplete(el);
          } else {
            isFormComplete = false;
            break;
          }
        }
        // modified to incorporate checkbox value which is "false" if not selected
        if (
          formVal === "" ||
          formVal === null ||
          (typeof formVal === "string" && formVal.toLowerCase() === "false")
        ) {
          isFormComplete = false;
          break;
        }
      }
    }

    return isFormComplete;
  };

  const handleOnChange = (elementUpdated = null) => {
    if (props.onChange) {
      props.onChange({
        isFormComplete: checkFormComplete(),
        formValues: parserState.formValues,
        elementUpdated,
      });
    }
  };

  const conditionalWatch = (id, event) => {
    const { elements, elementsIndex, logicIndex } = parserState;
    const position = elementsIndex[id];

    let newElements = [...elements];
    const eventValue = event; // event.target.value
    newElements[position] = {
      ...newElements[position],
      props: { ...newElements[position].props, value: event },
    };
    newElements[position].updateComponent({ ...newElements[position].props });

    // update formValues
    const value = transformElementValue(newElements[position], eventValue, {
      elements,
      elementsIndex,
    });
    const {
      logicId: elId,
      parentId,
      origRowIndex,
      colIndex,
    } = newElements[position];

    const evaluatedElements = evaluateComputeLogic(
      newElements,
      elementsIndex,
      logicIndex
    );
    // eslint-disable-next-line no-unused-expressions
    props.hasError
      ? applyShowLogicUpdates(elements, evaluatedElements, true, id)
      : applyShowLogicUpdates(elements, evaluatedElements);
    parserState = { ...parserState, elements: evaluatedElements };

    if (parentId) {
      setFormValue(elId, value, parentId, origRowIndex, colIndex);
    } else {
      setFormValue(elId, value);
    }

    const elNode = newElements[position];

    if (
      (["CheckBox", "Radio", "DropDown"].indexOf(elNode.controlTypeName) >= 0 ||
        (elNode.controlTypeName === "Textbox" &&
          event.type === "blur" &&
          event.target.value !== "")) &&
      elNode.isCallPersist === true &&
      typeof props.actions.saveQuestionnaire === "function"
    ) {
      props.actions.forceDisableSubmitBtn(true);
      props.toggleReloadPage();
      props.actions.saveQuestionnaire(() => {
        props.actions.forceDisableSubmitBtn(false);
      });
    }
    handleOnChange(elId);
  };

  const setUpdateComponentFn = (id) => (fn) => {
    const { elements, elementsIndex } = parserState;

    const newElements = cloneDeep(elements);
    const position = elementsIndex[id];

    newElements[position].updateComponent = fn;
    parserState = { ...parserState, elements: newElements };
  };

  const setComponentRef = (id) => (ref) => {
    const { elements, elementsIndex } = parserState;

    const newElements = cloneDeep(elements);
    const position = elementsIndex[id];

    if (ref) {
      newElements[position].componentRef = ref;
    }

    parserState = { ...parserState, elements: newElements };
  };

  const createIndexes = (elements) => {
    const elementsIndex = {};
    const logicIndex = {};

    elements.forEach((item, index) => {
      elementsIndex[item.id] = index;

      if (!logicIndex[item.logicId]) logicIndex[item.logicId] = [index];
      else logicIndex[item.logicId].push(index);
    });

    return { elementsIndex, logicIndex };
  };

  const removeEmptyRowFromTableFormValues = (parentId, rowIndex) => {
    if (parentId) {
      let currParserState = parserState.formValues.tables[parentId];
      Object.keys(currParserState).forEach((key) => {
        if (parseInt(key, 10) === rowIndex) {
          // newParserState[key] = currParserState[key]
          currParserState[key].forEach((col) => {
            col.isDeleted = true;
          });
        }
      });
      // this.parserState.formValues.tables[parentId] = newParserState;
      // return this.parserState.formValues.tables[parentId]
    }
  };

  // provide access to parser functions + outside functions via dependency injection
  const elementActions = () => ({
    getParserState: () => parserState,
    // setExactInput: setExactInput,
    getOverrides: () => props.overrideControls || {},
    setParserState: (newElements) => {
      parserState = {
        ...parserState,
        elements: newElements,
        ...createIndexes(newElements),
      };
    },
    setFormValues: () => setFormValues(),
    callOnChange: handleOnChange,
    // forceParserRefresh: () => this.setState({ forceRender: true }),
    refreshShowLogic: () => {
      const { elements, elementsIndex, logicIndex } = parserState;
      const evaluatedElements = evaluateComputeLogic(
        elements,
        elementsIndex,
        logicIndex
      );
      applyShowLogicUpdates(elements, evaluatedElements);
      parserState = {
        ...parserState,
        elements: evaluatedElements,
        ...createIndexes(evaluatedElements),
      };
    },
    setComponentRef,
    setUpdateComponentFn,
    setConditionalFn: conditionalWatch,
    setFormValue,
    ...props.actions,
    removeEmptyRowFromTableFormValues: (parentId, rowIndex) =>
      removeEmptyRowFromTableFormValues(parentId, rowIndex),
  });

  const buildElement = (node, localProps, isRootLevel) => {
    return createElementEntry(
      node,
      props,
      isRootLevel,
      {
        getOverrides: () => props.overrideControls || {},
        // getLookupValue: this.getLookupValue,
        conditionalWatch,
        setUpdateComponent: setUpdateComponentFn,
        setComponentRef,
        setFormValue,
        elementActions,
      },
      {
        rootNumberOfColumns: parserState.rootNumberOfColumns,
        readOnlyControl: props.readOnly,
        profileCountryCode: parserState.profileCountryCode,
      }
    );
  };

  const extractSimpleElements = (data, currentElements, isRootLevel) => {
    let elements = [];
    const formValues = { ...parserState.formValues };

    data.forEach((node) => {
      const elResult = buildElement(node, props, isRootLevel);
      if (elResult) {
        elements.push(elResult);
        formValues[elResult.id] = elResult.props.value;
      }
    });

    if (!elements.length) return currentElements;

    elements = currentElements.concat(elements);
    return elements;
  };

  const traverseElements = (
    lpageElements,
    currentElements,
    isRootLevel = true
  ) => {
    lpageElements.forEach((node) => {
      // const { excludeControls } = props;
      // const elementResult = getContentElement(node.elementType);

      currentElements = extractSimpleElements(
        [node],
        currentElements,
        isRootLevel
      );
    });

    return currentElements;
  };

  const parseData = (data) => {
    if (!data.pageElements) {
      console.error("ContentParser: missing provided property `pageElements`");
      return false;
    }

    parserState = {
      ...parserState,
      rootNumberOfColumns:
        parserState.rootNumberOfColumns || data.numberOfColumns,
    };

    const elements = traverseElements(
      data.pageElements,
      cloneDeep(parserState.elements)
    );
    const { elementsIndex, logicIndex } = createIndexes(elements);

    const evaluatedElements = evaluateComputeLogic(
      elements,
      elementsIndex,
      logicIndex
    );
    parserState = {
      ...parserState,
      elements: evaluatedElements,
      elementsIndex,
      logicIndex,
    };
    setFormValues();
    // parserState = {
    //   ...parserState,
    //   addressFields: getAddressField(data),
    // };
    return parserState;
  };

  const updateNested = () => {
    const { elements } = parserState;
    applyShowLogicUpdates(elements, elements);
    handleOnChange();
  };

  const runInitCallbacks = () => {
    const { initCallbacks } = parserState;
    initCallbacks.forEach((fn) => fn());
  };

  useEffect(() => {
    const { data } = props;
    const newParserState = parseData(data);
    // console.log("parserState, newParserState >>>", parserState, newParserState);
    setCompParserState(newParserState);
    setParserReady(true);
  }, [forceRender]);

  useEffect(() => {
    updateNested();
    runInitCallbacks();
  }, [parserReady]);

  const renderRows = () => {
    const { elements } = compParserState;
    const componentRows = [];
    const showLabels = true;
    elements.forEach((el) => {
      return componentRows.push(
        getElementComponent(el, componentRows.length, showLabels)
      );
    });
    return componentRows;
  };

  const { elements } = compParserState || {};

  if (!parserReady) {
    return (
      <div className="spinner">
        <FBSpinner />
      </div>
    );
  }

  if (!parserReady || !elements || !elements.length) return false;

  return <section className="content-parser-root">{renderRows()}</section>;
};

export default ContentParser;
