import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import {
  useMsal,
  useMsalAuthentication,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { saveReduxStateInLocalStorage } from "util/localStorageUtil";
import {
  InteractionType,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";
import LifePortraitHeader from "components/Header/LifePortraitHeader";
import { RouteConstants } from "routes/AppRoutes";
import {
  getPortraitDetailDataSummary,
  getPortraitGroupSummary,
  setSAMAccountNameAct,
} from "features/Dashboard/state/agentDashboardActions";
import {
  setPortraitIds,
  setAuthToken,
  setMode,
} from "../LifePortrait/state/lifePortraitSlice";
import {
  getPortraitData,
  getPortraitDataSummary,
} from "../LifePortrait/state/lifePortraitActions";
import { tokenRequestScopes } from "./authConfig";

export default function AgentLanding() {
  // const { instance, inProgress, accounts } = useMsal();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const applicationId = searchParams.get("a");
  const documentId = searchParams.get("d");

  const { result, error } = useMsalAuthentication(InteractionType.Redirect, {
    scopes: tokenRequestScopes,
  });
  const { instance } = useMsal();

  useEffect(() => {
    if (error) {
      // Error occurred attempting to acquire a token, either handle the error or do nothing
      console.log(`Error ${error}`);
      return;
    }

    if (result) {
      const account = instance.getActiveAccount();
      if (!account) {
        throw Error(
          "No active account! Verify a user has been signed in and setActiveAccount has been called."
        );
      }
      dispatch(setPortraitIds({ applicationId, documentId }));
      dispatch(setMode({ mode: "agent" }));
      instance
        .acquireTokenSilent({ scopes: tokenRequestScopes, account })
        .then((r) => {
          const errorNavigation = (errArg) => {
            const {
              code,
              message,
              response: { status, statusText },
            } = errArg;
            navigate(RouteConstants.Error, {
              state: {
                code,
                message,
                status,
                statusText,
              },
            });
          };

          dispatch(setAuthToken({ authToken: r.accessToken }));
          saveReduxStateInLocalStorage({
            applicationId,
            documentId,
            authToken: r.accessToken,
          });
          dispatch(
            setSAMAccountNameAct({
              SAMaccountName: r?.account?.idTokenClaims?.samAccountName,
            })
          ).then(() => {
            // redirect from MyDocusign in LNA
            if (searchParams.get("mode") === "dashboardDetail") {
              const requestData = { applicationId, documentId };
              dispatch(
                getPortraitDetailDataSummary(requestData, errorNavigation)
              );
              dispatch(
                getPortraitGroupSummary(requestData, errorNavigation)
              ).then(() => {
                navigate("/Dashboard/Detail");
              });
            }
          });

          if (searchParams.get("mode") === "dashboardDetail") return;

          dispatch(getPortraitDataSummary(errorNavigation))
            .then((s) => {
              if (s.status === "Initial") {
                navigate("/TNInsuranceAcknowledge/");
              } else if (s.status === "Declined") {
                dispatch(getPortraitData(errorNavigation));
                navigate("/OptedOut/");
              } else if (s.status === "Complete") {
                navigate("/AgentCompletion");
              } else if (s.status === "Incomplete") {
                navigate("/Incomplete");
              } else {
                dispatch(getPortraitData(errorNavigation));
                navigate("/Welcome/");
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(async (e) => {
          if (e instanceof InteractionRequiredAuthError) {
            await instance.acquireTokenRedirect({
              scopes: tokenRequestScopes,
              account,
            });
          }
          throw e;
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, result, instance, applicationId, documentId, navigate, dispatch]);

  return (
    <>
      <LifePortraitHeader id="header" />
      <div className="container-xl min-vh-100">
        <form className="shadow bg-body rounded py-3">
          <div className="card-body">
            <AuthenticatedTemplate>
              <p className="card-text">
                Please wait while the portrait is being prepared...
              </p>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <p className="card-text">
                Please wait while you are authenticated...
              </p>
            </UnauthenticatedTemplate>
          </div>
        </form>
      </div>
    </>
  );
}
