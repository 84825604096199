import * as React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export default function FBDatePicker(props) {
  const {
    control,
    id,
    label,
    inputFormat,
    required,
    value = null,
    disabled,
    readOnly,
    onChange: onInputChange,
    ...other
  } = props;
  const [dateValue, setDateValue] = React.useState(value);

  const handleChange = (date, field) => {
    field.onChange(date);
    if (onInputChange) onInputChange(date);
  };

  const validate = (dt) => {
    try {
      const dtStr = dt.toISOString();
      return dtStr && dtStr !== "";
    } catch {
      return false;
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Controller
        control={control}
        name={id}
        rules={{
          required: { value: required, message: `${label} is required` },
          validate,
        }}
        render={({ field, fieldState: { error } }) => (
          <DatePicker
            label={label}
            required={required}
            value={dateValue} // value
            disabled={disabled}
            readOnly={readOnly}
            error={!!error}
            onChange={(newValue) => {
              setDateValue(newValue);
              handleChange(newValue, field);
            }}
            inputFormat={inputFormat}
            renderInput={(params) => (
              <TextField
                size="small"
                sx={{ "& legend": { width: "auto" } }}
                {...params}
                {...other}
              />
            )}
          />
        )}
      />
    </LocalizationProvider>
  );
}

FBDatePicker.propTypes = {
  control: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  inputFormat: PropTypes.string,
  onChange: PropTypes.func,
};

FBDatePicker.defaultProps = {
  label: "",
  required: false,
  value: undefined,
  disabled: false,
  readOnly: false,
  inputFormat: "MM/dd/yyyy",
  onChange: undefined,
};
