/* eslint-disable no-continue */
import {
  propNamesHeartAttack,
  transformHeartAttackInput,
} from "./heartAttackHelper";
import { transformHeightInput } from "./heightHelper";
import {
  propNamesPapSmears,
  transformMultiAbnormalPapsInput,
} from "./multiAbnormalPapsHelper";
import { transformInput } from "./multiDateDescHelper";

const getResponseStatusFromSet = (responseStatusSet) => {
  if (responseStatusSet.has("started")) {
    return "started";
  }
  if (responseStatusSet.has("completed")) {
    return responseStatusSet.size === 1 ? "completed" : "started";
  }
  return "notstarted";
};

const getMultiDescQuestionResponseStatus = (questionDef, answer) => {
  const { numberOfTimes, responses } = transformInput(questionDef.id, answer);
  const count = parseInt(numberOfTimes, 10);
  if (typeof count !== "number" || Number.isNaN(count)) return "notstarted";

  for (let i = 0; i < count; i += 1) {
    const inputKeys = Object.keys(questionDef.input.options);
    for (let j = 0; j < inputKeys.length; j += 1) {
      const key = inputKeys[j];
      if (!key.endsWith("Show") || questionDef.input.options[key] === false) {
        // not an input or input is not required ex:dateShow = false
        continue;
      }
      const names = key.split("Show");
      if (names.length > 1) {
        const propName = names[0];
        const responseKey = `${questionDef.id}-${i}-${propName}`;

        const val = responses[responseKey];
        if (!val) return "started";

        switch (propName) {
          case "date":
            // eslint-disable-next-line no-restricted-globals
            if (isNaN(val)) return "started";
            break;
          case "provider":
          case "text":
          default:
            if (val.length === 0) return "started";
            break;
        }
      }
    }
  }
  return "completed";
};

const getHeartAttackQuestionResponseStatus = (questionDef, answer) => {
  const { numberOfTimes, responses } = transformHeartAttackInput(
    questionDef.id,
    answer
  );
  const count = parseInt(numberOfTimes, 10);
  if (typeof count !== "number" || Number.isNaN(count)) return "notstarted";

  for (let i = 0; i < count; i += 1) {
    const inputKeys = [
      propNamesHeartAttack.numberOfTimes,
      propNamesHeartAttack.date,
      propNamesHeartAttack.dropdown,
      propNamesHeartAttack.otherDesc,
      propNamesHeartAttack.medicationTreatment,
      propNamesHeartAttack.provider,
    ];
    for (let j = 0; j < inputKeys.length; j += 1) {
      const key = inputKeys[j];
      const names = key.split("Show");
      if (names.length > 1) {
        const propName = names[0];
        const responseKey = `${questionDef.id}-${i}-${propName}`;

        const val = responses[responseKey];
        if (!val) return "started";

        switch (propName) {
          case propNamesHeartAttack.date:
            // eslint-disable-next-line no-restricted-globals
            if (isNaN(val)) return "started";
            break;
          case propNamesHeartAttack.provider:
          case propNamesHeartAttack.dropdown:
          case propNamesHeartAttack.otherDesc:
          case propNamesHeartAttack.medicationTreatment:
          default:
            if (val.length === 0) return "started";
            break;
        }
      }
    }
  }
  return "completed";
};

const getMultiAbnormalPapsQuestionResponseStatus = (questionDef, answer) => {
  const { numberOfTimes, responses } = transformMultiAbnormalPapsInput(
    questionDef.id,
    answer
  );
  const count = parseInt(numberOfTimes, 10);
  if (typeof count !== "number" || Number.isNaN(count)) return "notstarted";

  for (let i = 0; i < count; i += 1) {
    const inputKeys = [
      propNamesPapSmears.numberOfTimes,
      propNamesPapSmears.date,
      propNamesPapSmears.drpPapSmear,
      propNamesPapSmears.otherPapSmear,
      propNamesPapSmears.drpTreatment,
      propNamesPapSmears.otherTreatment,
    ];
    for (let j = 0; j < inputKeys.length; j += 1) {
      const key = inputKeys[j];
      const names = key.split("Show");
      if (names.length > 1) {
        const propName = names[0];
        const responseKey = `${questionDef.id}-${i}-${propName}`;

        const val = responses[responseKey];
        if (!val) return "started";

        switch (propName) {
          case propNamesHeartAttack.date:
            // eslint-disable-next-line no-restricted-globals
            if (isNaN(val)) return "started";
            break;
          case propNamesHeartAttack.drpPapSmear:
          case propNamesHeartAttack.otherPapSmear:
          case propNamesHeartAttack.drpTreatment:
          case propNamesHeartAttack.otherTreatment:
          default:
            if (val.length === 0) return "started";
            break;
        }
      }
    }
  }
  return "completed";
};

const getHeightQuestionResponseStatus = (questionDef, answer) => {
  if (!answer || answer === "") return "notstarted";
  const { isComplete } = transformHeightInput(questionDef.id, answer);
  if (!isComplete) return "started";
  return "completed";
};

// ********************************************************
// This method does not handle status for Family History.
// The status for Family History is directly set from UI.
// ********************************************************
const getQuestionsResponseStatus = (
  questionDefs,
  responseDetails,
  physiciansMap
) => {
  if (questionDefs === undefined || responseDetails === undefined) {
    return "notstarted";
  }

  const responseStatusSet = new Set();
  const checkBoxGroupMap = new Map();
  for (let i = 0; i < questionDefs.length; i += 1) {
    const questionDef = questionDefs[i];
    const questionResponse = responseDetails?.details?.find(
      (r) => r.id === questionDef.id
    );
    const { required, dataType } = questionDef.input;
    const answer = questionResponse?.answer;

    if (required) {
      if (!answer) {
        // response is required but not answered
        responseStatusSet.add("notstarted");
        continue;
      }
    } else if (!answer && dataType !== "Checkbox") {
      // question is not required and there is no response
      // treat as complete
      responseStatusSet.add("completed");
      continue;
    }

    // question is either required or
    // there is an answer for not required question which may have a drilldown
    let status = "notstarted";
    switch (dataType) {
      case "YesNo":
        status = answer === "No" || answer === "Yes" ? "completed" : "started";
        break;
      case "Date":
      case "DropDown":
      case "Medication":
      case "Numeric":
      case "Radio":
      case "Text":
      case "Text-Multi":
        status = answer.length > 0 ? "completed" : "started";
        break;
      case "Checkbox":
        if (questionDef.input.options.checkBoxGroup) {
          // if part of checkbox group, track response with groupId as
          // there should be atleast one Yes
          const checkBoxGroupId = questionDef.input.options.checkBoxGroup;
          let answerSet = checkBoxGroupMap.get(checkBoxGroupId);
          if (!answerSet) {
            answerSet = new Set();
            checkBoxGroupMap.set(checkBoxGroupId, answerSet);
          }
          answerSet.add(answer);
        }
        status = answer?.length > 0 ? "completed" : "started";
        break;
      case "Provider":
        if (physiciansMap.has(answer) || answer?.length > 0) {
          status = "completed";
        }
        break;
      case "Caption":
        status = "completed";
        break;
      case "MultiDateDesc":
        status = getMultiDescQuestionResponseStatus(questionDef, answer);
        break;
      case "HeartAttacks":
        status = getHeartAttackQuestionResponseStatus(questionDef, answer);
        break;
      case "MultiAbnormalPaps":
        status = getMultiAbnormalPapsQuestionResponseStatus(
          questionDef,
          answer
        );
        break;
      case "Height":
        status = getHeightQuestionResponseStatus(questionDef, answer);
        break;
      default:
        // Unknown dataType
        responseStatusSet.add("notstarted");
        continue;
    }

    if (
      status !== "completed" || // dont check drilldowns if response is not complete
      !questionDef.drilldowns || // no drilldown questions to check
      questionDef.drilldowns.length === 0
    ) {
      responseStatusSet.add(status);
      continue;
    }

    const ddQuestionDefs = questionDef.drilldowns?.find((dd) =>
      dd?.drilldownWhen.includes(answer)
    )?.questions;

    responseStatusSet.add(status);

    if (!ddQuestionDefs) {
      // No drilldowns, then continue.
      continue;
    }

    const drilldownQuestionsStatus = getQuestionsResponseStatus(
      ddQuestionDefs,
      questionResponse,
      physiciansMap
    );
    responseStatusSet.add(drilldownQuestionsStatus);
  }

  checkBoxGroupMap.forEach((answerSet, key) => {
    // check if there is atleast one Yes in checkbox group
    // Exception for Primary Case Section - where we allow form to be marked as completed
    // when answer => Yes Or No
    if (
      (key === "PS-PC-1" || key === "PC-PC-1" || key === "PT-PC-1") &&
      (answerSet.has("No") || answerSet.has("Yes"))
    ) {
      responseStatusSet.add("completed");
    } else if (answerSet.has("Yes")) responseStatusSet.add("completed");
    else responseStatusSet.add("started");
  });

  return getResponseStatusFromSet(responseStatusSet);
};

export const getGroupResponseStatus = (portraitMetadata, groupResponse) => {
  const groupId = groupResponse.id;
  const sections = portraitMetadata.groups.find(
    (g) => g.id === groupId
  )?.sections;
  if (!sections) return "notstarted";

  let questionDefs = [];
  if (sections.length === 1) {
    questionDefs = sections[0].questions;
  } else {
    sections.forEach((s) => questionDefs.push(...s.questions));
  }
  if (questionDefs.length === 0) return "notstarted";

  const physiciansMap = new Map();
  // for (let i = 0; i < portraitResponse.physicians?.length; i += 1) {
  //   const physician = portraitResponse.physicians[i];
  //   physiciansMap.set(physician.id, physician.name);
  // }

  const groupResponseStatus = getQuestionsResponseStatus(
    questionDefs,
    groupResponse,
    physiciansMap
  );

  return groupResponseStatus;
};
