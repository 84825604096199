export const propNamesHeight = {
  feets: "ft",
  inches: "in",
};

export const transformHeightInput = (id, val) => {
  // "val =  5ft-6in";   Height will be in inches and function will split in feets and inches
  // "val - 76"
  let isInComplete = false;
  let height = {};
  if (val && !Number.isNaN(val)) {
    let strFeet;
    let strInches;
    if (val.split("-").length > 1) {
      [strFeet, strInches] = val.split("-");
    } else {
      const parsedHt = parseInt(val, 10);
      strFeet = `${Math.floor(parsedHt / 12)}ft`;
      strInches = `${Math.floor(parsedHt % 12)}in`;
    }
    if (strFeet && strFeet.replace(propNamesHeight.feets, "") !== "") {
      height = {
        [`${id}-${propNamesHeight.feets}`]: strFeet.replace(
          propNamesHeight.feets,
          ""
        ),
      };
    } else {
      height = { [`${id}-${propNamesHeight.feets}`]: "" };
      isInComplete = true;
    }

    if (strInches && strInches.replace(propNamesHeight.inches, "") !== "") {
      height = {
        ...height,
        [`${id}-${propNamesHeight.inches}`]: strInches.replace(
          propNamesHeight.inches,
          ""
        ),
      };
    } else {
      height = { ...height, [`${id}-${propNamesHeight.inches}`]: "" };
      isInComplete = true;
    }

    if (
      strFeet.replace(propNamesHeight.feets, "") === "0" &&
      strInches.replace(propNamesHeight.inches, "") === "0"
    ) {
      isInComplete = true;
    }
  } else {
    isInComplete = true;
    height = { ...height, [`${id}-${propNamesHeight.feets}`]: "", [`${id}-${propNamesHeight.inches}`]: "" };
  }
  return { height, isComplete: !isInComplete };
};

export const transformHeightOutput = (id, height) => {
  let isInComplete = false;
  const feet = height[`${id}-${propNamesHeight.feets}`];
  const inches = height[`${id}-${propNamesHeight.inches}`];
  if (!feet || !inches || (feet === "0" && inches === "0")) {
    isInComplete = true;
  }

  const parsedFt = parseInt(feet, 10);
  const parsedIn = parseInt(inches, 10);
  const ht = parsedFt * 12 + parsedIn;
  return {
    transformedValue: `${ht}`,
    isComplete: !isInComplete,
  };
};
