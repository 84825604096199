import * as React from "react";

export default function FBSummaryReminder() {
  return (
    <div>
      <h5>Reminder </h5>
      <p>
        Failure to provide truthful statements or answers means that
        coverage may not apply at the time of a claim. Additionally,
        Tennessee Code Annotated, Title 56 requires that you be advised
        as follow: &quot;It is a crime to knowingly provide false, incomplete,
        or misleading information to an insurance company for the purpose
        of defrauding the company. Penalties include imprisonment, fines,
        and denial of insurance benefits.&quot;
      </p>

    </div>
  );
}
