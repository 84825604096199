/* eslint-disable indent */
/* eslint-disable react/forbid-prop-types */
import * as React from "react";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PropTypes from "prop-types";
import Layout from "components/Header/Layout";
import { debounce, Grid } from "@mui/material";
import FBYesNoToggleButton from "components/Elements/FBYesNoToggleButton";
import FBCheckbox from "components/Elements/FBCheckbox";
import PortaitMedHistoryContainer from "../container/PortaitMedHistoryContainer";
import AdditionalMedicationContainer from "../container/AdditionalMedicationContainer";

export default function MedicationHistoryLandingPres({
  control,
  setValue,
  portraitMedications,
  additionalMedications,
  openMedForm,
  handleContinueBtnClick,
  handleBackBtnClick,
  additionalMedsQuestion,
  errorNavigation,
  portraitMode,
  formValues,
  invalidMeds,
  showMedEditForm,
  isLoading,
  acknowledgement,
  questionnaireId,
}) {
  return (
    <Layout>
      <div className="bgSecondaryFBgrey min-vh-100 py-3 px-3">
        <div className="shadow-sm p-2 mb-2 bg-white rounded">
          <h5>
            Here are the medications you&#39;ve added in your Life Portrait:
          </h5>
          <Grid container>
            <Grid item>
              {portraitMedications.length === 0
                ? "No medications currently listed"
                : portraitMedications?.map((item) => {
                    return (
                      <PortaitMedHistoryContainer
                        id={item.id}
                        control={control}
                        value={item.med}
                        required
                        setValue={setValue}
                        errorNavigation={errorNavigation}
                      />
                    );
                  })}
            </Grid>
          </Grid>
        </div>
        <div className="shadow-sm p-2 mb-2 bg-white rounded">
          <h5>{additionalMedsQuestion}</h5>
          <FBYesNoToggleButton
            id="ynOtherMeds"
            control={control}
            label=""
            value={formValues.ynOtherMeds}
            required
            disabled={showMedEditForm}
          />
          {formValues?.ynOtherMeds === "Yes" ? (
            <Grid container>
              <Grid item>
                {additionalMedications?.map((item, index) => {
                  return (
                    <AdditionalMedicationContainer
                      id={`medication-${index}`}
                      index={index}
                      value={item}
                    />
                  );
                })}
              </Grid>
            </Grid>
          ) : null}
          <div className="row py-3">
            {formValues?.ynOtherMeds === "Yes" && !showMedEditForm ? (
              <Button
                variant="outlined"
                onClick={openMedForm}
                sx={{ width: 200, ml: 2 }}
              >
                Add Another Medication
              </Button>
            ) : null}
          </div>
        </div>
        {!questionnaireId.endsWith("1-0") ? (
          <div className="shadow-sm p-2 mb-2 bg-white rounded">
            <FBCheckbox
              id="acknowledgement"
              control={control}
              label={`${acknowledgement}`}
              value={formValues.acknowledgement}
              required
              disabled={showMedEditForm}
            />
          </div>
        ) : null}
        <div className="shadow-sm p-2 mb-2 bg-white rounded">
          <div className="row justify-content-center py-3">
            <Button
              variant="outlined"
              onClick={handleBackBtnClick}
              className="FBMedFormFooterButton FBMedFormFooterButtonRight"
              disabled={showMedEditForm}
            >
              <ArrowBackIcon style={{ color: "#143247", fontSize: "18pt" }} />
              &nbsp;Back
            </Button>
            <Button
              variant="contained"
              className="FBMedFormFooterButton FBMedFormFooterButtonRight"
              onClick={debounce(handleContinueBtnClick, 300, true)}
              disabled={
                (formValues.ynOtherMeds === "Yes" &&
                  additionalMedications.length === 0) ||
                formValues.ynOtherMeds === "" ||
                invalidMeds() ||
                !formValues.acknowledgement ||
                isLoading
              }
            >
              {portraitMode === "client" ? "Next: Docusign" : "Continue"}
            </Button>
          </div>
        </div>
      </div>
    </Layout>
  );
}

MedicationHistoryLandingPres.propTypes = {
  control: PropTypes.any,
  setValue: PropTypes.any,
  portraitMedications: PropTypes.arrayOf(PropTypes.any),
  additionalMedications: PropTypes.arrayOf(PropTypes.any),
  handleContinueBtnClick: PropTypes.func,
  handleBackBtnClick: PropTypes.func,
  openMedForm: PropTypes.func,
  additionalMedsQuestion: PropTypes.string,
  errorNavigation: PropTypes.func,
  portraitMode: PropTypes.string,
  formValues: PropTypes.any,
  invalidMeds: PropTypes.func,
  showMedEditForm: PropTypes.string,
  isLoading: PropTypes.bool,
  acknowledgement: PropTypes.any,
  questionnaireId: PropTypes.string,
};
