/* eslint-disable react/prop-types */
import * as React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import HomeIcon from "@mui/icons-material/Home";
import Modal from "react-bootstrap/Modal";
import logo from "assets/LifePortrait1.png";
import AgentCard from "components/Elements/FBAgentCard";
import LifePortraitFooter from "components/Footer/LifePortraitFooter";
import LifePortraitChecklistDrawer from "./LifePortraitChecklistDrawer";

const drawerWidth = 350;

function Layout(props) {
  const { window, id, children } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { firstName, lastName } = useSelector((state) => state.lifePortrait);
  const { mediaType } = useSelector((state) => state.browser);
  const { agent } = useSelector(
    (state) => state.lifePortrait.questionnaireResponse
  );

  let showMenuPermanantly = false;
  if (["large", "extraLarge", "infinity"].includes(mediaType)) {
    showMenuPermanantly = true;
  }
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const navigate = useNavigate();
  const handleItemSelect = () => () => {
    setMobileOpen(false);
  };
  const goToHome = () => {
    navigate("/Welcome");
  };
  const [showNeedHelp, setShowNeedHelp] = React.useState(false);

  const drawer = (
    <div>
      <Toolbar>
        <div
          className="px-4 position-absolute start-0"
          style={{
            width: 250,
          }}
        >
          Life Portrait for {firstName} {lastName}
        </div>
        <div className="px-4 position-absolute end-0">
          <HomeIcon onClick={goToHome} style={{ cursor: "pointer" }} />
        </div>
      </Toolbar>
      <Divider />
      <List>
        <LifePortraitChecklistDrawer
          id={id || "lifePortraitCheckListDrawer"}
          onItemSelect={handleItemSelect()}
        />
      </List>
      <Divider />
      <div className="px-3 py-3">
        <a
          href="https://www.fbitn.com/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
          className="text-white text-decoration-none"
        >
          Privacy Policy
        </a>
      </div>
      <div className="px-3">
        <Link
          style={{ textDecoration: "none", color: "#FFF" }}
          to="/TermsAndConditionsPage"
          state={{ from: "checklist" }}
        >
          Terms and Conditions
        </Link>
      </div>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <AppBar
          position="fixed"
          sx={{
            width: !showMenuPermanantly
              ? undefined
              : { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
            backgroundColor: "white.main",
            boxShadow: 0,
          }}
        >
          <nav className="navbar navbar-expand-lg">
            <div className="container-fluid">
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{
                  mr: 2,
                  display: !showMenuPermanantly ? undefined : { sm: "none" },
                  color: "primary.main",
                  marginLeft: "5px",
                }}
              >
                <MenuIcon />
              </IconButton>
              <Link to="/Welcome">
                <img src={logo} alt="Farm Bureau Logo" />
              </Link>
              <Button>
                <HelpOutlineIcon
                  sx={{
                    color: "primary.main",
                  }}
                  onClick={() => setShowNeedHelp(true)}
                />
              </Button>
            </div>
          </nav>
          <div className="d-flex justify-content-center pt-3 d-block d-sm-none">
            <h4 style={{ color: "black", textAlign: "center" }}>
              Life Portrait for {firstName} {lastName}
            </h4>
          </div>
        </AppBar>
        <Box
          component="nav"
          sx={{
            width: !showMenuPermanantly ? undefined : { sm: drawerWidth },
            flexShrink: { sm: 0 },
          }}
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          {!showMenuPermanantly && (
            <Drawer
              container={container}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                // display: { xs: "block", sm: "none" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
                },
              }}
            >
              {drawer}
            </Drawer>
          )}
          {showMenuPermanantly && (
            <Drawer
              variant="permanent"
              sx={{
                // display: { xs: "none", sm: "block" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
                },
              }}
              open
            >
              {drawer}
            </Drawer>
          )}
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 0,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
          }}
        >
          <Toolbar />
          <br />
          <br />
          <br />
          <div className="d-block d-sm-none">
            <br />
            <br />
          </div>
          {children}
          <LifePortraitFooter id="footer" state={{ from: "checklist" }} />
        </Box>
      </Box>
      <Modal
        show={showNeedHelp}
        style={{ zIndex: 9999 }}
        onHide={() => setShowNeedHelp(false)}
        centered
      >
        <Modal.Header closeButton>Contact Agent</Modal.Header>
        <Modal.Body>
          <p align="center">NEED HELP?</p>
          <p align="center" className="pb-2">
            Your agent can help you access your Life Portrait and answer
            questions.
          </p>
          <AgentCard id="agentCard" agent={agent} />
        </Modal.Body>
      </Modal>
    </>
  );
}

Layout.propTypes = {
  window: PropTypes.func,
};

export default Layout;
