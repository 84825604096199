export const FamilyMembers = {
  Father: "father",
  Mother: "mother",
  SiblingExists: "siblingExists",
  Sibling: "sibling",
  Brother: "brother",
  Sister: "sister",
};

export const LivingEnum = {
  Alive: "Alive",
  Deceased: "Deceased",
};

export const FormActions = {
  Add: "Add",
  Edit: "Edit",
  Update: "Update",
  Cancel: "Cancel",
};

export const cboConditionsList = [
  {
    label: "Diabetes",
    value: "Diabetes",
    cascadeMedHist: "diabetes",
  },
  {
    label: "Cancer",
    value: "Cancer",
    cascadeMedHist: "cancer",
  },
  {
    label: "Huntington's Disease",
    value: "Huntington's Disease",
    cascadeMedHist: "huntingtonsDisease",
  },
  {
    label: "Heart Disease",
    value: "Heart Disease",
    cascadeMedHist: "heartDisease",
  },
  {
    label: "Polycystic Kidney Disease",
    value: "Polycystic Kidney Disease",
    cascadeMedHist: "polycysticKidneyDisease",
  },
  {
    label: "Suicide",
    value: "Suicide",
  },
  {
    label: "I don't know",
    value: "I don't know",
  },
  {
    label: "Other",
    value: "Other",
  },
];

export const medicalConditions = [
  {
    id: "diabetes",
    label: "Diabetes",
    showDrilldown: false,
  },
  {
    id: "cancer",
    label: "Cancer",
    showDrilldown: true,
  },
  {
    id: "huntingtonsDisease",
    label: "Huntington’s Disease",
    showDrilldown: false,
  },
  {
    id: "heartDisease",
    label: "Heart Disease",
    showDrilldown: true,
  },
  {
    id: "polycysticKidneyDisease",
    label: "Polycystic Kidney Disease",
    showDrilldown: false,
  },
  {
    id: "iDontKnow",
    label: "I don’t know",
    showDrilldown: false,
  },
  {
    id: "noneOfTheAbove",
    label: "None of the above",
    showDrilldown: false,
  },
];

export const siblingTypes = [
  {
    label: "Brother",
    value: "Brother",
    showControl: "",
  },
  {
    label: "Sister",
    value: "Sister",
    showControl: "",
  },
];

export const aliveDeceasedTypes = [
  {
    label: LivingEnum.Alive,
    value: LivingEnum.Alive,
    showControl: "",
  },
  {
    label: LivingEnum.Deceased,
    value: LivingEnum.Deceased,
    showControl: "",
  },
];
