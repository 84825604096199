import {
  rowDelimiter,
  colDelimiter,
  keyValueDelimiter,
  decode,
  encode,
} from "./genericHelper";

export const propNames = {
  numberOfTimes: "numberOfTimes",
  date: "date",
  text1: "text1",
  text2: "text2",
  text3: "text3",
  provider: "provider",
};

export const transformInput = (id, val, options) => {
  // eslint-disable-next-line max-len
  // "[2]date^2017-04-04T18:02:50.050Z~text1^~text2^~text3^~provider^progui1|date^2015-05-04T18:03:07.865Z~text1^~text2^~text3^~provider^progui1";
  let returnObj = {};
  let numberOfTimes;
  let isInComplete = false;
  if (val) {
    numberOfTimes = val.slice(0, val.indexOf("]") + 1).slice(1, -1);
    const strippedHosp = val.replace(val.slice(0, val.indexOf("]") + 1), "");
    returnObj = { ...returnObj, [`${id}-numberOfTimes`]: numberOfTimes };
    const rows = strippedHosp.split(rowDelimiter);

    if (rows.length) {
      rows.forEach(async (el, index) => {
        if (el && el !== "") {
          const cols = el.split(colDelimiter);
          if (cols.length > 0) {
            cols.forEach((col) => {
              const keyValueArr = col.split(keyValueDelimiter);

              if (keyValueArr.length > 0) {
                const key = keyValueArr[0];
                let value = keyValueArr[1];
                if (
                  options &&
                  options[`${key}Show`] === true &&
                  (value === undefined || value === "")
                ) {
                  isInComplete = true;
                }
                if (value && value !== "") {
                  if (key === propNames.date) {
                    value = new Date(value);
                  }
                  if (
                    key === propNames.text1 ||
                    key === propNames.text2 ||
                    key === propNames.text3
                  ) {
                    value = decode(value);
                  }
                  returnObj = {
                    ...returnObj,
                    [`${id}-${index}-${key}`]: value,
                  };
                }
              }
            });
          }
        }
      });
      // await Promise.all(promises);
    }

    if (!isInComplete && numberOfTimes && parseInt(numberOfTimes, 10) < 0) {
      isInComplete = true;
    }
  }
  return { numberOfTimes, responses: returnObj, isComplete: !isInComplete };
};

export const transformOutput = (id, value, responseCollection, options) => {
  let returnVal = "";
  let isInComplete = false;
  const numberOfTimes = value;
  if (
    numberOfTimes < 0 ||
    numberOfTimes === null ||
    numberOfTimes === undefined
  ) {
    isInComplete = true;
  }
  if (numberOfTimes > 0) {
    [...Array(numberOfTimes)].forEach((el, index) => {
      const date = responseCollection[`${id}-${index}-${propNames.date}`];
      const text1 =
        encode(responseCollection[`${id}-${index}-${propNames.text1}`]) || "";
      const text2 =
        encode(responseCollection[`${id}-${index}-${propNames.text2}`]) || "";
      const text3 =
        encode(responseCollection[`${id}-${index}-${propNames.text3}`]) || "";
      const provider =
        responseCollection[`${id}-${index}-${propNames.provider}`] || "";

      if (
        !isInComplete &&
        ((options?.dateShow && (!date || date === "")) ||
          (options?.text1Show && (!text1 || text1 === "")) ||
          (options?.text2Show && (!text2 || text2 === "")) ||
          (options?.text3Show && (!text3 || text3 === "")) ||
          (options?.providerShow &&
            (!provider ||
              provider === "" ||
              provider === "addNewProvider" ||
              provider === "manageProvider")))
      ) {
        isInComplete = true;
      }

      let dateStr = "";
      try {
        dateStr = date ? date?.toISOString() : ``;
        // eslint-disable-next-line no-empty
      } catch {
        isInComplete = true;
      }

      returnVal += `${propNames.date}${keyValueDelimiter}${dateStr}${colDelimiter}${propNames.text1}${keyValueDelimiter}${text1}${colDelimiter}${propNames.text2}${keyValueDelimiter}${text2}${colDelimiter}${propNames.text3}${keyValueDelimiter}${text3}${colDelimiter}${propNames.provider}${keyValueDelimiter}${provider}${rowDelimiter}`;
    });
  }

  return {
    transformedValue: `[${numberOfTimes}]${returnVal.slice(0, -1)}`,
    isComplete: !isInComplete,
  };
};
