/* eslint-disable jsx-a11y/label-has-associated-control */
import * as React from "react";
import { useSelector } from "react-redux";
import { memo } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Modal from "react-bootstrap/Modal";
import AgentCard from "components/Elements/FBAgentCard";

function WelcomePres({
  isNewPortrait,
  firstName,
  gotoPortrait,
  lastLoggedInfo,
}) {
  const [showNeedHelp, setShowNeedHelp] = React.useState(false);
  const { agent } = useSelector(
    (state) => state.lifePortrait.questionnaireResponse
  );
  return (
    <div className="bgSecondaryFBgrey container-fluid min-vh-100">
      <h4 className="d-flex justify-content-center pt-3">
        {isNewPortrait ? "Welcome, " : "Welcome back, "}
        {firstName}
      </h4>
      {!isNewPortrait && (
        <div className="d-flex justify-content-center">
          <small>{lastLoggedInfo}</small>
        </div>
      )}
      <div className="row row-cols-1 row-cols-md-2 row-cols-md-1 g-3 py-1 px-2">
        <div className="col">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">
                {isNewPortrait
                  ? "Let's get started on your Life Portrait"
                  : "Continue your Life Portrait"}
              </h5>
              <p className="text-center">
                <Button onClick={gotoPortrait} variant="contained">
                  {isNewPortrait ? "Begin Life Portrait" : "Continue"}&nbsp;
                  <ArrowForwardIcon />
                </Button>
              </p>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">
                <CheckCircleIcon style={{ fill: "#4caf50" }} />
                &nbsp;Your progress is automatically saved.
              </h5>
            </div>
          </div>
        </div>
        {isNewPortrait && (
          <div className="col">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">What is Life Portrait?</h5>
                <p className="card-text">
                  A Life Portrait is the next step in your application for Life
                  Insurance through Farm Bureau Insurance.
                </p>
                <h5 className="card-title">
                  What do I need to complete my Life Portrait?
                </h5>
                <div className="card-text">
                  <ul>
                    <li>A list of current medications that you take.</li>
                    <li>Contact information for doctors you have seen.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="col">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">
                <HelpOutlineIcon
                  style={{ color: "#004F74", cursor: "pointer" }}
                  onClick={() => setShowNeedHelp(true)}
                /> Need Help?
              </h5>
              <p className="card-text">
                Your agent can help you access your Life Portrait and answer
                questions.
                <br />
                <br />
              </p>
              <p className="text-center">
                <Button
                  variant="outlined"
                  onClick={() => setShowNeedHelp(true)}
                >
                  Agent Contact Info
                </Button>
              </p>
            </div>
          </div>

          <Modal
            show={showNeedHelp}
            style={{ zIndex: 9999 }}
            onHide={() => setShowNeedHelp(false)}
            centered
          >
            <Modal.Header closeButton>Contact Agent</Modal.Header>
            <Modal.Body>
              <p align="center">NEED HELP?</p>
              <p align="center" className="pb-2">
                Your agent can help you access your Life Portrait and answer
                questions.
              </p>
              <AgentCard id="agentCard" agent={agent} />
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}

WelcomePres.propTypes = {
  isNewPortrait: PropTypes.bool,
  firstName: PropTypes.string,
  gotoPortrait: PropTypes.func,
  lastLoggedInfo: PropTypes.string,
};

export default memo(WelcomePres);
