/* eslint-disable no-plusplus */
/* eslint-disable default-param-last */
/* eslint-disable indent */
/* eslint-disable no-unneeded-ternary */
import React from "react";
import { getContentControl, translateToProps } from "./ContentControls";
import ContentControlHOC from "./ContentControlHOC";

/*
 * Some elements require a factory to handle unique parsing situations.
 * Their data is not structured like other controls and may/may not contain other nested controls
 * inside of the data structure.
 *
 * If `recurse` is true, the parser will look to use a provided factory
 */

const ContentElementTypes = {
  DataRequest: {
    recurse: false,
  },
};

function isComplexElement(name) {
  const el = ContentElementTypes[name];
  return el && el.recurse === true && el.factory !== undefined ? true : false;
}

function isReviewerOnly(el) {
  return el && el.props.reviewerOnly === true ? true : false;
}

function isElementExcluded(excludedList, node) {
  return excludedList !== undefined &&
    (excludedList.indexOf(node.elementType) !== -1 ||
      excludedList.indexOf(node.controlTypeName) !== -1)
    ? true
    : false;
}

function getContentElement(name) {
  return ContentElementTypes[name] || null;
}

function isValidFactory(obj) {
  if (
    obj.component === undefined ||
    obj.elements === undefined ||
    !Array.isArray(obj.elements) ||
    typeof obj.component !== "function"
  ) {
    return false;
  }
  return true;
}

function isRootElement(el) {
  return el.isRootLevel ? true : false;
}

function getParentElement(node, elements, elementsIndex) {
  const { parentId } = node;

  if (!parentId) return null;

  const parentPosition = elementsIndex[parentId];
  const parentNode = elements[parentPosition];

  return parentNode;
}

/*
 * Some element types (factories) require the formValue to be translated so that the backend
 * service can update appropriately
 */
function transformElementValue(
  node,
  value,
  { elements, elementsIndex },
  isUpdated = true
) {
  const { parentId, logicId } = node;
  const parentPosition = parentId && elementsIndex[parentId];

  if (parentPosition !== undefined) {
    const parentNode = elements[parentPosition];
    const { controlTypeName: parentControlType, isTable } = parentNode;

    const baseOptions = {
      isDeleted: false,
      isRowLocked: false,
      isUpdated,
      ...(node.isNewRow && { isNewRow: node.isNewRow }),
      elementName: logicId,
      value,
    };

    if (!isTable) return value;

    switch (parentControlType) {
      case "Horziontal":
        return {
          ...baseOptions,
        };
      default:
        return {
          ...baseOptions,
        };
    }
  }

  return value;
}

function createElementEntry(
  node,
  props,
  isRootLevel,
  actions,
  otherProps = {}
) {
  const {
    conditionalWatch,
    setUpdateComponent,
    setComponentRef,
    elementActions,
  } = actions;
  const { rootNumberOfColumns, readOnlyControl } = otherProps;
  const controlResult = getContentControl(node.input.dataType, readOnlyControl);
  if (!controlResult && !isComplexElement(node.elementType)) return null;

  const { id, caption: label, showLogic, input, showMe: visible } = node;

  // const { control, setValue, setValueInReduxResponses } = props;
  const { control, tunnelToParent } = props;
  const { dataType: controlTypeName, indentation } = input;
  const sourceComponent = controlResult && controlResult.component;
  let finalComponent = null;

  const controlProps = translateToProps(node);
  const customControlProps =
    controlResult && controlResult.translateProps
      ? controlResult.translateProps(node, props)
      : {};

  // Depedency inject setUpdateComponent() from ContentParser.
  // This will result in a reference callback
  // from the ContentControlHOC which will allow the
  // ContentParser to setState() on ContentControlHOC's
  finalComponent = ContentControlHOC(
    sourceComponent,
    setUpdateComponent(id),
    setComponentRef(id)
  );

  let dropDownOptions = {};
  //   let showLogicLookUp = null;

  if (
    node?.input?.dataType === "DropDown" ||
    node?.input?.dataType === "Radio"
  ) {
    dropDownOptions = {
      items: node.input.options.listItems.map((el) => ({
        label: el,
        value: el,
      })),
    };
  }

  let options;
  if (["MultiDateDesc", "MultiAbnormalPaps"].includes(node?.input?.dataType)) {
    options = node.input.options;
  }

  return {
    id,
    label,
    controlTypeName,
    isRootLevel,
    isTable: false,
    component: finalComponent,
    logicId: id,
    onChange: conditionalWatch.bind(null, id),
    showLogic,
    updateComponent: () => {},
    ignoreFormValue:
      controlResult && controlResult.ignoreFormValue
        ? controlResult.ignoreFormValue
        : false,
    props: {
      ...controlProps,
      ...customControlProps,
      ...dropDownOptions,
      ...(controlResult &&
        controlResult.includePageId && { pageId: props.pageId }),
      controlTypeName,
      isComponentVisible: visible !== undefined ? visible : true,
      fullWidth: true,
      control,
      // setValue,
      // setValueInReduxResponses,
      tunnelToParent,
      rootNumberOfColumns,
      origControlTypeName: controlTypeName,
      elementActions,
      indentation,
      options,
    },
  };
}

function getElementFromStateById(
  id,
  index,
  elementActions,
  showLabels = false
) {
  const { getParserState } = elementActions();
  const { elements, logicIndex } = getParserState();

  if (!logicIndex[id]) {
    return null;
  }

  const positions = logicIndex[id];
  const elId = id + index;
  let elementMatch = false;

  for (let cIndex = 0; cIndex < positions[cIndex]; cIndex++) {
    const curPosition = positions[cIndex];
    if (elements[curPosition].id === elId) {
      elementMatch = elements[curPosition];
      break;
    }
  }

  if (!elementMatch) return false;

  const Component = elementMatch.component;
  return (
    <Component
      key={elementMatch.id}
      onChange={elementMatch.onChange}
      showLabels={showLabels}
      {...elementMatch.props}
    />
  );
}

function getElementComponent(
  el,
  index,
  showLabels = false,
  pageId,
  additionalProps = {}
) {
  if (!el.component) return null;

  const Component = el.component;
  return (
    <Component
      key={index}
      onChange={el.onChange}
      showLabels={showLabels}
      {...el.props}
      {...additionalProps}
    />
  );
}

export {
  getContentElement,
  getElementComponent,
  getElementFromStateById,
  getParentElement,
  createElementEntry,
  isRootElement,
  isReviewerOnly,
  isElementExcluded,
  isComplexElement,
  isValidFactory,
  transformElementValue,
  ContentElementTypes,
};
