/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { memo } from "react";
import PropTypes from "prop-types";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";

function EOVerifiedPres({ formValues, gotoTermsAndConditions, setDialogOpen }) {
  return (
    <form>
      <div className="bg-light min-vh-100">
        <div className="container py-3">

          <div>
            <div className="shadow bg-body rounded card-body bg-light">
              <h5 className="d-flex justify-content-center">
                <CheckCircleIcon style={{ fill: "#4caf50" }} />
              &nbsp;You&#39;re verified
              </h5>
              <p className="d-flex justify-content-center card-text py-3">
                Next, is your name and date of birth correct?
              </p>
              <div>

                <div className="d-flex justify-content-center">
                  <label htmlFor="lblName" className="form-label px-3">
                    Name:
                  </label>
                  <label htmlFor="txtName" className="form-label">
                    {formValues.firstName} {formValues.lastName}
                  </label>
                </div>

                <div className="d-flex justify-content-center">
                  <label htmlFor="lblDOB" className="form-label px-3">
                    Date of Birth:
                  </label>

                  <label htmlFor="birthDate" className="form-label">
                    {new Date(formValues.birthDate).toLocaleDateString()}
                  </label>

                </div>

              </div>
            </div>
            <div className="d-flex justify-content-center py-3">
              <div>
                <Button
                  id="txtVerifyCode"
                  variant="contained"
                  onClick={gotoTermsAndConditions}
                >
                  <CheckCircleOutlinedIcon />
                &nbsp; Yes, This Is Correct
                </Button>
              </div>
            </div>

            <div className="d-flex justify-content-center">
              <div>
                <Button
                  id="txtVerifyCode"
                  variant=""
                  onClick={() => setDialogOpen(true)}
                >
                  <EditIcon />
                &nbsp; No, I Need To Edit
                </Button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </form>
  );
}

EOVerifiedPres.propTypes = {
  formValues: PropTypes.object,
  gotoTermsAndConditions: PropTypes.func,
  setDialogOpen: PropTypes.func,
};

export default memo(EOVerifiedPres);
