import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import "styles/scss/index.scss";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import AppRoutes from "./routes/AppRoutes";
import store from "./store/configureStore";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap";
import { msalConfig } from "./features/Agent/authConfig";
import * as serviceWorker from "./serviceWorker";

/*
ok green: #4CAF50
FB red: #AC1F2F
FB primary blue: #004F74
FB dark blue: #143247
*/
const msalInstance = new PublicClientApplication(msalConfig);
msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS) {
    msalInstance.setActiveAccount(event.payload.account);
  }
});

const theme = createTheme({
  palette: {
    primary: {
      main: "#004f74",
    },
    secondary: {
      main: "#4899d5",
    },
    error: {
      main: "#ac1f2f",
    },
    success: {
      main: "#4caf50",
    },
    warning: {
      main: "#cc8800",
    },
    white: {
      main: "#ffffff",
    },
    FBdarkblue: {
      main: "#143247",
    },
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: "#143247",
          color: "#fff",
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          color: "#fff",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          whiteSpace: "unset",
          wordBreak: "normal",
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          backgroundColor: "#f5f5f5",
        },
      },
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <AppRoutes />
        </ThemeProvider>
      </Provider>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
