import * as React from "react";
import PropTypes from "prop-types";

export default function AgentCard(props) {
  const { id, agent } = props;
  if (!agent) return false;
  const { name, role, phoneNumber, email, pictureUrl, address } = agent;
  return (
    <div id={id} name={id}>
      <div className="agentCard">
        <div className="mask">
          <img src={`${pictureUrl}`} alt="Agent" />
        </div>
        <h2>{name}</h2>
        <p style={{ fontStyle: "italic" }}>{role}</p>
        <p>
          {address?.line1}
          <br />
          {address?.city} {address?.state} {address?.zip}
        </p>
        <p>
          <a href={`tel:1-${phoneNumber}`}>{phoneNumber}</a>
        </p>
        <p>
          <a href={`mailto:${email}?subject=Help with my Life Portrait`}>
            {email}
          </a>
        </p>
        <br />
        <br />
      </div>
    </div>
  );
}

AgentCard.propTypes = {
  id: PropTypes.string,
  agent: PropTypes.object,
};
