import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TermsAndConditionsOptedOutPres from "../presentation/OptedOutPres";
import { getPortraitAgent } from "../state/lifePortraitActions";

export default function OptedOut() {
  const dispatch = useDispatch();
  const {
    applicationId,
    documentId,
    questionnaireResponse: { agent },
  } = useSelector((state) => state.lifePortrait);
  useEffect(() => {
    if (!agent) {
      dispatch(getPortraitAgent({ applicationId, documentId }));
    }
  }, [applicationId, documentId, dispatch, agent]);

  return <TermsAndConditionsOptedOutPres id="OptedOut" agent={agent} />;
}
