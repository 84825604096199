/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import PropTypes from "prop-types";
import FacebookIcon from "@mui/icons-material/FacebookRounded";
import TwitterIcon from "@mui/icons-material/Twitter";
import logo from "assets/fbilogo.png";
import redLine from "assets/redLine.jpg";

export default function IncompletePres({ id }) {
  return (
    <>
      <nav
        id={id}
        name={id}
        className="navbar navbar-expand-lg"
        style={{ backgroundColor: "#eee" }}
      >
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <img src={logo} alt="Farm Bureau Logo" />
          </a>
        </div>
      </nav>
      <div className="container-xl min-vh-100" align="center">
        <div className="pt-4">
          <p
            style={{
              fontWeight: "bolder",
              fontSize: "42px",
              letterSpacing: "0.025em",
              color: "#143247",
            }}
          >
            We are sorry for the inconvenience, but your Life Portrait has
            expired.
          </p>
        </div>
        <div>
          <img src={redLine} alt="red line" />
        </div>
        <div className="mb-3">
          Please contact your agent for the next steps in the Underwriting
          process.
        </div>
        <br />
      </div>
      <div className="mt-auto bgPrimaryDarkBlueFB">
        <footer className="d-flex flex-wrap justify-content-between align-items-center px-2 py-2 border-top border-3">
          <div className="col-md-4 d-flex align-items-center">
            <span className="text-white small">
              &copy; {new Date().getFullYear()} Tennessee Farmers Insurance
              Companies.
              <br />
              P.O. Box 307 Columbia, TN 38402-0307
              <br />
              Customer Service Center 877-876-2222
            </span>
          </div>
          <ul className="nav col-md-4 justify-content-end list-unstyled d-flex">
            <li className="ms-3">
              <a
                aria-label="farmbureau"
                className="text-white text-decoration-none"
                href="https://www.fbitn.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                fbitn.com
              </a>
            </li>
            <li className="ms-3">
              <a
                aria-label="facebook"
                className="text-muted"
                href="https://www.facebook.com/FarmBureauInsuranceTN"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FacebookIcon color="white" />
              </a>
            </li>
            <li className="ms-3">
              <a
                aria-label="twitter"
                className="text-muted"
                href="https://twitter.com/FarmerCharlie"
                target="_blank"
                rel="noopener noreferrer"
              >
                <TwitterIcon color="white" />
              </a>
            </li>
          </ul>
        </footer>
      </div>
    </>
  );
}

IncompletePres.propTypes = {
  id: PropTypes.string,
};
