/* eslint-disable dot-notation */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setProviderAddedOrModified } from "features/Questionnaire/state/questionnaireSlice";
import EditAdditionalMedication from "../presentation/EditAdditionalMedication";
import AdditionalMedicationPres from "../presentation/AdditionalMedicationPres";
import {
  setShowMedEditForm,
  setAdditionalMedications,
} from "../state/medicationHistorySlice";

const AdditionalMedicationContainer = ({ id, index, value }) => {
  const { control, watch, reset, getValues, setValue } = useForm({
    mode: "all",
  });
  const formValues = watch();
  const dispatch = useDispatch();
  const { additionalMedications, showMedEditForm } = useSelector(
    (state) => state.medicationHistory
  );
  const medMenuObj = {
    showForm: additionalMedications[index].edit,
    option: showMedEditForm === "new" ? "new" : "edit",
  };
  const [medMenu, setMedMenu] = useState(medMenuObj);
  const showEditForm = () => {
    if (showMedEditForm === null) {
      reset({
        [`${id}-medication`]: additionalMedications[index].medication,
        [`${id}-reason`]: additionalMedications[index].reason,
        [`${id}-provider`]: additionalMedications[index].provider,
      });
      setMedMenu({
        ...medMenu,
        showForm: true,
        option: showMedEditForm === "new" ? "new" : "edit",
      });
      dispatch(setShowMedEditForm({ showMedEditForm: "edit" }));
    }
  };
  const deleteMed = (medication) => {
    const updatedMeds = [...additionalMedications];
    const x = updatedMeds.findIndex((m) => m === medication);
    updatedMeds.splice(x, 1);
    dispatch(setAdditionalMedications({ additionalMedications: updatedMeds }));
  };
  const hideEditForm = () => {
    if (showMedEditForm === "new") deleteMed(value);
    setMedMenu({ ...medMenu, showForm: false });
    if (showMedEditForm) {
      dispatch(setShowMedEditForm({ showMedEditForm: null }));
    }
  };

  const editMedication = () => {
    let med = {};
    const updatedMeds = [...additionalMedications];
    med = {
      medication: formValues[`${id}-medication`],
      reason: formValues[`${id}-reason`],
      provider: formValues[`${id}-provider`],
    };
    updatedMeds[index] = med;
    if (showMedEditForm) {
      dispatch(setShowMedEditForm({ showMedEditForm: null }));
    }
    dispatch(setAdditionalMedications({ additionalMedications: updatedMeds }));
    setMedMenu({
      ...medMenu,
      showForm: false,
      option: showMedEditForm === "new" ? "new" : "edit",
    });
  };

  const setValueInReduxResponses = () => {
    dispatch(setProviderAddedOrModified(true));
  };

  useEffect(() => {
    reset({
      [`${id}-medication`]: additionalMedications[index].medication,
      [`${id}-reason`]: additionalMedications[index].reason,
      [`${id}-provider`]: additionalMedications[index].provider,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMedEditForm]);

  return (
    <div>
      <AdditionalMedicationPres
        medication={value}
        showEditForm={showEditForm}
        deleteMedication={deleteMed}
        showMedEditForm={showMedEditForm}
      />
      {medMenu.showForm && showMedEditForm && (
        <EditAdditionalMedication
          option={medMenu.option}
          id={id}
          control={control}
          formValues={formValues}
          hideMedCard={hideEditForm}
          addMedicationHandler={editMedication}
          isValid={
            formValues[`${id}-medication`] &&
            formValues[`${id}-reason`] &&
            formValues[`${id}-provider`]
          }
          tunnelToParent={{
            setValueInReduxResponses,
            getValues,
            setValue,
          }}
        />
      )}
    </div>
  );
};

export default AdditionalMedicationContainer;
