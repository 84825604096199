const SessionStorageKeys = {
  ReduxState: "rState",
};

export const setItem = (key, val) => {
  sessionStorage.removeItem(key);
  sessionStorage.setItem(key, val);
};

export const getItem = (key) => {
  return sessionStorage.getItem(key);
};

export const saveReduxStateInLocalStorage = (val) => {
  setItem(SessionStorageKeys.ReduxState, JSON.stringify(val));
};

export const restoreStateFromLocalStorageToRedux = () => {
  const state = JSON.parse(getItem(SessionStorageKeys.ReduxState));
  return state;
};
