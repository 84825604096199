/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import ContentParser from "components/ContentParser";
import { useForm } from "react-hook-form";
import Button from "@mui/material/Button";

// import Result from "./HeartHealthChestPain.json";
import FBMonthYearPicker from "components/Elements/FBMonthYearPicker";
import FBHeight from "features/Height";
import Result from "./multiDateDescComponent.json";
// import Result from "./checkbox_dropdownRenderIssue.json";

export default function TestPageParser() {
  const {
    control,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const formValues = watch();
  useEffect(() => {
    reset({ renderpageHeight: "5ft-4in" });
  }, []);
  // const mockData = Result.questions[0].drilldowns[0].questions;
  const mockData = Result.questions;
  // const mockData = Result;
  // console.log("Mockdata >>>", mockData);
  const onChange = (data) => {
    // console.log("data >>> ", data);
    // const { formValues } = data;
    // console.log("transformedValues", formValues);
  };

  console.log("RenderPage >>>", errors, formValues);

  return (
    <div className="questionnaire-page-container">
      <div className="content-parser-container">
        <form>
          {/* <FBMonthYearPicker control={control} id="test" name="as" /> */}
          <FBHeight
            id="renderpageHeight"
            control={control}
            tunnelToParent={{ setValue }}
            // eslint-disable-next-line dot-notation
            value={formValues["renderpageHeight"]}
          />
          {/* <ContentParser
            key={1}
            pageId={1}
            numberOfColumns={mockData.numberOfColumns || 1}
            data={{ pageElements: mockData }}
            showLabels={false}
            debug={false}
            onChange={onChange}
            control={control}
            setValue={setValue}
          />
          <br />
          <br />

          <div className="row justify-content-center">
            <Button
              // disabled={!isValid}
              variant="contained"
              type="submit"
              className="FBMedFormFooterButton FBMedFormFooterButtonRight"
              onClick={(e) => {
                e.preventDefault();
                console.log("final form values", formValues);
              }}
            >
              Submit
            </Button>
          </div> */}
        </form>
      </div>
    </div>
  );
}
