import { memo } from "react";
import Button from "@mui/material/Button";

function UnauthorizedPres() {
  return (
    <div className="container-xl min-vh-100">
      <main>
        <div className="FBfamilyImage">
          <form className="d-none d-md-block pt-1">
            <div className="row row-cols-1 row-cols-md-1 row-cols-md-1 g-3 px-5 py-3">
              <h5 className="text-danger">
                You&apos;re one step closer to protecting the ones you love most
              </h5>
              <p>
                You can continue your Life Portrait by clicking on Access Life
                Portrait link <br /> from the email sent by your agent or
                contact your agent for help with access.
              </p>
            </div>
            <div className="px-5">
              {process.env.REACT_APP_DATA_API_URL.indexOf("-dev-") !== -1 && (
                <Button
                  href={`/?a=${process.env.REACT_APP_TEST_APP_ID}&d=${process.env.REACT_APP_TEST_DOC_ID}`}
                  variant="outlined"
                >
                  Test Portrait Link
                </Button>
              )}
            </div>
          </form>
        </div>
      </main>
      <div>
        <div className="row row-cols-1 row-cols-md-2 row-cols-md-2 g-3 px-2">
          <div className="col d-md-none bgSecondaryFBgrey">
            <div className="card-noborder">
              <div className="card-body">
                <h5 className="card-title">
                  <p className="text-danger">
                    You&apos;re one step closer to protecting the ones you love
                    most
                  </p>
                </h5>
                <p>
                  You can continue your Life Portrait by clicking on Access Life
                  Portrait link from the email sent by your agent or contact
                  your agent for help with access.
                </p>
                <p className="card-text">
                  {process.env.REACT_APP_DATA_API_URL.indexOf("-dev-") !==
                    -1 && (
                    <Button
                      href={`/?a=${process.env.REACT_APP_TEST_APP_ID}&d=${process.env.REACT_APP_TEST_DOC_ID}`}
                      variant="outlined"
                    >
                      Test Portrait Link
                    </Button>
                  )}
                </p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card-noborder">
              <div className="card-body">
                <h5 className="card-title">What is Life Portrait?</h5>
                <p className="card-text">
                  A Life Portrait is the next step in your application for Life
                  Insurance through Farm Bureau Insurance.
                </p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card-noborder">
              <div className="card-body">
                <h5 className="card-title">
                  What do I need to complete my Life Portrait?
                </h5>
                <span className="card-text">
                  <ul>
                    <li>A list of current medications that you take.</li>
                    <li>Contact information for doctors you have seen.</li>
                  </ul>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(UnauthorizedPres);
