import React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Typography } from "@mui/material";

const QuestionnaireGroupCompleted = ({
  currentGroup,
  gotoNextGroup,
  nextGroup,
  disableRouteToReviewNSubmit,
}) => {
  return (
    <div className="bgSecondaryFBgrey">
      <div className="container-fluid min-vh-100 py-3">
        <form>
          <div className="shadow-sm p-3 mb-3 bg-white rounded">
            <div className="d-flex justify-content-center">
              <CheckCircleIcon fontSize="large" style={{ fill: "#4caf50" }} />
            </div>
            <h3 className="d-flex justify-content-center py-3">
              You’ve completed the {currentGroup} section!
            </h3>
            <div className="d-flex justify-content-center">
              <h5>
                You’ll get a chance to review all your information in the
                application before submitting.
              </h5>
            </div>
            {disableRouteToReviewNSubmit && (
              <div className="d-flex justify-content-center mt-2">
                <Typography variant="subtitle2">
                  Navigation to Review and Submit is only possible once all the
                  sections are answered.
                </Typography>
              </div>
            )}
          </div>
        </form>
        <div className="d-flex justify-content-center py-2 px-2">
          <Button
            variant="contained"
            onClick={gotoNextGroup}
            disabled={disableRouteToReviewNSubmit}
          >
            Next Section: {nextGroup}
          </Button>
        </div>
      </div>
    </div>
  );
};

QuestionnaireGroupCompleted.propTypes = {
  currentGroup: PropTypes.oneOfType([PropTypes.string, undefined]),
  gotoNextGroup: PropTypes.func,
  nextGroup: PropTypes.oneOfType([PropTypes.string, undefined]),
  disableRouteToReviewNSubmit: PropTypes.bool,
};

export default QuestionnaireGroupCompleted;
