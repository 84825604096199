/* eslint-disable no-restricted-syntax */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import { useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";

import { useEffect } from "react";
import SiblingListPresentation from "../presentation/SiblingListPresentation";
import SiblingFormPresentation from "../presentation/SiblingFormPresentation";

export default function SiblingContainer(props) {
  const {
    member,
    medicalConditions,
    cboConditionsList,
    siblingTypes,
    aliveDeceasedTypes,
    setSiblings,
    LivingEnum,
    setSiblingsHandler,
    showSiblingForm,
    toggleShowSiblingFormHandler,
    siblingRecord,
    setSiblingRecordHandler,
    FormActions,
    previousHandler,
  } = props;

  const { familyMember, responses } = member;
  const { siblings } = responses || [];
  const { control, watch, reset, setValue } = useForm({ mode: "all" });
  const formSiblingValues = watch();

  useEffect(() => {
    if (
      !siblings ||
      (siblings && Array.isArray(siblings) && siblings.length === 0)
    ) {
      toggleShowSiblingFormHandler(undefined, true);
    }
  }, []);

  const siblingAlive =
    formSiblingValues[`${familyMember}_livingStatus`] || undefined;
  const siblingAliveUnknownInfo =
    formSiblingValues[`${familyMember}_doNotHaveInfo`];
  let showDetails = !siblingAliveUnknownInfo;
  if (showDetails) {
    showDetails =
      formSiblingValues[`${familyMember}_livingStatus`] || undefined;
  }

  const addSiblingHandler = () => {
    // const id = Math.floor(Math.random() * 50);
    let updatedSiblings = [];
    if (siblingRecord.action === FormActions.Add) {
      const id = uuidv4();
      const objectArr = [
        {
          id,
          ...formSiblingValues,
        },
      ];
      updatedSiblings = (siblings && siblings.concat(objectArr)) || objectArr;
    } else if (siblingRecord.action === FormActions.Edit) {
      updatedSiblings = siblings.map((sibling) => {
        return sibling.id === siblingRecord.id
          ? { ...sibling, ...formSiblingValues }
          : sibling;
      });
    }

    setSiblings("siblings", updatedSiblings);
    setSiblingsHandler(updatedSiblings);

    toggleShowSiblingFormHandler();
  };

  const addAnotherSiblingHandler = () => {
    setSiblingRecordHandler({
      action: FormActions.Add,
      id: undefined,
    });
    toggleShowSiblingFormHandler();
    reset({});
  };

  const deleteSiblingHandler = (e, id) => {
    e.preventDefault();
    let updatedSiblings = [];
    updatedSiblings = siblings.filter((sibling) => {
      return !(sibling.id === id);
    });
    setSiblings("siblings", updatedSiblings);
    setSiblingsHandler(updatedSiblings);
    if (updatedSiblings && updatedSiblings.length === 0) {
      addAnotherSiblingHandler();
    }
  };

  const medicalHistoryConditionChangeHandler = (e, medId) => {
    const formResponses = { ...formSiblingValues };
    if (
      (medId === `${familyMember}_medHist_iDontKnow` ||
        medId === `${familyMember}_medHist_noneOfTheAbove`) &&
      e
    ) {
      for (const [key, value] of Object.entries(formResponses)) {
        if (
          (key !== `${familyMember}_medHist_iDontKnow` ||
            key !== `${familyMember}_medHist_noneOfTheAbove`) &&
          key.startsWith(`${familyMember}_medHist_`) &&
          value
        ) {
          if (
            key.endsWith("_TypeOfCancer") ||
            key.endsWith("_AgeAtDiagnosis")
          ) {
            setValue(key, "");
          } else setValue(key, false);
        }
      }
    }
    if (
      (medId !== `${familyMember}_medHist_iDontKnow` ||
        medId !== `${familyMember}_medHist_noneOfTheAbove`) &&
      e
    ) {
      for (const [key, value] of Object.entries(formResponses)) {
        if (
          (key === `${familyMember}_medHist_iDontKnow` ||
            key === `${familyMember}_medHist_noneOfTheAbove`) &&
          value
        ) {
          if (
            key.endsWith("_TypeOfCancer") ||
            key.endsWith("_AgeAtDiagnosis")
          ) {
            setValue(key, "");
          } else setValue(key, false);
        }
      }
    }
  };

  const getDisableStateForContinueBtn = () => {
    let disableSiblingModifyBtn = true;

    if (
      formSiblingValues[`${familyMember}_type`] &&
      formSiblingValues[`${familyMember}_type`] !== ""
    ) {
      if (formSiblingValues[`${familyMember}_doNotHaveInfo`] === true) {
        disableSiblingModifyBtn = false;
      }

      if (formSiblingValues[`${familyMember}_livingStatus`] !== "") {
        let ageFlag = true;
        if (
          formSiblingValues[`${familyMember}_age`] !== "" ||
          formSiblingValues[`${familyMember}_doNotHaveAgeInfo`] === true
        ) {
          ageFlag = false;
        }

        let primaryCauseOfDeathFlag = false;
        const primaryCauseOfDeathComplete = !(
          formSiblingValues[`${familyMember}_primaryCauseOfDeath`] ===
            undefined ||
          formSiblingValues[`${familyMember}_primaryCauseOfDeath`] === "" ||
          formSiblingValues[`${familyMember}_primaryCauseOfDeath`] === null
        );
        if (
          formSiblingValues[`${familyMember}_livingStatus`] === "Deceased" &&
          !primaryCauseOfDeathComplete
        ) {
          primaryCauseOfDeathFlag = true;
        }

        if (
          formSiblingValues[`${familyMember}_livingStatus`] === "Deceased" &&
          primaryCauseOfDeathComplete
        ) {
          if (
            (formSiblingValues[`${familyMember}_primaryCauseOfDeath`] ===
              "Other" ||
              formSiblingValues[`${familyMember}_primaryCauseOfDeath`] ===
                "Cancer") &&
            formSiblingValues[`${familyMember}_primaryCauseOfDeathDetail`] ===
              ""
          ) {
            primaryCauseOfDeathFlag = true;
          } else primaryCauseOfDeathFlag = false;
        }

        let medConditionFlag = true;

        const atleast1Itemchecked = medicalConditions.some(
          (el) => formSiblingValues[`${familyMember}_medHist_${el.id}`] === true
        );

        if (atleast1Itemchecked) {
          const medArr = medicalConditions.filter(
            (el) =>
              (el.id === "heartDisease" || el.id === "cancer") &&
              formSiblingValues[`${familyMember}_medHist_${el.id}`] === true
          );

          if (medArr.length === 0) {
            medConditionFlag = false;
          } else {
            const isIncomplete = medArr.every((el) => {
              if (
                el.id === "heartDisease" &&
                formSiblingValues[`${familyMember}_medHist_${el.id}`] === true
              ) {
                if (
                  formSiblingValues[
                    `${familyMember}_medHist_${el.id}_AgeAtDiagnosis`
                  ] === undefined &&
                  formSiblingValues[
                    `${familyMember}_medHist_${el.id}_UnKnownAge`
                  ] === undefined
                ) {
                  return false;
                }
                if (
                  formSiblingValues[
                    `${familyMember}_medHist_${el.id}_AgeAtDiagnosis`
                  ] === "" &&
                  formSiblingValues[
                    `${familyMember}_medHist_${el.id}_UnKnownAge`
                  ] === false
                ) {
                  return false;
                }
                return true;
              }

              if (
                el.id === "cancer" &&
                formSiblingValues[`${familyMember}_medHist_${el.id}`] === true
              ) {
                if (
                  formSiblingValues[
                    `${familyMember}_medHist_${el.id}_TypeOfCancer`
                  ] === "" ||
                  formSiblingValues[
                    `${familyMember}_medHist_${el.id}_TypeOfCancer`
                  ] === undefined
                ) {
                  return false;
                }
                return true;
              }

              return false;
            });
            medConditionFlag = !isIncomplete;
          }
        }

        disableSiblingModifyBtn =
          ageFlag || primaryCauseOfDeathFlag || medConditionFlag;
      }
    }
    return disableSiblingModifyBtn;
  };

  const cancelBtnHandler = () => {
    toggleShowSiblingFormHandler(undefined, false);
    if (!siblings || (Array.isArray(siblings) && siblings.length === 0)) {
      previousHandler();
    }
  };

  return (
    <div className="bgSecondaryFBgrey py-3">
      <div className="">
        <div className="d-flex justify-content-center mb-3">
          Add each biological sibling and their medical history one at a time
          below
        </div>
        {siblings && Array.isArray(siblings) && siblings.length > 0 && (
          <SiblingListPresentation
            familyMember={familyMember}
            siblings={siblings}
            toggleShowSiblingFormHandler={toggleShowSiblingFormHandler}
            siblingRecord={siblingRecord}
            setSiblingRecordHandler={setSiblingRecordHandler}
            FormActions={FormActions}
            reset={reset}
            deleteSiblingHandler={deleteSiblingHandler}
            showSiblingForm={showSiblingForm}
            addAnotherSiblingHandler={addAnotherSiblingHandler}
          />
        )}

        {showSiblingForm && (
          <SiblingFormPresentation
            familyMember={familyMember}
            // handleSubmit={handleSubmit}
            // onSubmit={onSubmit}
            control={control}
            siblingTypes={siblingTypes}
            aliveDeceasedTypes={aliveDeceasedTypes}
            showDetails={showDetails}
            siblingAlive={siblingAlive}
            LivingEnum={LivingEnum}
            cboConditionsList={cboConditionsList}
            medicalConditions={medicalConditions}
            formSiblingValues={formSiblingValues}
            disableSiblingModifyBtn={getDisableStateForContinueBtn()}
            addSiblingHandler={addSiblingHandler}
            siblingRecord={siblingRecord}
            FormActions={FormActions}
            cancelBtnHandler={cancelBtnHandler}
            medicalHistoryConditionChangeHandler={
              medicalHistoryConditionChangeHandler
            }
            setValue={setValue}
          />
        )}
      </div>
    </div>
  );
}
