/* eslint-disable prefer-const */

/* Controls used from controls/ */
import FBYesNoToggleButton from "components/Elements/FBYesNoToggleButton";
import FBComboBox from "components/Elements/FBComboBox";
import FBDatePicker from "components/Elements/FBDatePicker";
import FBTextBox from "components/Elements/FBTextBox";
import FBMonthYearPicker from "components/Elements/FBMonthYearPicker";
import FBRadioGroup from "components/Elements/FBRadioGroup";
import FBNumber from "components/Elements/FBNumber";
import FBPhysician from "features/Provider";
import FBMedication from "features/Medication";
import FBCheckbox from "components/Elements/FBCheckbox";
import FBCaption from "components/Elements/FBCaption";
import FBMultiDateDesc from "features/MultiDateDesc";
import FBHeartAttack from "features/HeartAttack";
import FBMultiAbnormalPaps from "features/MultiAbnormalPaps";
import FBHeight from "features/Height";

const ContentControls = {
  Caption: {
    fullWidth: true,
    ignoreFormValue: true,
    component: FBCaption,
  },
  DropDown: {
    component: FBComboBox,
  },
  YesNo: {
    component: FBYesNoToggleButton,
  },
  Date: {
    component: FBDatePicker,
  },
  DateMonthYear: {
    component: FBMonthYearPicker,
  },
  Text: {
    component: FBTextBox,
  },
  Numeric: {
    component: FBNumber,
  },
  Radio: {
    component: FBRadioGroup,
  },
  Checkbox: {
    component: FBCheckbox,
  },
  HeartAttacks: {
    component: FBHeartAttack,
  },
  Medication: {
    component: FBMedication,
  },
  MultiDateDesc: {
    component: FBMultiDateDesc,
  },
  MultiAbnormalPaps: {
    component: FBMultiAbnormalPaps,
  },
  Provider: {
    component: FBPhysician,
  },
  Height: {
    component: FBHeight,
  },
};

function translateToProps(data) {
  let { id, value, caption, input, note } = data;

  const { required, options, placeholder, label, dataType } = input;
  const { format, listItems, multiline, currency } = options || {};
  const controlProps = {
    id,
    label: dataType === "Checkbox" ? caption : label,
    caption,
    placeholder,
    name: id,
    required,
    value,
    multiline,
    format,
    listItems,
    note,
    currency,
  };

  return controlProps;
}

function getContentControl(name, readOnlyControl = false, debug = false) {
  if (!ContentControls[name] && debug) {
    console.warn(`ContentParser: Unknown control type ${name}`);
    return null;
  }
  const controlName = readOnlyControl ? "ReadOnly" : name;
  return ContentControls[controlName];
}

export { getContentControl, translateToProps };
