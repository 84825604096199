import { CircularProgress } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export default function RedirectToDocusignPres() {
  return (
    <div className="bg-light min-vh-100">
      <div className="container">
        <div className="py-3">
          <form className="shadow bg-body rounded">
            <div className="card-body">
              <center>
                <h5 className="card-title">
                  Redirecting to DocuSign
                  &nbsp;<ArrowForwardIcon />&nbsp;<ArrowForwardIcon />&nbsp;<ArrowForwardIcon />
                </h5>
                <p>
                  <CircularProgress color="inherit" />
                </p>
              </center>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
