import { createSlice } from "@reduxjs/toolkit";
import states from "../questionnaires/us-states.json";

const initialState = {
  isLoading: true,
  reviewChecked: false,
  authToken: null,
  isAuthenticated: false,
  documentId: "",
  applicationId: "",
  mode: "client",
  phoneNumber: "",
  prefix: null,
  firstName: null,
  middleName: null,
  lastName: null,
  suffix: null,
  birthDate: null,
  status: null,
  questionnaireMetadata: null,
  questionnaire: null,
  questionnaireResponse: {
    providers: [],
    agent: null,
  },
  states,
  showNeedHelp: false,
};

const lifePortraitSlice = createSlice({
  name: "lifePortrait",
  initialState,
  reducers: {
    setPhoneNumber(state, action) {
      const { phoneNumber, status } = action.payload;
      state.phoneNumber = phoneNumber;
      state.status = status;
    },
    setAuthToken(state, action) {
      const { authToken } = action.payload;
      state.authToken = authToken;
    },
    setSAMAccountName(state, action) {
      const { SAMaccountName } = action.payload;
      state.SAMaccountName = SAMaccountName;
      state.isAuthenticated = true;
    },
    setMode(state, action) {
      const { mode } = action.payload;
      state.mode = mode;
    },
    setPortraitIds(state, action) {
      const { applicationId, documentId } = action.payload;
      state.applicationId = applicationId;
      state.documentId = documentId;
    },
    setPortraitData(state, action) {
      state.questionnaireResponse = action.payload.portrait;
    },
    setPortraitGroupData(state, action) {
      const { id: groupId } = action.payload;
      const portrait = state.questionnaireResponse;
      const group = portrait.groups.find((g) => g.id === groupId);
      if (group) {
        group.status = action.payload.status;
        group.details = action.payload.details;
      } else {
        portrait.groups.push(action.payload);
      }
    },
    setPortraitSummary(state, action) {
      const {
        prefix,
        firstName,
        middleName,
        lastName,
        suffix,
        birthDate,
        status,
      } = action.payload;
      state.prefix = prefix;
      state.firstName = firstName;
      state.middleName = middleName;
      state.lastName = lastName;
      state.suffix = suffix;
      state.birthDate = birthDate;
      state.status = status;
    },
    setPortraitStatus(state, action) {
      const { status } = action.payload;
      state.questionnaireResponse.status = status;
    },
    setPortraitQuestionnaireMetadata(state, action) {
      state.questionnaireMetadata = action.payload.questionnaireMetadata;
    },
    setLifePortraitQuestionnaire(state, action) {
      state.questionnaire = action.payload.questionnaire;
    },
    setLifePortraitProviders(state, action) {
      state.questionnaireResponse.providers = action.payload.providers;
    },
    setLifePortraitAgent(state, action) {
      state.questionnaireResponse.agent = action.payload.agent;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload.isLoading;
    },
    setReviewChecked(state, action) {
      state.reviewChecked = action.payload.reviewChecked;
    },
    setLifePortraitState(state, action) {
      const {
        authToken,
        reviewChecked,
        isAuthenticated,
        SAMaccountName,
        documentId,
        applicationId,
        mode,
        // phoneNumber,
        prefix,
        firstName,
        middleName,
        lastName,
        suffix,
        birthDate,
        status,
      } = action.payload;

      state.authToken = authToken;
      state.reviewChecked = reviewChecked;
      state.isAuthenticated = isAuthenticated;
      state.SAMaccountName = SAMaccountName;
      state.documentId = documentId;
      state.applicationId = applicationId;
      state.mode = mode;
      // state.phoneNumber = phoneNumber;
      state.prefix = prefix;
      state.firstName = firstName;
      state.middleName = middleName;
      state.lastName = lastName;
      state.suffix = suffix;
      state.birthDate = birthDate;
      state.status = status;
    },
    setShowNeedHelp(state, action) {
      state.showNeedHelp = action.payload.showNeedHelp;
    },
  },
});
export const {
  setPhoneNumber,
  setAuthToken,
  setMode,
  setPortraitIds,
  setPortraitData,
  setPortraitGroupData,
  setPortraitSummary,
  setPortraitStatus,
  setPortraitQuestionnaireMetadata,
  setLifePortraitQuestionnaire,
  setLifePortraitProviders,
  setLifePortraitAgent,
  setIsLoading,
  setReviewChecked,
  setSAMAccountName,
  setLifePortraitState,
  setShowNeedHelp,
} = lifePortraitSlice.actions;

export const getQuestionnaireId = () => (state) => {
  return state.lifePortrait?.questionnaire?.id;
};
export const getPhoneNumber = () => (state) => {
  return state.lifePortrait.phoneNumber;
};
export const getAuthToken = () => (state) => {
  return state.lifePortrait.authToken;
};
export const getPortraitIds = () => (state) => {
  const { applicationId, documentId } = state.lifePortrait;
  return { applicationId, documentId };
};

export default lifePortraitSlice.reducer;
