import React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { FormControlLabel } from "@mui/material";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";

export default function FBRadioGroup(props) {
  const {
    control,
    id,
    label,
    required,
    defaultValue,
    value: propValue,
    disabled,
    error: propError,
    helperText,
    placeholder,
    controlWidth,
    items,
    onChange: onInputChange,
  } = props;

  return (
    <Controller
      name={id}
      control={control}
      defaultValue={defaultValue || propValue || ""}
      rules={{
        required: { value: required, message: `${label} is required` },
      }}
      render={({ field, fieldState: { error = propError } }) => {
        if (field.value === undefined || field.value === "") {
          // eslint-disable-next-line no-param-reassign
          field.value = propValue || "";
        }
        return (
          <RadioGroup
            row
            id={id}
            name={id}
            required={required}
            label={label}
            value={propValue || ""}
            disabled={disabled}
            error={error || ""}
            helpertext={error?.message || helperText}
            placeholder={placeholder}
            sx={{ width: controlWidth }}
            onChange={(e) => {
              field.onChange(e);
              if (onInputChange) onInputChange(e.target.value);
            }}
          >
            {items.map((item) => (
              <FormControlLabel
                key={item.value}
                value={item.value}
                control={<Radio />}
                label={item.label}
              />
            ))}
          </RadioGroup>
        );
      }}
    />
  );
}

FBRadioGroup.propTypes = {
  control: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  placeholder: PropTypes.string,
  controlWidth: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  onChange: PropTypes.func,
};

FBRadioGroup.defaultProps = {
  label: "",
  required: false,
  defaultValue: undefined,
  value: undefined,
  disabled: false,
  error: false,
  helperText: undefined,
  placeholder: undefined,
  controlWidth: "220px",
  onChange: undefined,
};
