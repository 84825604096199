import * as React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";

export default function FBTextBox(props) {
  const {
    control,
    id,
    label,
    required,
    defaultValue,
    value: propValue,
    autoFocus,
    disabled,
    error: propError,
    helperText,
    placeholder,
    controlWidth,
    onChange: onInputChange,
    name,
    multiline,
    patternRules,
  } = props;
  return (
    <Controller
      name={id}
      control={control}
      defaultValue={defaultValue || propValue || ""}
      rules={{
        required: { value: required, message: `${label} is required` },
        pattern: patternRules,
      }}
      render={({ field, fieldState: { error = propError } }) => {
        if (field.value === undefined || field.value === "") {
          // eslint-disable-next-line no-param-reassign
          field.value = propValue || "";
        }
        return (
          <TextField
            id={id}
            required={required}
            label={label}
            value={propValue}
            autoFocus={autoFocus}
            disabled={disabled}
            error={!!error}
            helperText={error ? error.message || helperText : helperText}
            placeholder={placeholder}
            size="small"
            variant="outlined"
            sx={{ width: controlWidth, "& legend": { width: "auto" } }}
            onChange={(e) => {
              field.onChange(e);
              if (onInputChange) onInputChange(e.target.value);
            }}
            name={name}
            multiline={multiline}
            // {...other}
          />
        );
      }}
    />
  );
}

FBTextBox.propTypes = {
  control: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  placeholder: PropTypes.string,
  controlWidth: PropTypes.string,
  onChange: PropTypes.func,
  multiline: PropTypes.bool,
  patternRules: PropTypes.object,
};

FBTextBox.defaultProps = {
  label: "",
  required: false,
  defaultValue: undefined,
  value: undefined,
  autoFocus: false,
  disabled: false,
  error: false,
  helperText: undefined,
  placeholder: undefined,
  controlWidth: "250px",
  onChange: undefined,
  multiline: undefined,
};
