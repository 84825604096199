import serviceUrls from "./apiConfig/serviceUrls";
import { axiosRequest } from "./apiConfig/axiosRequest";

const fakeServerUrl = `http://localhost:4000`;

export const getDashboardPortraitsFromFakeSvc = (
  request,
  navigationCallBack
) => {
  const { page, pageSize } = request;
  let urlSegment = "";

  if (page || page === 0) urlSegment += `page=${page + 1}`;
  if (pageSize) urlSegment += `&pageSize=${pageSize}`;
  const urlEndpoint = `${fakeServerUrl}/portraits?${urlSegment}`;

  return axiosRequest(
    navigationCallBack,
    "GET",
    urlEndpoint,
    request.group,
    null,
    null,
    null
  );
};

export const getDashboardPortraitsSvc = (payload, navigationCallBack) => {
  const urlEndpoint = serviceUrls.AGENT_DASHBOARD_PORTRAIT_SEARCH;

  return axiosRequest(
    navigationCallBack,
    "post",
    urlEndpoint,
    payload,
    null,
    null,
    null
  );
};

export const getDashboardSnapshotSvc = (payload, navigationCallBack) => {
  const urlEndpoint = serviceUrls.AGENT_DASHBOARD_PORTRAIT_COUNTS;

  return axiosRequest(
    navigationCallBack,
    "post",
    urlEndpoint,
    payload,
    null,
    null,
    null
  );
};

export const getPortraitGroupSummarySvc = (params, navigationCallBack) => {
  let urlEndpoint = serviceUrls.AGENT_DASHBOARD_PORTRAIT_GROUP_SUMMARY;
  urlEndpoint = urlEndpoint
    .replace(":appId", params.applicationId)
    .replace(":docId", params.documentId);
  return axiosRequest(
    navigationCallBack,
    "get",
    urlEndpoint,
    null,
    null,
    null,
    null
  );
};

export const getPortraitClientsSvc = (params, navigationCallBack) => {
  let urlEndpoint = serviceUrls.AGENT_DASHBOARD_PORTRAIT_CLIENT_GET;
  urlEndpoint = urlEndpoint
    .replace(":appId", params.applicationId)
    .replace(":docId", params.documentId);

  return axiosRequest(
    navigationCallBack,
    "get",
    urlEndpoint,
    null,
    null,
    null,
    null
  );
};

export const updatePortraitClientsSvc = (
  params,
  payload,
  navigationCallBack
) => {
  let urlEndpoint = serviceUrls.AGENT_DASHBOARD_PORTRAIT_CLIENT_UPDATE;
  urlEndpoint = urlEndpoint
    .replace(":appId", params.applicationId)
    .replace(":docId", params.documentId);

  return axiosRequest(
    navigationCallBack,
    "put",
    urlEndpoint,
    payload,
    null,
    null,
    null
  );
};

export const transferPortraitSvc = (params, payload, navigationCallBack) => {
  let urlEndpoint = serviceUrls.AGENT_DASHBOARD_PORTRAIT_TRANSFER;
  urlEndpoint = urlEndpoint
    .replace(":appId", params.applicationId)
    .replace(":docId", params.documentId);

  return axiosRequest(
    navigationCallBack,
    "put",
    urlEndpoint,
    payload,
    null,
    null,
    null
  );
};

export const transferPortraitClientEmailSvc = (
  params,
  payload,
  navigationCallBack
) => {
  let urlEndpoint =
    serviceUrls.AGENT_DASHBOARD_PORTRAIT_TRANSFER_EMAIL_TO_CLIENT;
  urlEndpoint = urlEndpoint
    .replace(":appId", params.applicationId)
    .replace(":docId", params.documentId);

  return axiosRequest(
    navigationCallBack,
    "put",
    urlEndpoint,
    payload,
    null,
    null,
    null
  );
};

export const createEnvelopeSvc = (param, navigationCallBack) => {
  let urlEndpoint = serviceUrls.AGENT_DASHBOARD_PORTRAIT_CREATE_ENVELOPE;
  const { payload, applicationId, documentId } = param;
  urlEndpoint = urlEndpoint
    .replace(":appId", applicationId)
    .replace(":docId", documentId);
  return axiosRequest(
    navigationCallBack,
    "post",
    urlEndpoint,
    payload,
    null,
    null,
    null
  );
};

export const getPortraitSignatureStatusSvc = (params, navigationCallBack) => {
  let urlEndpoint = serviceUrls.AGENT_DASHBOARD_PORTRAIT_SIGNATURE_STATUS;
  urlEndpoint = urlEndpoint
    .replace(":appId", params.applicationId)
    .replace(":docId", params.documentId);
  return axiosRequest(
    navigationCallBack,
    "get",
    urlEndpoint,
    null,
    null,
    null,
    null
  );
};

export const getPortraitAgentSigningUrlSvc = (params, navigationCallBack) => {
  let urlEndpoint = serviceUrls.AGENT_DASHBOARD_PORTRAIT_AGENT_SIGNING_URL;
  urlEndpoint = urlEndpoint
    .replace(":appId", params.applicationId)
    .replace(":docId", params.documentId);
  return axiosRequest(
    navigationCallBack,
    "get",
    urlEndpoint,
    null,
    null,
    null,
    null
  );
};

export const updateRecipientSvc = (param, navigationCallBack) => {
  let urlEndpoint = serviceUrls.AGENT_DASHBOARD_PORTRAIT_ENVELOPE_UPDRECIPIENT;
  const { payload, applicationId, documentId } = param;
  urlEndpoint = urlEndpoint
    .replace(":appId", applicationId)
    .replace(":docId", documentId);
  return axiosRequest(
    navigationCallBack,
    "post",
    urlEndpoint,
    payload,
    null,
    null,
    null
  );
};

export const voidEnvelopeSvc = (param, navigationCallBack) => {
  let urlEndpoint = serviceUrls.AGENT_DASHBOARD_PORTRAIT_ENVELOPE_VOID;
  const { applicationId, documentId } = param;
  urlEndpoint = urlEndpoint
    .replace(":appId", applicationId)
    .replace(":docId", documentId);
  return axiosRequest(
    navigationCallBack,
    "post",
    urlEndpoint,
    null,
    null,
    null,
    null
  );
};

export const downloadPortraitPDFSvc = (param, navigationCallBack) => {
  let urlEndpoint = serviceUrls.AGENT_DASHBOARD_PORTRAIT_PRINTPDF;
  const { applicationId, documentId } = param;
  urlEndpoint = urlEndpoint
    .replace(":appId", applicationId)
    .replace(":docId", documentId);
  return axiosRequest(
    navigationCallBack,
    "get",
    urlEndpoint,
    null,
    { responseType: "blob" },
    null,
    null,
    null
  );
};

export const updatePortraitArchiveSvc = (request, navigationCallBack) => {
  const { applicationId, documentId } = request;
  let urlEndpoint = serviceUrls.PORTRAIT_ARCHIVE;
  urlEndpoint = urlEndpoint
    .replace(":appId", applicationId)
    .replace(":docId", documentId);

  return axiosRequest(
    navigationCallBack,
    "POST",
    urlEndpoint,
    request.archive,
    null,
    null,
    null
  );
};

export const getDashboardPermissions = (user, navigationCallBack) => {
  const urlEndpoint = serviceUrls.AGENT_DASHBOARD_PORTRAIT_PERMISSIONS;
  return axiosRequest(
    navigationCallBack,
    "GET",
    urlEndpoint,
    { user },
    null,
    null,
    null
  );
};
