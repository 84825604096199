/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-array-index-key */
import React from "react";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import Chip from "@mui/material/Chip";
import { propNames } from "util/medicationHelper";
import { Stack, Typography } from "@mui/material";

const PortraitMedHistoryPres = ({ id, medications, handleEditClick }) => {
  return (
    medications.map((el) => {
      return (
        <Stack direction="row">
          <Typography sx={{ marginY: 'auto' }}>{`${el[`${id}-${propNames.medication}`]}`}</Typography>
          {el[`${id}-${propNames.current}`] === "Yes" ? (
            <Chip
              label="current"
              color="success"
              variant="outlined"
              size="small"
              sx={{ marginY: 'auto', ml: 2 }}
            />
          ) : null}
          <IconButton
            size="small"
            onClick={(event) => handleEditClick(event, el, "edit")}
            sx={{ ml: 1 }}
          >
            <EditIcon size="small" />
          </IconButton>
        </Stack>
      );
    })
  );
};

PortraitMedHistoryPres.propTypes = {
  id: PropTypes.string,
  medications: PropTypes.any,
  handleEditClick: PropTypes.func,
};

export default PortraitMedHistoryPres;
