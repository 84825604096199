/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Badge, Box, Typography } from "@mui/material";
import FBNumber from "components/Elements/FBNumber";
import FBMonthYearPicker from "components/Elements/FBMonthYearPicker";
// import FBComboBox from "components/Elements/FBComboBox";
import FBProvider from "features/Provider";
import FBTextBox from "components/Elements/FBTextBox";

import {
  transformInput,
  transformOutput,
  propNames,
} from "util/multiDateDescHelper";
import { idComplete } from "util/genericHelper";

// let renderCount = 0;
const FBMultiDateDescLanding = (props) => {
  // renderCount += 1;
  const lblComplete = idComplete;
  const { id, required, value, tunnelToParent, options, label } = props;
  const {
    setValue: parentSetValue,
    setValueInReduxResponses,
    getValues,
  } = tunnelToParent;
  // console.log("props >>", props);
  const {
    overrideDefaultOrder,
    dateShow,
    dateCaption,
    text1Show,
    text1Caption,
    text2Show,
    text2Caption,
    text3Show,
    text3Caption,
    providerShow,
    providerCaption,
    itemCaption,
  } = {
    overrideDefaultOrder: false,
    dateShow: true,
    dateCaption: "When did they occur?",
    text1Show: false,
    text1Caption: "Text 1 question?",
    text2Show: false,
    text2Caption: "Text 2 question?",
    text3Show: false,
    text3Caption: "Text 3 question?",
    providerShow: true,
    providerCaption: "What hospital?",
    itemCaption: "Hospitalization",
    ...options,
  };

  const { control, watch, reset, setValue } = useForm({
    mode: "all",
  });

  const formValues = watch();

  const [numberOfTimes, setNumberOfTimes] = useState(null);

  useEffect(() => {
    if (value) {
      const {
        numberOfTimes: lnumberOfTimes,
        responses,
        isComplete,
      } = transformInput(id, value, options);
      const evalnum = parseInt(lnumberOfTimes, 10);
      // eslint-disable-next-line no-restricted-globals
      if (typeof evalnum === "number" && !isNaN(evalnum)) {
        setNumberOfTimes(evalnum);
        // if (lnumberOfTimes) setValue(id, value);
        reset(responses);
      }
      parentSetValue(`${id}${lblComplete}`, isComplete);
      // setValue(id, value);
    }
  }, [id]);

  const transformAndUpdateParent = (lid, lnumberOfTimes, lformValues) => {
    const { transformedValue, isComplete } = transformOutput(
      lid,
      lnumberOfTimes,
      lformValues,
      options
    );
    const custTransValue =
      transformedValue === "[null]" ? undefined : transformedValue;
    if (parentSetValue) {
      parentSetValue(`${id}${lblComplete}`, isComplete);
      parentSetValue(id, custTransValue, {
        shouldDirty: true,
      });
    }
    if (setValueInReduxResponses) {
      setValueInReduxResponses(id, custTransValue, getValues);
    }
  };

  const numberOfTimesChangeHandler = (e) => {
    let lnoOfTimes = e === "" || e === undefined ? null : parseInt(e, 10);
    // Following condition always restricts the use to 10 items
    if (typeof lnoOfTimes === "number" && lnoOfTimes >= 10) lnoOfTimes = 10;
    setNumberOfTimes(lnoOfTimes);
    transformAndUpdateParent(id, lnoOfTimes, formValues);
  };

  const inputChangeHandler = (e, controlId) => {
    transformAndUpdateParent(id, numberOfTimes, {
      ...formValues,
      [controlId]: e,
    });
  };

  const getDateInput = (index) => {
    if (!dateShow) return false;
    return (
      <>
        <Typography color="text.secondary" variant="body2" gutterBottom>
          {dateCaption}
        </Typography>
        <FBMonthYearPicker
          id={`${id}-${index}-${propNames.date}`}
          control={control}
          required={required}
          onChange={(e) => {
            inputChangeHandler(e, `${id}-${index}-date`);
          }}
          value={formValues[`${id}-${index}-${propNames.date}`]}
        />
        <br />
      </>
    );
  };

  const getText1Input = (index) => {
    if (!text1Show) return false;
    return (
      <>
        <Typography color="text.secondary" variant="body2" gutterBottom>
          {text1Caption}
        </Typography>
        <FBTextBox
          id={`${id}-${index}-${propNames.text1}`}
          control={control}
          required={required}
          onChange={(e) => {
            inputChangeHandler(e, `${id}-${index}-${propNames.text1}`);
          }}
          value={formValues[`${id}-${index}-${propNames.text1}`]}
        />
      </>
    );
  };

  const controlOrder = (index) => {
    if (overrideDefaultOrder) {
      return (
        <>
          {getText1Input(index)}
          {getDateInput(index)}
        </>
      );
    }
    return (
      <>
        {getDateInput(index)}
        {getText1Input(index)}
      </>
    );
  };

  return (
    <Box
      sx={{
        width: "fit-content",
        border: 1,
        padding: 1,
        borderColor: "lightslategrey",
        borderRadius: 2,
      }}
    >
      {/* {`Rendercount:${renderCount}`} */}
      <Typography color="text.secondary" variant="body2" gutterBottom>
        {label}
      </Typography>
      <FBNumber
        id={`${id}-${propNames.numberOfTimes}`}
        required={required}
        control={control}
        onChange={numberOfTimesChangeHandler}
        value={numberOfTimes}
      />
      <br />
      {typeof numberOfTimes === "number" &&
        numberOfTimes > 0 &&
        [...Array(numberOfTimes)].map((el, index) => {
          return (
            <Box
              key={`${id}-${index}`}
              sx={{
                marginTop: "10px",
                // marginBottom: "5px",
                width: "fit-content",
                border: 1,
                padding: 1,
                borderColor: "lightslategrey",
                borderRadius: 2,
              }}
            >
              <Typography variant="subtitle1" gutterBottom>
                <Badge>{`${itemCaption}-${index + 1}`}</Badge>
              </Typography>
              {controlOrder(index)}
              {text2Show && (
                <>
                  <Typography
                    color="text.secondary"
                    variant="body2"
                    gutterBottom
                  >
                    {text2Caption}
                  </Typography>
                  <FBTextBox
                    id={`${id}-${index}-${propNames.text2}`}
                    control={control}
                    required={required}
                    onChange={(e) => {
                      inputChangeHandler(
                        e,
                        `${id}-${index}-${propNames.text2}`
                      );
                    }}
                    value={formValues[`${id}-${index}-${propNames.text2}`]}
                  />
                </>
              )}
              {text3Show && (
                <>
                  <Typography
                    color="text.secondary"
                    variant="body2"
                    gutterBottom
                  >
                    {text3Caption}
                  </Typography>
                  <FBTextBox
                    id={`${id}-${index}-${propNames.text3}`}
                    control={control}
                    required={required}
                    onChange={(e) => {
                      inputChangeHandler(
                        e,
                        `${id}-${index}-${propNames.text3}`
                      );
                    }}
                    value={formValues[`${id}-${index}-${propNames.text3}`]}
                  />
                </>
              )}
              {providerShow && (
                <>
                  <Typography
                    color="text.secondary"
                    variant="body2"
                    gutterBottom
                  >
                    {providerCaption}
                  </Typography>
                  {/* <FBComboBox */}
                  <FBProvider
                    id={`${id}-${index}-${propNames.provider}`}
                    control={control}
                    required={required}
                    tunnelToParent={{
                      setValue,
                    }}
                    onChange={(e) => {
                      inputChangeHandler(
                        e,
                        `${id}-${index}-${propNames.provider}`
                      );
                    }}
                  />
                </>
              )}
            </Box>
          );
        })}
    </Box>
  );
};

export default FBMultiDateDescLanding;
